import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';

const version = 'v1';
const endpointSolicitacoes = `/${version}/solicitacoes`;

export const RELATORIO_FLUXO_RELACIONAMENTO_EXCEL = `${endpointSolicitacoes}/fluxo-relacionamento/excel`;
export const ENDPOINT_CADASTRO_COMPLETO = `${endpointSolicitacoes}/cadastro-completo`;

export function useSolicitacoesService() {
  const { backendGet, backendGetBlob, backendPut } = Sebrae.useBackend();

  const getSolicitacoes = React.useCallback(
    () => backendGet(endpointSolicitacoes),
    [backendGet],
  );

  const getRecuperarSilicitacao = React.useCallback(
    (id) => backendGet(`${endpointSolicitacoes}/${id}`),
    [backendGet],
  );

  const getListagemDatasConsulta = React.useCallback(
    () => backendGet(`${endpointSolicitacoes}/listagemDatasConsulta`),
    [backendGet],
  );

  const getRelatorioCadastroCompleto = React.useCallback(
    (params) =>
      backendGetBlob(`${endpointSolicitacoes}/cadastro-completo/excel`, params),
    [backendGetBlob],
  );

  const getSituacoes = React.useCallback(
    () => backendGet(`${endpointSolicitacoes}/situacoes`),
    [backendGet],
  );

  const getFinalidades = React.useCallback(
    () => backendGet(`${endpointSolicitacoes}/finalidades`),
    [backendGet],
  );

  const putVincular = React.useCallback(
    (vinculoDto, queryParams) =>
      backendPut(`${endpointSolicitacoes}/vincular?${queryParams}`, vinculoDto),
    [backendPut],
  );

  const getFluxoRelacionamento = React.useCallback(
    (params) =>
      backendGet(`${endpointSolicitacoes}/fluxo-relacionamento`, params),
    [backendGet],
  );

  const getFluxoRelacionamentoQuantitativo = React.useCallback(
    (params) =>
      backendGet(
        `${endpointSolicitacoes}/fluxo-relacionamento/quantitativo`,
        params,
      ),
    [backendGet],
  );

  const getFluxoRelacionamentoAgrupado = React.useCallback(
    (params) =>
      backendGet(
        `${endpointSolicitacoes}/fluxo-relacionamento/agrupado`,
        params,
      ),
    [backendGet],
  );

  return {
    getSolicitacoes,
    getFinalidades,
    putVincular,
    getFluxoRelacionamento,
    getRecuperarSilicitacao,
    getRelatorioCadastroCompleto,
    getListagemDatasConsulta,
    getSituacoes,
    getFluxoRelacionamentoQuantitativo,
    getFluxoRelacionamentoAgrupado,
  };
}
