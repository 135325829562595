import * as React from 'react';
import { TableInstance, TableState } from 'react-table';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { BaSeTheme } from '../../../theme';
import { BaSeShapeButton } from '../../button/shape-button/shape-button';
import { BaSeSelect, SelectValue } from '../../select/select';
import { BaSeSmall1 } from '../../typography/small/small1';
import { BaSeText } from '../../typography/text/text';
import { DataInterfaceProps } from '../data-styled';
import { PageReducedRange } from '../data-utils';
import { DataHeaderSelectionProps } from '../selection/header-selection';
import {
  DataPaginationController,
  DataPaginationFixedItem,
  DataPaginationWrapper,
} from './pagination-styled';

export interface DataPaginationProps<Item extends object = {}>
  extends Pick<
      TableInstance<Item>,
      | 'canNextPage'
      | 'canPreviousPage'
      | 'gotoPage'
      | 'nextPage'
      | 'page'
      | 'pageCount'
      | 'previousPage'
      | 'setPageSize'
    >,
    Pick<TableState<Item>, 'pageSize' | 'pageIndex'>,
    Pick<DataInterfaceProps, 'highlightedColor' | 'foregroundColor'>,
    Pick<DataHeaderSelectionProps<Item>, 'genderOfItemDescription'> {
  total: number;
  pageReducedRange: PageReducedRange;
  compactMode?: boolean;
  itemsPerPage?: number[];
  notFoundMessage?: string;
  singularItemDescription?: string;
  pluralItemDescription?: string;
  setDataPageSize(pageSize: number): void;
}

export function BaSeDataPagination<Item extends object = {}>({
  canNextPage,
  canPreviousPage,
  page,
  pageCount,
  pageIndex,
  pageReducedRange,
  pageSize,
  total,
  notFoundMessage,
  singularItemDescription,
  pluralItemDescription,
  genderOfItemDescription = 'male',
  highlightedColor = BaSeTheme.colors.institucionais.azulSebrae,
  foregroundColor = BaSeTheme.colors.institucionais.cinzaSebrae60,
  itemsPerPage = [5, 10, 15],
  compactMode = false,
  gotoPage,
  nextPage,
  previousPage,
  setDataPageSize,
  setPageSize,
}: DataPaginationProps<Item>): JSX.Element {
  const { getMessage } = React.useContext(BaSeI18nContext);

  return (
    <DataPaginationWrapper>
      {page ? (
        <>
          <BaSeSmall1 color={foregroundColor}>
            {total === 0
              ? notFoundMessage ??
                getMessage(
                  'dataPagination.noItemsToDisplay',
                  pluralItemDescription ??
                    getMessage('dataItem.pluralItemDescription'),
                )
              : total === 1
                ? getMessage(
                    'dataPagination.showingOneItems',
                    singularItemDescription ??
                      getMessage('dataItem.singularItemDescription'),
                  )
                : total === page.length
                  ? getMessage(
                      `dataPagination.showingAllItems.${genderOfItemDescription}`,
                      total,
                      pluralItemDescription ??
                        getMessage('dataItem.pluralItemDescription'),
                    )
                  : getMessage(
                      'dataPagination.showingItems',
                      page.length,
                      total,
                      pluralItemDescription ??
                        getMessage('dataItem.pluralItemDescription'),
                    )}
          </BaSeSmall1>
          {total > 0 && (
            <DataPaginationController>
              {compactMode ? (
                <>
                  <BaSeShapeButton
                    type="tertiary"
                    color={foregroundColor}
                    onClick={() => previousPage()}
                    isDisabled={!canPreviousPage}
                    sizeIcon="big"
                    nameIcon="angle-left"
                  />

                  <BaSeShapeButton
                    type="tertiary"
                    color={foregroundColor}
                    onClick={() => nextPage()}
                    isDisabled={!canNextPage}
                    sizeIcon="big"
                    nameIcon="angle-right"
                  />
                </>
              ) : (
                <>
                  {pageReducedRange?.length > 1 && (
                    <>
                      <DataPaginationFixedItem>
                        <BaSeShapeButton
                          type="tertiary"
                          color={foregroundColor}
                          size="small"
                          sizeIcon="small"
                          onClick={() => gotoPage(0)}
                          isDisabled={!canPreviousPage}
                          nameIcon="angle-double-left"
                        />
                      </DataPaginationFixedItem>
                      <DataPaginationFixedItem>
                        <BaSeShapeButton
                          type="tertiary"
                          color={foregroundColor}
                          size="small"
                          sizeIcon="small"
                          onClick={() => previousPage()}
                          isDisabled={!canPreviousPage}
                          nameIcon="angle-left"
                        />
                      </DataPaginationFixedItem>
                      <span style={{ display: 'flex', gap: '4px' }}>
                        {pageReducedRange.map((pageOption, index) => (
                          <DataPaginationFixedItem
                            key={`${pageOption}-${index}`}
                          >
                            {typeof pageOption === 'number' ? (
                              <BaSeShapeButton
                                type={
                                  pageOption === pageIndex
                                    ? 'primary'
                                    : 'tertiary'
                                }
                                color={
                                  pageOption === pageIndex
                                    ? highlightedColor
                                    : foregroundColor
                                }
                                size="small"
                                sizeIcon="small"
                                onClick={() => gotoPage(pageOption)}
                                value={pageOption + 1 + ''}
                              />
                            ) : (
                              <BaSeText color={foregroundColor}>
                                {pageOption}
                              </BaSeText>
                            )}
                          </DataPaginationFixedItem>
                        ))}
                      </span>
                      <DataPaginationFixedItem>
                        <BaSeShapeButton
                          type="tertiary"
                          color={foregroundColor}
                          size="small"
                          sizeIcon="small"
                          onClick={() => nextPage()}
                          isDisabled={!canNextPage}
                          nameIcon="angle-right"
                        />
                      </DataPaginationFixedItem>
                      <DataPaginationFixedItem>
                        <BaSeShapeButton
                          type="tertiary"
                          color={foregroundColor}
                          size="small"
                          sizeIcon="small"
                          onClick={() => gotoPage(pageCount - 1)}
                          isDisabled={!canNextPage}
                          nameIcon="angle-double-right"
                        />
                      </DataPaginationFixedItem>
                    </>
                  )}
                  <BaSeSmall1 color={foregroundColor}>
                    {getMessage('dataPagination.perPage')}
                  </BaSeSmall1>
                  <BaSeSelect
                    size="small"
                    width="70px"
                    color={foregroundColor}
                    removeEmptyValue={true}
                    selectedValueId={pageSize}
                    values={itemsPerPage.map((itemPageSize) => ({
                      id: +itemPageSize,
                      label: itemPageSize + '',
                    }))}
                    onChange={({ id: itemPageSize }: SelectValue) => {
                      if (itemPageSize) {
                        setPageSize(+itemPageSize);
                        setDataPageSize(+itemPageSize);
                      }
                    }}
                  />
                </>
              )}
            </DataPaginationController>
          )}
        </>
      ) : (
        <BaSeSmall1 color={foregroundColor}>
          {total === 0
            ? notFoundMessage ??
              getMessage(
                'dataPagination.noItemsToDisplay',
                pluralItemDescription ??
                  getMessage('dataItem.pluralItemDescription'),
              )
            : total === 1
              ? getMessage(
                  'dataPagination.showingOneItems',
                  singularItemDescription ??
                    getMessage('dataItem.singularItemDescription'),
                )
              : getMessage(
                  'dataPagination.showingMoreThanOneItem',
                  total,
                  pluralItemDescription ??
                    getMessage('dataItem.pluralItemDescription'),
                )}
        </BaSeSmall1>
      )}
    </DataPaginationWrapper>
  );
}
