import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { elevatedCss, getShadow, ShadownizedProps } from '../../theme/shadows';
import { ThemeColorValue, ThemeShadowLevel } from '../../theme/theme-interface';
import { transformLightness } from '../../utils/color-transformation/transform-lightness';
import {
  calculed,
  gridTemplateAreaSideBySide,
  gridTemplateAreaStacked,
  gridTemplateAreaWide,
} from './card-utils';
export interface CardSliceCheckProps {
  hideHeader: boolean;
  hideContentInfo: boolean;
  hideContentCategory: boolean;
  hideContentTag: boolean;
  hideContentAction: boolean;
  onlyHeader?: boolean;
}
export interface CardAppearenceProps extends ShadownizedProps {
  disposition: 'side-by-side' | 'stacked' | 'wide';
  withSideDestak: boolean;
  height: string;
  width: string;
  shadowLevel: ThemeShadowLevel;
  destakColor: ThemeColorValue;
  borderColor: ThemeColorValue;
  backgroundColor: ThemeColorValue;
  backgroundImage?: string;
}

export interface ContentTagProps {
  isCollapsed?: boolean;
  contentTagGrouped?: boolean;
  contentTagExpandable?: boolean;
  contentTagHeight?: string;
}

export interface CardWrapperProps
  extends CardSliceCheckProps,
    CardAppearenceProps {}

type CardArea = 'he' | 'ci' | 'cc' | 'ct' | 'ca';

const gridAreaMapper = ({
  disposition = 'stacked',
  // eslint-disable-next-line comma-dangle
  ...sliceCheck
}: CardSliceCheckProps & Pick<CardAppearenceProps, 'disposition'>) =>
  ({
    stacked: css`
      grid-template-areas: ${gridTemplateAreaStacked(sliceCheck)};
    `,
    'side-by-side': css`
      grid-template-areas: ${gridTemplateAreaSideBySide(sliceCheck)};
    `,
    wide: css`
      grid-template-areas: ${gridTemplateAreaWide(sliceCheck)};
    `,
  })[disposition];

const cardSlice = (area: CardArea) => css`
  grid-area: ${area};
  place-self: stretch;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  background-color: transparent;
`;

export const CardHeader = styled.header.attrs({
  className: 'BaSe--card-header',
})<
  Pick<CardAppearenceProps, 'disposition' | 'withSideDestak'> &
    Pick<CardSliceCheckProps, 'onlyHeader'>
>`
  &.BaSe--card-header {
    ${cardSlice('he')}

    ${({ disposition, withSideDestak }) =>
      disposition === 'stacked'
        ? css`
            border-top-left-radius: ${withSideDestak ? '6px' : '12px'};
            border-top-right-radius: 12px;
          `
        : css`
            border-top-left-radius: ${withSideDestak ? '6px' : '12px'};
            border-bottom-left-radius: ${withSideDestak ? '6px' : '12px'};
          `}

    ${({ onlyHeader, withSideDestak }) =>
      onlyHeader &&
      css`
        border-bottom-left-radius: ${withSideDestak ? '6px' : '12px'};
        border-bottom-right-radius: 12px;
      `}

    padding: 12px;
  }
`;

export const CardContentInfo = styled.div.attrs({
  className: 'BaSe--card-content-info',
})`
  ${cardSlice('ci')}

  padding: 24px;
`;

export const CardContentCategoryItem = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
`;

export const CardContentCategory = styled.div.attrs({
  className: 'BaSe--card-content-category',
})<
  Pick<CardAppearenceProps, 'disposition'> &
    Pick<CardSliceCheckProps, 'hideHeader'>
>`
  ${cardSlice('cc')}

  ${({ disposition, hideHeader }) =>
    disposition === 'stacked' && hideHeader
      ? css`
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        `
      : disposition === 'side-by-side' &&
        css`
          border-top-right-radius: 12px;
        `}

  padding: 12px;
  gap: 12px;
`;

export const ContentTag = styled.li`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
`;

export const CardTagContainer = styled.div.attrs({
  className: 'BaSe--card-content-tag',
})<Pick<CardAppearenceProps, 'disposition'>>`
  ${cardSlice('ct')}
  display: block;
  width: 100%;

  ${({ disposition }) =>
    disposition === 'wide' &&
    css`
      border-bottom-left-radius: 0.75em;
      border-bottom-right-radius: 0.75em;
    `}
`;

export const CardTagWrapper = styled.ul<ContentTagProps>`
  position: relative;
  padding: 1.5em 3.75em 1.5em 1.5em;
  max-height: 100%;
  flex-wrap: wrap;
  gap: 1.5em;
  display: ${({ isCollapsed, contentTagExpandable }) =>
    isCollapsed || contentTagExpandable ? 'flex' : 'grid'};

  ${({ contentTagGrouped, isCollapsed }) =>
    contentTagGrouped &&
    (isCollapsed
      ? css`
          ${ContentTag}:not(:nth-last-of-type(2))::after {
            margin-left: 0.5em;
            content: '|';
            color: ${BaSeTheme.colors.institucionais.cinzaSebrae75};
          }
        `
      : css`
          ${ContentTag} {
            flex: 1 100%;
          }
        `)}

  ${({ contentTagExpandable, contentTagHeight, isCollapsed }) =>
    isCollapsed &&
    contentTagHeight &&
    contentTagExpandable &&
    css`
      max-height: calc(${contentTagHeight} - 0.5em);
      overflow-y: hidden;
    `}
`;

export const TagButtonWrapper = styled.li`
  position: absolute;
  top: 18px;
  right: 24px;
`;

export const ContentTagSeparator = styled.hr`
  margin: 0;
  border: none;
  width: 100%;
  height: 0.5px;
  margin-top: 8px;
  background: ${BaSeTheme.colors.institucionais.cinzaSebrae90};
`;

export const CardContentAction = styled.footer.attrs({
  className: 'BaSe--card-content-action',
})`
  &.BaSe--card-content-action {
    ${cardSlice('ca')}

    padding: 12px;
    gap: 12px;
  }
`;

export const CardWrapper = styled.section.attrs({
  className: 'BaSe--card',
})<CardWrapperProps>`
  &.BaSe--card {
    will-change: box-shadow, transform, border-left-width;
    display: grid;
    box-sizing: content-box;
    border-radius: 12px;
    position: relative;

    transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};

    ${gridAreaMapper}

    ${({ withSideDestak, height, width }) => css`
      height: ${calculed({ size: height, correction: 2 })};
      width: ${calculed({ size: width, correction: withSideDestak ? 5 : 2 })};
    `}

  ${({
      withSideDestak,
      destakColor,
      backgroundColor,
      borderColor,
      disposition,
      backgroundImage,
      hideHeader,
      hideContentAction,
      hideContentTag,
      hideContentCategory,
    }) => css`
      background-color: ${backgroundColor};
      border: 1px solid ${borderColor};

      ${hideHeader &&
      backgroundImage &&
      css`
        background-image: url('${backgroundImage}');
        background-repeat: no-repeat;
        background-size: cover;
      `}

      ${withSideDestak &&
      css`
        border-left: 4px solid ${destakColor};
      `}

    ${!hideHeader &&
      css`
        & > ${CardHeader} {
          background-color: ${destakColor};
          background-image: url('${backgroundImage}');
          background-repeat: no-repeat;
          background-size: cover;
        }
      `}

    ${disposition === 'wide'
        ? css`
            ${!hideContentAction &&
            css`
              & > ${CardContentAction} {
                align-self: flex-start;
                justify-self: flex-end;
              }
            `}

            ${!hideContentTag &&
            css`
              & > ${CardTagContainer} {
                box-shadow:
                  8px 0 0 0 ${backgroundColor} inset,
                  -8px 0 0 0 ${backgroundColor} inset,
                  0 2px 0 0 ${borderColor} inset;
              }
            `}
          `
        : css`
            ${!hideContentCategory &&
            css`
              & > ${CardContentCategory} {
                background-color: ${transformLightness(backgroundColor, 0.96)};
              }
            `}
          `}
    `}

  ${({ shadowLevel, elevationOnHoverOrFocus, isElevated }) =>
      shadowLevel &&
      css`
        box-shadow: ${getShadow(shadowLevel)};

        ${isElevated && elevatedCss}

        ${elevationOnHoverOrFocus &&
        css`
          &:hover,
          &:focus-within {
            ${elevatedCss}
          }
        `}
      `};
  }
`;
