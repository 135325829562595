import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import Hammer from 'react-hammerjs';
import { useMenuItemsShrink } from '../../hooks/menu-items-shrink';
import { BaSeTheme } from '../../theme';
import {
  EventBreakpointType,
  ThemeBreakpointType,
  ThemeColorValue,
} from '../../theme/theme-interface';
import { objectNotEmpty } from '../../utils/object-utils';
import { A11yBarProps, BaSeA11yBar } from '../a11y-bar/a11y-bar';
import { BaSeAccountProfile } from '../account-profile/account-profile';
import { AccountProfileProps } from '../account-profile/account-profile-props';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { BaSeLogoSebrae } from '../image/logo-sebrae/logo-sebrae';
import { BaSeLogoSebraeInverse } from '../image/logo-sebrae/logo-sebrae-inverse';
import { BaSeUserApps, SolutionsProps } from '../solutions/user-apps';
import { BaSeHeading5 } from '../typography/heading/heading5';
import { BaSeParagraph } from '../typography/paragraph/paragraph';
import { BaSeText } from '../typography/text/text';
import {
  HeaderCloseIconContent,
  HeaderCollapsedContainer,
  HeaderCollapsedContent,
  HeaderCollapsedMenuItemContainer,
  HeaderCollapsedMenuItemNonNav,
  HeaderCollapsedTransparentContainer,
  HeaderContainer,
  HeaderExpandedComponentsContainer,
  HeaderExpandedMenuItemContainer,
  HeaderExpandedMenuItemsContainer,
  HeaderFlex,
  HeaderGhostContainer,
  HeaderMenuIconContainer,
  HeaderOutsideContainer,
  HeaderSideMenu,
  SubHeaderContainer,
} from './header-styled';

export interface HeaderMenuItem {
  label?: string;
  onClick?: (
    e:
      | React.MouseEvent<HTMLDivElement>
      | React.MouseEvent<HTMLLIElement>
      | React.KeyboardEvent<HTMLLIElement>,
  ) => void;
  component?: (closeMenu: () => void) => JSX.Element;
  hideOnSidebar?: boolean;
}

export interface HeaderCustomComponents {
  component: (closeMenu: () => void) => JSX.Element;
  showOnExpanded?: boolean;
  showOnCollapsed?: boolean;
  showOnSidebar?: boolean;
  showOnSubmenu?: boolean;
}

export interface SubmenuInterface {
  open?: boolean;
  backgroundColor?: string;
  collapsedBreakpoint?: EventBreakpointType;
}

export interface HeaderProps {
  menuItens?: HeaderMenuItem[];
  textColor?: ThemeColorValue;
  backgroundColor?: ThemeColorValue;
  sidebarBackgroundColor?: ThemeColorValue;
  sidebarTextColor?: ThemeColorValue;
  systemName: string;
  accountProfileProps?: AccountProfileProps;
  customComponents?: HeaderCustomComponents[];
  logoOnClick?: (
    e:
      | React.MouseEvent<HTMLImageElement, MouseEvent>
      | React.KeyboardEvent<HTMLImageElement>,
  ) => void;
  collapsedBreakpoint?: EventBreakpointType;
  systemLogo?: () => JSX.Element;
  logoInverseColor?: boolean;
  submenuProps?: SubmenuInterface;
  delimitedOnBreakpoint?: ThemeBreakpointType;
  withA11yBar?: boolean;
  a11yBarProps?: Partial<A11yBarProps>;
  hasBoxShadow?: boolean;
  solutionsProps?: SolutionsProps;
}

const animationTime = 250;
const LEFT_TO_RIGHT = 4;
const RIGHT_TO_LEFT = 2;

export const BaSeHeader: React.FC<HeaderProps> = ({
  menuItens = [],
  textColor = BaSeTheme.colors.contrast.institucionais.azulSebrae36,
  backgroundColor = BaSeTheme.colors.institucionais.azulSebrae36,
  sidebarBackgroundColor = BaSeTheme.colors.contrast.institucionais
    .azulSebrae36,
  sidebarTextColor = BaSeTheme.colors.institucionais.azulSebrae36,
  systemName = '',
  accountProfileProps,
  customComponents = [],
  collapsedBreakpoint = 'onTablet',
  logoOnClick,
  systemLogo,
  logoInverseColor = true,
  submenuProps = {},
  delimitedOnBreakpoint,
  withA11yBar = false,
  a11yBarProps,
  hasBoxShadow = true,
  solutionsProps,
}) => {
  const {
    open: submenuOpen = false,
    backgroundColor: submenuBackgroundColor,
    collapsedBreakpoint: submenuCollapsedBreakpoint,
  } = submenuProps;

  const [menuDisplay, setMenuDisplay] = React.useState<boolean>(false);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [submenuOpenQuery, setSubmenuOpenQuery] =
    React.useState<boolean>(false);
  const [classClosed, setClassClosed] = React.useState<string>('');

  const handleMenuAnimations = React.useCallback(
    (force = false) => {
      if (force || menuOpen) {
        setClassClosed('closed');
        setTimeout(() => {
          setMenuOpen(false);
          setClassClosed('');
        }, animationTime);
      } else {
        setMenuOpen(true);
      }
    },
    [menuOpen],
  );

  const handleDelimiterBreakpoint = React.useCallback(() => {
    if (
      delimitedOnBreakpoint &&
      delimitedOnBreakpoint in BaSeTheme.breakpoints
    ) {
      return BaSeTheme.breakpoints[delimitedOnBreakpoint].minWidth;
    }
    return;
  }, [delimitedOnBreakpoint]);

  const handleDeactivateFocusTrap = React.useCallback(() => {
    handleMenuAnimations(true);
  }, []);

  const handleClickMenuItem = React.useCallback(
    (
      item: HeaderMenuItem,
      e:
        | React.MouseEvent<HTMLDivElement>
        | React.MouseEvent<HTMLLIElement>
        | React.KeyboardEvent<HTMLLIElement>,
    ) => {
      if (item.onClick) {
        item.onClick(e);
        handleMenuAnimations(true);
      }
    },
    [],
  );

  useMenuItemsShrink(setMenuDisplay, collapsedBreakpoint);
  useMenuItemsShrink(setSubmenuOpenQuery, submenuCollapsedBreakpoint);

  const delayTime = React.useCallback((index: number) => 100 + index * 50, []);

  const handleSwipe = React.useCallback(
    (swipe: HammerInput, direction: number) => {
      if (swipe.direction === direction) {
        handleMenuAnimations();
      }
    },
    [handleMenuAnimations],
  );

  const SystemLogoMemo = React.useMemo(() => {
    return (
      systemLogo?.() ??
      (logoInverseColor ? (
        <BaSeLogoSebraeInverse
          easterEgg={true}
          onClick={logoOnClick}
          width={68}
        />
      ) : (
        <BaSeLogoSebrae easterEgg={true} onClick={logoOnClick} width={68} />
      ))
    );
  }, [systemLogo, logoInverseColor]); // TODO add estaerEgg props no array do memo

  React.useEffect(() => {
    if (accountProfileProps) {
      accountProfileProps.clientName ??= systemName;
    }
  }, [accountProfileProps, systemName]);

  return (
    <>
      {withA11yBar && (
        <BaSeA11yBar
          delimitedOnBreakpoint={delimitedOnBreakpoint}
          {...a11yBarProps}
        />
      )}
      <HeaderOutsideContainer
        hasBoxShadow={hasBoxShadow}
        className="BaSe--header"
        color={backgroundColor}
      >
        <HeaderContainer
          textColor={textColor}
          hasItems={menuItens.length > 0}
          mobile={menuDisplay}
          maxWidth={handleDelimiterBreakpoint()}
        >
          {menuDisplay && menuItens.length > 0 && (
            <HeaderFlex alignStart={true}>
              <HeaderMenuIconContainer>
                <BaSeShapeButton
                  nameIcon="bars"
                  type="tertiary"
                  buttonType="button"
                  size="medium"
                  sizeIcon="medium"
                  color={textColor}
                  onClick={() => handleMenuAnimations()}
                />
              </HeaderMenuIconContainer>
              {customComponents
                .filter((widget) => widget.showOnCollapsed)
                .map((item, key) => (
                  <HeaderFlex alignStart={true} fullWidth={true} key={key}>
                    {item.component(() => {})}
                  </HeaderFlex>
                ))}
            </HeaderFlex>
          )}
          <HeaderFlex alignStart={menuItens.length === 0} gap={16}>
            {SystemLogoMemo}
            {!menuDisplay && (
              <HeaderExpandedComponentsContainer>
                {customComponents
                  .filter((widget) => widget.showOnExpanded)
                  .map((item, key) => (
                    <HeaderFlex fullWidth={true} key={key}>
                      {item.component(() => {})}
                    </HeaderFlex>
                  ))}
              </HeaderExpandedComponentsContainer>
            )}
            {menuDisplay &&
              menuItens.length === 0 &&
              customComponents
                .filter((widget) => widget.showOnCollapsed)
                .map((item, key) => (
                  <HeaderFlex alignStart={true} fullWidth={true} key={key}>
                    {item.component(() => {})}
                  </HeaderFlex>
                ))}
          </HeaderFlex>
          <HeaderFlex fullHeight={true} alignEnd={menuDisplay}>
            {!menuDisplay && (
              <HeaderExpandedMenuItemsContainer
                showBorder={menuItens.length > 0 && !!accountProfileProps}
                color={textColor}
                role="menu"
              >
                {menuItens.map((item, index) => (
                  <HeaderExpandedMenuItemContainer
                    key={index}
                    role="menuitem"
                    tabIndex={!!item.onClick ? 0 : undefined}
                    onKeyDown={(e: React.KeyboardEvent<HTMLLIElement>) =>
                      e.key === 'Enter' && item?.onClick?.(e)
                    }
                    onClick={item.onClick}
                    color={textColor}
                  >
                    {item?.component?.(() => {}) ?? (
                      <BaSeParagraph color={textColor}>
                        {item.label}
                      </BaSeParagraph>
                    )}
                  </HeaderExpandedMenuItemContainer>
                ))}
              </HeaderExpandedMenuItemsContainer>
            )}
            {objectNotEmpty(solutionsProps) && (
              <BaSeUserApps {...(solutionsProps as SolutionsProps)} />
            )}
            {accountProfileProps && (
              <BaSeAccountProfile
                foregroundColor={textColor}
                backgroundColor={backgroundColor}
                {...accountProfileProps}
                buttonCollapsed={menuDisplay}
              />
            )}
          </HeaderFlex>
        </HeaderContainer>
      </HeaderOutsideContainer>
      {
        <SubHeaderContainer
          open={submenuOpen && submenuOpenQuery}
          color={submenuBackgroundColor}
        >
          {customComponents
            .filter((widget) => widget.showOnSubmenu)
            .map((item, key) => (
              <HeaderFlex key={key}>{item.component(() => {})}</HeaderFlex>
            ))}
        </SubHeaderContainer>
      }
      {menuDisplay && (
        <Hammer onSwipe={(e) => handleSwipe(e, LEFT_TO_RIGHT)}>
          <HeaderGhostContainer />
        </Hammer>
      )}
      {menuOpen && menuDisplay && (
        <Hammer onSwipe={(e) => handleSwipe(e, RIGHT_TO_LEFT)}>
          <HeaderCollapsedContainer>
            <HeaderCollapsedTransparentContainer />
            <FocusTrap
              focusTrapOptions={{
                delayInitialFocus: true,
                escapeDeactivates: true,
                clickOutsideDeactivates: true,
                onDeactivate: handleDeactivateFocusTrap,
              }}
            >
              <HeaderCollapsedContent
                className={`BaSe--header-collapsed-content ${classClosed}`}
              >
                <HeaderSideMenu
                  sidebarBackgroundColor={
                    sidebarBackgroundColor ||
                    BaSeTheme.colors.defaultColors.white
                  }
                >
                  <BaSeHeading5 isBold={true} color={sidebarTextColor}>
                    {systemName}
                  </BaSeHeading5>
                  <HeaderCollapsedMenuItemNonNav>
                    {customComponents
                      .filter((widget) => widget.showOnSidebar)
                      .map((item, key) => (
                        <HeaderCollapsedMenuItemContainer
                          as="div"
                          delay={100}
                          key={key}
                          hasClick={false}
                        >
                          {item.component(() => handleMenuAnimations())}
                        </HeaderCollapsedMenuItemContainer>
                      ))}
                  </HeaderCollapsedMenuItemNonNav>
                  <nav>
                    <ul role="menu">
                      {menuItens
                        .filter((i) => !i.hideOnSidebar)
                        .map((item, index) => (
                          <HeaderCollapsedMenuItemContainer
                            color={sidebarTextColor}
                            tabIndex={!!item.onClick ? 0 : undefined}
                            key={index}
                            role="menuitem"
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLLIElement>,
                            ) =>
                              e.key === 'Enter' && handleClickMenuItem(item, e)
                            }
                            onClick={(e: React.MouseEvent<HTMLLIElement>) =>
                              handleClickMenuItem(item, e)
                            }
                            hasClick={!!item.onClick}
                            delay={delayTime(index)}
                          >
                            {item?.component?.(() =>
                              handleMenuAnimations(),
                            ) ?? (
                              <BaSeText color={sidebarTextColor}>
                                {item.label}
                              </BaSeText>
                            )}
                          </HeaderCollapsedMenuItemContainer>
                        ))}
                    </ul>
                  </nav>
                </HeaderSideMenu>
                <HeaderCloseIconContent
                  buttonBackgroundColor={sidebarBackgroundColor}
                >
                  <BaSeShapeButton
                    onClick={() => handleMenuAnimations()}
                    buttonType="button"
                    nameIcon="close"
                    shape="circle"
                    size="medium"
                    sizeIcon="medium"
                    type="tertiary"
                    color={sidebarTextColor}
                  />
                </HeaderCloseIconContent>
              </HeaderCollapsedContent>
            </FocusTrap>
          </HeaderCollapsedContainer>
        </Hammer>
      )}
    </>
  );
};

BaSeHeader.displayName = 'BaSeHeader';
