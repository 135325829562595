import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { numberWithUnit } from '../../utils/string-utils';
import { transformLightness } from '../../utils/color-transformation/transform-lightness';

export type DrawerAnchor = 'left' | 'right' | 'bottom' | 'top';

export interface DrawerProps {
  backgroundColor: string;
  foregroundColor: string;
  open: boolean;
  anchor: DrawerAnchor;
  pullerPosition?: 'start' | 'middle' | 'end';
  height: number | string;
  width: number | string;
}

const {
  colors: {
    defaultColors: { shadowColor },
  },
  transitions: { durationFast, allPropertiesContinuousSlow },
  stackOrder: { normal: zIndexNormal },
} = BaSeTheme;

function isInPosition(anchor: DrawerAnchor, pos: DrawerAnchor[]): boolean {
  return pos.includes(anchor);
}
function isHorizontalPosition(anchor: DrawerAnchor): boolean {
  return isInPosition(anchor, ['left', 'right']);
}

export const DrawerWrapper = styled.div.attrs({
  className: 'BaSe--drawer',
})<Pick<DrawerProps, 'anchor' | 'pullerPosition' | 'height' | 'width'>>`
  overflow: hidden;
  display: flex;

  flex-direction: ${({ anchor }) =>
    `${isHorizontalPosition(anchor) ? 'row' : 'column'}${
      isInPosition(anchor, ['right', 'bottom']) ? '-reverse' : ''
    }`};
  ${({ pullerPosition, anchor, height, width }) => css`
    flex-direction: ${isHorizontalPosition(anchor) ? 'row' : 'column'}${isInPosition(anchor, ['right', 'bottom']) ? '-reverse' : ''};
    ${isHorizontalPosition(anchor)
      ? `height: ${numberWithUnit(height)}`
      : `width: ${numberWithUnit(width)}`};
    align-items: ${pullerPosition === 'start'
      ? 'flex-start'
      : pullerPosition === 'end'
        ? 'flex-end'
        : 'center'};
  `};
`;

export const DrawerContent = styled.div.attrs({
  className: 'BaSe--drawer-content',
})<
  Pick<DrawerProps, 'backgroundColor' | 'open' | 'height' | 'width' | 'anchor'>
>`
  will-change: margin-left, margin-right, margin-bottom;
  overflow: hidden;
  transition: ${allPropertiesContinuousSlow};
  z-index: ${zIndexNormal};
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ height }) => numberWithUnit(height)};
  width: ${({ width }) => numberWithUnit(width)};
  ${({ open, width, height, anchor }) => css`
    box-shadow: ${
      isHorizontalPosition(anchor) ? '0 8px' : '8px 0'
    } 12px ${shadowColor};

    margin-${anchor}: -${
      open ? '0' : numberWithUnit(isHorizontalPosition(anchor) ? width : height)
    }`}
`;

export const DrawerPuller = styled.button.attrs({
  className: 'BaSe--drawer-puller',
})<
  Pick<DrawerProps, 'anchor' | 'open' | 'backgroundColor' | 'foregroundColor'>
>`
  will-change: background-color, box-shadow;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  display: grid;
  place-items: center;

  z-index: ${zIndexNormal + 1};

  box-shadow: ${({ anchor: anchor }) => `
    ${
      isInPosition(anchor, ['top', 'bottom'])
        ? '0'
        : `${isInPosition(anchor, ['left']) ? '2px' : '-2px'}`
    }
    ${
      isInPosition(anchor, ['right', 'left'])
        ? '0'
        : `${isInPosition(anchor, ['top']) ? '2px' : '-2px'}`
    }`}
    4px ${shadowColor};

  margin: ${({ anchor: anchor }) => `
    ${
      isInPosition(anchor, ['right', 'left'])
        ? '30px'
        : `${isInPosition(anchor, ['bottom']) ? '4px' : '0'}`
    }
    ${
      isInPosition(anchor, ['top', 'bottom'])
        ? '30px'
        : `${isInPosition(anchor, ['left']) ? '4px' : '0'}`
    }
    ${
      isInPosition(anchor, ['right', 'left'])
        ? '30px'
        : `${isInPosition(anchor, ['top']) ? '4px' : '0'}`
    }
    ${
      isInPosition(anchor, ['top', 'bottom'])
        ? '30px'
        : `${isInPosition(anchor, ['right']) ? '4px' : '0'}`
    }
  `};

  background-color: ${({ backgroundColor }) => backgroundColor};

  &:hover {
    background-color: ${({ foregroundColor }) =>
      transformLightness(foregroundColor, 0.94)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px
      ${({ foregroundColor }) => transformLightness(foregroundColor, 0.93)};
  }

  .BaSe--icon {
    transition: transform ${durationFast};
  }

  height: ${({ anchor: anchor }) =>
    isHorizontalPosition(anchor) ? '48px' : '24px'};

  width: ${({ anchor: anchor }) =>
    isHorizontalPosition(anchor) ? '24px' : '48px'};

  border-radius: ${({ anchor: anchor }) => `
    ${isInPosition(anchor, ['top', 'left']) ? '0' : '16px'}
    ${isInPosition(anchor, ['top', 'right']) ? '0' : '16px'}
    ${isInPosition(anchor, ['bottom', 'right']) ? '0' : '16px'}
    ${isInPosition(anchor, ['bottom', 'left']) ? '0' : '16px'}
  `};

  ${({ open }) =>
    !open &&
    css`
      .BaSe--icon {
        transform: rotate(180deg);
      }
    `}
`;
