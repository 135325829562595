import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../utils/string-utils';
import { BaSeTheme } from '../../theme';

export const initialPosition = 'initial';
export const defaultBorderRadius = '50%';
export const paddingWithIcon = '2px';
export const paddingWithValue = '2px 6px';
export const paddingWithoutAnything = '8px';

interface BadgeStyle {
  right: string | number;
  top: string | number;
  left: string | number;
  bottom: string | number;
  bgC: string;
  border: string;
  color: string;
  borderRadius: string | number;
  padding: string | number;
  width?: string | number;
  height?: string | number;
}

export const Badge = styled.div.attrs({
  className: 'BaSe--badge',
})<BadgeStyle>`
  &.BaSe--badge {
    line-height: 2.25em;
    min-width: ${({ width }) => (width ? numberWithUnit(width) : '4em')};
    min-height: ${({ height }) => (height ? numberWithUnit(height) : '4em')};
    box-sizing: border-box;
    display: flex;
    padding: ${({ padding }) => padding};
    border-radius: ${({ borderRadius }) => numberWithUnit(borderRadius)};
    font-size: 0.5em;
    font-family: ${BaSeTheme.typography.fontFamily};
    align-items: center;
    justify-content: center;
    position: relative;
    border: 2px solid ${({ border }) => border};
    color: ${({ color }) => color};
    background-color: ${({ bgC }) => bgC};
    top: ${({ top }) => numberWithUnit(top)};
    bottom: ${({ bottom }) => numberWithUnit(bottom)};
    left: ${({ left }) => numberWithUnit(left)};
    right: ${({ right }) => numberWithUnit(right)};

    ${({ width }) => css`
      width: ${numberWithUnit(width)};
    `}
    ${({ height }) => css`
      height: ${numberWithUnit(height)};
    `}
  }
`;

export const BadgeContainer = styled.div.attrs({
  className: 'BaSe--badge-container',
})`
  &.BaSe--badge-container {
    display: flex;
    width: fit-content;
  }
`;

export const BadgeContent = styled.div.attrs({
  className: 'BaSe--badge-content',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
`;
