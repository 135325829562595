import * as React from 'react';
import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';

export interface ContrastLevelProps {
  background: string;
  border: string;
}
export interface OptionsWrapperProps {
  background: string;
  border: string;
  isVisible: boolean;
  hasScroll?: boolean;
  left?: string;
}

export interface AccessButtonProps {
  background: string;
  primaryColor: string;
  activeColor: string;
  disabledBackgroundColor: string;
  disabledPrimaryColor: string;
  isDisabled: boolean;
}

export const BarWrapper = styled.section.attrs({
  className: 'BaSe--a11y-bar',
})<{ backgroundColor: string }>`
  padding: 0 0.5em;
  width: calc(100% - 1em);
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const ItemWrapper = styled.div.attrs({
  className: 'BaSe--a11y-bar-item',
})`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.25em;
`;

export const ItemSeparator = styled.hr<{ color: string }>`
  height: 1.23em;
  width: 1px;
  border: none;
  background-color: ${({ color }) => color};
`;

export const InvisibleButton = styled.button.attrs({
  type: 'button',
})<{ onClick?: React.MouseEventHandler }>`
  &,
  &:hover,
  &:focus,
  &:active {
    appearance: none;
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    line-height: 1.23em;

    ${({ onClick }) =>
      onClick &&
      css`
        cursor: pointer;
      `}
  }
`;

export const OptionsWrapper = styled.div.attrs({
  className: 'BaSe--a11y-bar-options',
})<OptionsWrapperProps>`
  position: absolute;
  top: 2em;
  left: 0;
  width: 17.5em;
  border-radius: 0.25em;
  box-sizing: border-box;
  background: ${({ background }) => background};
  z-index: ${BaSeTheme.stackOrder.aboveOverlay};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  /* padding: 1.25em 1em; */
  border: 1px solid ${({ border }) => border};
  ${({ isVisible }) =>
    isVisible
      ? css`
          height: auto;
          /* overflow: auto; */
          box-shadow: ${BaSeTheme.shadows.profundidade8};
          opacity: 1;
        `
      : css`
          height: 0;
          padding: 0;
          overflow: hidden;
          border: none;
          opacity: 0;
        `};
`;

export const OptionsTriangle = styled.div<OptionsWrapperProps>`
  z-index: ${BaSeTheme.stackOrder.normal + 1};
  position: absolute;
  transform: rotate(45deg);
  width: 0.5em;
  height: 0.5em;
  top: -5.5px;
  ${({ background, border, left }) => css`
    background-color: ${background};
    border-top: 1px solid ${border};
    border-left: 1px solid ${border};
    border-top-left-radius: 0.125em;
    left: ${left ?? 'calc(50% - 1.2em)'};
  `}
`;

export const AccessButton = styled.button.attrs({
  type: 'button',
  className: 'BaSe--a11y-bar-button',
})<AccessButtonProps>`
  font-size: 16px;
  border-radius: 0.25em;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  place-items: center;
  width: 7.25em;
  height: 7.25em;
  gap: 1.5em;
  padding: 0.75em 1em;
  box-sizing: border-box;
  cursor: pointer;
  ${({ background, primaryColor, activeColor, isDisabled }) =>
    !isDisabled &&
    css`
      background-color: ${background};
      border: 1px solid ${primaryColor};

      &:hover,
      &:focus {
        background-color: ${activeColor};
      }
    `}
  ${({ isDisabled, disabledBackgroundColor, disabledPrimaryColor }) =>
    isDisabled &&
    css`
      background-color: ${disabledBackgroundColor};
      border: 1px solid ${disabledPrimaryColor};
      cursor: not-allowed;
    `}
`;

export const OptionFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25em 1em 0;
`;

export const GridAcessButton = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1em;
  padding: 1.25em 1em;
`;

export const AcessButtonPrefix = styled.span<{ isVisible: boolean }>`
  ${({ isVisible }) =>
    !isVisible &&
    css`
      visibility: hidden;
    `}
`;

export const ContrastControl = styled.span`
  display: flex;
  gap: 0.25em;
  align-items: center;
`;

export const ContrastLevel = styled.span<ContrastLevelProps>`
  ${({ background, border }) => css`
    border: 1px solid ${border};
    background-color: ${background};
    width: 6px;
    height: 6px;
    border-radius: 12px;
    transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  `}
`;

export const LanguageList = styled.div`
  max-height: 17.5em;
  padding: 0.675em 1em 1.25em;
  margin: 0.675em 0 0 0;
  overflow: auto;
`;
