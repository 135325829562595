import * as React from 'react';
import { FlexibleStringOptions } from '../utils/types';

type SubscriptionHandler<Payload> = (payload?: Payload) => void;

interface MessageEventBus<Event, Payload> extends MessageEvent {
  data: {
    event: Event;
    payload: Payload;
  };
}

interface Subscription {
  unsubscribe(): void;
}

interface HookEventBus {
  publish<Event, Payload>(event: Event, payload?: Payload): void;
  subscribe<Event, Payload>(
    event: Event,
    handler: SubscriptionHandler<Payload>,
  ): Subscription;
}

interface HookEventBusConfig {
  targetWindow?: Window;
  targetOrigin?: FlexibleStringOptions<'*'>;
}

const targetEvent = 'message';

export function useEventBus(config?: HookEventBusConfig): HookEventBus {
  const { targetWindow = window, targetOrigin = '*' } = config ?? {};

  const publish = React.useCallback(
    <Event, Payload>(event: Event, payload: Payload) =>
      targetWindow.postMessage({ event, payload }, targetOrigin as string),
    [targetWindow, targetOrigin],
  );

  const subscribe = React.useCallback(
    <Event, Payload>(event: Event, handler: SubscriptionHandler<Payload>) => {
      const eventHandler = (message: MessageEventBus<Event, Payload>) =>
        (message.data.event === event && handler(message.data.payload)) as void;

      targetWindow.addEventListener(targetEvent, eventHandler);

      return {
        unsubscribe: () =>
          targetWindow.removeEventListener(targetEvent, eventHandler),
      };
    },
    [targetWindow],
  );

  return {
    publish,
    subscribe,
  };
}
