import { BaSeTheme } from '../../theme';
import { CardSliceCheckProps } from './card-styled';

export const colorMapper = {
  default: BaSeTheme.colors.institucionais.azulSebrae36,
  destructive: BaSeTheme.colors.feedbackInterface.erro45,
  attention: BaSeTheme.colors.feedbackInterface.atencao55,
  confirmation: BaSeTheme.colors.feedbackInterface.sucesso26,
};

export function calculed({
  size,
  correction,
}: {
  size?: string;
  correction: number;
}): string {
  return size ? `calc(${size} - ${correction}px)` : 'auto';
}

export function gridTemplateAreaStacked({
  hideHeader = false,
  hideContentInfo = false,
  hideContentCategory = false,
  hideContentTag = false,
  hideContentAction = false,
}: CardSliceCheckProps): string {
  const he = hideHeader ? '' : ' "he" '.repeat(3);
  const cc = hideContentCategory ? '' : ' "cc" ';
  const ct = hideContentTag ? '' : ' "ct" ';
  const ci = hideContentInfo ? '' : ' "ci" '.repeat(4);
  const ca = hideContentAction ? '' : ' "ca" ';
  const lines = [he, cc, ct, ci, ca];
  return lines.join('');
}

export function gridTemplateAreaSideBySide({
  hideHeader = false,
  hideContentInfo = false,
  hideContentCategory = false,
  hideContentTag = false,
  hideContentAction = false,
}: CardSliceCheckProps): string {
  const clearEmptyLine = (line: string) =>
    line.replace(/^('\s+'|'\s+he\s+')$/g, '');
  const he = hideHeader ? '' : ' he ';
  const ci = hideContentInfo ? '' : ' ci '.repeat(2);
  const cc = hideContentCategory ? '' : ' cc '.repeat(2);
  const ct = hideContentTag ? '' : ' ct '.repeat(2);
  const ca = hideContentAction ? '' : ' ca '.repeat(2);
  const he_cc = `'${he} ${cc}'`;
  const he_ct = `'${he} ${ct}'`;
  const he_ci = `'${he} ${ci}'`;
  const he_ca = `'${he} ${ca}'`;
  const lines = [he_cc, he_ct, ...Array(3).fill(he_ci), he_ca];
  return lines.map(clearEmptyLine).join('');
}

export function gridTemplateAreaWide({
  hideHeader = false,
  hideContentInfo = false,
  hideContentCategory = false,
  hideContentTag = false,
}: CardSliceCheckProps): string {
  const clearEmptyLine = (line: string) => line.replace(/.*×.*/g, '');
  const he = hideHeader ? '' : ' he '.repeat(2);
  const ci = hideContentInfo ? '×' : ' ci '.repeat(4);
  const ct = hideContentTag ? '×' : ' ct '.repeat(4);
  let lines: string[];
  if (hideContentCategory) {
    const he_ci_ca = `'${he} ${ci} ca'`;
    const he_ct_ct = `'${he} ${ct} ct'`;
    lines = [...Array(4).fill(he_ci_ca), he_ct_ct];
  } else {
    const cc = ' cc '.repeat(3);
    const he_cc_ca = `'${he} ${cc} ca'`;
    const he_ci = `'${he} ${ci}'`;
    const he_ct = `'${he} ${ct}'`;
    lines = [he_cc_ca, ...Array(3).fill(he_ci), he_ct];
  }
  return lines.map(clearEmptyLine).join('');
}
