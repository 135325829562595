import { BaSeTheme } from '../../../theme';
import { Color } from '../../../utils/color-transformation/color';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';

export const mapSizeCheckbox = {
  small: 13,
  medium: 16,
};

export const mapTagValues = (color: string) => {
  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.destructive ||
    color === BaSeTheme.components.button.colors.attention ||
    color === BaSeTheme.components.button.colors.confirmation;

  if (colorIsMapped) {
    const darkColor =
      color === 'default'
        ? BaSeTheme.colors.defaultColors.white
        : ColorMapper[color].foreground;
    return {
      primary: {
        dark: {
          iconColor:
            ColorMapper[color === 'default' ? 'grayScale' : color].foreground,
          style: {
            bgC: BaSeTheme.colors.defaultColors.white,
            bcCHover:
              ColorMapper[color === 'default' ? 'grayScale' : color].hover
                .defaultDark,
            boxShadowFocus: `0px 0px 4px ${BaSeTheme.colors.defaultColors.white}`,
            border: BaSeTheme.colors.defaultColors.transparent,
          },
        },
        light: {
          iconColor: ColorMapper[color].foreground,
          style: {
            bgC: ColorMapper[color].hover.defaultLight,
            bcCHover: ColorMapper[color].hover.defaultDark,
            boxShadowFocus: `0px 0px 4px ${ColorMapper[color].focus.defaultLight}`,
            border: BaSeTheme.colors.defaultColors.transparent,
          },
        },
      },
      secondary: {
        dark: {
          iconColor: darkColor,
          style: {
            bgC: BaSeTheme.colors.institucionais.cinzaSebrae20,
            bcCHover:
              ColorMapper[color === 'default' ? 'grayScale' : color].focus
                .defaultLight,
            boxShadowFocus: `0px 0px 4px ${darkColor}`,
            border: darkColor,
          },
        },
        light: {
          iconColor: ColorMapper[color].foreground,
          style: {
            bgC: BaSeTheme.colors.defaultColors.white,
            bcCHover: ColorMapper[color].hover.defaultDark,
            boxShadowFocus: `0px 0px 4px ${ColorMapper[color].focus.defaultLight}`,
            border: ColorMapper[color].foreground,
          },
        },
      },
    };
  }

  const customColorHsv = Color.generateFromHex(color);
  return {
    primary: {
      light: {
        iconColor:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
        style: {
          bgC: ColorMapper.light.hover.create(
            color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
          ),
          bcCHover: ColorMapper.dark.hover.create(
            color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
          ),
          boxShadowFocus: `0px 0px 4px ${ColorMapper.light.focus.create(
            color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
          )}`,
          border: BaSeTheme.colors.defaultColors.transparent,
        },
      },
      dark: {
        iconColor:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
        style: {
          bgC: BaSeTheme.colors.defaultColors.white,
          bcCHover: ColorMapper.dark.hover.create(
            color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
          ),
          boxShadowFocus: `0px 0px 4px ${BaSeTheme.colors.defaultColors.white}`,
          border: BaSeTheme.colors.defaultColors.transparent,
        },
      },
    },
    secondary: {
      light: {
        iconColor:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
        style: {
          bgC: BaSeTheme.colors.defaultColors.transparent,
          bcCHover: ColorMapper.dark.hover.create(
            color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
          ),
          boxShadowFocus: `0px 0px 4px ${ColorMapper.light.focus.create(
            color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
          )}`,
          border:
            color && customColorHsv
              ? customColorHsv.toString()
              : BaSeTheme.colors.institucionais.azulSebrae36,
        },
      },
      dark: {
        iconColor:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
        style: {
          bgC: BaSeTheme.colors.institucionais.cinzaSebrae20,
          bcCHover: ColorMapper.light.focus.create(
            color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
          ),
          boxShadowFocus: `0px 0px 4px ${
            color && customColorHsv
              ? customColorHsv.toString()
              : BaSeTheme.colors.institucionais.azulSebrae36
          }`,
          border:
            color && customColorHsv
              ? customColorHsv.toString()
              : BaSeTheme.colors.institucionais.azulSebrae36,
        },
      },
    },
  };
};
