import styled, { css } from 'styled-components';
import { mapSizeRadio } from './map-radio-styled';
import { BaSeTheme } from '../../../theme';

export type RadioShapeProps = 'circle' | 'square';

interface ContainerRadioProps {
  disabled: boolean;
  checked: boolean;
  backgroundColor: string;
  backgroundOnHover: string;
  border: string;
  iconColor: string;
  shape: RadioShapeProps;
}

interface LabelRadioProps {
  disabled: boolean;
  size: 'medium' | 'small';
  width?: string;
}

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const RadioMarker = styled.div<ContainerRadioProps>`
  border-radius: ${({ shape }) => (shape === 'circle' ? '50%' : '25%')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: ${BaSeTheme.stackOrder.normal};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  background: ${({ backgroundColor }) => backgroundColor};
  ${({ checked }) =>
    checked
      ? css`
          width: calc(100% - 10px);
          height: calc(100% - 10px);
        `
      : css`
          width: 0;
          height: 0;
        `}
`;

export const ContainerRadio = styled.div<ContainerRadioProps>`
  margin: 5px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  max-height: 18px;
  max-width: 18px;
  min-height: 18px;
  min-width: 18px;
  position: relative;
  display: flex;

  &::before {
    content: '';
    border-radius: ${({ shape }) => (shape === 'circle' ? '50%' : '0.25em')};
    border: 2px solid
      ${({ disabled, checked, border }) =>
        checked
          ? border
          : disabled
            ? BaSeTheme.colors.institucionais.cinzaSebrae90
            : BaSeTheme.colors.institucionais.cinzaSebrae75};
    background: ${({ disabled, checked, iconColor }) =>
      checked
        ? iconColor
        : disabled
          ? BaSeTheme.colors.institucionais.cinzaSebrae97
          : BaSeTheme.colors.defaultColors.white};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: ${BaSeTheme.stackOrder.default};
  }
  ${({ disabled, checked, backgroundOnHover }) =>
    !disabled &&
    css`
      &:hover,
      &.hover {
        &::before {
          border: 2px solid
            ${() =>
              checked
                ? backgroundOnHover
                : BaSeTheme.colors.institucionais.azulSebrae30};
        }
        & ${RadioMarker} {
          background: ${backgroundOnHover};
        }
      }
    `};
`;

export const InputRadio = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  z-index: ${BaSeTheme.stackOrder.normal + 1};
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const LabelRadio = styled.label<LabelRadioProps>`
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;
  font-family: ${BaSeTheme.typography.fontFamily};
  line-height: 1.46em;
  font-size: ${({ size }) => mapSizeRadio[size] ?? mapSizeRadio.medium}px;
  width: ${({ width }) => width};
  ${({ disabled }) =>
    disabled
      ? css`
          color: ${BaSeTheme.colors.institucionais.cinzaSebrae75};
          cursor: not-allowed;
        `
      : css`
          color: ${BaSeTheme.colors.institucionais.cinzaSebrae30};
          cursor: pointer;
        `};
`;
