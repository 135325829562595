import styled from 'styled-components';
import { BaSeScrollbarCss } from '../../../theme/scrollbar';

export const SideMenuContainer = styled.nav.attrs({
  className: 'BaSe--side-menu',
})`
  &.BaSe--side-menu {
    display: flex;
    flex-flow: column;

    ul {
      margin: 0;
      padding: 0;
    }

    ${BaSeScrollbarCss};
  }
`;
