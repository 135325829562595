export function objectFindValueFromSomeKey<
  ObjectToFind extends { [key: string]: any },
>(
  obj: ObjectToFind | null | undefined,
  keys: string[],
): ReturnType<ObjectToFind[keyof ObjectToFind]> | undefined {
  if (obj === undefined || obj === null) {
    return undefined;
  }
  for (const key of keys) {
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    }
  }
  return undefined;
  // return keys.reduce((value, key) => {
  //   if (value !== undefined) {
  //     return value;
  //   }
  //   const result = obj.hasOwnProperty(key) ? obj[key] : undefined;
  //   return result;
  // }, undefined);
}
