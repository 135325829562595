import * as React from 'react';

type setStateArgument<State> = State | ((actualState: State) => State);

export interface DataSelectionContextInterface<Item extends object> {
  isAllSelected: boolean;
  isAllFromPageSelected: boolean;
  selectedItems: Item[];
  setIsAllSelected(arg: setStateArgument<boolean>): void;
  setIsAllFromPageSelected(arg: setStateArgument<boolean>): void;
  setSelectedItems(arg: setStateArgument<Item[]>): void;
}

export interface DataSelectionProviderProps<Item extends object>
  extends DataSelectionContextInterface<Item> {
  hasSelection: boolean;
}

export const BaSeDataSelectionContext = React.createContext<
  DataSelectionContextInterface<object>
>({
  isAllSelected: false,
  isAllFromPageSelected: false,
  selectedItems: [],
  setIsAllSelected: () => {},
  setIsAllFromPageSelected: () => {},
  setSelectedItems: () => {},
});

export function BaSeDataSelectionProvider<Item extends object>({
  hasSelection,
  isAllSelected,
  isAllFromPageSelected,
  selectedItems,
  setIsAllSelected,
  setIsAllFromPageSelected,
  setSelectedItems,
  children,
}: React.PropsWithChildren<DataSelectionProviderProps<Item>>): JSX.Element {
  if (hasSelection) {
    return (
      <BaSeDataSelectionContext.Provider
        value={{
          isAllSelected,
          isAllFromPageSelected,
          selectedItems,
          setIsAllSelected,
          setIsAllFromPageSelected,
          setSelectedItems,
        }}
      >
        {children}
      </BaSeDataSelectionContext.Provider>
    );
  }

  return <>{children}</>;
}

export const BaSeDataSelectionConsumer = BaSeDataSelectionContext.Consumer;
