import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../../utils/string-utils';
import { BaSeTheme } from '../../../theme';
import { ThemeColorValue } from '../../../theme/theme-interface';

export interface ColorsCustomProps {
  color: string;
  borderColor: string;
  backgroundColor: string;
  focusTextColor?: string;
  focusBorderColor?: string;
  boxShadowFocus?: string;
}

export interface CustomOverrideColorsGenerics {
  customColors?: ColorsCustomProps;
}

interface StyledApoioProps {
  color?: string;
}

interface StyledInputProps extends CustomOverrideColorsGenerics {
  paddingRight?: number;
  align?: 'start' | 'end';
  size?: number;
}

interface StyledContainerInput {
  width: number | string;
}

interface ContainerIconProps {
  rotation: boolean;
}

export const StyledApoio = styled.span<StyledApoioProps>`
  ${({ color }) => css`
    font-size: 0.83em;
    color: ${color};
    margin-top: 3px;
    line-height: 1.2em;
    font-family: ${BaSeTheme.typography.fontFamily};
    font-style: italic;
    font-weight: normal;
  `}
`;

export const ContainerInput = styled.div.attrs({
  className: 'BaSe--input',
})<StyledContainerInput>`
  ${({ width }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    width: ${numberWithUnit(width)};
  `}
`;

export const WrapperInput = styled.div.attrs({
  className: 'BaSe--input-wrapper',
})<StyledInputProps>`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;

  .BaSe--loading-spinner {
    margin-right: 0.65em;
  }

  & > input.BaSe--input-input {
    ${({ paddingRight = 12, size, customColors, align }) => css`
      font-family: ${BaSeTheme.typography.fontFamily};
      font-size: ${BaSeTheme.typography.fontSizeBase};
      line-height: ${BaSeTheme.typography.lineHeightBase};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding-left: 12px;
      padding-right: ${paddingRight}px;
      width: calc(100% - ${paddingRight}px);
      color: ${customColors?.color};
      background: ${customColors?.backgroundColor};
      border: 1px solid ${customColors?.borderColor};
      border-radius: 4px;
      height: ${size}px;
      text-align: ${align};
      &:focus {
        outline: none !important;
        box-shadow: 0 0 4px ${customColors?.boxShadowFocus};
        border: 1px solid ${customColors?.focusBorderColor};
        color: ${customColors?.focusTextColor};
      }
    `}
  }
`;

export const ContainerIcon = styled.div<ContainerIconProps>`
  ${({ rotation }) => css`
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    justify-content: center;
    align-items: center;
    justify-content: center;
    display: flex;

    button {
      font-family: ${BaSeTheme.typography.fontFamily};
      font-size: ${BaSeTheme.typography.fontSizeBase};
      line-height: ${BaSeTheme.typography.lineHeightBase};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border-radius: 0 4px 4px 0;

      &:focus {
        border-radius: 0 4px 4px 0;
      }
    }
    & > img {
      transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
      margin-right: 1em;
    }
    ${
      rotation &&
      css`
        & > img {
          transform: rotateX(180deg);
        }
      `
    }}
  `}
`;

export const MoreInfoContainer = styled.div`
  padding-top: 4px;
`;

export const WrapperHelper = styled.div`
  padding-top: 2px;
`;

export const CheckIconWrapper = styled.div<{ top: number }>`
  ${({ top }) => css`
    position: relative;
    right: 12px;
    top: ${top}px;
  `}
`;

export const StyledTextFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledInputCounter = styled.div<{ color: ThemeColorValue }>`
  display: flex;
  justify-content: flex-end;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ color }) => color};
  margin-top: 2px;
  text-align: end;
  width: 100%;
`;
