import { isAfter, isEqual, isSameMonth } from 'date-fns';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import { BaSeI18nContext } from '../../contexts/i18n';
import { useNextHashId } from '../../hooks/next-id';
import { localDateFormate, localeMapFormat } from '../../utils/date';
import { idGenerator } from '../../utils/id-generator';
import { BaSeHelperText } from '../helpers/helper-text/helper-text';
import { BaSeIcon } from '../image/icon';
import { BaSeInput } from '../input/text/input';
import { MoreInfoContainer, WrapperHelper } from '../input/text/input-styled';
import { inputColorManager } from '../input/text/map-input-style';
import { BaSeFormLabel } from '../labels/form-label/form-label';
import { DatePickerColorManager, MaskDatePickerSize } from './date-picker-map';
import {
  DatePickerColorsMapperInterface,
  DatePickerProps,
  HighlightedDatesPicker,
} from './date-picker-props';
import {
  DatePickerCalendarContainer,
  DatePickerWrapper,
} from './date-picker-styled';
import { getFormattedDataset } from '../../utils/dataset-utils';
import { IMaskInput } from 'react-imask/esm/index';
import { BaSeTheme } from '../../theme';

const idSequence = idGenerator();

export const BaSeDatePicker: React.FC<DatePickerProps> = ({
  id: externalId,
  name,
  dataset,
  startDate,
  endDate,
  closeOnScroll,
  multipleMonths,
  selectRange,
  color,
  inputStatus,
  size,
  isDisabled,
  maxDate,
  minDate,
  placeholder,
  label,
  subLabel,
  showHelpButton,
  helpButtonProps,
  moreInfoLabel,
  moreInfoDetails,
  complement,
  calendarType = 'days',
  alwaysIsOpen = false,
  onFocus,
  onBlur,
  onSelect,
  onCalendarOpen,
  onCalendarClose,
  onStartDateChange,
  onEndDateChange,
}) => {
  const id = externalId ?? useNextHashId(idSequence);
  const [inputDate, setInputDate] = React.useState<string>('');
  const [reg, setReg] = React.useState(false);
  const { locale, registerDateLocale } = React.useContext(BaSeI18nContext);
  const [labelHeight, setLabelHeight] = React.useState<number | undefined>();
  const [hasFocus, setHasFocus] = React.useState(false);

  const datePickerRef = React.useRef<HTMLDivElement>(null);

  const formattedDataset = getFormattedDataset(dataset);

  const hasComplement = React.useMemo(() => complement !== '', [complement]);

  const getColors = React.useCallback(
    (): DatePickerColorsMapperInterface =>
      DatePickerColorManager(color, inputStatus),
    [color, inputStatus],
  );

  const handleCalendarClose = React.useCallback(() => {
    onCalendarClose?.();
    setHasFocus(false);
  }, [onCalendarClose]);
  const handleSelection = React.useCallback(
    (e: Date | null) => {
      onSelect?.(e);
      setHasFocus(false);
    },
    [onSelect],
  );

  const handleFocus = React.useCallback(
    (e?: React.FocusEvent) => {
      onFocus?.(e);
      setHasFocus(true);
    },
    [onFocus],
  );

  const handleBlur = React.useCallback(
    (e?: React.FocusEvent) => {
      onBlur?.(e);
      setHasFocus(false);
    },
    [onBlur],
  );

  const handleChange = React.useCallback(
    (dates: [Date, Date] | Date | null) => {
      if (Array.isArray(dates)) {
        const [start, end] = dates as [Date, Date];
        onStartDateChange?.(start);
        onEndDateChange?.(end);
        return;
      }
      onStartDateChange?.(dates);
    },
    [],
  );

  const showSelected = React.useCallback(
    () => (multipleMonths ? endDate ?? startDate : startDate),
    [multipleMonths, startDate, endDate],
  );

  const showHighlightedDates = React.useCallback(() => {
    if (!selectRange) {
      return [{ 'unique-date': [startDate ?? new Date()] }];
    }
    const datesToHighlight = [];
    if (endDate) {
      datesToHighlight.push({ endDate: [endDate] });
    }
    datesToHighlight.push({ startDate: [startDate ?? new Date()] });
    return datesToHighlight;
  }, [selectRange, startDate, endDate]);

  const compareStarEndDates = React.useCallback(() => {
    if (!(!!startDate && !!endDate)) {
      return false;
    }
    if (calendarType === 'months') {
      return isSameMonth(startDate, endDate);
    }
    return isEqual(startDate, endDate);
  }, [startDate, endDate]);

  const getMaskSize = React.useCallback(() => MaskDatePickerSize(size), [size]);
  const getValidStatus = React.useCallback((): string => {
    const inputStatusTypes = {
      normal: 'normal',
      valid: 'valid',
      invalid: 'invalid',
    };
    return isDisabled
      ? 'disabled'
      : !inputStatus || !(inputStatus in inputStatusTypes)
        ? 'normal'
        : inputStatusTypes[inputStatus];
  }, [inputStatus, isDisabled]);

  const hasInfoLabel = React.useCallback(
    () => !!label || !!subLabel || !!showHelpButton,
    [label, subLabel, showHelpButton],
  );

  const getIconTopCoordinates = React.useCallback(() => {
    let parentHeight;
    if (labelHeight) {
      parentHeight = labelHeight;
    }
    const sizes = {
      small: 23,
      default: 24.4,
    };
    const aditionalSize = hasInfoLabel()
      ? sizes[size ?? 'default'] ?? sizes.default
      : 0;
    if (parentHeight) {
      return parentHeight + (size === 'small' ? 8 : 12);
    }
    return aditionalSize + (size === 'small' ? 8 : 12);
  }, [size, labelHeight]);

  const getSize = React.useCallback(() => (size === 'big' ? 24 : 16), [size]);
  const getIconColor = React.useCallback(() => {
    return inputColorManager(color)[getValidStatus()]?.[
      hasFocus ? 'focusTextColor' : 'color'
    ];
  }, [hasFocus, color]);
  const getIconName = React.useCallback(() => {
    const toErase = selectRange && startDate && endDate;
    const iconsNameMap = {
      valid: 'check',
      invalid: 'exclamation-circle-solid',
      default: 'calendar-alt',
      erase: 'trash-alt',
    };
    return toErase
      ? 'trash-alt'
      : iconsNameMap[inputStatus ?? 'default'] ?? iconsNameMap.default;
  }, [inputStatus, selectRange, startDate, endDate]);

  const getCalendarType = React.useCallback(() => {
    const attrTypeCalendar = [
      {
        attr: 'showMonthYearPicker',
        value: calendarType === 'months',
      },
    ]
      .filter((ele) => ele.value)
      .map((ele) => ({ [ele.attr]: true }));
    return attrTypeCalendar[0] ?? {};
  }, [calendarType]);

  const getCustomInputMask = React.useCallback(() => {
    if (calendarType === 'months') {
      return '00/0000';
    }
    return '00/00/0000';
  }, [calendarType]);

  const handleFocusIcon = React.useCallback(() => {
    if (selectRange && startDate && endDate) {
      onStartDateChange?.(null);
      onEndDateChange?.(null);
      return;
    }
    datePickerRef.current?.querySelector('input')?.focus?.();
  }, [selectRange, startDate, endDate]);

  const handleIcon = React.useCallback(() => {
    return (
      <div onClick={handleFocusIcon}>
        <BaSeIcon
          size={getSize()}
          name={getIconName()}
          description="BaSe--date-picker-icon"
          color={getIconColor()}
        />
      </div>
    );
  }, [getSize, getIconName, getIconColor, handleFocusIcon]);

  const renderCustomInput = React.useCallback(
    () =>
      selectRange ? (
        <BaSeInput
          dataset={dataset}
          size={size}
          isReadOnly={true}
          value={inputDate}
        />
      ) : (
        <IMaskInput mask={getCustomInputMask()} {...formattedDataset} />
      ),

    [selectRange, size, getCustomInputMask, inputDate],
  );

  const handleRawChange = React.useCallback(
    (rawEvent: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!selectRange && rawEvent.currentTarget.value) {
        const nullDate =
          rawEvent.currentTarget.value.replace(/\D/g, '')?.length > 0;
        if (!nullDate) {
          onStartDateChange?.(null);
          onEndDateChange?.(null);
        }
      }
    },
    [selectRange],
  );

  React.useEffect(() => {
    setInputDate(
      () =>
        localDateFormate[locale]?.(startDate) +
        `${endDate ? ' - ' + localDateFormate[locale]?.(endDate) : ''}`,
    );
    if (startDate && endDate && isAfter(startDate, endDate)) {
      onEndDateChange?.(undefined);
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    registerDateLocale?.(locale);
    const timeout = setTimeout(() => setReg(true), 0);
    return () => clearTimeout(timeout);
  }, [registerDateLocale, locale]);

  return (
    <DatePickerWrapper
      ref={datePickerRef}
      isStartDaySameEndDay={compareStarEndDates()}
      colorProps={getColors()}
      maskInputProp={{
        size: getMaskSize(),
        ...inputColorManager(color)[getValidStatus()],
      }}
      monthPickNotRange={calendarType === 'months' && !selectRange}
      alwaysIsOpen={alwaysIsOpen}
    >
      {!alwaysIsOpen && (
        <DatePickerCalendarContainer
          top={getIconTopCoordinates()}
          width={getSize()}
        >
          {handleIcon()}
        </DatePickerCalendarContainer>
      )}
      <BaSeFormLabel
        id={id}
        label={label}
        subLabel={subLabel}
        showHelpButton={showHelpButton}
        helpButtonProps={helpButtonProps}
        getContainerHeight={(value?: number) => setLabelHeight(value)}
      />
      {reg && (
        <DatePicker
          id={id}
          name={name}
          onChangeRaw={handleRawChange}
          formatWeekDay={(nameOfDay) => nameOfDay[0]}
          onCalendarOpen={onCalendarOpen}
          onCalendarClose={handleCalendarClose}
          selected={showSelected()}
          onChange={handleChange}
          onSelect={handleSelection}
          locale={locale}
          customInput={renderCustomInput()}
          monthsShown={calendarType === 'days' ? +!!multipleMonths + 1 : 1}
          startDate={startDate}
          endDate={endDate}
          selectsRange={selectRange}
          highlightDates={showHighlightedDates() as HighlightedDatesPicker[]}
          dropdownMode="select"
          dateFormat={localeMapFormat[locale][calendarType]}
          wrapperClassName="BaSe--date-picker"
          popperPlacement="auto"
          disabled={isDisabled}
          maxDate={maxDate}
          minDate={minDate}
          placeholderText={placeholder}
          closeOnScroll={closeOnScroll}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInputError={() => {
            onStartDateChange?.(null);
            onEndDateChange?.(null);
          }}
          {...getCalendarType()}
          inline={alwaysIsOpen}
        />
      )}
      {hasComplement && (
        <WrapperHelper>
          <BaSeHelperText
            isItalic={true}
            color={BaSeTheme.colors.institucionais.cinzaSebrae60}
            label={complement ?? ''}
          />
        </WrapperHelper>
      )}
      {!!moreInfoLabel && (
        <MoreInfoContainer>
          <BaSeHelperText
            size="small"
            label={moreInfoLabel}
            details={moreInfoDetails}
          />
        </MoreInfoContainer>
      )}
    </DatePickerWrapper>
  );
};
