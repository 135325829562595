import * as React from 'react';
import { GridProps, GridWrapper } from './grid-styled';

export const BaSeGrid: React.FC<GridProps> = ({
  gap,
  padding,
  delimitedOnBreakpoint,
  children,
}) => (
  <GridWrapper
    className="BaSe--grid"
    padding={padding}
    gap={gap}
    delimitedOnBreakpoint={delimitedOnBreakpoint}
  >
    {children}
  </GridWrapper>
);

BaSeGrid.displayName = 'BaSeGrid';
