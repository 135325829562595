type ObjectToMapValues = string | number | boolean | undefined;
interface ObjectToMap {
  [key: string]: ObjectToMapValues;
}

export function objectToMap(
  data: ObjectToMap | URLSearchParams,
): Map<string, ObjectToMapValues> {
  const obj = data instanceof URLSearchParams ? Object.fromEntries(data) : data;
  const map = new Map<string, ObjectToMapValues>();

  Object.keys(obj).forEach((key) => map.set(key, obj?.[key]));
  return map;
}
