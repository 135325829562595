import * as React from 'react';
import { RootContext } from '../contexts/root';

export const NotFoundPage: React.FC = () => (
  <RootContext.Consumer>
    {({ notFoundPage: renderComponent }) =>
      renderComponent?.() ?? (
        <section>
          <header>
            <h1>404</h1>
          </header>
          <p>Ops! Não achou.</p>
          <p>
            <a href="/">Voltar</a> para o início.
          </p>
        </section>
      )
    }
  </RootContext.Consumer>
);
