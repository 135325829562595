import * as React from 'react';
import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { ThemeNamedType } from '../../../theme/theme-interface';
import { numberWithUnit } from '../../../utils/string-utils';

export interface TagStyledProps {
  smallSize: number;
  mediumSize: number;
  bigSize: number;
  size: 'small' | 'medium' | 'big';
  icon?: string;
  type: Exclude<ThemeNamedType, 'tertiary'>;
  themeMode?: 'light' | 'dark';
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?<E extends React.SyntheticEvent>(event: E): void;
  onClose?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

const borderSize = 1;

function getSize({
  size,
  bigSize,
  mediumSize,
  smallSize,
}: Pick<
  TagStyledProps,
  'size' | 'bigSize' | 'mediumSize' | 'smallSize'
>): number {
  return size === 'big' ? bigSize : size === 'medium' ? mediumSize : smallSize;
}

interface StyledTagContainerProps extends TagStyledProps {
  bgC: string;
  bcCHover: string;
  boxShadowFocus: string;
  border: string;
  newIconColor: string;
}

interface StyledTagIconProps {
  type: Exclude<ThemeNamedType, 'tertiary'>;
  newIconColor: string;
  size: 'small' | 'medium' | 'big';
}

export interface StyledTagTextProps {
  icon?: string;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  newIconColor: string;
  limitedWidth?: number | string;
  size: 'small' | 'medium' | 'big';
}

interface StyledTagCloseProps {
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  bcCHover: string;
  size: 'small' | 'medium' | 'big';
  type: Exclude<ThemeNamedType, 'tertiary'>;
  themeMode?: 'light' | 'dark';
}

function getDiffSize({
  size,
  hasClose,
  hasIcon,
}: {
  size: 'small' | 'medium' | 'big';
  hasClose: boolean;
  hasIcon: boolean;
}): number {
  const sizes = {
    small: [16, 31, 29, 42],
    medium: [16, 34, 32, 48],
    big: [24, 50, 48, 72],
  };
  const index = parseInt(`${+hasClose}${+hasIcon}`, 2);
  return sizes[size][index];
}

export const TagContainerStyled = styled.div<StyledTagContainerProps>`
  height: ${({ size }) =>
    size === 'small' ? 17 : size === 'medium' ? 20 : 32}px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  border: ${({ type, border, isSelected, themeMode }) =>
    (type === 'primary' && !isSelected) || themeMode === 'dark'
      ? 'none'
      : `${borderSize}px solid ${border}`};
  border-radius: ${(props) => getSize(props)}px;
  background-color: ${({ bgC }) => bgC};

  ${({ onClick, boxShadowFocus, bcCHover, isSelected, themeMode }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        ${isSelected &&
        css`
          border-color: ${bcCHover};
        `}
        ${isSelected &&
        themeMode === 'dark' &&
        css`
          background-color: ${bcCHover};
        `}
      }
      &:focus {
        ${isSelected &&
        css`
          border-color: ${bcCHover};
        `}
        outline: none;
        box-shadow: ${boxShadowFocus};
      }
    `}

  ${({ onClick, bcCHover, type, isSelected }) =>
    onClick &&
    type === 'secondary' &&
    css`
      &:hover {
        border-color: ${bcCHover};
        ${isSelected &&
        css`
          background-color: ${bcCHover};
        `}
      }
      &:focus {
        border-color: ${bcCHover};
        ${isSelected &&
        css`
          background-color: ${bcCHover};
        `}
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `};
`;

export const TagIcon = styled.span<StyledTagIconProps>`
  border-radius: 50%;
  background-color: ${({ type, newIconColor }) =>
    type === 'primary' ? newIconColor : 'none'};
  height: ${({ size }) =>
    size === 'small' ? 17 : size === 'medium' ? 20 : 32}px;
  width: ${({ size }) =>
    size === 'small' ? 17 : size === 'medium' ? 20 : 32}px;
  display: flex;
  align-items: center;
  justify-items: center;
  img {
    display: flex;
    flex: 1;
  }
`;
export const TagText = styled.span<StyledTagTextProps>`
  padding: ${({ size }) => (size === 'big' ? 8 : 2)}px
    ${({ size }) => (size === 'big' ? 12 : 8)}px;

  ${({ onClose }) =>
    onClose &&
    css`
      padding-right: 4px;
    `}

  ${({ icon }) =>
    icon &&
    css`
      padding-left: 4px;
    `}

  ${({ icon, newIconColor }) =>
    icon &&
    newIconColor === BaSeTheme.colors.defaultColors.transparent &&
    css`
      margin-left: 0;
    `}

  ${({ limitedWidth, size, onClose, icon }) =>
    limitedWidth &&
    css`
      & > small[class*='BaSe'] {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 100%;
        vertical-align: top;
        max-width: calc(
          ${numberWithUnit(limitedWidth)} -
            ${numberWithUnit(
              getDiffSize({ size, hasClose: !!onClose, hasIcon: !!icon }),
            )}
        );
      }
    `}
`;
export const TagClose = styled.span<StyledTagCloseProps>`
  button {
    font-family: ${BaSeTheme.typography.fontFamily};
    font-size: ${BaSeTheme.typography.fontSizeBase};
    line-height: ${BaSeTheme.typography.lineHeightBase};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: ${({ size }) =>
      size === 'small' ? 17 : size === 'medium' ? 20 : 32}px;
    width: ${({ size }) =>
      size === 'small' ? 17 : size === 'medium' ? 20 : 32}px;
    padding: 0;
    display: flex;
    ${({ themeMode, bcCHover }) =>
      themeMode === 'dark' &&
      css`
        &:hover {
          background-color: ${bcCHover};
        }
      `}
    img {
      flex: 1;
      height: ${({ size }) =>
        size === 'big' ? 28 : size === 'medium' ? 14 : 12}px;
      width: ${({ size }) =>
        size === 'big' ? 28 : size === 'medium' ? 14 : 12}px;
    }
  }
`;
