export function log(context: string, message: string, ...data: any[]): void {
  const tag = `
    line-height: 24px;
    font-size: 12px;
    border-radius: 12px;
    padding: 0 12px;
    background-color: #005eb8;
    color: #fff;
  `;
  // eslint-disable-next-line no-console
  console.log.apply(null, [`%cSebrae ${context}`, tag, message, ...data]);
}
