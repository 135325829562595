import { BaSeFooter, BaSeTheme } from '@base/react';
import * as React from 'react';
import { version } from '../../../package.json';
import { Footer } from './footer-styled';

export const LayoutFooter = () => {
  return (
    <Footer>
      <BaSeFooter
        collapsedBreakpoint={'onMobile'}
        backgroundColor={BaSeTheme.colors.defaultColors.white}
        systemVersion={version}
      />
    </Footer>
  );
};
