export const messageError = (statusCode) => {
  if (statusCode === 400) {
    return 'Dados inválidos fornecidos pelo cliente';
  }
  if (statusCode === 401) {
    return 'Usuário não autenticado';
  }
  if (statusCode === 403) {
    return 'Usuário sem permissão para esta operação';
  }
  if (statusCode === 404) {
    return 'Nenhum resultado encontrado na pesquisa.';
  }
  if (statusCode === 500) {
    return 'Erro inesperado no servidor, não foi possível processar a solicitação';
  }
};
