import * as React from 'react';
import { BaSeI18nConsumer } from '../../../contexts/i18n';
import { BaSeButton, ButtonProps } from '../../button/button/button';
import { ShapeButtonProps } from '../../button/shape-button/shape-button';
import { BaSeTag, TagProps } from '../../labels/tag/tag';
import { BaSeSmall1 } from '../../typography/small/small1';
import { DataRenderProps, DataResetableSelection } from '../data';
import { DataInterfaceProps } from '../data-styled';
import {
  ActivatedFilters,
  CustomFilterItem,
  DataFilterAreaWrapper,
  DataFilterAreaWrapperProps,
  FilterArea,
} from './filter-area-styled';

export type SetStateArgument<State> = State | ((actualState: State) => State);

export interface CustomFilterRenderProps<Filter>
  extends Pick<
    DataFilterAreaProps<Filter>,
    'setFilter' | 'setTags' | 'resetSelection'
  > {}

export interface DataFilterAreaProps<Filter>
  extends Omit<DataRenderProps<any, Filter>, 'table'>,
    Omit<
      DataFilterAreaWrapperProps,
      'transitionInAction' | 'filterAreaVisible'
    >,
    Pick<DataInterfaceProps, 'textColor' | 'separatorColor' | 'hasHeader'> {
  customFilterClearButtonProps?: Partial<
    Omit<
      ButtonProps,
      'onClick' | 'autoFocus' | 'isLoading' | 'loadingText' | 'buttonType'
    >
  >;
  tags: TagProps[];
  customFilterVisible?: boolean;
  customFilterVisibleWhenHasTags?: boolean;
  hasCustomFilter?: boolean;
  customFilterTitle?: string;
  customFilterActivatedTitle?: string;
  customFilterIconVisible?: boolean;
  customFilterIconProps?: Partial<
    Omit<ShapeButtonProps, 'onClick' | 'autoFocus'>
  >;
  renderCustomFilter?(
    renderProps: CustomFilterRenderProps<Filter>,
  ): JSX.Element;
  setClearFilterButtonClicked(clicked: SetStateArgument<number>): void;
  onClearFilter?(clearFilterProps: DataResetableSelection): void;
}

export function BaSeDataFilterArea<Filter>({
  customFilterClearButtonProps,
  textColor,
  headerBackgroundColor,
  filterAreaBackgroundColor,
  tags,
  separatorColor,
  customFilterVisible: initialCustomFilterVisible = false,
  customFilterVisibleWhenHasTags = false,
  hasHeader,
  customFilterTitle,
  customFilterActivatedTitle,
  setClearFilterButtonClicked,
  onClearFilter,
  renderCustomFilter,
  setFilter,
  setTags,
  resetSelection,
}: DataFilterAreaProps<Filter>): JSX.Element {
  if (renderCustomFilter === undefined) {
    throw Error('BaSeDataCustomFilter: "renderCustomFilter" é obrigatório');
  }

  const tagsVisible = React.useMemo(() => tags?.length > 0, [tags?.length]);
  const customFilterVisible = React.useMemo(
    () =>
      (customFilterVisibleWhenHasTags && tags.length > 0) ||
      initialCustomFilterVisible,
    [initialCustomFilterVisible, customFilterVisibleWhenHasTags, tags?.length],
  );

  return (
    <DataFilterAreaWrapper
      hasHeader={hasHeader}
      separatorColor={separatorColor}
      filterAreaVisible={tagsVisible || customFilterVisible}
      headerBackgroundColor={headerBackgroundColor}
      filterAreaBackgroundColor={filterAreaBackgroundColor}
    >
      {(customFilterVisible || tagsVisible) && (
        <FilterArea hasHeader={hasHeader}>
          <BaSeI18nConsumer>
            {({ getMessage }) => (
              <>
                {customFilterVisible && (
                  <CustomFilterItem>
                    <BaSeSmall1 color={textColor}>
                      {customFilterTitle ??
                        getMessage('dataFilter.customFilterTitle')}
                    </BaSeSmall1>
                    <div>
                      {renderCustomFilter({
                        setFilter,
                        setTags,
                        resetSelection,
                      })}
                    </div>
                  </CustomFilterItem>
                )}

                {tagsVisible && (
                  <ActivatedFilters>
                    <BaSeSmall1 isItalic={true} color={textColor}>
                      {customFilterActivatedTitle ??
                        getMessage('dataFilter.customFilterActivatedTitle')}
                    </BaSeSmall1>

                    {tags.map((tag) => (
                      <BaSeTag
                        key={`${tag.label}-${tag.icon}`}
                        color={textColor}
                        {...tag}
                      />
                    ))}

                    <BaSeButton
                      size="small"
                      type="tertiary"
                      color="destructive"
                      value={getMessage('dataFilter.customFilterClear')}
                      onClick={() => {
                        setFilter(undefined as unknown as Filter);
                        setTags?.([]);
                        onClearFilter?.({ resetSelection });
                        setClearFilterButtonClicked((clicked) => ++clicked);
                      }}
                      {...customFilterClearButtonProps}
                    />
                  </ActivatedFilters>
                )}
              </>
            )}
          </BaSeI18nConsumer>
        </FilterArea>
      )}
    </DataFilterAreaWrapper>
  );
}
