import { BaSeTheme } from '../../theme';
import { Color } from '../../utils/color-transformation/color';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';
import { InputStatus } from '../input/text/input';

const DatePickerColorMapper = {
  titleProps: {
    color: ColorMapper.grayScale.hover.defaultDark,
  },
  headerProps: {
    color: BaSeTheme.colors.defaultColors.white, // Adaptar para themeMode dark
  },
  arrowProps: {
    color: ColorMapper.default.foreground,
    hoverColor: ColorMapper.default.hover.defaultDark,
  },
  containerCardProps: {
    color: BaSeTheme.colors.defaultColors.white, // Adaptar para themeMode dark
    borderColor: ColorMapper.default.focus.defaultLight,
  },
  daysText: {
    color: ColorMapper.default.getColorByNumber(20),
    hoverColor: ColorMapper?.default.hover.defaultLight,
    focusColor: ColorMapper.default.foreground,
    shadowColor: ColorMapper.default.focus.defaultLight,
  },
};

export const DatePickerColorManager = (
  color?: string,
  inputStatus?: InputStatus,
) => {
  const statusMapper = {
    normal: 'default',
    invalid: 'destructive',
    valid: 'confirmation',
  };
  let statusColor;
  if (inputStatus && inputStatus in statusMapper && inputStatus !== 'normal') {
    statusColor = statusMapper[inputStatus];
  }
  if (statusColor) {
    return {
      ...DatePickerColorMapper,
      arrowProps: {
        ...DatePickerColorMapper?.arrowProps,
        color: ColorMapper[statusColor].foreground,
        hoverColor: ColorMapper[statusColor].hover.defaultDark,
      },
      containerCardProps: {
        ...DatePickerColorMapper.containerCardProps,
        borderColor: ColorMapper[statusColor].focus.defaultLight,
      },
      daysText: {
        ...DatePickerColorMapper.daysText,
        color: ColorMapper[statusColor].getColorByNumber(20),
        hoverColor: ColorMapper[statusColor].hover.defaultLight,
        focusColor: ColorMapper[statusColor].foreground,
        shadowColor: ColorMapper[statusColor].focus.defaultLight,
      },
      statusColor: ColorMapper[statusColor].foreground,
    };
  }
  if (!color) {
    return { ...DatePickerColorMapper };
  }
  if (ColorMapper.isValidType(color)) {
    return {
      ...DatePickerColorMapper,
      arrowProps: {
        ...DatePickerColorMapper?.arrowProps,
        color: ColorMapper[color].foreground,
        hoverColor: ColorMapper[color].hover.defaultDark,
      },
      containerCardProps: {
        ...DatePickerColorMapper.containerCardProps,
        borderColor: ColorMapper[color].focus.defaultLight,
      },
      daysText: {
        ...DatePickerColorMapper.daysText,
        color: ColorMapper[color].getColorByNumber(20),
        hoverColor: ColorMapper[color].hover.defaultLight,
        focusColor: ColorMapper[color].foreground,
        shadowColor: ColorMapper[color].focus.defaultLight,
      },
    };
  }
  if (!Color.validateColor(color)) {
    return { ...DatePickerColorMapper };
  }
  return {
    ...DatePickerColorMapper,
    arrowProps: {
      ...DatePickerColorMapper?.arrowProps,
      color,
      hoverColor: ColorMapper.dark.hover.create(color),
    },
    containerCardProps: {
      ...DatePickerColorMapper.containerCardProps,
      borderColor: ColorMapper.light.focus.create(color),
    },
    daysText: {
      ...DatePickerColorMapper.daysText,
      color: ColorMapper.create(color, 0.2),
      hoverColor: ColorMapper.light.hover.create(color),
      focusColor: color,
      shadowColor: ColorMapper.light.focus.create(color),
    },
  };
};

const maskSizeMap = {
  small: 32,
  medium: 40,
  big: 48,
};

export const MaskDatePickerSize = (optionalSize?: string) => {
  return maskSizeMap[
    !optionalSize || !(optionalSize in maskSizeMap) ? 'medium' : optionalSize
  ];
};
