import * as React from 'react';
import { HeaderGroup } from 'react-table';
import { useOutsideEvent } from '../../../hooks/outside-event';
import { CustomFilterRenderProps } from '../filter-area/filter-area';
import {
  ColumnFilterRenderProps,
  ColumnIdFlaggable,
  DataTableColumnConfig,
} from './table';
import { ColumnFilterProps, ColumnFilterWrapper } from './table-styled';

export interface DataTableColumnFilterProps<
  Item extends object = {},
  Filter = any,
> extends ColumnFilterProps,
    CustomFilterRenderProps<Filter> {
  renderColumnFilter: (
    id: any,
    columnConfig: DataTableColumnConfig<Item, Filter>[],
  ) =>
    | ((renderProps: ColumnFilterRenderProps<Filter>) => JSX.Element)
    | undefined;
  column: HeaderGroup<any>;
  columnConfig: DataTableColumnConfig<any, any>[];
  setColumnFilterVisible: React.Dispatch<
    React.SetStateAction<ColumnIdFlaggable>
  >;
}

export function DataTableColumnFilter<Item extends object = {}, Filter = any>({
  separatorColor,
  backgroundColor,
  renderColumnFilter,
  column,
  columnConfig,
  setFilter,
  setTags,
  resetSelection,
  setColumnFilterVisible,
}: React.PropsWithoutRef<
  DataTableColumnFilterProps<Item, Filter>
>): JSX.Element {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useOutsideEvent<HTMLDivElement>(wrapperRef, () =>
    setTimeout(
      () =>
        setColumnFilterVisible((actual) => ({
          ...actual,
          [column.id]: false,
        })),
      100,
    ),
  );
  return (
    <ColumnFilterWrapper
      ref={wrapperRef}
      separatorColor={separatorColor}
      backgroundColor={backgroundColor}
      onClick={(event) => event.stopPropagation()}
    >
      {renderColumnFilter(
        column.id,
        columnConfig,
      )?.({
        setFilter,
        setTags,
        resetSelection,
        closeFilter: () =>
          setColumnFilterVisible((actual) => ({
            ...actual,
            [column.id]: false,
          })),
      })}
    </ColumnFilterWrapper>
  );
}
