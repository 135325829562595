import * as React from 'react';
import { BaSeConfig } from '../../config/config';
import { BaSeI18nConsumer } from '../../contexts/i18n';
import { BaSeTheme } from '../../theme';
import { maxShadowLevel, validateShadowLevel } from '../../theme/shadows';
import { ThemeColorValue, ThemeNamedColor } from '../../theme/theme-interface';
import { BaSeButton, ButtonProps } from '../button/button/button';
import {
  BaSeShapeButton,
  ShapeButtonProps,
} from '../button/shape-button/shape-button';
import { BaSeIcon } from '../image/icon';
import { BaSeHeading4 } from '../typography/heading/heading4';
import { BaSeHeading5 } from '../typography/heading/heading5';
import { BaSeSmall1 } from '../typography/small/small1';
import { BaSeCardContentTag, CardContentTagProps } from './card-content-tag';
import {
  CardAppearenceProps,
  CardContentAction,
  CardContentCategory,
  CardContentCategoryItem,
  CardContentInfo,
  CardHeader,
  CardWrapper,
} from './card-styled';
import { colorMapper } from './card-utils';

export interface CardCategory {
  icon?: string;
  label: string;
}

export interface RenderCardAction {
  shapeButtons?: ShapeButtonProps[];
  buttons?: ButtonProps[];
}

export interface CardProps
  extends Partial<Omit<CardContentTagProps, 'hideContentTag'>>,
    Partial<CardAppearenceProps> {
  headerColor?: ThemeColorValue;
  renderHeader?(): JSX.Element | string;
  renderContentInfo?(): JSX.Element | string;
  renderContentCategory?(): JSX.Element | CardCategory[];
  renderContentAction?(): JSX.Element | RenderCardAction;
}

const colors = BaSeTheme.colors;

export const BaSeCard: React.FC<CardProps> = ({
  height = 'auto',
  width = 'auto',
  disposition = 'stacked',
  shadowLevel = 2,
  elevationOnHoverOrFocus = true,
  isElevated = false,
  withSideDestak = true,
  destakColor: externalDestakColor = 'default',
  backgroundColor = colors.defaultColors.white,
  foregroundColor = colors.institucionais.cinzaSebrae45,
  headerColor = colors.defaultColors.white,
  borderColor = colors.institucionais.cinzaSebrae90,
  backgroundImage,
  renderHeader,
  renderContentInfo,
  renderContentCategory,
  renderContentTag,
  renderContentAction,
}) => {
  const hideHeader = React.useMemo(
    () => typeof renderHeader !== 'function',
    [renderHeader],
  );
  const hideContentInfo = React.useMemo(
    () => typeof renderContentInfo !== 'function',
    [renderContentInfo],
  );
  const hideContentCategory = React.useMemo(
    () => typeof renderContentCategory !== 'function',
    [renderContentCategory],
  );
  const hideContentTag = React.useMemo(
    () => typeof renderContentTag !== 'function',
    [renderContentTag],
  );
  const hideContentAction = React.useMemo(
    () => typeof renderContentAction !== 'function',
    [renderContentAction],
  );
  const onlyHeader = React.useMemo(
    () =>
      !!(
        hideContentAction &&
        hideContentCategory &&
        hideContentInfo &&
        hideContentTag
      ),
    [],
  );
  const destakColor: ThemeColorValue = React.useMemo(
    () =>
      colorMapper[externalDestakColor as ThemeNamedColor] ??
      externalDestakColor,
    [externalDestakColor],
  );

  if (!validateShadowLevel(shadowLevel)) {
    throw Error(`[BaSeCard] "shadowLevel" ${shadowLevel} não existe`);
  }

  if (elevationOnHoverOrFocus && shadowLevel === maxShadowLevel) {
    throw Error(
      `[BaSeCard] "shadowLevel" ${shadowLevel} não pode ter
      elevação com "elevationOnHoverOrFocus".
      Já está no último nivel`,
    );
  }

  if (disposition === 'side-by-side') {
    if (hideHeader) {
      throw Error(
        `[BaSeCard] Quando "disposition" for "side-by-side",
        não pode ocutar o header.
        Use o "disposition" igual a "stacked" sem o header`,
      );
    } else if (
      hideContentInfo &&
      hideContentCategory &&
      hideContentTag &&
      hideContentAction
    ) {
      throw Error(
        `[BaSeCard] Quando "disposition" for "side-by-side",
        não pode ocutar os outros sem se o header.
        Use o "disposition" igual a "stacked" só com o header`,
      );
    }
  }

  if (disposition === 'wide') {
    if (hideHeader && hideContentAction) {
      throw Error(
        `[BaSeCard] Quando "disposition" for "wide",
        não pode ocutar o header e o content action.
        Use o "disposition" igual a "stacked" sem o header e sem o content action`,
      );
    }

    if (hideContentInfo && hideContentCategory && hideContentTag) {
      throw Error(
        `[BaSeCard] Quando "disposition" for "wide",
        não pode ocutar o content info, content category e content tag.
        Use o "disposition" igual a "stacked" só com o header e o content action`,
      );
    }

    if (hideContentAction) {
      throw Error(
        `[BaSeCard] Quando "disposition" for "wide",
        não pode ocutar o content action.
        Use o "disposition" igual a "stacked" ou "side-by-side" sem o content action`,
      );
    }
  }

  const header = React.useCallback(() => {
    if (hideHeader) {
      return;
    }
    const he = renderHeader?.();
    if (typeof he === 'string') {
      return (
        <CardHeader
          disposition={disposition}
          withSideDestak={withSideDestak}
          onlyHeader={onlyHeader}
        >
          <BaSeHeading4 color={headerColor}>{he}</BaSeHeading4>
        </CardHeader>
      );
    }
    return (
      <CardHeader
        disposition={disposition}
        withSideDestak={withSideDestak}
        onlyHeader={onlyHeader}
      >
        {he}
      </CardHeader>
    );
  }, [hideHeader, renderHeader, headerColor]);

  const contentInfo = React.useCallback(() => {
    if (hideContentInfo) {
      return;
    }
    const ci = renderContentInfo?.();
    if (typeof ci === 'string') {
      return (
        <CardContentInfo>
          <BaSeHeading5 color={foregroundColor}>{ci}</BaSeHeading5>
        </CardContentInfo>
      );
    }
    return <CardContentInfo>{ci}</CardContentInfo>;
  }, [hideContentInfo, renderContentInfo, foregroundColor]);

  const contentCategory = React.useCallback(() => {
    if (hideContentCategory) {
      return;
    }
    const cc = renderContentCategory?.();
    if (React.isValidElement(cc)) {
      return (
        <CardContentCategory disposition={disposition} hideHeader={hideHeader}>
          {cc}
        </CardContentCategory>
      );
    }
    return (
      <CardContentCategory disposition={disposition} hideHeader={hideHeader}>
        {(cc as CardCategory[])?.map((category) => (
          <BaSeI18nConsumer key={`${category.label}-${category.icon}`}>
            {({ getMessage }) => (
              <CardContentCategoryItem>
                {category.icon && (
                  <BaSeIcon
                    description={getMessage('icon.label', category.label)}
                    name={category.icon}
                    color={destakColor}
                    size={13}
                  />
                )}
                <BaSeSmall1 color={foregroundColor}>
                  {category.label}
                </BaSeSmall1>
              </CardContentCategoryItem>
            )}
          </BaSeI18nConsumer>
        ))}
      </CardContentCategory>
    );
  }, [
    hideContentCategory,
    renderContentCategory,
    foregroundColor,
    destakColor,
  ]);

  const contentAction = React.useCallback(() => {
    if (hideContentAction) {
      return;
    }
    const ct = renderContentAction?.();
    if (React.isValidElement(ct)) {
      return <CardContentAction>{ct}</CardContentAction>;
    }
    return (
      <CardContentAction>
        {(ct as RenderCardAction)?.shapeButtons?.map((shapeButton) => (
          <BaSeShapeButton
            key={`${shapeButton.nameIcon}-${shapeButton.value}`}
            color={foregroundColor}
            type="secondary"
            {...shapeButton}
          />
        ))}
        {(ct as RenderCardAction)?.buttons?.map((button) => (
          <BaSeButton
            key={`${button.leftIcon}-${button.value}-${button.rightIcon}`}
            color={foregroundColor}
            type="secondary"
            {...button}
          />
        ))}
      </CardContentAction>
    );
  }, [hideContentAction, renderContentAction, foregroundColor]);

  return (
    <CardWrapper
      height={height}
      width={width}
      disposition={disposition}
      shadowLevel={shadowLevel}
      withSideDestak={withSideDestak}
      elevationOnHoverOrFocus={elevationOnHoverOrFocus}
      isElevated={isElevated}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      backgroundImage={
        backgroundImage ??
        (hideHeader
          ? ''
          : `${BaSeConfig.staticAssetsUrl}/images/others/card/background.png`)
      }
      destakColor={destakColor}
      hideHeader={hideHeader}
      hideContentInfo={hideContentInfo}
      hideContentCategory={hideContentCategory}
      hideContentTag={hideContentTag}
      hideContentAction={hideContentAction}
    >
      {header()}
      {contentInfo()}
      {!hideContentTag && (
        <BaSeCardContentTag
          disposition={disposition}
          destakColor={destakColor}
          foregroundColor={foregroundColor}
          renderContentTag={renderContentTag}
        />
      )}
      {contentCategory()}
      {contentAction()}
    </CardWrapper>
  );
};

BaSeCard.displayName = 'BaSeCard';
