import * as React from 'react';
import { BaSeDataElement } from '../../../elements/data';
import { BaSeData, DataArgsToMapper, DataProps } from '../data';
import { DataBodyList } from './list-styled';

export interface DataListProps<Item extends object, Filter>
  extends Omit<
    DataProps<Item, Filter>,
    'render' | 'whenRenderTextCell' | 'config' | 'groupConfig'
  > {
  renderCard(args: DataArgsToMapper<Item>): JSX.Element;
}

function BaSeDataListForwardedRef<Item extends object, Filter>(
  props: React.PropsWithoutRef<DataListProps<Item, Filter>>,
  ref?: React.Ref<BaSeDataElement<Filter>>,
): JSX.Element {
  const {
    itemsPerPage = [3, 5, 10, 15],
    densityLevel = 'normal',
    hasHeader = true,
    renderCard,
    hasSelection,
    compactMode,
    // eslint-disable-next-line comma-dangle
    ...dataProps
  } = props;

  if (typeof renderCard !== 'function') {
    throw Error('[BaSeDataList] "renderCard" inválido');
  }

  return (
    <BaSeData<Item, Filter>
      {...dataProps}
      ref={ref}
      config={[
        {
          header: '',
          accessorKey: 'BaSe--data-list-item' as keyof Item,
          mapper: renderCard,
        },
      ]}
      groupConfig={undefined}
      itemsPerPage={itemsPerPage}
      densityLevel={densityLevel}
      hasSelection={hasSelection}
      hasHeader={hasHeader}
      compactMode={compactMode}
      whenRenderTextCell={() => <></>}
      render={({ table: { rows, page, prepareRow } }) => (
        <DataBodyList densityLevel={densityLevel} compactMode={compactMode}>
          <ul className="BaSe--data-list">
            {(page ?? rows).map((row) => {
              prepareRow(row);
              return (
                <li key={row.id} className="BaSe--data-list-item">
                  {row.cells[0].render('Cell')}
                  {hasSelection && row.cells[1].render('Cell')}
                </li>
              );
            })}
          </ul>
        </DataBodyList>
      )}
    />
  );
}

export const BaSeDataList = React.forwardRef(BaSeDataListForwardedRef) as <
  Item extends object = {},
  Filter = any,
>(
  props: DataListProps<Item, Filter> & {
    ref?: React.Ref<BaSeDataElement<Filter>>;
  },
) => JSX.Element;

BaSeDataList['displayName'] = 'BaSeDataList';
