import * as React from 'react';
import { RootContext } from '../contexts/root';

export const ForbiddenPage: React.FC = () => (
  <RootContext.Consumer>
    {({ forbiddenPage: renderComponent }) =>
      renderComponent?.() ?? (
        <section>
          <header>
            <h1>403</h1>
          </header>
          <p>Na na ni na não… Acesso negado!</p>
          <p>
            <a href="/">Voltar</a> para o ínicio.
          </p>
        </section>
      )
    }
  </RootContext.Consumer>
);
