import styled from 'styled-components';
import { BaSeTheme } from '../../theme';

export const SingleSolutionGrid = styled.div`
  display: grid;
  gap: 0.75em;
  grid-template-columns: 40px 1fr;
`;

export const SystemLogo = styled.img`
  width: 2.5em;
  height: 2.5em;
  min-width: 2.5em;
  min-height: 2.5em;
  border-radius: 1.25em;
  box-shadow: ${BaSeTheme.shadows.profundidade2};
`;

export const SystemLogoWrapper = styled.div`
  width: 2.5em;
  height: 2.5em;
  min-width: 2.5em;
  min-height: 2.5em;
  border-radius: 1.25em;
  box-shadow: ${BaSeTheme.shadows.profundidade2};
  display: grid;
  place-items: center;

  img {
    width: 20px;
    height: 10px;
  }
`;

export const SingleInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
