import styled from 'styled-components';

export const SelectedImage = styled.img.attrs({
  className: 'BaSe--image-picker-selected-image',
  loading: 'lazy',
  decoding: 'async',
})<{ hasRadius: boolean }>`
  height: 75px;
  width: 75px;
  min-height: 75px;
  min-width: 75px;
  max-height: 75px;
  max-width: 75px;
  object-fit: cover;
  border-radius: ${({ hasRadius }) => (hasRadius ? '50%' : '0')};
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
