import { useKeycloak } from '@react-keycloak/web';
import {
  KeycloakError,
  KeycloakInstance,
  KeycloakLoginOptions,
  KeycloakLogoutOptions,
  KeycloakTokenParsed,
} from 'keycloak-js';
import * as React from 'react';
import { log } from '../utils/log';

export interface TokenInfoInterface
  extends Pick<
    KeycloakTokenParsed,
    | 'iss'
    | 'sub'
    | 'aud'
    | 'exp'
    | 'iat'
    | 'auth_time'
    | 'nonce'
    | 'acr'
    | 'amr'
    | 'azp'
    | 'session_state'
    | 'realm_access'
    | 'resource_access'
  > {
  [unmappedKey: string]: any;

  bairro?: string;
  cep?: string;
  cidade?: string;
  cpf?: string;
  dataNascimento?: string;
  email?: string;
  empresas?: any[];
  escolaridade?: string;
  family_name?: string;
  foto?: string;
  genero?: string;
  given_name?: string;
  logradouro?: string;
  name?: string;
  telefoneCelular?: string;
  telefoneResidencial?: string;
  telefoneTrabalho?: string;
  uf?: string;

  'allowed-origins'?: string[];

  address?: any;

  email_verified?: boolean;
  groups?: string[];

  jti?: string;
  locale?: string;
  preferred_username?: string;
  scope?: string;
  typ?: string;
  upn?: string;
}

export interface RoleFilterInterface {
  prefix?: string;
  suffix?: string;
  contains?: string;
}

export interface HookAuthInterface {
  keycloak: KeycloakInstance;
  isInitialized: boolean;
  isAuthenticated: boolean;
  token: string;
  tokenInfo: TokenInfoInterface;
  hasRole(role: string): boolean;
  hasRoles(roles: string[]): boolean;
  getRoles(filter?: RoleFilterInterface): string[];
  countRoles(filter?: RoleFilterInterface): number;
  login(options?: KeycloakLoginOptions): void;
  logout(options?: KeycloakLogoutOptions): void;
}

function getClientId(keycloak: KeycloakInstance): string {
  const suffixes = {
    frontend: '-frontend',
    backend: '-backend',
  };
  type CustomKeycloakInstance = Omit<KeycloakInstance, 'clientId'> & {
    rolesClientId?: string;
    clientId: string;
  };
  const customKeycloak: CustomKeycloakInstance =
    keycloak as CustomKeycloakInstance;
  const clientId =
    customKeycloak?.rolesClientId ??
    (customKeycloak.clientId.includes(suffixes.frontend)
      ? customKeycloak.clientId.replace(suffixes.frontend, suffixes.backend)
      : customKeycloak.clientId);
  return clientId;
}

export function useAuth(): HookAuthInterface {
  let keycloakProvider;
  try {
    keycloakProvider = useKeycloak();
  } catch (_error) {
    keycloakProvider = {
      keycloak: undefined as unknown as KeycloakInstance,
      initialized: undefined as unknown as boolean,
    };
  }

  const { keycloak, initialized: isInitialized } = keycloakProvider;

  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(
    keycloak?.authenticated ?? false,
  );
  const [token, setToken] = React.useState<string>(keycloak?.token ?? '');
  const [tokenInfo, setTokenInfo] = React.useState<TokenInfoInterface>(
    keycloak?.tokenParsed ?? {},
  );

  React.useEffect(() => {
    if (isInitialized === undefined) {
      log('Auth', 'Autenticação desligada');
    }
  }, [isInitialized]);

  const hasRole = React.useCallback(
    (role: string) => {
      if (!role) {
        throw Error('Informe uma role para verificar.');
      }
      const clientId = getClientId(keycloak);
      return keycloak?.hasResourceRole(role, clientId) ?? false;
    },
    [keycloak],
  );

  const hasRoles = React.useCallback(
    (roles: string[]) => {
      if (!Array.isArray(roles)) {
        throw Error('Informe uma lista de roles para verificar.');
      }
      return roles.some((role) => hasRole(role));
    },
    [keycloak, hasRole],
  );

  const getRoles = React.useCallback(
    (filter?: RoleFilterInterface) => {
      const clientId = getClientId(keycloak);
      const roles = keycloak.resourceAccess?.[clientId]?.roles ?? [];
      if (!filter || roles.length === 0) {
        return roles;
      }
      let filteredRoles: string[] = roles;
      if (filter.prefix) {
        filteredRoles = filteredRoles.filter((role) =>
          role.startsWith(filter.prefix as string),
        );
      }
      if (filter.suffix) {
        filteredRoles = filteredRoles.filter((role) =>
          role.endsWith(filter.suffix as string),
        );
      }
      if (filter.contains) {
        filteredRoles = filteredRoles.filter((role) =>
          role.includes(filter.contains as string),
        );
      }
      return filteredRoles;
    },
    [keycloak],
  );

  const countRoles = React.useCallback(
    (filter?: RoleFilterInterface) => getRoles(filter).length,
    [keycloak, getRoles],
  );

  const login = React.useCallback(
    (options?: KeycloakLoginOptions) => keycloak?.login(options),
    [keycloak],
  );

  const logout = React.useCallback(
    (options?: KeycloakLogoutOptions) => keycloak?.logout(options),
    [keycloak],
  );

  const handler = React.useCallback(() => {
    if (!keycloak) {
      return;
    }
    setIsAuthenticated(keycloak.authenticated ?? false);
    setToken(keycloak.token ?? '');
    setTokenInfo(keycloak.tokenParsed ?? {});
  }, [keycloak]);

  React.useEffect(() => {
    if (!keycloak) {
      return;
    }

    keycloak.onReady = () => {
      log('Auth', '😃 pronto');
      handler();
    };

    keycloak.onAuthSuccess = () => {
      log('Auth', '😃 autenticado com sucesso');
      handler();
    };

    keycloak.onAuthRefreshSuccess = () => {
      log('Auth', '😃 atualização da autenticação com sucesso');
      handler();
    };

    keycloak.onAuthError = (error: KeycloakError) => {
      log('Auth', '😖 autenticação falhou', error);
      handler();
    };

    keycloak.onAuthRefreshError = () => {
      log('Auth', '😖 atualização da autenticação falhou');
      handler();
    };

    keycloak.onAuthLogout = () => {
      log('Auth', '👋 fez o logout');
      handler();
    };
  }, [keycloak]);

  return {
    keycloak,
    isInitialized,
    isAuthenticated,
    token,
    tokenInfo,
    hasRole,
    hasRoles,
    getRoles,
    countRoles,
    login,
    logout,
  };
}
