export class HttpErrorModel {
  constructor(
    public message: string,
    public statusCode = 0,
    public errorCode = '',
  ) {}

  toString(): string {
    return `[${this.statusCode}] ${this.message}`;
  }

  toObject(): Pick<HttpErrorModel, 'message' | 'statusCode' | 'errorCode'> {
    return {
      message: this.message,
      statusCode: this.statusCode,
      errorCode: this.errorCode,
    };
  }

  toJSON(indented = true): string {
    return JSON.stringify(this.toObject(), null, indented ? 2 : 0);
  }
}
