import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../utils/string-utils';
import { BaSeTheme } from '../../theme';
import { ThemeBreakpointType } from '../../theme/theme-interface';

export interface GridProps {
  padding?: number | string;
  gap?: number | string;
  delimitedOnBreakpoint?: ThemeBreakpointType;
}

function getValue(unit?: number | string): string | undefined {
  if (unit === undefined) {
    return undefined;
  }
  return numberWithUnit(unit);
}

export const GridWrapper = styled.div<GridProps>`
  margin: 0 auto;
  position: relative;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(12, 1fr);
  width: auto;
  ${({ delimitedOnBreakpoint }) =>
    delimitedOnBreakpoint &&
    css`
      max-width: ${BaSeTheme.breakpoints[delimitedOnBreakpoint].minWidth}px;
    `}
  ${({ padding }) =>
    padding &&
    css`
      padding: ${getValue(padding)};
    `}
  /*mobileSmall*/
  ${({ gap }) =>
    BaSeTheme.breakpoints.mobileSmall.mediaQueryCss(`
    gap: ${getValue(gap) ?? `${BaSeTheme.breakpoints.mobileSmall.gap}px`};
  `)}
  /*mobile*/
  ${({ gap }) =>
    BaSeTheme.breakpoints.mobile.mediaQueryCss(`
    gap: ${getValue(gap) ?? `${BaSeTheme.breakpoints.mobile.gap}px`};
  `)}
  /*mobileLarge*/
  ${({ gap }) =>
    BaSeTheme.breakpoints.mobileLarge.mediaQueryCss(`
    gap: ${getValue(gap) ?? `${BaSeTheme.breakpoints.mobileLarge.gap}px`};
  `)}
  /*tablet*/
  ${({ gap }) =>
    BaSeTheme.breakpoints.tablet.mediaQueryCss(`
    gap: ${getValue(gap) ?? `${BaSeTheme.breakpoints.tablet.gap}px`};
  `)}
  /*desktop*/
  ${({ gap }) =>
    BaSeTheme.breakpoints.desktop.mediaQueryCss(`
    gap: ${getValue(gap) ?? `${BaSeTheme.breakpoints.desktop.gap}px`};
  `)}
  /*desktopLarge*/
  ${({ gap }) =>
    BaSeTheme.breakpoints.desktopLarge.mediaQueryCss(`
    gap: ${getValue(gap) ?? `${BaSeTheme.breakpoints.desktopLarge.gap}px`};
  `)}
`;
