import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';

export interface SolutionsMenuProps {
  isVisible: boolean;
  background: string;
  border: string;
}

export const SolutionsWrapper = styled.div.attrs({
  className: 'BaSe--User-Apps',
})`
  position: relative;
`;

export const SolutionsMenu = styled.div.attrs({
  className: 'BaSe--User-Apps-menu',
})<SolutionsMenuProps>`
  position: absolute;
  top: 2em;
  right: -2em;
  width: 26em;
  border-radius: 0.25em;
  box-sizing: border-box;
  background: ${({ background }) => background};
  z-index: ${BaSeTheme.stackOrder.aboveOverlay};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  border: 1px solid ${({ border }) => border};
  ${({ isVisible }) =>
    isVisible
      ? css`
          height: auto;
          box-shadow: ${BaSeTheme.shadows.profundidade8};
          opacity: 1;
        `
      : css`
          height: 0;
          padding: 0;
          overflow: hidden;
          border: none;
          opacity: 0;
        `};
`;

export const SolutionMenuHeader = styled.div`
  padding: 24px 22px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SolutionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25em;
  padding: 1.25em 1em 0;
`;

export const SolutionsGrid = styled.div`
  padding: 1.25em 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5em;
  row-gap: 1em;
`;

export const InformationWrapper = styled.div`
  padding: 0.75em 1em 0;
`;
