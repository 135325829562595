import { BaSeTheme } from '../../theme';
import styled from 'styled-components';
import { numberWithUnit } from '../../utils/string-utils';

interface ContentProps {
  gap: string | number;
}

interface LabelProps {
  fontSize: string;
  lineHeight: string;
}

export const shapeButtonBorderRadius = {
  circle: '50%',
  square: '4px;',
};

export const Wrapper = styled.div`
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const WrapperLabel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: ${BaSeTheme.stackOrder.aboveNormal};
  background-color: transparent;
  position: relative;
  margin-bottom: 2px;
`;

export const Label = styled.label<LabelProps>`
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
`;

export const SubLabel = styled.label`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: 1.46em;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae60};
  font-style: italic;
  font-weight: normal;
  margin-left: 4px;
`;

export const Content = styled.div<ContentProps>`
  max-width: fit-content;
  display: flex;
  gap: ${({ gap }) => numberWithUnit(gap)};
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 16;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
`;
