import { BaSeTheme } from '../../theme';
import { Color } from '../../utils/color-transformation/color';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';

export const mapSizeCheckbox = {
  small: 13,
  medium: 16,
};

export const mapBadgeValues = (color: string) => {
  color = !color ? 'default' : color;
  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.destructive ||
    color === BaSeTheme.components.button.colors.attention ||
    color === BaSeTheme.components.button.colors.confirmation;
  // color === BaSeTheme.components.button.colors.negative;
  if (colorIsMapped) {
    return {
      primary: {
        color: BaSeTheme.colors.defaultColors.white,
        style: {
          bgC: ColorMapper[color].foreground,
          border: BaSeTheme.colors.defaultColors.white,
        },
      },
      secondary: {
        color: ColorMapper[color].hover.defaultDark,
        style: {
          bgC: ColorMapper[color].hover.defaultLight,
          border: ColorMapper[color].foreground,
        },
      },
      tertiary: {
        color: ColorMapper[color].hover.defaultDark,
        style: {
          bgC: ColorMapper[color].hover.defaultLight,
          border: 'none',
        },
      },
    };
  }
  const customColorHsv = Color.generateFromHex(color);
  return {
    primary: {
      color: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
        border: BaSeTheme.colors.defaultColors.white,
      },
    },
    secondary: {
      color: ColorMapper.dark.hover.create(
        color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
      ),
      style: {
        bgC: ColorMapper.light.hover.create(
          color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
        ),
        border:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
      },
    },
    tertiary: {
      color: ColorMapper.dark.hover.create(
        color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
      ),
      style: {
        bgC: ColorMapper.light.hover.create(
          color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
        ),
        border: 'none',
      },
    },
  };
};
