import * as React from 'react';
import { BaSeDataElement } from '../../../elements/data';
import { BaSeData, DataProps } from '../data';
import { DataListProps } from '../list/list';
import { DataBodyGrid, DataBodyGridProps } from './grid-styled';

export interface DataGridProps<Item extends object, Filter>
  extends Omit<
      DataProps<Item, Filter>,
      'render' | 'whenRenderTextCell' | 'config' | 'groupConfig'
    >,
    Pick<DataBodyGridProps, 'gridColumns'>,
    Pick<DataListProps<Item, Filter>, 'renderCard'> {}

function BaSeDataGridForwardedRef<Item extends object, Filter>(
  props: React.PropsWithoutRef<DataGridProps<Item, Filter>>,
  ref?: React.Ref<BaSeDataElement<Filter>>,
): JSX.Element {
  const {
    gridColumns = 3,
    itemsPerPage = Array(4)
      .fill(gridColumns)
      .map((column, index) => column * (index + 1)),
    densityLevel = 'normal',
    hasSelection,
    compactMode,
    renderCard,
    // eslint-disable-next-line comma-dangle
    ...dataProps
  } = props;

  if (typeof renderCard !== 'function') {
    throw Error('BaSeDataGrid: "renderCard" inválido');
  }

  if (gridColumns <= 1) {
    throw Error(
      '[BaSeDataGrid] Se vai mostrar um item, melhor usar o BaSeDataList, não?',
    );
  }
  return (
    <BaSeData<Item, Filter>
      {...dataProps}
      ref={ref}
      config={[
        {
          header: '',
          accessorKey: 'BaSe--data-grid-item' as keyof Item,
          mapper: renderCard,
        },
      ]}
      groupConfig={undefined}
      itemsPerPage={itemsPerPage}
      densityLevel={densityLevel}
      hasSelection={hasSelection}
      compactMode={compactMode}
      whenRenderTextCell={() => <></>}
      render={({ table: { rows, page, prepareRow } }) => (
        <DataBodyGrid
          gridColumns={gridColumns}
          densityLevel={densityLevel}
          compactMode={compactMode}
        >
          <ul className="BaSe--data-grid">
            {(page ?? rows).map((row) => {
              prepareRow(row);
              return (
                <li key={row.id} className="BaSe--data-grid-item">
                  {row.cells[0].render('Cell')}
                  {hasSelection && row.cells[1].render('Cell')}
                </li>
              );
            })}
          </ul>
        </DataBodyGrid>
      )}
    />
  );
}

export const BaSeDataGrid = React.forwardRef(BaSeDataGridForwardedRef) as <
  Item extends object = {},
  Filter = any,
>(
  props: DataGridProps<Item, Filter> & {
    ref?: React.Ref<BaSeDataElement<Filter>>;
  },
) => JSX.Element;

BaSeDataGrid['displayName'] = 'BaSeDataGrid';
