import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { elevatedCss, getShadow, ShadownizedProps } from '../../theme/shadows';
import { ThemeColorValue } from '../../theme/theme-interface';
import { numberWithUnit } from '../../utils/string-utils';

export interface BoxAppearenceProps extends ShadownizedProps {
  height?: string | number;
  width?: string | number;
  borderRadius?: string | number;
  borderColor: ThemeColorValue;
  backgroundColor: ThemeColorValue;
  isLast: boolean;
}

export const BoxContent = styled.ul.attrs({
  className: 'BaSe--box',
})<Omit<BoxAppearenceProps, 'isLast'>>`
  margin: 0;
  padding: 0;
  will-change: box-shadow, transform;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};

  ${({ height, width, borderRadius }) => css`
    height: ${numberWithUnit(height, 'px', 'auto')};
    width: ${numberWithUnit(width, 'px', 'auto')};
    border-radius: ${numberWithUnit(borderRadius)};
    overflow: ${numberWithUnit(height, 'px', 'auto') !== 'auto'
      ? 'auto'
      : 'visible'};
  `}

  ${({ shadowLevel, elevationOnHoverOrFocus, isElevated }) =>
    shadowLevel &&
    css`
      box-shadow: ${getShadow(shadowLevel)};

      ${isElevated && elevatedCss}

      ${elevationOnHoverOrFocus &&
      css`
        &:hover,
        &:focus-within {
          ${elevatedCss}
        }
      `}
    `}
`;

export const ContentItem = styled.li<
  Pick<BoxAppearenceProps, 'borderColor' | 'isLast'>
>`
  padding: 16px;

  ${({ borderColor, isLast }) =>
    !isLast &&
    css`
      border-bottom: 1px solid ${borderColor};
    `}
`;
