import {
  BaSeBackendNoSignalPage,
  BaSeDialogContainer,
  BaSeErrorPage,
  BaSeForbiddenPage,
  BaSeLoadingPage,
  BaSeNotFoundPage,
  BaSeRoot,
} from '@base/react';
import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { name as appName, version as appVersion } from '../package.json';
import { Routes } from './routes';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  // <React.StrictMode>
  <BaSeRoot>
    <Sebrae.Root
      appName={appName}
      appVersion={appVersion}
      errorPage={(error) => <BaSeErrorPage error={error} />}
      loadingPage={() => <BaSeLoadingPage />}
      notFoundPage={() => <BaSeNotFoundPage />}
      forbiddenPage={() => <BaSeForbiddenPage />}
      backendNoSignalPage={() => <BaSeBackendNoSignalPage />}
    >
      <Routes />
      <BaSeDialogContainer />
    </Sebrae.Root>
  </BaSeRoot>,
  // </React.StrictMode>
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
