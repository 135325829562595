import * as React from 'react';

export interface DataRowInfoContextInterface<Payload> {
  rowInfo: Payload;
  setRowInfo: React.Dispatch<React.SetStateAction<Payload>>;
}

export const BaSeDataRowInfoContext = React.createContext<
  DataRowInfoContextInterface<any>
>({
  rowInfo: undefined,
  setRowInfo: () => {},
});

export const BaSeDataRowInfoProvider: React.FC = ({ children }) => {
  const [rowInfo, setRowInfo] =
    React.useState<DataRowInfoContextInterface<any>>();

  return (
    <BaSeDataRowInfoContext.Provider
      value={{
        rowInfo,
        setRowInfo,
      }}
    >
      {children}
    </BaSeDataRowInfoContext.Provider>
  );
};

export const BaSeDataRowInfoConsumer = BaSeDataRowInfoContext.Consumer;
