import * as React from 'react';
import { BaSeBreakpointContext } from '../contexts/breakpoint';
import { EventBreakpointType } from '../theme/theme-interface';

export function useMenuItemsShrink(
  setMenuItemsShrink: (state: boolean) => void,
  collapsedBreakpoint?: EventBreakpointType,
): void {
  const queryContext = React.useContext(BaSeBreakpointContext);

  const handleMenuItemsShrink = React.useCallback(() => {
    if (!collapsedBreakpoint || !(collapsedBreakpoint in queryContext)) {
      setMenuItemsShrink(true);
    } else {
      let queryMarked = false;
      // eslint-disable-next-line guard-for-in
      for (const query in queryContext) {
        queryMarked += queryContext[query];
        if (query === collapsedBreakpoint) {
          break;
        }
      }
      setMenuItemsShrink(!!queryMarked);
    }
  }, [queryContext]);

  React.useEffect(() => {
    handleMenuItemsShrink();
  }, [handleMenuItemsShrink]);
}
