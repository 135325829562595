import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { mapSizeCheckbox } from './map-checkbox-style';

export type CheckboxShapeProps = 'circle' | 'square';

interface DisabledCheckbox {
  disabled: boolean;
}

interface CheckedCheckbox {
  checked: boolean;
}
interface StyledCheckboxProps extends DisabledCheckbox, CheckedCheckbox {
  name?: string;
  focus: boolean;
  hover: boolean;
  backgroundColor: string;
  backgroundOnHover: string;
  border: string;
  boxShadowFocus: string;
  isHoverable: boolean;
  shape: CheckboxShapeProps;
}

interface StyledLabelProps extends DisabledCheckbox {
  size: 'medium' | 'small';
}

interface CheckboxContainerProps extends DisabledCheckbox {
  verticalAlign: 'flex-end' | 'start' | 'center';
}

interface AlignHorizontal {
  horizontalAlign: 'row' | 'row-reverse';
}

export const checkboxWrapperHeight = 24;
export const checkboxContainerSize = 18;
export const checkboxBorderSize = 2;
export const checkboxIconSize = 16 - checkboxBorderSize;

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: inline-block;
  vertical-align: middle;
  width: ${checkboxContainerSize}px;
  height: ${checkboxWrapperHeight}px;
  position: relative;
  display: grid;
  place-items: center;
  align-self: ${({ verticalAlign }) => verticalAlign};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${BaSeTheme.stackOrder.normal + 1};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const hoverCheckedStyles = (backgroundColor: string) => css`
  background: ${backgroundColor};
  border-color: ${backgroundColor};
  transition: none;
`;

const hoverUncheckedStyles = css`
  border: ${checkboxBorderSize}px solid
    ${BaSeTheme.colors.institucionais.azulSebrae30};
`;

const checkedStyles = (
  backgroundColor: string,
  shape: CheckboxShapeProps,
) => css`
  display: inline-block;
  width: ${checkboxIconSize}px;
  height: ${checkboxIconSize}px;
  border: ${checkboxBorderSize}px solid ${backgroundColor};
  background: ${backgroundColor};
  border-radius: ${shape === 'square' ? '0.25em' : '50%'};
  position: relative;
  transition: ${`background ${BaSeTheme.transitions.durationNormal}`};
`;

const uncheckedStyles = (disabled: boolean, shape: CheckboxShapeProps) => css`
  display: inline-block;
  width: ${checkboxIconSize}px;
  height: ${checkboxIconSize}px;
  border-radius: ${shape === 'square' ? '0.25em' : '50%'};
  background: ${disabled
    ? BaSeTheme.colors.institucionais.cinzaSebrae97
    : BaSeTheme.colors.defaultColors.white};
  border: ${checkboxBorderSize}px solid
    ${disabled
      ? BaSeTheme.colors.defaultColors.lightGray
      : BaSeTheme.colors.institucionais.cinzaSebrae75};
  transition: ${`background ${BaSeTheme.transitions.durationNormal}`};
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  ${(props) =>
    props.checked
      ? checkedStyles(props.backgroundColor, props.shape)
      : uncheckedStyles(props.disabled, props.shape)}

  ${(props) =>
    props.focus &&
    css`
      outline: none;
      box-shadow: 0px 0px 0px 3px ${props.boxShadowFocus};
    `}

  ${(props) =>
    !props.disabled &&
    props.hover &&
    (props.checked
      ? hoverCheckedStyles(props.backgroundOnHover)
      : hoverUncheckedStyles)}

  ${({ isHoverable, disabled, checked, backgroundOnHover }) =>
    isHoverable &&
    css`
      &:hover {
        ${!disabled &&
        (checked
          ? hoverCheckedStyles(backgroundOnHover)
          : hoverUncheckedStyles)}
      }
    `}
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  font-family: ${BaSeTheme.typography.fontFamily};
  line-height: ${checkboxWrapperHeight}px;
  margin: 0 5px;
  font-size: ${({ size }) => mapSizeCheckbox[size] ?? mapSizeCheckbox.medium}px;
  ${({ disabled }) => css`
    color: ${disabled
      ? BaSeTheme.colors.institucionais.cinzaSebrae75
      : BaSeTheme.colors.institucionais.cinzaSebrae30};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;

export const WrapperCheckbox = styled.div.attrs({
  className: 'BaSe--checkbox',
})<AlignHorizontal>`
  display: flex;
  flex-direction: ${({ horizontalAlign }) => horizontalAlign};
  min-height: ${checkboxWrapperHeight}px;
  align-items: center;
  text-align: ${({ horizontalAlign }) =>
    horizontalAlign === 'row' ? 'left' : 'right'};
`;
