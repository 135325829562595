import styled from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { ThemeNamedColor } from '../../../theme/theme-interface';

interface StyledMessageProps {
  color?: ThemeNamedColor;
}

export const MessageColors = {
  default: {
    headingTextColor: BaSeTheme.colors.institucionais.azulSebrae36,
    backgroundColor: BaSeTheme.colors.institucionais.azulSebrae97,
    borderColor: BaSeTheme.colors.institucionais.azulSebrae60,
  },

  attention: {
    headingTextColor: BaSeTheme.colors.feedbackInterface.atencao35,
    backgroundColor: BaSeTheme.colors.feedbackInterface.atencao95,
    borderColor: BaSeTheme.colors.feedbackInterface.atencao55,
  },
  confirmation: {
    headingTextColor: BaSeTheme.colors.feedbackInterface.sucesso20,
    backgroundColor: BaSeTheme.colors.feedbackInterface.sucesso95,
    borderColor: BaSeTheme.colors.feedbackInterface.sucesso45,
  },
  destructive: {
    headingTextColor: BaSeTheme.colors.feedbackInterface.erro35,
    backgroundColor: BaSeTheme.colors.feedbackInterface.erro95,
    borderColor: BaSeTheme.colors.feedbackInterface.erro35,
  },
};

export const StyledMessage = styled.div<StyledMessageProps>`
  border-radius: 0.25em;
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  gap: 0.75em;
  padding: 0.75em;
  align-items: flex-start;
  position: relative;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae30};

  > :first-child {
    flex: 1 1 0%;
  }

  > .BaSe--heading6 {
    margin-bottom: 0.5em;
  }
`;

export const StyledMessageDefault = styled(StyledMessage)`
  border-color: ${MessageColors.default.borderColor};
  background-color: ${MessageColors.default.backgroundColor};

  .BaSe--heading6 {
    color: ${MessageColors.default.headingTextColor};
  }
`;

export const StyledMessageAttention = styled(StyledMessage)`
  border-color: ${MessageColors.attention.borderColor};
  background-color: ${MessageColors.attention.backgroundColor};

  .BaSe--heading6 {
    color: ${MessageColors.attention.headingTextColor};
  }
`;

export const StyledMessageConfirmation = styled(StyledMessage)`
  border-color: ${MessageColors.confirmation.borderColor};
  background-color: ${MessageColors.confirmation.backgroundColor};

  .BaSe--heading6 {
    color: ${MessageColors.confirmation.headingTextColor};
  }
`;

export const StyledMessageDestructive = styled(StyledMessage)`
  border-color: ${MessageColors.destructive.borderColor};
  background-color: ${MessageColors.destructive.backgroundColor};

  .BaSe--heading6 {
    color: ${MessageColors.destructive.headingTextColor};
  }
`;
