import * as React from 'react';
import {
  Row,
  TableProps,
  UsePaginationInstanceProps,
  UseTableInstanceProps,
} from 'react-table';
import { BaSeDataRowInfoProvider } from '../../../contexts/data/row-info';
import {
  BaSeDataSelectionConsumer,
  DataSelectionContextInterface,
} from '../../../contexts/data/selection';
import { BaSeTheme } from '../../../theme';
import { transformLightness } from '../../../utils/color-transformation/transform-lightness';

import { BaSeShapeButton } from '../../button/shape-button/shape-button';

import { BaSeSmall1 } from '../../typography/small/small1';
import { DATA_SELECTION_ID, DataRenderProps } from '../data';
import { rowIsSelected } from '../data-utils';
import { ColumnIdFlaggable, DataTableProps } from './table';
import {
  DataBodyTableProps,
  DataCompactTable,
  DataCompactTables,
  DataStickyCell,
  DataStickyCellContainer,
  DataTableCell,
  DataTableHeader,
  lightness,
} from './table-styled';

export interface DataTableCompactModeProps<
  // eslint-disable-next-line @typescript-eslint/ban-types
  Item extends object = {},
  Filter = any,
> extends Omit<DataRenderProps<Item, Filter>, 'table'>,
    Pick<
      DataTableProps<Item, Filter>,
      | 'columnConfig'
      | 'shadow'
      | 'idAccessorKey'
      | 'densityLevel'
      | 'backgroundColor'
      | 'foregroundColor'
      | 'highlightedColor'
      | 'separatorColor'
      | 'textColor'
      | 'tableHeaderBackgroundColor'
    >,
    Pick<UseTableInstanceProps<Item>, 'rows' | 'getTableProps' | 'prepareRow'>,
    Pick<UsePaginationInstanceProps<Item>, 'page'> {
  columnFilterVisible: ColumnIdFlaggable;
  setColumnFilterVisible: React.Dispatch<
    React.SetStateAction<ColumnIdFlaggable>
  >;
  compactModeExpandable?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface CompactTableProps<Item extends object = {}> {
  tableProps?: TableProps;
  foregroundColor?: string;
  row: Row<Item>;
  stickyColumn: (id: keyof Item | string) => boolean | undefined;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function CompactTable<Item extends object = {}>({
  shadow,
  densityLevel,
  textColor,
  separatorColor,
  tableHeaderBackgroundColor,
  backgroundColor,
  borderColor,
  tableProps,
  row,
  foregroundColor,
  compactModeExpandable,
  stickyColumn,
}: DataBodyTableProps & CompactTableProps<Item>): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(true);
  return (
    <DataCompactTable
      shadow={shadow}
      densityLevel={densityLevel}
      textColor={textColor}
      separatorColor={separatorColor}
      tableHeaderBackgroundColor={tableHeaderBackgroundColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      <table
        {...tableProps}
        aria-expanded={
          compactModeExpandable ? (isOpen ? 'true' : 'false') : undefined
        }
      >
        <thead>
          <tr>
            <td colSpan={2}>
              <DataStickyCellContainer>
                {row.cells
                  .filter((cell) => stickyColumn(cell.column.id))
                  .map((cell, index) => (
                    <DataStickyCell key={cell.column.id}>
                      {compactModeExpandable && index === 0 ? (
                        <BaSeShapeButton
                          nameIcon={isOpen ? 'angle-down' : 'angle-right'}
                          onClick={() => setIsOpen((value) => !value)}
                          type="tertiary"
                        />
                      ) : null}
                      <DataTableCell>{cell.render('Cell')}</DataTableCell>
                    </DataStickyCell>
                  ))}
              </DataStickyCellContainer>
            </td>
          </tr>
        </thead>
        {isOpen && (
          <tbody>
            {row.cells
              .filter((cell) => !stickyColumn(cell.column.id))
              .map((cell) => (
                <tr key={cell.column.id}>
                  <td {...cell.column.getHeaderProps()}>
                    <DataTableHeader>
                      <BaSeSmall1 color={foregroundColor}>
                        {cell.column.render('Header')}
                      </BaSeSmall1>
                    </DataTableHeader>
                  </td>
                  <td {...cell.getCellProps()}>
                    <DataTableCell>{cell.render('Cell')}</DataTableCell>
                  </td>
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </DataCompactTable>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function DataTableCompactMode<Item extends object = {}, Filter = any>({
  page,
  rows,
  idAccessorKey,
  shadow,
  // columnFilterVisible,
  densityLevel,
  columnConfig = [],
  highlightedColor = BaSeTheme.colors.institucionais.azulSebrae,
  foregroundColor = BaSeTheme.colors.institucionais.cinzaSebrae60,
  textColor = BaSeTheme.colors.institucionais.cinzaSebrae30,
  separatorColor = BaSeTheme.colors.institucionais.cinzaSebrae90,
  backgroundColor = BaSeTheme.colors.defaultColors.white,
  tableHeaderBackgroundColor = BaSeTheme.colors.defaultColors.white,
  prepareRow,
  // setColumnFilterVisible,
  // setFilter,
  // setTags,
  getTableProps,
  compactModeExpandable,
}: React.PropsWithoutRef<
  DataTableCompactModeProps<Item, Filter>
>): JSX.Element {
  const stickyColumn = React.useCallback(
    (id: keyof Item | string) =>
      id === DATA_SELECTION_ID ||
      columnConfig?.find(({ columnId: columnId }) => columnId === id)
        ?.stickyOnCompactMode,
    [columnConfig],
  );

  const selected = React.useCallback(
    (row: Row<Item>, isAllSelected: boolean, selectedItems: Item[]) =>
      rowIsSelected({
        itemKey: idAccessorKey as keyof Item,
        item: row.original,
        isAllSelected,
        selectedItems,
      }),
    [idAccessorKey],
  );

  return (
    <DataCompactTables>
      {(page ?? rows).map((row) => {
        prepareRow(row);
        return (
          <BaSeDataRowInfoProvider key={row.id}>
            <BaSeDataSelectionConsumer>
              {({
                isAllSelected,
                selectedItems,
              }: DataSelectionContextInterface<Item>) => (
                <CompactTable
                  compactModeExpandable={compactModeExpandable}
                  shadow={shadow}
                  densityLevel={densityLevel}
                  textColor={textColor}
                  separatorColor={separatorColor}
                  tableHeaderBackgroundColor={
                    selected(row, isAllSelected, selectedItems)
                      ? transformLightness(highlightedColor, lightness)
                      : tableHeaderBackgroundColor
                  }
                  backgroundColor={
                    selected(row, isAllSelected, selectedItems)
                      ? transformLightness(highlightedColor, lightness)
                      : backgroundColor
                  }
                  borderColor={
                    selected(row, isAllSelected, selectedItems)
                      ? highlightedColor
                      : backgroundColor
                  }
                  tableProps={getTableProps({
                    className: 'BaSe--data-table',
                  })}
                  row={row}
                  foregroundColor={foregroundColor}
                  stickyColumn={stickyColumn}
                />
              )}
            </BaSeDataSelectionConsumer>
          </BaSeDataRowInfoProvider>
        );
      })}
    </DataCompactTables>
  );
}
