import * as React from 'react';
import { BaSeTextAreaElement } from '../../elements/text-area';
import { useNextHashId } from '../../hooks/next-id';
import { BaSeTheme } from '../../theme';
import { idGenerator } from '../../utils/id-generator';
import { BaSeHelperText } from '../helpers/helper-text/helper-text';
import { BaSePopupButton } from '../helpers/popup-button/popup-button';
import { InputProps } from '../input/text/input';
import {
  ButtonInfoContainer,
  MoreInfoContainer,
  StyledOuterTextAreaBox,
  StyledTextArea,
  StyledTextAreaContainer,
  StyledTextAreaErrorText,
  StyledTextAreaFooter,
  StyledTextAreaFooterLimit,
  StyledTextAreaLabel,
  StyledTextAreaLabelContainer,
  StyledTextAreaSubLabel,
  WrapperHelper,
} from './text-area-styled';
import { CounterType, useCounter } from '../../hooks/counter';
import { getFormattedDataset } from '../../utils/dataset-utils';

const idSequence = idGenerator();

export interface TextAreaProps
  extends Pick<
    InputProps,
    | 'isDisabled'
    | 'placeholder'
    | 'label'
    | 'value'
    | 'onChange'
    | 'subLabel'
    | 'showHelpButton'
    | 'helpButtonProps'
    | 'moreInfoLabel'
    | 'moreInfoDetails'
    | 'inputStatus'
    | 'id'
    | 'dataset'
  > {
  textSize?: 'medium' | 'small';
  hasCounter?: CounterType;
  counterMinLength?: number;
  counterMaxLength?: number;
  textError?: string;
  name?: string;
  rows?: number;
  complement?: string;
  maxLength?: number;
}

export const BaSeTextArea = React.forwardRef<
  BaSeTextAreaElement,
  TextAreaProps
>(
  (
    {
      id: externalId,
      dataset,
      isDisabled = false,
      placeholder = '',
      label = '',
      textSize = 'medium',
      value = '',
      onChange = () => {},
      textError = '',
      name = '',
      rows = 0,
      subLabel = '',
      helpButtonProps = {},
      showHelpButton = false,
      complement = '',
      moreInfoLabel = '',
      moreInfoDetails = '',
      inputStatus = 'normal',
      hasCounter = 'chars',
      maxLength,
      counterMinLength = 0,
      counterMaxLength = 0,
    },
    forwardedRef,
  ) => {
    if (hasCounter === 'chars' && counterMaxLength > 0) {
      if (counterMaxLength > (maxLength ?? 0)) {
        throw Error(
          '[BaSeTextArea] "maxLength" informado é menor do que o "counterMaxLength"',
        );
      }
      maxLength ??= counterMaxLength;
    }

    const { counterDescription, cropText } = useCounter({
      value,
      hasCounter,
      counterMinLength,
      counterMaxLength,
      onChange,
    });
    const id = externalId ?? useNextHashId(idSequence);
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const [hasFocus, setHasFocus] = React.useState(false);

    const formattedDataset = getFormattedDataset(dataset);

    const updateTextAreaValue = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (hasCounter !== 'chars') {
          return;
        }
        let val = event.target.value;
        if (!!counterMaxLength) {
          val = val.substring(0, counterMaxLength);
        }
        if (maxLength) {
          val = val.substring(0, maxLength);
        }
        onChange(val);
      },
      [counterMaxLength, hasCounter, maxLength, onChange],
    );

    const handleErrorStatus = React.useCallback(
      () => inputStatus === 'invalid',
      [inputStatus, counterMaxLength],
    );

    const giveFocus = React.useCallback(() => {
      textAreaRef.current?.focus();
    }, [textAreaRef.current]);

    React.useImperativeHandle(
      forwardedRef,
      () => ({ focus: () => textAreaRef?.current?.focus?.() }),
      [textAreaRef],
    );

    return (
      <StyledTextAreaContainer>
        <StyledTextAreaLabelContainer>
          <StyledTextAreaLabel size={textSize} htmlFor={id}>
            {label}
          </StyledTextAreaLabel>
          <StyledTextAreaSubLabel size={textSize} htmlFor={id}>
            {subLabel}
          </StyledTextAreaSubLabel>
          {showHelpButton && (
            <ButtonInfoContainer>
              <BaSePopupButton {...helpButtonProps} />
            </ButtonInfoContainer>
          )}
        </StyledTextAreaLabelContainer>
        <StyledOuterTextAreaBox
          disabled={isDisabled}
          hasFocus={hasFocus}
          onClick={() => giveFocus()}
          hasError={handleErrorStatus()}
        >
          <StyledTextArea
            id={id}
            {...formattedDataset}
            maxLength={maxLength}
            disabled={isDisabled}
            hasError={handleErrorStatus()}
            rows={rows}
            name={name}
            ref={textAreaRef}
            value={value}
            onChange={updateTextAreaValue}
            onInput={(event) =>
              counterMaxLength
                ? cropText(event.currentTarget.value)
                : onChange(event.currentTarget.value)
            }
            placeholder={placeholder}
            size={textSize}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
          />
        </StyledOuterTextAreaBox>
        <StyledTextAreaFooter showMultiple={!!complement && !!counterMaxLength}>
          {inputStatus === 'invalid' ? (
            <StyledTextAreaErrorText>{textError}</StyledTextAreaErrorText>
          ) : (
            !!complement && (
              <WrapperHelper>
                <BaSeHelperText
                  color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                  isItalic={true}
                  size="medium"
                  label={complement}
                />
              </WrapperHelper>
            )
          )}
          {hasCounter && (
            <StyledTextAreaFooterLimit
              color={BaSeTheme.colors.institucionais.cinzaSebrae45}
            >
              {counterDescription}
            </StyledTextAreaFooterLimit>
          )}
        </StyledTextAreaFooter>
        {!!moreInfoLabel && (
          <MoreInfoContainer>
            <BaSeHelperText
              size="small"
              label={moreInfoLabel}
              details={moreInfoDetails}
            />
          </MoreInfoContainer>
        )}
      </StyledTextAreaContainer>
    );
  },
);

BaSeTextArea.displayName = 'BaSeTextArea';
