import styled from 'styled-components';
import { numberWithUnit } from '../../../utils/string-utils';

interface StyledContainerSelectList {
  width: number | string;
}

interface StyledSelectList {
  gap: number | string;
}

export const ContainerSelectList = styled.div.attrs({
  className: 'BaSe--select-list-container',
})<StyledContainerSelectList>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => numberWithUnit(width)};
`;

export const WrapperSelectList = styled.div.attrs({
  className: 'BaSe--select-list',
})<StyledSelectList>`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-end;
  gap: ${({ gap }) => numberWithUnit(gap)};
`;
