import { BaSeTheme } from '../../../theme';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';
import { Color } from '../../../utils/color-transformation/color';

export const translationValueToName = {
  0: 'invalid',
  1: 'valid',
  2: 'normal',
  3: 'disabled',
};

export const mapBaSeInputSize = {
  small: 28,
  medium: 36,
  big: 44,
};

export const mapInputValues = (color: string) => {
  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.destructive ||
    color === BaSeTheme.components.button.colors.attention ||
    color === BaSeTheme.components.button.colors.confirmation;
  if (colorIsMapped) {
    return {
      color: ColorMapper[color].foreground,
      style: {
        bgC: ColorMapper[color].foreground,
        boxShadowFocus: `0 0 4px ${ColorMapper[color].focus.defaultLight}`,
        border: ColorMapper[color].foreground,
      },
    };
  } else {
    const customColorHsv = Color.generate(color);
    return {
      color:
        color && customColorHsv
          ? customColorHsv.toString()
          : BaSeTheme.colors.institucionais.azulSebrae36,
      style: {
        bgC:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
        boxShadowFocus: `0 0 4px ${ColorMapper.light.focus.create(
          color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
        )}`,
        border:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
      },
    };
  }
};

export const mapBaSeInputColor = {
  normal: {
    color: BaSeTheme.colors.institucionais.cinzaSebrae60,
    borderColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
    backgroundColor: BaSeTheme.colors.defaultColors.white,
    focusTextColor: BaSeTheme.colors.institucionais.azulSebrae36,
    focusBorderColor: BaSeTheme.colors.institucionais.azulSebrae36,
    boxShadowFocus: BaSeTheme.colors.institucionais.azulSebrae75,
  },
  valid: {
    color: BaSeTheme.colors.feedbackInterface.sucesso20,
    borderColor: BaSeTheme.colors.feedbackInterface.sucesso20,
    backgroundColor: BaSeTheme.colors.defaultColors.white,
    focusTextColor: BaSeTheme.colors.feedbackInterface.sucesso20,
    focusBorderColor: BaSeTheme.colors.feedbackInterface.sucesso20,
    boxShadowFocus: BaSeTheme.colors.feedbackInterface.sucesso70,
  },
  invalid: {
    color: BaSeTheme.colors.feedbackInterface.erro35,
    borderColor: BaSeTheme.colors.feedbackInterface.erro35,
    backgroundColor: BaSeTheme.colors.defaultColors.white,
    focusTextColor: BaSeTheme.colors.feedbackInterface.erro35,
    focusBorderColor: BaSeTheme.colors.feedbackInterface.erro35,
    boxShadowFocus: BaSeTheme.colors.feedbackInterface.erro70,
  },
  disabled: {
    color: BaSeTheme.colors.institucionais.cinzaSebrae75,
    borderColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
    backgroundColor: BaSeTheme.colors.institucionais.cinzaSebrae97,
  },
};

const removeAtributes = (overrider: any) => {
  const storageObj: { valid?: any; invalid?: any } = {};
  if (overrider?.valid) {
    storageObj.valid = {
      borderColor:
        overrider.valid.foreground ?? mapBaSeInputColor.valid.borderColor,
      backgroundColor:
        overrider.valid.background ?? mapBaSeInputColor.valid.backgroundColor,
      focusTextColor:
        overrider.valid.foreground ?? mapBaSeInputColor.valid.focusTextColor,
      focusBorderColor:
        overrider.valid.foreground ?? mapBaSeInputColor.valid.focusBorderColor,
      boxShadowFocus: Color.validateColor(overrider?.valid.foreground)
        ? ColorMapper.light.focus.create(overrider.valid.foreground)
        : mapBaSeInputColor.valid.boxShadowFocus,
    };
  }
  if (overrider?.invalid) {
    storageObj.invalid = {
      color: overrider.invalid.foreground ?? mapBaSeInputColor.invalid.color,
      borderColor:
        overrider.invalid.foreground ?? mapBaSeInputColor.invalid.borderColor,
      backgroundColor:
        overrider.invalid.background ??
        mapBaSeInputColor.invalid.backgroundColor,
      focusTextColor:
        overrider.invalid.foreground ??
        mapBaSeInputColor.invalid.focusTextColor,
      focusBorderColor:
        overrider.invalid.foreground ??
        mapBaSeInputColor.invalid.focusBorderColor,
      boxShadowFocus: Color.validateColor(overrider?.invalid.foreground)
        ? ColorMapper.light.focus.create(overrider.invalid.foreground)
        : mapBaSeInputColor.invalid.boxShadowFocus,
    };
  }
  return { ...storageObj };
};

export const inputColorManager = (color?: string, overrideColor?: any) => {
  if (!color) {
    return { ...mapBaSeInputColor, ...removeAtributes(overrideColor) };
  }
  if (color in ColorMapper) {
    return {
      ...mapBaSeInputColor,
      ...removeAtributes(overrideColor),
      normal: {
        ...mapBaSeInputColor?.normal,
        focusTextColor: ColorMapper[color].foreground,
        focusBorderColor: ColorMapper[color].foreground,
        boxShadowFocus: ColorMapper[color].focus.defaultLight,
      },
    };
  }
  if (!Color.validateColor(color)) {
    return { ...mapBaSeInputColor, ...removeAtributes(overrideColor) };
  }
  return {
    ...mapBaSeInputColor,
    ...removeAtributes(overrideColor),
    normal: {
      ...mapBaSeInputColor?.normal,
      focusTextColor: color,
      focusBorderColor: color,
      boxShadowFocus: ColorMapper.light.focus.create(color),
    },
  };
};

const labelColorsMapper = {
  color: BaSeTheme.colors.institucionais.cinzaSebrae45,
};

const subLabelColorMapper = {
  color: BaSeTheme.colors.institucionais.cinzaSebrae60,
};

const overrideExtraColorsMapper = {
  label: labelColorsMapper.color,
  subLabel: subLabelColorMapper.color,
  border: undefined,
};

export const overrideExtraColors = (overrideColors?: any) => {
  if (!overrideColors) {
    return { ...overrideExtraColorsMapper };
  }
  const overrideObj = { ...overrideExtraColorsMapper };
  const createValidObj = Object.keys(overrideColors);
  createValidObj.forEach((obj) => {
    if (overrideColors[obj] && obj in overrideExtraColorsMapper) {
      overrideObj[obj] = overrideColors[obj];
    }
  });
  return overrideObj;
};
