function addSeparatorToNumber({
  integer,
  classSeparator = '.',
}: {
  integer: string;
  classSeparator?: string;
}): string {
  let fin = '';
  let count = 0;
  for (let i = integer.length - 1; i >= 0; i--) {
    if (count === 3) {
      fin = classSeparator + fin;
      count = 0;
    }

    fin = integer[i] + fin;
    count++;
  }
  return fin;
}

export function decimalMask({
  value = '',
  decimalSeparator = ',',
  classSeparator = '.',
}: {
  value?: string;
  decimalSeparator?: string;
  classSeparator?: string;
}): string {
  let val = value + '';
  if (val) {
    let negative = '';
    if (val[0] === '-') {
      negative = val[0];
    }
    val = val.replace(/\D/g, '');
    if (!val) {
      return '';
    }
    if (val.length < 5) {
      try {
        val = parseFloat(parseInt(val, 10) / 100 + '').toFixed(2) + '';
        const [integer, float] = val.split('.');
        const newInteger = addSeparatorToNumber({
          integer,
          classSeparator,
        });
        val = `${newInteger ?? ''}${decimalSeparator}${float ?? ''}`;
      } catch {
        val = '';
      }
    } else {
      const splitter = val.length - 2;
      const integer = val.slice(0, splitter);
      const float = val.slice(splitter, splitter + 2);
      const newInteger = addSeparatorToNumber({
        integer,
        classSeparator,
      });
      val = `${newInteger ?? ''}${decimalSeparator}${float ?? ''}`;
    }
    return negative ? negative + val : val;
  } else {
    return '';
  }
}

export function removeNonDigit(val: string): string {
  return !!val ? String(val)?.replace(/[\D\s]+/g, '') : '';
}

export function isNegative(val: string): boolean {
  const negativeRegExp = /^-/;
  return negativeRegExp.test(val);
}

export function substringContainMask(target: string, value: string): boolean {
  if (!target || !value) {
    return false;
  }
  const mappedTable = target?.split('')?.map(() => false);
  for (
    let index = 0, targetIndex = 0;
    index <= value.length - 1 && targetIndex <= target.length - 1;
    index++
  ) {
    if (value[index] === target[targetIndex]) {
      mappedTable[targetIndex] = true;
      targetIndex++;
    }
  }
  return mappedTable.findIndex((bol) => bol === false) !== -1;
}

export function forceCurrencyValue({
  value = '',
  decimalSeparator = ',',
}: { value?: string; decimalSeparator?: string } = {}): string {
  const valueSplitted = String(value).split(decimalSeparator);
  const integer = removeNonDigit(valueSplitted[0]);
  const decimal = valueSplitted[1]
    ? valueSplitted[1]?.length === 2
      ? valueSplitted[1]
      : valueSplitted[1] + '0'
    : '00';
  const hasNegativeSign = isNegative(valueSplitted[0]);
  return parseFloat(
    `${hasNegativeSign ? '-' : ''}${integer}.${decimal}`,
  ).toFixed(2);
}
