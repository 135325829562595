import { BaSeTheme } from '../../../theme';
import { Color } from '../../../utils/color-transformation/color';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';

export const mapSizeRadio = {
  small: 13,
  medium: 16,
};

export const mapRadioValues = (color: string) => {
  const isDisabled = color === 'disabled';
  return {
    primary: {
      generalColors: {
        iconColor: BaSeTheme.colors.defaultColors.white,
        style: {
          bgC: ColorMapper[color].foreground,
          bcCHover: isDisabled
            ? BaSeTheme.colors.defaultColors.lightGray
            : ColorMapper[color].hover.defaultDark,
          boxShadowFocus: isDisabled
            ? 'none'
            : ColorMapper[color].focus.defaultLight,
          border: isDisabled
            ? ColorMapper.disabled.foreground
            : ColorMapper[color].foreground,
        },
      },
      negative: {
        iconColor: ColorMapper[color].foreground,
        style: {
          bgC: BaSeTheme.colors.defaultColors.white,
          bcCHover: isDisabled
            ? BaSeTheme.colors.defaultColors.lightGray
            : BaSeTheme.colors.institucionais.cinzaSebrae97,
          boxShadowFocus: isDisabled
            ? 'none'
            : BaSeTheme.colors.institucionais.azulSebrae90,
          border: isDisabled ? ColorMapper.disabled.foreground : 'none',
        },
      },
    },
  };
};

export const mapCustomColorsRadioValues = (customColor: string) => {
  const customColorHsv = Color.generateFromHex(customColor);

  return {
    primary: {
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC:
          customColor && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
        bcCHover: ColorMapper.dark.hover.create(
          customColor
            ? customColor
            : BaSeTheme.colors.institucionais.azulSebrae36,
        ),
        boxShadowFocus: ColorMapper.light.focus.create(
          customColor
            ? customColor
            : BaSeTheme.colors.institucionais.azulSebrae36,
        ),
        border:
          customColor && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
      },
    },
  };
};
