import styled from 'styled-components';
import { BaSeTheme } from '../../../theme';

interface StyledSpanProps {
  iconColor: string | null;
  iconSize: number | null;
  isBoldIcon: boolean;
}

export const StyledAvatar = styled.img.attrs({
  className: 'BaSe--avatar BaSe--avatar-image',
})`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const StyledSpan = styled.span.attrs({
  className: 'BaSe--avatar BaSe--avatar-nameAbbreviation',
})<StyledSpanProps>`
  font-family: ${BaSeTheme.typography.fontFamily};
  color: ${(props) => props.iconColor};
  font-size: ${(props) => props.iconSize}px;
  font-weight: ${(props) => (props.isBoldIcon ? 'bold' : 'normal')};
`;
