import styled, { css, keyframes } from 'styled-components';
import { ThemeColorValue } from '../../../theme/theme-interface';
import { BaSeTheme } from '../../../theme';
import { DataInterfaceProps, spacePixels } from '../data-styled';

export interface DataFilterAreaWrapperProps
  extends Pick<DataInterfaceProps, 'separatorColor' | 'hasHeader'> {
  filterAreaVisible?: boolean;
  filterAreaBackgroundColor?: ThemeColorValue;
  headerBackgroundColor?: ThemeColorValue;
}

const showUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
`;

export const showUpAnimation = css`
  transform-origin: center;
  will-change: opacity, transform;
  animation: ${showUp} ${BaSeTheme.transitions.durationFast}
    ${BaSeTheme.transitions.continuousTimingFunction} 1;
`;

export const FilterArea = styled.div<DataFilterAreaWrapperProps>`
  flex: 1 100%;
  padding: ${({ hasHeader }) => (hasHeader ? `16px ${spacePixels}` : 0)};
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > * {
    ${showUpAnimation}
  }
`;

export const ActivatedFilters = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
`;

export const CustomFilterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 8px;
`;

export const DataFilterAreaWrapper = styled.div.attrs({
  className: 'BaSe--data-filter-area',
})<DataFilterAreaWrapperProps>`
  transition: max-height ${`
    ${BaSeTheme.transitions.durationFast}
    ${BaSeTheme.transitions.continuousTimingFunction}
  `};
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: content-box;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  border: none;
  will-change: max-height, margin-bottom, overflow;
  background-color: ${({ filterAreaBackgroundColor }) =>
    filterAreaBackgroundColor};

  ${({ separatorColor, hasHeader, filterAreaVisible, headerBackgroundColor }) =>
    separatorColor &&
    hasHeader &&
    filterAreaVisible &&
    headerBackgroundColor !== BaSeTheme.colors.defaultColors.transparent &&
    css`
      border-top: 1px solid ${separatorColor};
    `}

  ${({ filterAreaVisible }) =>
    filterAreaVisible
      ? css`
          max-height: 100%;
          margin-bottom: 0;
        `
      : css`
          max-height: 0;
          margin-bottom: -100%;
        `}

  ${({ hasHeader, headerBackgroundColor }) =>
    (!hasHeader ||
      headerBackgroundColor === BaSeTheme.colors.defaultColors.transparent) &&
    css`
      border-radius: 8px 8px 0 0;
    `}
`;
