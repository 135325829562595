import * as React from 'react';
import { BaSeI18nConsumer } from '../../../contexts/i18n';
import { BaSeTheme } from '../../../theme';
import { Color } from '../../../utils/color-transformation/color';
import { objectNotEmpty } from '../../../utils/object-utils';
import { BaSePopover } from '../../alerts/popover/popover';
import { BaSeTooltip } from '../../alerts/tooltip/tooltip';
import { BaSeIcon } from '../../image/icon';
import { BaSeLoadingSpinner } from '../../loading/spinner';
import { BaSeSmall2 } from '../../typography/small/small2';
import { ButtonProps } from '../button-props';
import {
  mapCustomColorsShapeButtonValues,
  shapeButtonBorderRadius,
  shapeButtonIconMapSize,
  shapeButtonMapStyle,
} from '../map-button-style';
import * as Styled from './shape-button-styled';
import { getFormattedDataset } from '../../../utils/dataset-utils';

const extraSmallIconSize = { iconSize: 12 };

const widthMap = {
  'mega-small': 1,
  'extra-small': 1.25,
  small: 2,
  medium: 2.5,
  big: 3,
};

export interface ShapeButtonProps
  extends Omit<
    ButtonProps,
    | 'textColor'
    | 'direction'
    | 'isBig'
    | 'isBold'
    | 'leftIcon'
    | 'descriptionLeftIcon'
    | 'rightIcon'
    | 'descriptionRightIcon'
    | 'spacedIcons'
    | 'width'
    | 'loadingText'
    | 'size'
  > {
  size?: 'mega-small' | 'extra-small' | 'small' | 'medium' | 'big';
}

export const BaSeShapeButton: React.FC<ShapeButtonProps> = ({
  id,
  name,
  dataset,
  nameIcon,
  descriptionIcon,
  value,
  type = 'primary',
  color = BaSeTheme.components.button.colors.default,
  shape = 'circle',
  sizeIcon = 'small',
  size = 'medium',
  isDisabled = false,
  buttonType = 'button',
  sideButton = 'none',
  autoFocus = false,
  isLoading = false,
  tooltip = {},
  popover = {},
  onClick,
  onFocus,
  onBlur,
}) => {
  if (!nameIcon && !value) {
    throw Error(
      '[BaSeShapeButton] error: Nome do ícone ou um valor textual é obrigatório!',
    );
  }
  if (objectNotEmpty(tooltip) && objectNotEmpty(popover)) {
    throw Error(
      '[BaSeShapeButton] error: Informe só o "tooltip" ou o "popover"!',
    );
  }

  const borderRadius =
    shapeButtonBorderRadius[shape] ?? shapeButtonBorderRadius.circle;

  const { iconSize } = React.useMemo(
    () =>
      size === 'extra-small' || size === 'mega-small'
        ? extraSmallIconSize
        : shapeButtonIconMapSize[sizeIcon] ?? shapeButtonIconMapSize.medium,
    [size, sizeIcon],
  );

  const padding = React.useMemo(
    () =>
      // TODO molhorar código
      size === 'big'
        ? (40 - iconSize) / 2 + 4
        : size === 'medium'
          ? (40 - iconSize) / 2
          : size === 'small' || size === 'extra-small'
            ? 4
            : 1.5,
    [size, iconSize],
  );

  const width = React.useMemo(() => widthMap?.[size ?? 'medium'] ?? 0, [size]);

  if (width <= 0) {
    throw Error(
      `[BaSeShapeButton] error: Informe o "size" com uma das opções:
        - "mega-small"
        - "extra-small"
        - "small"
        - "medium"
        - "big"`,
    );
  }

  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.negative ||
    color === BaSeTheme.components.button.colors.destructive ||
    color === BaSeTheme.components.button.colors.confirmation;

  const colorIsNegative = color === BaSeTheme.components.button.colors.negative;

  const getColorsAtributes = () => {
    if (color) {
      if (!colorIsMapped) {
        if (Color.validateColor(color as string)) {
          return mapCustomColorsShapeButtonValues(color as string)?.[type];
        }
        return shapeButtonMapStyle('default')[type]?.standard;
      }
      return shapeButtonMapStyle(color as string)[type]?.standard;
    }
    return shapeButtonMapStyle('default')[type]?.standard;
  };

  const typeMapped = colorIsNegative
    ? isDisabled
      ? shapeButtonMapStyle('default')[type]?.negative.disabled
      : shapeButtonMapStyle('default')[type]?.negative
    : isDisabled
      ? shapeButtonMapStyle('default')[type]?.standard.disabled
      : getColorsAtributes();

  const {
    background,
    border,
    backgroundOnFocus,
    iconColor,
    hoverColor,
    boxShadowFocus,
    borderHoverColor,
    iconHoverColor,
  } = typeMapped;

  const [isHover, setIsHover] = React.useState<boolean>(false);
  const [isTooltipOrPopoverOpen, setIsTooltipOrPopoverOpen] =
    React.useState(false);

  const formattedDataset = getFormattedDataset(dataset);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      buttonRef?.current?.focus();
      onClick(event);
      const timeout = setTimeout(() => {
        if (buttonRef.current?.isConnected) {
          setIsTooltipOrPopoverOpen(false);
        }
      }, 1);
      return () => clearTimeout(timeout);
    },
    [onClick, buttonRef],
  );

  return (
    <Styled.ShapeButton
      id={id}
      name={name}
      {...formattedDataset}
      ref={buttonRef}
      width={width}
      autoFocus={autoFocus}
      disabled={isDisabled}
      type={buttonType}
      backgroundOnFocus={backgroundOnFocus}
      border={isHover && !!borderHoverColor ? borderHoverColor : border}
      borderRadius={borderRadius}
      backgroundColor={background}
      backgroundOnHover={hoverColor}
      boxShadowOnFocus={
        size.includes('small')
          ? boxShadowFocus.replace('3', '2')
          : boxShadowFocus
      }
      padding={border === 'none' ? padding : padding - 1}
      sideButton={sideButton}
      onMouseEnter={() => {
        setIsHover(true);
        setIsTooltipOrPopoverOpen(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
        setIsTooltipOrPopoverOpen(false);
      }}
      onClick={handleClick}
      onFocus={(e) => {
        onFocus?.(e);
        setIsTooltipOrPopoverOpen(true);
      }}
      onBlur={(e) => {
        onBlur?.(e);
        setIsTooltipOrPopoverOpen(false);
      }}
    >
      <BaSeI18nConsumer>
        {({ getMessage }) =>
          isLoading ? (
            <BaSeLoadingSpinner
              diameter={iconSize}
              color={isHover && !!iconHoverColor ? iconHoverColor : iconColor}
              description={getMessage('loading.description')}
              disabled={isDisabled}
              type="tertiary"
            />
          ) : nameIcon ? (
            <BaSeIcon
              description={
                descriptionIcon?.trim() ||
                getMessage('buttonIcon.defaultDescription')
              }
              name={nameIcon}
              size={iconSize}
              color={isHover && !!iconHoverColor ? iconHoverColor : iconColor}
              colorTransition="fast"
            />
          ) : (
            <div
              style={{
                width: iconSize,
                height: iconSize,
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <BaSeSmall2
                color={isHover && !!iconHoverColor ? iconHoverColor : iconColor}
              >
                {value}
              </BaSeSmall2>
            </div>
          )
        }
      </BaSeI18nConsumer>
      {objectNotEmpty(tooltip) && (
        <BaSeTooltip
          buddyRef={buttonRef}
          open={isTooltipOrPopoverOpen}
          {...(tooltip as any)}
        />
      )}
      {objectNotEmpty(popover) && (
        <BaSePopover
          buddyRef={buttonRef}
          open={isTooltipOrPopoverOpen}
          hasCloseButton={false}
          {...(popover as any)}
        />
      )}
    </Styled.ShapeButton>
  );
};

BaSeShapeButton.displayName = 'BaSeShapeButton';
