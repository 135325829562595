import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../../utils/string-utils';

interface StyledContainerInputList {
  width: number | string;
}

export const ContainerInputList = styled.div.attrs({
  className: 'BaSe--input-list-container',
})<StyledContainerInputList>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => numberWithUnit(width)};
`;

export const WrapperInputList = styled.div<{ isFlex: boolean }>`
  ${({ isFlex }) => css`
    display: ${isFlex ? 'flex' : 'block'};
    flex-direction: row;
    position: relative;
    align-items: stretch;
  `}
`;
