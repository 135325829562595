import styled, { keyframes } from 'styled-components';
import { BaSeTheme } from '../../theme';

export interface TransitionProps {
  delay: string;
}

export interface RotateTransitionProps extends TransitionProps {
  rotation: number;
}

export interface SlideDownTransitionProps extends TransitionProps {
  initialOpacity: number;
}

const rotate = ({ rotation }: RotateTransitionProps) => keyframes`
  40% {
    transform: scale(.8);
  }
  100% {
    transform: rotate(${rotation}deg) scale(1);
  }
`;

const slideDown = ({ initialOpacity }: SlideDownTransitionProps) => keyframes`
  0% {
    opacity: ${initialOpacity};
    transform: translateY(-100%);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const RotateTransitionStyled = styled.span<RotateTransitionProps>`
  display: inline-block;
  animation: ${(props) => rotate(props)} ${BaSeTheme.transitions.durationSlow}
    ${({ delay }) => delay} ${BaSeTheme.transitions.elasticTimingFunction}
    forwards;
`;

export const SlideDownTransitionStyled = styled.span<SlideDownTransitionProps>`
  display: inline-block;
  opacity: ${({ initialOpacity }) => initialOpacity};
  transform-origin: center;
  animation: ${(props) => slideDown(props)}
    ${BaSeTheme.transitions.durationSlow} ${({ delay }) => delay}
    ${BaSeTheme.transitions.elasticTimingFunction} forwards;
`;
