import * as React from 'react';
import { ThemeColorValue } from '../../theme/theme-interface';
import { BaSeTheme } from '../../theme';
import { idGenerator } from '../../utils/id-generator';
import { useNextHashId } from '../../hooks/next-id';
import * as Styled from './rating-styled';
import { BaSeSmall1 } from '../typography/small/small1';
import {
  BaSeShapeButton,
  ShapeButtonProps,
} from '../button/shape-button/shape-button';

const idSequence = idGenerator();

export interface RatingItem
  extends Pick<
    ShapeButtonProps,
    'value' | 'color' | 'nameIcon' | 'autoFocus' | 'tooltip' | 'popover'
  > {
  colorSelected?: ThemeColorValue;
  nameIconSelected?: string;
}

export interface RatingProps
  extends Pick<ShapeButtonProps, 'shape' | 'size' | 'isDisabled' | 'sizeIcon'> {
  id?: string;
  values: RatingItem[];
  selected?: number;
  label?: string;
  subLabel?: string;
  rangeInformation?: string[];
  isFillUntilSelected?: boolean;
  gap?: number | string;
  onSelect(index?: number): void;
}

export const BaSeRating: React.FC<RatingProps> = ({
  id: externalId,
  values = [],
  selected,
  size = 'medium',
  label,
  subLabel,
  rangeInformation,
  shape = 'square',
  sizeIcon = 'medium',
  isDisabled,
  isFillUntilSelected = true,
  gap = '16px',
  onSelect,
}) => {
  const id = externalId ?? useNextHashId(idSequence);

  if (values.length === 0) {
    throw Error('[BaSeRating] error: O campo values é obrigatório!');
  }
  if (values.length < 2) {
    throw Error(
      '[BaSeRating] error: Para o campo values é necessário passar uma lista com pelo menos 2 valores',
    );
  }

  const getTypeUnselected = React.useCallback(
    (item: RatingItem) => (!item.nameIcon ? 'secondary' : 'tertiary'),
    [],
  );

  const isSelected = React.useCallback(
    (index: number, exactly = false) =>
      selected !== undefined && !exactly && isFillUntilSelected
        ? selected >= index
        : selected === index,
    [isFillUntilSelected, selected],
  );

  const getTypeSelected = React.useCallback(
    (item: RatingItem) =>
      item.colorSelected || item.nameIconSelected
        ? getTypeUnselected(item)
        : 'primary',
    [getTypeUnselected],
  );

  const type = React.useCallback(
    (item: RatingItem, index: number) =>
      isSelected(index) ? getTypeSelected(item) : getTypeUnselected(item),
    [isFillUntilSelected, selected, getTypeSelected, getTypeUnselected],
  );

  const getId = React.useCallback(
    (index: number) =>
      selected === undefined && index === 0
        ? id
        : isSelected(index, true)
          ? id
          : undefined,
    [selected],
  );

  const getNameIcon = React.useCallback(
    (item: RatingItem, index: number) =>
      isSelected(index) && item.nameIconSelected
        ? item.nameIconSelected
        : item.nameIcon,
    [selected],
  );
  const getColor = React.useCallback(
    (item: RatingItem, index: number) =>
      isSelected(index) && item.colorSelected ? item.colorSelected : item.color,
    [selected],
  );

  return (
    <Styled.Wrapper>
      <Styled.WrapperLabel>
        <Styled.Label
          fontSize={size === 'small' ? '13px' : '16px'}
          lineHeight={size === 'small' ? '16px' : '23px'}
          htmlFor={id}
        >
          {label}
        </Styled.Label>
        <Styled.SubLabel htmlFor={id}>{subLabel}</Styled.SubLabel>
      </Styled.WrapperLabel>

      <Styled.Content gap={gap}>
        {values.map((item, index) => (
          <BaSeShapeButton
            {...item}
            id={getId(index)}
            isDisabled={isDisabled}
            key={index}
            shape={shape}
            size={size}
            type={type(item, index)}
            nameIcon={getNameIcon(item, index)}
            color={getColor(item, index)}
            sizeIcon={sizeIcon}
            onClick={() => onSelect(index)}
          />
        ))}
      </Styled.Content>

      <Styled.LabelWrapper>
        {rangeInformation &&
          rangeInformation.map((value, key) => (
            <BaSeSmall1
              key={key}
              color={BaSeTheme.colors.institucionais.cinzaSebrae30}
            >
              {value}
            </BaSeSmall1>
          ))}
      </Styled.LabelWrapper>
    </Styled.Wrapper>
  );
};

BaSeRating.displayName = 'BaSeRating';
