import * as React from 'react';
import { RootContext } from '../contexts/root';

export const ErrorPage: React.FC<{ error: string }> = ({ error }) => (
  <RootContext.Consumer>
    {({ errorPage: renderComponent }) =>
      renderComponent?.(error) ?? (
        <section
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'grid',
            placeItems: 'center',
            color: '#f66',
          }}
        >
          <div>
            <p>:( Poxa! Deu ruim…</p>
            <code>{error?.toString()}</code>
          </div>
        </section>
      )
    }
  </RootContext.Consumer>
);
