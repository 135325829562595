import { BaSeButton, BaSeLoading, BaSeParagraph, BaSeTheme } from '@base/react';
import * as React from 'react';
import {
  FontBold,
  InforContainer,
  ResultCard,
  ResultSearch,
} from '../pages/report/report-styled';

export const CustomResultSearchCard = ({
  searchResult,
  loading,
  quantity,
  cnpj,
  instituicao,
  onSelectIF,
  ifPrioritaria,
  agrupadoData,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  return (
    <>
      {loading ? (
        <div>
          <BaSeLoading roundedCorner={true} />
        </div>
      ) : (
        searchResult && (
          <ResultCard>
            <ResultSearch>
              {quantity === 1 ? (
                <>
                  <BaSeParagraph
                    isBold
                    color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                  >
                    Resultado da pesquisa
                  </BaSeParagraph>
                  <br />
                  <BaSeParagraph
                    isBold
                    color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                  >
                    CNPJ: {cnpj}
                  </BaSeParagraph>
                  <BaSeParagraph
                    isBold
                    color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                  >
                    Instituição financeira prioritária: {ifPrioritaria}
                  </BaSeParagraph>
                  <br />
                  <BaSeParagraph
                    color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                  >
                    Clique no botão para selecionar a instituição financeira
                    prioritária para atender o cliente selecionado.
                  </BaSeParagraph>
                  <br />
                  <BaSeButton
                    value="Selecionar IF para vincular"
                    onClick={onSelectIF}
                    type="secondary"
                    color={BaSeTheme.colors.institucionais.azulSebrae36}
                  />
                </>
              ) : quantity > 1 ? (
                <>
                  <BaSeParagraph
                    isBold
                    color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                  >
                    Resultado da pesquisa: Foram encontrados {quantity}{' '}
                    clientes.
                  </BaSeParagraph>
                  <br />
                  {agrupadoData.map((item, index) => (
                    <InforContainer
                      key={index}
                      color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                      isBold
                    >
                      <FontBold> {item.instituicaoFinanceiraNome}:</FontBold>
                      {''} {item.qtdSolicitacoes}
                    </InforContainer>
                  ))}
                  <br />
                  <BaSeParagraph
                    color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                  >
                    Clique no botão para selecionar a instituição financeira
                    prioritária para atender os clientes selecionados.
                  </BaSeParagraph>
                  <br />
                  <BaSeButton
                    value="Selecionar IF para vincular"
                    onClick={onSelectIF}
                    type="secondary"
                    color={BaSeTheme.colors.institucionais.azulSebrae36}
                  />
                </>
              ) : (
                <BaSeParagraph
                  color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                >
                  Não foram encontrados registros conforme parâmetros da
                  pesquisa.
                </BaSeParagraph>
              )}
            </ResultSearch>
          </ResultCard>
        )
      )}
    </>
  );
};
