import * as React from 'react';
import { RootContext } from '../contexts/root';

export const LoadingPage: React.FC = () => (
  <RootContext.Consumer>
    {({ loadingPage: renderComponent }) =>
      renderComponent?.() ?? (
        <section
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <p>Carregando… Aguarde.</p>
        </section>
      )
    }
  </RootContext.Consumer>
);
