import { dateValidator } from './validators/date-validator';
import { cpfValidator } from './validators/cpf-validator';
import { cnpjValidator } from './validators/cnpj-validator';
import { cepValidator } from './validators/cep-validator';
import { emailValidator } from './validators/email-validator';
import { decimalValidator } from './validators/number-validator';

export class ValidatorUtil {
  static readonly validateCpf = (value: string, required = true) => {
    if (!required && !value) {
      return true;
    }
    return cpfValidator(value);
  };

  static readonly validateCnpj = (value: string, required = true) => {
    if (!required && !value) {
      return true;
    }
    return cnpjValidator(value);
  };

  static readonly validateDate = (value: string, required = true) => {
    if (!required && !value) {
      return true;
    }
    return dateValidator(value);
  };

  static readonly validateCep = (value: string, required = true) => {
    if (!required && !value) {
      return true;
    }
    return cepValidator(value);
  };

  static readonly validateEmail = (value: string, required = true) => {
    if (!required && !value) {
      return true;
    }
    return emailValidator(value);
  };

  static readonly validateDecimalNumber = (value: string, required = true) => {
    if (!required && !value) {
      return true;
    }
    return decimalValidator(value);
  };

  // TODO colocar o tipo
  //   `' cnpj' | 'cep' | 'email' | 'data' | 'date' | 'decimal' | 'cpf'`
  //      no arg `mask`
  static readonly checkValidator = (mask: string | RegExp) => {
    const mappedValues = {
      cnpj: ValidatorUtil.validateCnpj,
      cep: ValidatorUtil.validateCep,
      email: ValidatorUtil.validateEmail,
      data: ValidatorUtil.validateDate,
      date: ValidatorUtil.validateDate,
      decimal: ValidatorUtil.validateDecimalNumber,
      cpf: ValidatorUtil.validateCpf,
    };
    if (
      typeof mask === 'string' &&
      ['cnpj', 'cep', 'email', 'data', 'date', 'decimal', 'cpf'].includes(mask)
    ) {
      return mappedValues[mask as keyof typeof mappedValues];
    }
    return (_value: string, _required?: boolean) => false;
  };
}
