import { BaSeTheme } from '@base/react';
import styled from 'styled-components';

export const Footer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;

  footer {
    display: block;
  }
`;

export const SideMenu = styled.div`
  background: ${BaSeTheme.colors.institucionais.cinzaSebrae97};
  height: 1080px;
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  overflow: visible;
  position: relative;
  background-color: rgb(224, 229, 235);
`;

export const Link = styled.div`
  :hover {
    background: ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  }
`;
