import * as React from 'react';
import { SideMenuMainItem, SideMenuChildItem } from './item-styled';
import { BaSeIcon } from '../../image/icon';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { SideMenuColors, colorMappedCustom } from './item-map';

import { BaSeParagraph } from '../../typography/paragraph/paragraph';
import { BaSeSmall1 } from '../../typography/small/small1';

export interface SideMenuItenProp {
  label?: string;
  callback?: () => void;
  subItens?: Omit<SideMenuItenProp, 'subItens'>[];
  active?: boolean;
  icon?: string;
  theme?: string;
  activeIndex?: number;
  activeCallback?: any;
  color?: string;
  insideIndex?: number;
}

// styled interfaces
export interface SideMenuItemStyledProp {
  textColor: string;
  hoverTextColor: string;
  selectedTextColor: string;
  childrenActiveTextColor: string;
  bottomLineColor: string;
}

export const BaSeSideMenuItem: React.FC<SideMenuItenProp> = ({
  label,
  callback,
  subItens,
  active,
  icon,
  theme,
  activeCallback,
  activeIndex,
  insideIndex,
  color,
}) => {
  const menuColors = !!color
    ? colorMappedCustom(color)[theme ?? 'light'] ??
      colorMappedCustom(color).dark
    : SideMenuColors[theme ?? 'light'] ?? SideMenuColors.dark;
  const [activeIndexChild, setActiveIndexChild] = React.useState(-1);

  const [hasHover, setHasHover] = React.useState<boolean>(false);
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);

  const handleStateColor = React.useCallback(() => {
    if (hasHover || hasFocus) {
      return menuColors?.hoverTextColor;
    }
    if (insideIndex === activeIndex) {
      return menuColors?.selectedTextColor;
    }
    return menuColors?.textColor;
  }, [hasHover, hasFocus, menuColors, insideIndex, activeIndex]);

  const { getMessage } = React.useContext(BaSeI18nContext);

  const handleCallback = () => {
    callback?.();
    activeCallback?.(insideIndex);
  };

  const handleChildrenCallback = (index: number, fun?: () => void) => {
    fun?.();
    setActiveIndexChild?.(index);
  };

  React.useEffect(() => {
    if (active) {
      activeCallback?.(insideIndex);
    }
  }, [active]);

  React.useEffect(() => {
    if (activeIndex !== insideIndex) {
      setActiveIndexChild(-1);
    }
  }, [activeIndex, insideIndex]);

  React.useEffect(() => {
    subItens?.forEach((item, key) => {
      if (item?.active) {
        setActiveIndexChild(key);
        activeCallback?.(insideIndex);
        return;
      }
    });
  }, [subItens]);

  return (
    <>
      <SideMenuMainItem
        tabIndex={0}
        onClick={handleCallback}
        {...menuColors}
        onMouseEnter={() => setHasHover(true)}
        onMouseLeave={() => setHasHover(false)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        active={insideIndex === activeIndex}
      >
        {!!icon && (
          <BaSeIcon
            size={13}
            name={icon}
            description={getMessage('icon.label', label as string)}
            color={handleStateColor()}
          />
        )}
        <BaSeParagraph color={menuColors.textColor} isBold={true}>
          {label}
        </BaSeParagraph>
      </SideMenuMainItem>
      {insideIndex === activeIndex && !!subItens && subItens.length && (
        <ul style={{ paddingLeft: 20 }}>
          {subItens.map(
            ({ label: childLabel, callback: childCallback }, key) => {
              const isActive = activeIndexChild === key;
              return (
                <SideMenuChildItem
                  tabIndex={0}
                  active={isActive}
                  {...menuColors}
                  onClick={() => handleChildrenCallback(key, childCallback)}
                  key={key}
                >
                  <BaSeSmall1
                    isBold={isActive}
                    color={
                      isActive
                        ? menuColors.childrenActiveTextColor
                        : menuColors.textColor
                    }
                  >
                    {childLabel}
                  </BaSeSmall1>
                </SideMenuChildItem>
              );
            },
          )}
        </ul>
      )}
    </>
  );
};
