import * as React from 'react';

export type FetchColumnResult = BoardItem[] | Promise<BoardItem[]>;

export interface BoardColumn {
  id: number | string;
  index?: number;
  header: HeaderProps;
  items: BoardItems;
  backgroundColor?: string;
  params: any;
  isDropDisabled?: boolean;
  textWithoutItems?: string;
}

export interface BoardItemEvent {
  item: Pick<BoardItem, 'id'>;
  columnOrigin?: Pick<BoardColumn, 'id' | 'index'>;
  columnTarget?: Pick<BoardColumn, 'id' | 'index'>;
  // type: 'DRAG' | 'DROP' | '...';
}

export interface BoardColumnEvent {
  column: BoardColumn;
  type: 'SCROLL' | 'RELOAD' | 'INITIAL';
}

export interface BoardItem {
  id: number | string;
  index?: number;
  isDragDisabled?: boolean;
  render: (item: BoardItem) => string | JSX.Element;
}
export type BoardItems = BoardItem[];

export interface HeaderProps {
  title: string;
  subTitle?: string;
  foregroundColor?: string;
  backgroundColor?: string;
  render?: (items: BoardItems) => JSX.Element;
}

type BoardColumns = BoardColumn[];

export interface BoardContext {
  columns: BoardColumns;
  setColumns: React.Dispatch<React.SetStateAction<BoardColumns>>;
}

export const BaSeBoardContext = React.createContext<BoardContext>({
  columns: [],
  setColumns: () => {},
});

export const BaSeBoardProvider: React.FC = ({ children }) => {
  const [columns, setColumns] = React.useState([]);

  return (
    <BaSeBoardContext.Provider
      value={{
        columns,
        setColumns,
      }}
    >
      {children}
    </BaSeBoardContext.Provider>
  );
};

export const BaSeBoardConsumer = BaSeBoardContext.Consumer;
