import * as React from 'react';
import { useNextHashId } from '../../hooks/next-id';
import { idGenerator } from '../../utils/id-generator';
import { isNumeric } from '../../utils/string-utils';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { BaSeHelperText } from '../helpers/helper-text/helper-text';
import {
  BaSePopupButton,
  HelperButtonInterface,
} from '../helpers/popup-button/popup-button';
import {
  ButtonInfoContainer,
  CounterContainer,
  CounterInnerContainer,
  MoreInfoContainer,
  StyledApoio,
  StyledCounterInput,
  StyledLabel,
  StyledSubLabel,
  WrapperCounterLabel,
} from './counter-styled';
import { mapCounterSize } from './map-counter-style';
import { ThemeNamedType } from '../../theme/theme-interface';

const idSequence = idGenerator();

interface BaSeCounterProps {
  value?: number;
  size?: 'small' | 'medium' | 'big';
  label?: string;
  subLabel?: string;
  complement?: string;
  moreInfoLabel?: string;
  moreInfoDetails?: string;
  showHelpButton?: boolean;
  type?: Exclude<ThemeNamedType, 'tertiary'>;
  minValue?: number;
  maxValue?: number;
  stepValue?: number;
  isDisabled?: boolean;
  width?: string | number;
  helpButtonProps?: HelperButtonInterface;
  color?: string;
  onChange?: (value: number) => void;
}

export const BaSeCounter: React.FC<BaSeCounterProps> = ({
  value = 0,
  size = 'medium',
  label = '',
  subLabel = '',
  complement = '',
  moreInfoLabel = '',
  moreInfoDetails = '',
  showHelpButton = false,
  type = 'primary',
  color = 'default',
  minValue = Number.MIN_SAFE_INTEGER,
  maxValue = Number.MAX_SAFE_INTEGER,
  stepValue = 1,
  isDisabled = false,
  width = 0,
  helpButtonProps = {},
  onChange = () => {},
}) => {
  const id = useNextHashId(idSequence);
  const onChangeInput = (newValue: any) => {
    const cleanedValue = newValue.target.value;
    if (isNumeric(cleanedValue)) {
      const castedValue = Number(cleanedValue);
      onChange(
        castedValue <= maxValue
          ? castedValue >= minValue
            ? castedValue
            : minValue
          : maxValue,
      );
    }
  };

  const increaseValue = () => onChange(Number(value) + stepValue);
  const decreaseValue = () => onChange(Number(value) - stepValue);
  const isLeftButtonDisabled = Number(value) <= Number(minValue) || isDisabled;
  const isRightButtonDisabled = Number(value) >= Number(maxValue) || isDisabled;
  const height = mapCounterSize[size]?.padding ?? mapCounterSize.medium.padding;
  const hasError = color === 'destructive';

  return (
    <CounterContainer>
      <WrapperCounterLabel>
        <StyledLabel
          fontSize={size === 'small' ? '13px' : '16px'}
          lineHeight={size === 'small' ? '16px' : '23px'}
          htmlFor={id}
        >
          {label}
        </StyledLabel>
        <StyledSubLabel
          fontSize={size === 'small' ? '13px' : '16px'}
          lineHeight={size === 'small' ? '16px' : '23px'}
          htmlFor={id}
        >
          {subLabel}
        </StyledSubLabel>
        {showHelpButton && (
          <ButtonInfoContainer>
            <BaSePopupButton {...helpButtonProps} />
          </ButtonInfoContainer>
        )}
      </WrapperCounterLabel>
      <CounterInnerContainer>
        <BaSeShapeButton
          shape="square"
          nameIcon="minus"
          sideButton="left"
          isDisabled={!hasError && isLeftButtonDisabled}
          type={type}
          color={color}
          onClick={() => !isLeftButtonDisabled && decreaseValue()}
          size={size}
          buttonType="button"
        />
        <StyledCounterInput
          id={id}
          value={value}
          disabled={isDisabled}
          hasError={hasError}
          width={width}
          height={height}
          onChange={onChangeInput}
        />
        <BaSeShapeButton
          shape="square"
          nameIcon="plus"
          sideButton="right"
          isDisabled={!hasError && isRightButtonDisabled}
          type={type}
          color={color}
          onClick={() => !isRightButtonDisabled && increaseValue()}
          size={size}
          buttonType="button"
        />
      </CounterInnerContainer>
      <StyledApoio hasError={hasError}>{complement}</StyledApoio>
      {!!moreInfoLabel && (
        <MoreInfoContainer>
          <BaSeHelperText
            size="small"
            label={moreInfoLabel}
            details={moreInfoDetails}
          />
        </MoreInfoContainer>
      )}
    </CounterContainer>
  );
};

BaSeCounter.displayName = 'BaSeCounter';
