import * as React from 'react';
import { SideMenuItenProp, BaSeSideMenuItem } from '../item/item';
import { SideMenuContainer } from './menu-styled';

// rules interfaces

export interface SideMenuProps {
  itens?: SideMenuItenProp[];
  themeMode?: 'light' | 'dark';
  color?: string;
}

export const BaSeSideMenu: React.FC<SideMenuProps> = ({
  itens = [],
  themeMode = 'light',
  color,
}) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(-1);

  if (!itens || !itens.length) {
    return <React.Fragment />;
  }

  return (
    <SideMenuContainer>
      <ul>
        {itens.map((props, index) => (
          <BaSeSideMenuItem
            {...props}
            key={index}
            theme={themeMode}
            activeIndex={activeIndex}
            activeCallback={setActiveIndex}
            insideIndex={index}
            color={color}
          />
        ))}
      </ul>
    </SideMenuContainer>
  );
};

BaSeSideMenu.displayName = 'BaSeSideMenu';
