import * as React from 'react';
import { BaSeI18nContext } from '../../contexts/i18n';
import { BaSeTheme } from '../../theme';
import { BaSeIcon } from '../image/icon';
import {
  DrawerAnchor,
  DrawerContent,
  DrawerProps,
  DrawerPuller,
  DrawerWrapper,
} from './drawer-styled';

const {
  defaultColors: { white },
  institucionais: { cinzaSebrae45 },
} = BaSeTheme.colors;

export interface BaSeDrawerProps extends Partial<DrawerProps> {
  onToggle?(opened: boolean): void;
}

function isVerticalPosition(anchor: DrawerAnchor): boolean {
  return ['top', 'bottom'].includes(anchor);
}

export const BaSeDrawer: React.FC<BaSeDrawerProps> = ({
  children,
  backgroundColor = white,
  foregroundColor = cinzaSebrae45,
  anchor = 'left',
  pullerPosition = 'middle',
  height,
  width,
  open: externalOpen = true,
  onToggle,
}) => {
  if (isVerticalPosition(anchor) && !height) {
    throw Error(
      '[BaSeDrawer] Ao informar a posição "bottom" é necessário definir a prop "height".',
    );
  }

  if (!isVerticalPosition(anchor) && !width) {
    throw Error(
      '[BaSeDrawer] Ao informar a posição "left" ou "right" é necessário definir a prop "width".',
    );
  }

  const internalHeight = React.useMemo(() => height ?? '100%', [height]);
  const internalWidth = React.useMemo(() => width ?? '100%', [width]);

  const { getMessage } = React.useContext(BaSeI18nContext);

  const [open, setOpen] = React.useState(externalOpen);

  React.useEffect(() => onToggle?.(open), [open]);
  React.useEffect(() => setOpen(externalOpen), [externalOpen]);

  return (
    <DrawerWrapper
      anchor={anchor}
      pullerPosition={pullerPosition}
      height={internalHeight}
      width={internalWidth}
    >
      <DrawerContent
        backgroundColor={backgroundColor}
        open={open}
        anchor={anchor}
        height={internalHeight}
        width={internalWidth}
      >
        {children}
      </DrawerContent>

      <DrawerPuller
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        open={open}
        anchor={anchor}
        onClick={() => setOpen((oldState) => !oldState)}
      >
        <BaSeIcon
          name={`angle-${
            anchor === 'bottom' ? 'down' : anchor === 'top' ? 'up' : anchor
          }`}
          description={getMessage('buttonIcon.toggleDrawer')}
          color={foregroundColor}
          size={24}
        />
      </DrawerPuller>
    </DrawerWrapper>
  );
};

BaSeDrawer.displayName = 'BaSeDrawer';
