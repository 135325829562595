import * as React from 'react';
import { DataSelectionContextInterface } from '../../../contexts/data/selection';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { BaSeButton, ButtonProps } from '../../button/button/button';
import {
  BaSeShapeButton,
  ShapeButtonProps,
} from '../../button/shape-button/shape-button';
import { BaSeSmall1 } from '../../typography/small/small1';
import { DataArgsToSelectionAction, DataReloadable } from '../data';
import { DataInterfaceProps } from '../data-styled';
import { DataPaginationProps } from '../pagination/pagination';
import {
  DataHeaderTitleSelection,
  DataSelectionCounter,
} from './selection-styled';

export interface DataHeaderSelectionProps<Item extends object>
  extends DataReloadable,
    Pick<DataInterfaceProps, 'foregroundColor' | 'highlightedColor'>,
    Pick<
      DataPaginationProps<Item>,
      'pluralItemDescription' | 'singularItemDescription'
    >,
    Pick<
      DataSelectionContextInterface<Item>,
      'selectedItems' | 'isAllSelected' | 'setSelectedItems'
    > {
  genderOfItemDescription?: 'male' | 'female';
  total?: number;
  selectionIconActions?(
    args: DataArgsToSelectionAction<Item>,
  ): ShapeButtonProps[];
  selectionButtonActions?(args: DataArgsToSelectionAction<Item>): ButtonProps[];
}

export function DataHeaderSelection<Item extends object>({
  isAllSelected,
  pluralItemDescription,
  singularItemDescription,
  selectedItems,
  foregroundColor,
  highlightedColor,
  genderOfItemDescription = 'male',
  total = 0,
  reloadData,
  selectionIconActions,
  selectionButtonActions,
  setSelectedItems,
}: React.PropsWithoutRef<DataHeaderSelectionProps<Item>>): JSX.Element {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const showCounter = React.useMemo(
    () => isAllSelected || selectedItems.length > 0,
    [isAllSelected, selectedItems.length],
  );

  return (
    <DataHeaderTitleSelection>
      {selectionIconActions?.({
        selectedItems,
        isAllSelected,
        reloadData,
      })?.map((selectionIconAction) => (
        <BaSeShapeButton
          key={`${selectionIconAction.nameIcon}-${selectionIconAction.value}`}
          size="small"
          sizeIcon="small"
          type="tertiary"
          color={foregroundColor}
          {...selectionIconAction}
        />
      ))}

      {selectionButtonActions?.({
        selectedItems,
        isAllSelected,
        reloadData,
      })?.map((selectionButtonAction) => (
        <BaSeButton
          key={`${selectionButtonAction.rightIcon}-${selectionButtonAction.value}-${selectionButtonAction.leftIcon}`}
          size="small"
          type="tertiary"
          color={foregroundColor}
          {...selectionButtonAction}
        />
      ))}

      <DataSelectionCounter fadeIn={showCounter}>
        <BaSeSmall1 color={foregroundColor}>
          {isAllSelected || selectedItems.length === total
            ? getMessage(
                `dataSelection.allItemsSelected.${genderOfItemDescription}`,
                total,
                pluralItemDescription ??
                  getMessage('dataItem.pluralItemDescription'),
              )
            : selectedItems.length === 1
              ? getMessage(
                  `dataSelection.oneItemSelected.${genderOfItemDescription}`,
                  singularItemDescription ??
                    getMessage('dataItem.singularItemDescription'),
                )
              : getMessage(
                  `dataSelection.manyItemSelected.${genderOfItemDescription}`,
                  selectedItems.length,
                  pluralItemDescription ??
                    getMessage('dataItem.pluralItemDescription'),
                )}
          .
        </BaSeSmall1>
        {selectedItems.length > 1 && (
          <BaSeButton
            type="tertiary"
            value={getMessage('dataSelection.cancelSelection')}
            size="small"
            leftIcon="close"
            color={highlightedColor}
            onClick={() => setSelectedItems([])}
          />
        )}
      </DataSelectionCounter>
    </DataHeaderTitleSelection>
  );
}
