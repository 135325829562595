import { BaSeTheme } from '../../theme';
import { Color } from '../../utils/color-transformation/color';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';

export const mapSizeCheckbox = {
  small: 13,
  medium: 16,
};

export const mapSelectValues = (color: string) => {
  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.destructive ||
    color === BaSeTheme.components.button.colors.attention ||
    color === BaSeTheme.components.button.colors.confirmation;
  if (colorIsMapped) {
    return {
      style: {
        bgC: ColorMapper[color].foreground,
        bcCHover: ColorMapper[color].hover.defaultLight,
        boxShadowFocus: ColorMapper[color].focus.defaultLight,
        border: ColorMapper[color].foreground,
      },
    };
  } else {
    const customColorHsv = Color.generateFromHex(color);
    return {
      style: {
        bgC:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
        bcCHover: ColorMapper.light.hover.create(
          color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
        ),
        boxShadowFocus: ColorMapper.light.focus.create(
          color ? color : BaSeTheme.colors.institucionais.azulSebrae36,
        ),
        border:
          color && customColorHsv
            ? customColorHsv.toString()
            : BaSeTheme.colors.institucionais.azulSebrae36,
      },
    };
  }
};

export const mapBaSeSelectColor = {
  default: {
    iconColor: ColorMapper.grayScale.foreground,
    color: BaSeTheme.colors.institucionais.cinzaSebrae60,
    item: BaSeTheme.colors.institucionais.cinzaSebrae30,
    selectedColor: ColorMapper.grayScale.hover.defaultDark,
    borderColor: ColorMapper.grayScale.focus.defaultLight,
    bgC: BaSeTheme.colors.defaultColors.white,
  },
  error: {
    iconColor: BaSeTheme.colors.feedbackInterface.erro35,
    color: BaSeTheme.colors.feedbackInterface.erro35,
    item: BaSeTheme.colors.feedbackInterface.erro35,
    selectedColor: BaSeTheme.colors.feedbackInterface.erro35,
    borderColor: BaSeTheme.colors.feedbackInterface.erro35,
    bgC: BaSeTheme.colors.defaultColors.white,
  },
  disabled: {
    iconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
    color: BaSeTheme.colors.institucionais.cinzaSebrae75,
    item: BaSeTheme.colors.institucionais.cinzaSebrae60,
    selectedColor: ColorMapper.grayScale.hover.defaultDark,
    borderColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
    bgC: BaSeTheme.colors.institucionais.cinzaSebrae97,
  },
};
