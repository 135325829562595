import * as React from 'react';
import { DataSelectionContextInterface } from '../../../contexts/data/selection';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { BaSeTheme } from '../../../theme';
import { ThemeColorValue } from '../../../theme/theme-interface';
import { BaSeButton } from '../../button/button/button';
import { BaSeParagraph } from '../../typography/paragraph/paragraph';
import { DataInterfaceProps } from '../data-styled';
import { DataPaginationProps } from '../pagination/pagination';
import { DataHeaderSelectionProps } from './header-selection';
import {
  DataSelectOrUnselectAllArea,
  DataSelectOrUnselectAllItem,
} from './selection-styled';

export interface SelectOrUnselectAllArgs<Item extends object>
  extends Pick<
    DataSelectionContextInterface<Item>,
    'isAllSelected' | 'setIsAllSelected' | 'setSelectedItems'
  > {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface DataSelectOrUnselectAllProps<Item extends object>
  extends Pick<
      DataInterfaceProps,
      'highlightedColor' | 'textColor' | 'backgroundColor'
    >,
    Pick<
      DataSelectionContextInterface<Item>,
      | 'isAllFromPageSelected'
      | 'selectedItems'
      | 'isAllSelected'
      | 'setIsAllSelected'
      | 'setSelectedItems'
    >,
    Pick<
      DataPaginationProps<Item>,
      'pluralItemDescription' | 'singularItemDescription' | 'compactMode'
    >,
    Pick<DataHeaderSelectionProps<Item>, 'total' | 'genderOfItemDescription'> {
  hasPagination?: boolean;
  hasSelectOrUnselectAll?: boolean;
  selectOrUnselectAllBackgroundColor?: ThemeColorValue;
  selectOrUnselectAllHandler?(args: SelectOrUnselectAllArgs<Item>): void;
}

const colors = BaSeTheme.colors;

export function DataSelectOrUnselectAll<Item extends object>({
  hasPagination,
  singularItemDescription,
  pluralItemDescription,
  compactMode,
  isAllFromPageSelected,
  selectedItems,
  isAllSelected,
  hasSelectOrUnselectAll,
  genderOfItemDescription = 'male',
  total = 0,
  highlightedColor = colors.institucionais.azulSebrae,
  backgroundColor = colors.defaultColors.white,
  textColor = colors.institucionais.cinzaSebrae30,
  selectOrUnselectAllBackgroundColor = colors.institucionais.cinzaSebrae97,
  selectOrUnselectAllHandler,
  setIsAllSelected,
  setSelectedItems,
}: React.PropsWithoutRef<DataSelectOrUnselectAllProps<Item>>): JSX.Element {
  const { getMessage } = React.useContext(BaSeI18nContext);
  const [loading, setLoading] = React.useState(false);

  const remainItemsCount = React.useMemo(
    () => total - selectedItems.length,
    [total, selectedItems],
  );

  const onClick = React.useCallback(
    (selectOrUnselectAll: boolean) => {
      if (typeof selectOrUnselectAllHandler === 'function') {
        return selectOrUnselectAllHandler({
          isAllSelected: selectOrUnselectAll,
          setLoading,
          setIsAllSelected,
          setSelectedItems,
        });
      }

      setIsAllSelected(selectOrUnselectAll);
    },
    [selectOrUnselectAllHandler, setSelectedItems, setIsAllSelected],
  );

  if (!hasSelectOrUnselectAll || remainItemsCount < 1) {
    return <></>;
  }

  return (
    <DataSelectOrUnselectAllArea
      backgroundColor={
        compactMode ? backgroundColor : selectOrUnselectAllBackgroundColor
      }
    >
      {hasPagination &&
        isAllFromPageSelected &&
        selectedItems.length !== total &&
        (isAllSelected ? (
          <DataSelectOrUnselectAllItem>
            <BaSeParagraph color={textColor}>
              {`${getMessage(
                `dataSelection.allItemsSelected.${genderOfItemDescription}`,
                total,
                pluralItemDescription ??
                  getMessage('dataItem.pluralItemDescription'),
              )}.`}
            </BaSeParagraph>
            <BaSeButton
              isLoading={loading}
              size="medium"
              type="tertiary"
              color={highlightedColor}
              leftIcon="close"
              onClick={() => onClick(false)}
              value={getMessage(
                `dataSelection.unselectAllItems.${genderOfItemDescription}`,
              )}
            />
          </DataSelectOrUnselectAllItem>
        ) : (
          <DataSelectOrUnselectAllItem>
            <BaSeParagraph color={textColor}>
              {`${
                selectedItems.length === 1
                  ? getMessage(
                      `dataSelection.almostAllSelected.${genderOfItemDescription}`,
                    )
                  : getMessage(`dataSelection.allPageSelected`)
              }.`}
            </BaSeParagraph>
            <BaSeButton
              isLoading={loading}
              size="medium"
              type="tertiary"
              color={highlightedColor}
              leftIcon="check-double-tonescale"
              onClick={() => onClick(true)}
              value={
                remainItemsCount === 1
                  ? getMessage(
                      `dataSelection.selectTheLastOne.${genderOfItemDescription}`,
                      singularItemDescription ??
                        getMessage('dataItem.singularItemDescription'),
                    )
                  : getMessage(
                      `dataSelection.selectTheOtherRemaining.${genderOfItemDescription}`,
                      remainItemsCount,
                      pluralItemDescription ??
                        getMessage('dataItem.pluralItemDescription'),
                    )
              }
            />
          </DataSelectOrUnselectAllItem>
        ))}
    </DataSelectOrUnselectAllArea>
  );
}
