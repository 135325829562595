import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { ThemeColorValue } from '../../../theme/theme-interface';
import { transformLightness } from '../../../utils/color-transformation/transform-lightness';
import { DataInterfaceProps, densityPixels, spacePixels } from '../data-styled';
import { showUpAnimation } from '../filter-area/filter-area-styled';

export interface DataBodyTableProps
  extends Pick<
    DataInterfaceProps,
    | 'densityLevel'
    | 'separatorColor'
    | 'textColor'
    | 'backgroundColor'
    | 'shadow'
  > {
  tableHeaderBackgroundColor?: ThemeColorValue;
  borderColor?: ThemeColorValue;
  compactModeExpandable?: boolean;
}

export interface DataCompactTablesProps
  extends Pick<DataInterfaceProps, 'backgroundColor'> {}

export interface ColumnFilterProps
  extends Pick<DataInterfaceProps, 'separatorColor' | 'backgroundColor'> {}

const tableRow = ({
  separatorColor,
  densityLevel,
}: Partial<DataBodyTableProps>) => css`
  tr {
    ${DataTableHeader},
    ${DataTableCell} {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
    }

    ${DataTableHeader} {
      gap: 4px;
      justify-content: flex-start;
    }

    th,
    td {
      will-change: background-color;
      transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
      vertical-align: middle;
      will-change: min-height, padding;
      min-height: ${densityPixels(densityLevel, 4)};
      padding: ${`${densityPixels(densityLevel)} 8px`};

      &:first-of-type {
        padding-left: ${spacePixels};
      }
      &:last-of-type {
        padding-right: ${spacePixels};
      }
    }

    td {
      border: 1px solid ${separatorColor};
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }
`;

export const lightness = 0.96;

export const DataTableHeader = styled.span`
  position: relative;
`;

export const DataTableCell = styled(DataTableHeader)``;

export const DataBodyTable = styled.div.attrs({
  className: 'BaSe--data-body-table',
})<DataBodyTableProps>`
  padding: 0;
  margin: 0;
  box-sizing: content-box;

  & > table.BaSe--data-table {
    width: 100%;
    border: none;

    ${(props) => tableRow(props)}

    ${({ tableHeaderBackgroundColor }) => css`
      thead > tr {
        background-color: ${tableHeaderBackgroundColor};
      }
    `}

    ${({ backgroundColor, textColor }) => css`
      tbody > tr {
        will-change: background-color;
        transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
        background-color: ${transformLightness(
          backgroundColor as string,
          lightness,
        )};

        &:hover {
          background-color: ${transformLightness(
            textColor as string,
            lightness,
          )};
        }
      }
    `}
  }
`;

export const ColumnFilterWrapper = styled.div.attrs({
  className: 'BaSe--data-table-column-filter',
})<ColumnFilterProps>`
  position: absolute;
  top: 100%;
  z-index: ${BaSeTheme.stackOrder.aboveNormal};
  border-radius: 4px;
  padding: ${spacePixels};
  border: 1px solid ${({ separatorColor }) => separatorColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${BaSeTheme.shadows.profundidade2};
  ${showUpAnimation}
`;

export const DataCompactTables = styled.div<DataCompactTablesProps>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: ${spacePixels};
  justify-content: stretch;
  align-items: stretch;
`;

export const DataCompactTable = styled.div.attrs({
  className: 'BaSe--data-compact-mode-table',
})<DataBodyTableProps>`
  box-sizing: border-box;
  border-radius: 8px;
  will-change: background-color;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};

  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: ${shadow};
    `}

  ${({ backgroundColor, borderColor }) =>
    backgroundColor &&
    css`
      border: 1px solid ${borderColor};
      background: ${backgroundColor};
    `}

  & > table.BaSe--data-table {
    width: 100%;

    ${(props) => tableRow(props)}

    ${({ tableHeaderBackgroundColor }) =>
      tableHeaderBackgroundColor &&
      css`
        thead > tr > td {
          border-radius: 8px 8px 0 0;
          background-color: ${tableHeaderBackgroundColor};
          border-top: none;
        }
      `}

    ${({ textColor }) => css`
      tbody > tr:hover > td {
          background-color: ${transformLightness(
            textColor as string,
            lightness,
          )};
        }
      }
    `}

    tr:first-of-type td {
      border-top: none;
    }

    tr:last-of-type td {
      border-bottom: none;
    }
  }
`;

export const DataStickyCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  gap: 4px;
`;

export const DataStickyCell = styled(DataTableHeader)`
  &:last-of-type {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
    align-items: center;
  }
`;
