import * as React from 'react';
import { BaSeTheme } from '../../theme';
import { BoxAppearenceProps, BoxContent, ContentItem } from './box-styled';

export interface BoxProps extends Partial<BoxAppearenceProps> {
  renderContent(): JSX.Element | JSX.Element[];
}

const colors = BaSeTheme.colors;

export const BaSeBox: React.FC<BoxProps> = ({
  height,
  width,
  borderRadius = 8,
  elevationOnHoverOrFocus = true,
  isElevated = false,
  shadowLevel = 0,
  backgroundColor = colors.defaultColors.white,
  borderColor = colors.institucionais.cinzaSebrae90,
  renderContent,
}) => {
  const content = React.useMemo(() => renderContent(), [renderContent]);

  return (
    <BoxContent
      height={height}
      width={width}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      shadowLevel={shadowLevel}
      elevationOnHoverOrFocus={elevationOnHoverOrFocus}
      isElevated={isElevated}
    >
      {Array.isArray(content) ? (
        content.map((item, index) => (
          <ContentItem
            key={index}
            borderColor={borderColor}
            isLast={content.length === index + 1}
          >
            {item}
          </ContentItem>
        ))
      ) : (
        <ContentItem borderColor={borderColor} isLast={true}>
          {content}
        </ContentItem>
      )}
    </BoxContent>
  );
};
