import styled from 'styled-components';
import { numberWithUnit } from '../../../utils/string-utils';
import { BaSeTheme } from '../../../theme';

interface DualButtonType {
  background: string;
  borderColor: string;
  borderRadius: string;
  boxShadowFocus: string;
  hoverColor: string;
  width: number | string;
}

export const LeftButton = styled.button<DualButtonType>`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: ${BaSeTheme.typography.lineHeightBase};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  width: ${({ width }) => numberWithUnit(width)};
  background-color: ${(props) => props.background};
  border-style: solid;
  border-color: ${(props) => props.borderColor};
  border-width: 1px 1px 1px 1px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  &:hover {
    &:before {
      background-color: ${(props) => props.hoverColor};
    }
    cursor: pointer;
    background-color: ${(props) => props.hoverColor};
  }
  &:focus {
    border-radius: ${(props) => props.borderRadius}px 0px 0px
      ${(props) => props.borderRadius}px;
    background-color: ${(props) => props.hoverColor};
    box-shadow: ${(props) => props.boxShadowFocus};
    outline: none;
    z-index: ${BaSeTheme.stackOrder.belowOverlay};
    &:before {
      border-radius: ${(props) => props.borderRadius}px 0px 0px
        ${(props) => props.borderRadius}px;
      background-color: ${(props) => props.hoverColor};
      box-shadow: ${BaSeTheme.shadows.profundidade2};
      outline: none;
    }
  }
  &:active {
    box-shadow: none;
    transform: translateY(2px);
  }
`;

export const RightButton = styled.button<DualButtonType>`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: ${BaSeTheme.typography.lineHeightBase};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  width: ${({ width }) => numberWithUnit(width)};
  background-color: ${(props) => props.background};
  border-style: solid;
  border-color: ${(props) => props.borderColor};
  border-width: 1px 1px 1px 0px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.hoverColor};
  }
  &:focus {
    border-radius: 0px ${(props) => props.borderRadius}px
      ${(props) => props.borderRadius}px 0px;
    background-color: ${(props) => props.hoverColor};
    box-shadow: ${(props) => props.boxShadowFocus};
    outline: none;
    z-index: ${BaSeTheme.stackOrder.belowOverlay};
  }
  &:active {
    box-shadow: none;
    transform: translateY(2px);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: 'row';
`;

export const Label = styled.label`
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: 1.4em;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
  display: flex;
  margin: 1px;
`;
