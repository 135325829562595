import * as React from 'react';
import { numberWithUnit } from '../../../utils/string-utils';
import { BaSeStaticAsset } from '../../static-asset/static-asset';
import { getLogoPath, LogoSebraeProps } from './logo-utils';

export const BaSeLogoSebraeRestrictInverse: React.FC<LogoSebraeProps> = ({
  onClick,
  width = 'auto',
  easterEgg = false,
}) => (
  <BaSeStaticAsset
    classIdentifier="BaSe--logo-sebrae-restrict-inverse"
    description="Logotipo do Sebrae"
    path={getLogoPath(easterEgg, 'images/logos/sebrae_logo-restrict-inverse')}
    onClick={onClick}
    width={numberWithUnit(width)}
  />
);

BaSeLogoSebraeRestrictInverse.displayName = 'BaSeLogoSebraeRestrictInverse';
