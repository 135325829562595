import styled from 'styled-components';
import { BaSeTheme } from '../../theme';
import { ThemeColorValue } from '../../theme/theme-interface';

interface PageWrapperProps {
  color?: ThemeColorValue;
}

export const PageWrapperStyled = styled.section.attrs({
  className: 'BaSe--page',
})<PageWrapperProps>`
  &.BaSe--page {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    place-items: center;
    padding: 50px;
    background-color: ${({ color }) =>
      color ?? BaSeTheme.colors.defaultColors.white};
    font-family: ${BaSeTheme.typography.fontFamily};
    font-size: ${BaSeTheme.typography.fontSizeBase};
  }
`;
