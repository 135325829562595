import * as React from 'react';
import { BaSeTheme } from '../../theme';
import { BaSeLogoSebraeRestrict } from '../image/logo-sebrae/logo-sebrae-restrict';
import { BaSeSmall1 } from '../typography/small/small1';
import {
  SingleInfoWrapper,
  SingleSolutionGrid,
  SystemLogo,
  SystemLogoWrapper,
} from './single-solution-styled';

export interface SingleSolutionProps {
  title: string;
  description: string;
  image?: string;
  link?: string;
}

export interface InnerSingleSolutionProps extends SingleSolutionProps {
  isVisible: boolean;
  textColor?: string;
}

const SINGLE_LINE_HEIGHT = 17;
const CHARACTER_INFO_COUNT = 50;
const CHARACTER_TITLE_COUNT = 30;
const MAX_LINE_COUNT_DESCRIPTION = 3;
const MAX_LINE_COUNT_TITLE = 2;

const { cinzaSebrae45 } = BaSeTheme.colors.institucionais;

export const BaSeSingleSolution: React.FC<InnerSingleSolutionProps> = ({
  title = '',
  description = '',
  image = '',
  isVisible,
  textColor = cinzaSebrae45,
  // link = '',
}) => {
  const [isContentReduced, setIsContentReduced] =
    React.useState<boolean>(false);

  const [isTitleReduced, setIsTitleReduced] = React.useState<boolean>(false);

  const titleRef = React.useRef<HTMLDivElement>(null);
  const descriptionRef = React.useRef<HTMLDivElement>(null);

  const renderSystemLogo = React.useCallback(() => {
    if (image) {
      return (
        <SystemLogoWrapper>
          <SystemLogo src={image} alt={title} />;
        </SystemLogoWrapper>
      );
    }
    return (
      <SystemLogoWrapper>
        <BaSeLogoSebraeRestrict />
      </SystemLogoWrapper>
    );
  }, [image, title]);

  const handleReduceString = React.useCallback(
    (text: string, amount: number) => {
      return text.substring(0, amount) + '...';
    },
    [],
  );

  const reduceDescription = React.useCallback(() => {
    if (isContentReduced) {
      return handleReduceString(description, CHARACTER_INFO_COUNT);
    }
    return description;
  }, [handleReduceString, isContentReduced, description]);

  const reduceTitle = React.useCallback(() => {
    if (isTitleReduced) {
      return handleReduceString(title, CHARACTER_TITLE_COUNT);
    }
    return title;
  }, [handleReduceString, isTitleReduced, title]);

  React.useEffect(() => {
    if (
      (descriptionRef?.current?.clientHeight ?? 0) >
        SINGLE_LINE_HEIGHT * MAX_LINE_COUNT_DESCRIPTION &&
      !isContentReduced &&
      isVisible
    ) {
      setIsContentReduced(true);
    }
    if (!isVisible) {
      setIsContentReduced(false);
    }
  }, [isContentReduced, descriptionRef, isVisible]);

  React.useEffect(() => {
    if (
      (titleRef?.current?.clientHeight ?? 0) >
        SINGLE_LINE_HEIGHT * MAX_LINE_COUNT_TITLE &&
      !isTitleReduced &&
      isVisible
    ) {
      setIsTitleReduced(true);
    }
    if (!isVisible) {
      setIsTitleReduced(false);
    }
  }, [isVisible, titleRef, isTitleReduced]);

  return (
    <SingleSolutionGrid>
      {renderSystemLogo()}
      <SingleInfoWrapper>
        <div ref={titleRef}>
          <BaSeSmall1 isBold={true} color={textColor}>
            {reduceTitle()}
          </BaSeSmall1>
        </div>
        <div ref={descriptionRef}>
          <BaSeSmall1 color={textColor}>{reduceDescription()}</BaSeSmall1>
        </div>
      </SingleInfoWrapper>
    </SingleSolutionGrid>
  );
};

BaSeSingleSolution.displayName = 'BaSeSingleSolution';
