import React from 'react';
import { BaSeTheme } from '../../theme';
import { BaSeHeading5 } from '../typography/heading/heading5';
import {
  CustomTabItem,
  TabColorProps,
  TabContainer,
  TabItem,
  TapControlProps,
} from './tabs-styled';
import { BaSeIcon } from '../image/icon';
import { numberWithUnit } from '../../utils/string-utils';

const outlineSize = 3;

interface TitleWithIcon {
  label: string;
  iconName: string;
  iconDescription: string;
  iconSize: string | number;
}

export interface TabProps extends TapControlProps, TabColorProps {
  title:
    | string
    | ((isActive: boolean, isDisabled: boolean) => JSX.Element)
    | TitleWithIcon;
  index: number;
  isDisabled?: boolean;
  onActivatedTabIndex: (tabIndex: number) => void;
  onActiveItem: (tabLeft: number, tabWidth: number) => void;
  onDestakItem: (tabLeft: number, tabWidth: number) => void;
}

export const BaSeTab: React.FC<TabProps> = ({
  title,
  index,
  isActive,
  activeColor = BaSeTheme.colors.institucionais.azulSebrae36,
  inactiveColor = BaSeTheme.colors.institucionais.cinzaSebrae45,
  isDisabled = false,
  onActivatedTabIndex,
  onActiveItem,
  onDestakItem,
}) => {
  const itemRef = React.createRef<HTMLButtonElement>();

  const label = React.useMemo(
    () =>
      typeof title === 'string'
        ? title
        : typeof title === 'object' && 'label' in title
          ? title.label
          : undefined,
    [title],
  );

  const icon = React.useMemo(
    () =>
      typeof title === 'object' && 'iconName' in title
        ? {
            name: title.iconName,
            description: title.iconDescription,
            size: title.iconSize,
          }
        : undefined,
    [title],
  );

  const renderCustomTab = React.useMemo(
    () => (typeof title === 'function' ? title : undefined),
    [title],
  );

  const onSelectedItem = React.useCallback(() => {
    const rect = itemRef?.current?.getBoundingClientRect();
    const offsetLeft = itemRef?.current?.offsetLeft;
    const tabLeft = offsetLeft ? offsetLeft - outlineSize : 0;
    const tabWidth = rect?.width ? rect?.width + outlineSize * 2 : 0;

    onDestakItem(0, 0);
    onActiveItem(tabLeft, tabWidth);
    onActivatedTabIndex(index);
  }, [itemRef, onDestakItem, onActiveItem, onActivatedTabIndex, index]);

  React.useEffect(() => {
    if (isActive) {
      onSelectedItem();
    }
  }, [isActive, onSelectedItem]);

  return (
    <TabContainer className="BaSe--tab" isActive={isActive}>
      {renderCustomTab ? (
        <CustomTabItem
          ref={itemRef}
          isActive={isActive}
          activeColor={activeColor}
          disabled={isDisabled}
          inactiveColor={inactiveColor}
          onClick={onSelectedItem}
        >
          {renderCustomTab(isActive, isDisabled)}
        </CustomTabItem>
      ) : (
        <TabItem
          ref={itemRef}
          isActive={isActive}
          disabled={isDisabled}
          activeColor={activeColor}
          inactiveColor={inactiveColor}
          onClick={onSelectedItem}
        >
          <BaSeHeading5
            isBold={isActive}
            color={isActive ? activeColor : inactiveColor}
          >
            {label}
          </BaSeHeading5>
          {icon && (
            <BaSeIcon
              name={icon.name}
              size={numberWithUnit(icon.size)}
              color={isActive ? activeColor : inactiveColor}
              description={icon.description}
            />
          )}
        </TabItem>
      )}
    </TabContainer>
  );
};

BaSeTab.displayName = 'BaSeTab';
