import * as React from 'react';
import { BaSeTheme } from '../../theme';
import { ContainerStyled } from './container-styled';
import { ContainerPropsInterface } from './container-props';

export const BaSeContainer: React.FC<ContainerPropsInterface> = ({
  delimitedOnBreakpoint,
  fullViewport = false,
  withAutoScroll = false,
  children,
  // eslint-disable-next-line comma-dangle
  ...divProps
}) => {
  const maxWidth = React.useMemo(() => {
    if (
      delimitedOnBreakpoint &&
      delimitedOnBreakpoint in BaSeTheme.breakpoints
    ) {
      return BaSeTheme.breakpoints[delimitedOnBreakpoint].minWidth;
    }
    return;
  }, [delimitedOnBreakpoint]);

  return (
    <ContainerStyled
      maxWidth={maxWidth}
      fullViewport={fullViewport}
      autoScroll={withAutoScroll}
      {...(divProps as any)}
    >
      {children}
    </ContainerStyled>
  );
};

BaSeContainer.displayName = 'BaSeContainer';
