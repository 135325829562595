/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as React from 'react';
import { BaSeI18nConsumer } from '../../contexts/i18n';
import { useOutsideEvent } from '../../hooks/outside-event';
import { BaSeTheme } from '../../theme';
import { ThemeColorValue } from '../../theme/theme-interface';
import {
  ColorMapper,
  ColorMapperType,
} from '../../utils/color-transformation/color-mapper';
import { objectNotEmpty } from '../../utils/object-utils';
import { AccordionOption, BaSeAccordion } from '../accordion/accordion';
import { BaSeTooltip, TooltipProps } from '../alerts/tooltip/tooltip';
import {
  BaSeArrowButton,
  BaSeArrowButtonProps,
} from '../button/arrow-button/arrow-button';
import { ButtonProps } from '../button/button-props';
import { BaSeButton } from '../button/button/button';
import {
  BaSeShapeButton,
  ShapeButtonProps,
} from '../button/shape-button/shape-button';
import { BaSeIcon } from '../image/icon';
import { BaSeParagraph } from '../typography/paragraph/paragraph';
import {
  BaSeItemProps,
  DisplayButton,
  ItemButtonType,
  PositionRelativeType,
  SingleActionItemInterface,
  SingleItemProps,
  SingleItemStyledProps,
} from './action-item-props';
import {
  ActionItemAccordionContent,
  ActionItemContainer,
  ActionItemListWrapper,
  ActionItemSingleItem,
  ActionItemTitleAccordionContainer,
  ActionItemWrapper,
} from './action-item-styled';

const NEGATIVE_INDEX = -99;
const DEFAULT_ITEM_COLORS = {
  unselected: {
    textColor: ColorMapper.default.foreground,
    backgroundColor: BaSeTheme.colors.defaultColors.white,
    hoverBackgroundColor: ColorMapper.default.hover.defaultLight,
  },
  selected: {
    textColor: BaSeTheme.colors.defaultColors.white,
    backgroundColor: ColorMapper.default.foreground,
    hoverBackgroundColor: ColorMapper.default.hover.defaultDark,
  },
  disabled: {
    textColor: ColorMapper.disabled.foreground,
    backgroundColor: ColorMapper.disabled.background,
    disabled: true,
  },
};

interface SingleActionItemTitleContentProps {
  item: SingleItemProps;
  customColor: SingleItemStyledProps;
  createDisableIconColor: () => ThemeColorValue;
}

const SingleActionItemTitleContent: React.FC<
  SingleActionItemTitleContentProps
> = ({ createDisableIconColor, customColor, item }) => {
  return (
    <>
      {item?.icon && (
        <BaSeI18nConsumer>
          {({ getMessage }) => (
            <BaSeIcon
              description={getMessage('icon.label', item.text)}
              name={item.icon as string}
              color={createDisableIconColor()}
            />
          )}
        </BaSeI18nConsumer>
      )}
      <BaSeParagraph color={customColor?.textColor}>{item.text}</BaSeParagraph>
    </>
  );
};

interface SingleActionItemTitleProps {
  customColor: SingleItemStyledProps;
  selfRef: React.RefObject<HTMLButtonElement>;
  item: SingleItemProps;
  index: number;

  callback?: () => void;
  updateCurrent: (index: number) => void;
  createDisableIconColor: () => ThemeColorValue;
}

const SingleActionItemTitle: React.FC<SingleActionItemTitleProps> = ({
  customColor,
  item,
  selfRef,
  callback,
  updateCurrent,
  createDisableIconColor,
  index,
}) => {
  const handleClick = React.useCallback(() => {
    if (!item?.isDisabled) {
      item.callback();
      callback?.();
    }
  }, [item, callback]);

  const handleFocus = React.useCallback(
    (event: React.FocusEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      updateCurrent(index);
    },
    [updateCurrent],
  );

  const disableTabIndex = React.useCallback(() => {
    return item?.isDisabled ? { tabIndex: -1 } : {};
  }, [item]);

  return (
    <ActionItemSingleItem
      ref={selfRef}
      onFocus={handleFocus}
      onClick={handleClick}
      customColor={customColor}
      aria-disabled={item?.isDisabled}
      {...disableTabIndex()}
    >
      <SingleActionItemTitleContent
        createDisableIconColor={createDisableIconColor}
        customColor={customColor}
        item={item}
      />
    </ActionItemSingleItem>
  );
};

const SingleActionItem: React.FC<SingleActionItemInterface> = ({
  item,
  index,
  opened,
  hasFocus,
  callback,
  updateCurrent,
  subitems,
  onClick,
}) => {
  const [currentIndex, setCurrentIndex] =
    React.useState<number>(NEGATIVE_INDEX);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  const selfRef = React.useRef<HTMLButtonElement>(null);

  const getIconColor = React.useCallback(() => {
    if (!item?.color) {
      return ColorMapper.default.foreground;
    }
    if (ColorMapper.isValidType(item?.color ?? '')) {
      const colorMapper = ColorMapper[item.color] as ColorMapperType;
      if ('foreground' in colorMapper) {
        return colorMapper.foreground;
      }
    }
    if (ColorMapper.isValidColor(item?.color ?? '')) {
      return item.color;
    }
    return ColorMapper.default.foreground;
  }, [item]);

  const createDisableIconColor = React.useCallback(() => {
    if (item?.isDisabled) {
      return BaSeTheme.colors.institucionais.cinzaSebrae75;
    }
    return item.isSelected
      ? BaSeTheme.colors.defaultColors.white
      : getIconColor();
  }, [item, getIconColor]);

  const getItemColor = React.useCallback((): SingleItemStyledProps => {
    if (item?.isDisabled) {
      return DEFAULT_ITEM_COLORS.disabled;
    }
    const selected = item?.isSelected ? 'selected' : 'unselected';
    if (ColorMapper.isValidType(item?.color ?? '')) {
      return {
        unselected: {
          textColor: ColorMapper[item.color as string].foreground as string,
          backgroundColor: BaSeTheme.colors.defaultColors.white,
          hoverBackgroundColor: ColorMapper[item.color as string].hover
            .defaultLight as string,
        },
        selected: {
          backgroundColor: ColorMapper[item.color as string]
            .foreground as string,
          textColor: BaSeTheme.colors.defaultColors.white,
          hoverBackgroundColor: ColorMapper[item.color as string].hover
            .defaultDark as string,
        },
      }[selected];
    }
    if (ColorMapper.isValidColor(item?.color ?? '')) {
      return {
        unselected: {
          textColor: item?.color as string,
          backgroundColor: BaSeTheme.colors.defaultColors.white,
          hoverBackgroundColor: ColorMapper.light.hover.create(
            item.color as string,
          ),
        },
        selected: {
          backgroundColor: item?.color,
          textColor: BaSeTheme.colors.defaultColors.white,
          hoverBackgroundColor: ColorMapper.dark.hover.create(
            item.color as string,
          ),
        },
      }[selected];
    }
    return DEFAULT_ITEM_COLORS[selected];
  }, [item]);

  React.useEffect(() => {
    if (hasFocus && opened && selfRef?.current) {
      selfRef?.current?.focus();
    }
  }, [hasFocus, opened, selfRef, index]);

  const customColor = getItemColor();

  const returnDefault = React.useCallback(() => {
    onClick?.();
    setCurrentIndex(NEGATIVE_INDEX);
    setMenuOpen(false);
  }, [onClick]);

  const options = React.useMemo<AccordionOption[]>(
    () => [
      {
        title: ({ setOpen, open }) => (
          <ActionItemTitleAccordionContainer
            onClick={() => setOpen(!open)}
            customColor={customColor}
          >
            <SingleActionItemTitleContent
              createDisableIconColor={createDisableIconColor}
              customColor={customColor}
              item={item}
            />
          </ActionItemTitleAccordionContainer>
        ),
        content: (
          <ActionItemListWrapper>
            <ActionItemAccordionContent>
              {item.subitems?.map((subitem, key) => (
                <SingleActionItem
                  callback={returnDefault}
                  item={subitem}
                  key={key}
                  index={key}
                  opened={menuOpen}
                  hasFocus={key === currentIndex}
                  updateCurrent={setCurrentIndex}
                  subitems={subitem.subitems}
                  onClick={onClick}
                />
              ))}
            </ActionItemAccordionContent>
          </ActionItemListWrapper>
        ),
        noPaddingContent: true,
        noPaddingTitle: true,
      },
    ],
    [customColor, item, menuOpen],
  );

  return subitems ? (
    <BaSeAccordion
      backgroundContentColor={BaSeTheme.colors.defaultColors.white}
      titleButtonIconSize="small"
      titleButtonSize="small"
      hasContentBorder={false}
      hasTitleBorder={false}
      shadow={BaSeTheme.shadows.profundidade0}
      multipleOpen={true}
      options={options}
    />
  ) : (
    <SingleActionItemTitle
      customColor={customColor}
      selfRef={selfRef}
      item={item}
      callback={callback}
      updateCurrent={updateCurrent}
      createDisableIconColor={createDisableIconColor}
      index={index}
    />
  );
};

export const BaSeActionItem: React.FC<BaSeItemProps> = ({
  icon = 'ellipsis-v',
  descriptionIcon,
  type = 'tertiary',
  size = 'extra-small',
  sizeIcon = 'medium',
  color = BaSeTheme.colors.institucionais.azulSebrae36,
  items = [],
  isDisabled = false,
  closeOnAction = true,
  minWidth = 164,
  open = false,
  noIcon = false,
  trackPosition = false,
  position = 'top-left',
  tooltip = {},
  buttonType = 'base-shape-button',
  customButtonProps = {},
  onClose,
  onOpen,
}) => {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] =
    React.useState<number>(NEGATIVE_INDEX);
  const [styles, setStyles] = React.useState<string>('');

  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  useOutsideEvent(menuRef, () => {
    setMenuOpen(false);
    onClose?.();
  });

  const handleButtonType = React.useCallback((): ItemButtonType => {
    if (!buttonType) {
      return 'base-shape-button';
    }
    const option: ItemButtonType[] = [
      'base-button',
      'base-shape-button',
      'base-arrow-button',
    ];
    return option.find((i) => i === buttonType) ?? 'base-shape-button';
  }, [buttonType]);

  const displayButton: DisplayButton = React.useCallback(() => {
    return {
      'base-button': (
        baseButtonProps: Partial<
          Omit<
            ButtonProps,
            | 'onClick'
            | 'onFocus'
            | 'onBlur'
            | 'isDisabled'
            | 'size'
            | 'color'
            | 'type'
          >
        >,
      ) => (
        <BaSeButton
          {...baseButtonProps}
          tooltip={tooltip}
          onClick={() => setMenuOpen((prev) => !prev)}
          isDisabled={isDisabled}
          size={
            'extra-small' === size || 'mega-small' === size ? 'small' : size
          }
          color={color}
          type={type}
          onFocus={undefined}
          onBlur={undefined}
        />
      ),
      'base-shape-button': (
        baseShapeButtonProps?: Partial<
          Omit<
            ShapeButtonProps,
            | 'tooltip'
            | 'isDisabled'
            | 'size'
            | 'sizeIcon'
            | 'color'
            | 'type'
            | 'nameIcon'
            | 'onClick'
            | 'onFocus'
            | 'onBlur'
          >
        >,
      ) => (
        <BaSeI18nConsumer>
          {({ getMessage }) => (
            <BaSeShapeButton
              {...baseShapeButtonProps}
              tooltip={tooltip}
              isDisabled={isDisabled}
              size={size}
              sizeIcon={sizeIcon}
              color={color}
              type={type}
              nameIcon={icon}
              descriptionIcon={
                descriptionIcon?.trim() ||
                getMessage('actionItem.iconDescription')
              }
              onClick={() => setMenuOpen((prev) => !prev)}
              onFocus={undefined}
              onBlur={undefined}
            />
          )}
        </BaSeI18nConsumer>
      ),
      'base-arrow-button': (baseArrowButtonProps: BaSeArrowButtonProps) => (
        <BaSeArrowButton
          {...baseArrowButtonProps}
          tooltip={tooltip}
          isDisabled={isDisabled}
          color={color}
          type={type}
          onClick={() => setMenuOpen((prev) => !prev)}
          onFocus={undefined}
          onBlur={undefined}
        />
      ),
    };
  }, [isDisabled, size, color, type, tooltip, sizeIcon, icon]);

  const returnDefault = React.useCallback(() => {
    setCurrentIndex(NEGATIVE_INDEX);
    setMenuOpen(false);
    onClose?.();
  }, [onClose]);

  const handlePosition = React.useCallback(
    (pos: PositionRelativeType) => {
      let styledPosition = '';
      styledPosition += pos.includes('top') ? 'top:100%;' : 'bottom: 100%;';
      styledPosition += pos.includes('left') ? 'left:0;' : 'right: 0;';
      return styledPosition;
    },
    [position],
  );

  React.useEffect(() => {
    if (menuOpen) {
      onOpen?.();
      setTimeout(() => {
        setCurrentIndex(0);
      }, 200);
    } else {
      setStyles('');
      setCurrentIndex(NEGATIVE_INDEX);
    }
  }, [menuOpen]);

  React.useEffect(() => {
    if (currentIndex < 0 && currentIndex !== NEGATIVE_INDEX) {
      setCurrentIndex(items.length - 1);
    }
    if (currentIndex > items.length - 1) {
      setCurrentIndex(0);
    }
  }, [currentIndex]);

  React.useEffect(() => {
    if (noIcon) {
      setMenuOpen(open);
    }
  }, [open, noIcon]);

  React.useEffect(() => {
    if (trackPosition) {
      try {
        let style = '';
        const { bottom, right, width, height } =
          menuRef?.current?.getBoundingClientRect() as DOMRect;
        if (right && bottom && width && height && !styles) {
          const { innerWidth, innerHeight } = window as Window;
          if (height < innerHeight) {
            if (bottom > innerHeight) {
              style += 'bottom: 100%;';
            } else {
              style += 'top:100%;';
            }
          }
          if (width < innerWidth) {
            if (right > innerWidth) {
              style += 'right:0;';
            } else {
              style += 'left:0;';
            }
          }
          if (style === styles) {
            return;
          }
          setStyles(style);
        }
      } catch {
        setStyles('');
      }
    } else {
      if (position) {
        setStyles(handlePosition(position));
      }
    }
  }, [menuRef, menuRef?.current?.getBoundingClientRect()]);

  return (
    <ActionItemWrapper
      onMouseEnter={() => {
        setIsTooltipOpen(true);
      }}
      onMouseLeave={() => {
        setIsTooltipOpen(false);
      }}
      relative={!noIcon}
      ref={menuRef}
    >
      {!noIcon &&
        displayButton()[handleButtonType() as ItemButtonType](
          customButtonProps,
        )}
      {items.length > 0 && !isDisabled && (
        <ActionItemContainer
          minWidth={minWidth}
          addStyles={styles}
          className={menuOpen ? 'BaSe--action-item-open' : ''}
        >
          <ActionItemListWrapper>
            {items.map((item, key) => (
              <SingleActionItem
                callback={closeOnAction ? returnDefault : undefined}
                item={item}
                key={key}
                index={key}
                opened={menuOpen}
                hasFocus={key === currentIndex}
                updateCurrent={setCurrentIndex}
                subitems={item.subitems}
                onClick={closeOnAction ? returnDefault : undefined}
              />
            ))}
          </ActionItemListWrapper>
        </ActionItemContainer>
      )}
      {objectNotEmpty(tooltip) && !!noIcon && (
        <BaSeTooltip
          buddyRef={menuRef}
          open={isTooltipOpen}
          {...(tooltip as Omit<TooltipProps, 'open' | 'buddyRef'>)}
        />
      )}
    </ActionItemWrapper>
  );
};

BaSeActionItem.displayName = 'BaSeActionItem';
