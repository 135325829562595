import styled, { css } from 'styled-components';
import { numberWithUnit, urlSanitizer } from '../../utils/string-utils';
import { BaSeConfig } from '../../config/config';
import { BaSeTheme } from '../../theme';
import { ThemeColorValue } from '../../theme/theme-interface';
import { transformToFilter } from '../../utils/color-transformation/transform-to-filter';

const iconAncleRight = 'images/icons/angle-down.svg';

function getFilter(color: string): string {
  return transformToFilter(color);
}

function getImageUrl(): string {
  return `${urlSanitizer(BaSeConfig.staticAssetsUrl)}/${iconAncleRight}`;
}

interface AccordionContainerProps {
  isSingle: boolean;
  shadow: string;
  radius: number | string;
  isOpen: boolean;
}

interface AccordionTitleProps
  extends Pick<AccordionContainerProps, 'isSingle' | 'radius' | 'isOpen'> {
  isFirst: boolean;
  isLast: boolean;
  headerColor: ThemeColorValue;
  borderHeaderColor: ThemeColorValue;
  backgroundHeaderColor: ThemeColorValue;
  noPaddingTitle?: boolean;
  hasBorder?: boolean;
}

interface AccordionContentProps
  extends Partial<
    Pick<
      AccordionTitleProps,
      'isOpen' | 'isLast' | 'borderHeaderColor' | 'radius'
    >
  > {
  backgroundColor: ThemeColorValue;
  noPaddingContent?: boolean;
  hasBorder?: boolean;
}

export const AccordionContainer = styled.div.attrs({
  className: 'BaSe--accordion',
})<AccordionContainerProps>`
  ${({ isOpen }) => css`
    overflow: ${isOpen ? 'visible' : 'hidden'};
  `}
  box-shadow: ${({ shadow }) => shadow};

  ${({ isSingle, radius }) =>
    !isSingle &&
    css`
      border-radius: ${numberWithUnit(radius)};
    `}
`;

export const AccordionContainerWrapper = styled.div<
  Pick<AccordionContainerProps, 'isOpen'>
>`
  ${({ isOpen }) => css`
    overflow: ${isOpen ? 'visible' : 'hidden'};

    .BaSe--accordion-title {
      &::after {
        transform: rotate(180deg);
      }
    }
    .BaSe--accordion-content {
      max-height: 10000vh;
    }
  `}
`;

export const AccordionTitleContainer = styled.div.attrs({
  className: 'BaSe--accordion-title-container',
})<AccordionTitleProps>`
  padding: ${({ noPaddingTitle = false }) => !noPaddingTitle && '1em'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  gap: 1em;

  ${({ borderHeaderColor, hasBorder = true }) =>
    hasBorder &&
    css`
      border: 1px solid ${borderHeaderColor};
    `}

  ${({
    isFirst,
    isSingle,
    isLast,
    isOpen,
    backgroundHeaderColor,
    radius,
  }) => css`
    ${isFirst &&
    !isSingle &&
    css`
      border-radius: ${numberWithUnit(radius)} ${numberWithUnit(radius)} 0 0;
    `};

    ${!isSingle &&
    !isOpen &&
    isLast &&
    css`
      border-radius: 0 0 ${numberWithUnit(radius)} ${numberWithUnit(radius)};
    `}

    ${isOpen &&
    css`
      border-bottom: none;
    `}

    background-color:${backgroundHeaderColor};
  `}
`;

export const AccordionTitle = styled.label.attrs({
  className: 'BaSe--accordion-title',
})<AccordionTitleProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  cursor: pointer;
  position: relative;

  color: ${({ headerColor }) => headerColor};
  border: 1px solid ${({ borderHeaderColor }) => borderHeaderColor};
  background: ${({ backgroundHeaderColor }) => backgroundHeaderColor};

  ${({ isFirst, isSingle, radius }) =>
    isFirst &&
    !isSingle &&
    css`
      border-radius: ${numberWithUnit(radius)} ${numberWithUnit(radius)} 0 0;
    `}

  ${({ isLast, isSingle, isOpen, radius }) =>
    !isSingle &&
    !isOpen &&
    isLast &&
    css`
      border-radius: 0 0 ${numberWithUnit(radius)} ${numberWithUnit(radius)};
    `}

  &::after {
    will-change: transform;
    content: url(${getImageUrl()});
    position: absolute;
    right: 1em;
    top: 1em;
    width: 2em;
    height: 2em;
    text-align: center;
    transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
    filter: ${({ headerColor }) => getFilter(headerColor)};
  }
`;

export const AccordionContent = styled.div.attrs({
  className: 'BaSe--accordion-content',
})<AccordionContentProps>`
  will-change: max-height, padding;
  background: ${({ backgroundColor }) => backgroundColor};
  box-sizing: border-box;
  max-height: 0;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  padding: 0 1em;

  ${({ isOpen }) => css`
    overflow: ${isOpen ? 'visible' : 'hidden'};
  `}

  ${({ noPaddingContent }) =>
    noPaddingContent &&
    css`
      padding: 0;
    `}

  ${({ isOpen, borderHeaderColor, hasBorder }) =>
    isOpen &&
    css`
      ${hasBorder &&
      css`
        border: 1px solid ${borderHeaderColor};
      `}
      border-top: none;
    `}

  ${({ isLast, radius }) =>
    isLast &&
    css`
      border-radius: 0 0 ${numberWithUnit(radius)} ${numberWithUnit(radius)};
    `}
`;

export const AccordionButtonWrapper = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    transform: rotateZ(${isOpen ? '180deg' : '0deg'});
    transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
    width: 3em
    height: 3em
    display: grid;1    align-self: center;
    place-items: center;
  `}
`;
