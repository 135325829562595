import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../utils/string-utils';

export const ContainerStyled = styled.div.attrs({
  className: 'BaSe--container',
})<{ maxWidth?: number; fullViewport: boolean; autoScroll: boolean }>`
  &.BaSe--container {
    width: 100%;
    margin: 0 auto;

    max-width: ${({ maxWidth }) => numberWithUnit(maxWidth, 'px', '100%')};

    ${({ fullViewport }) =>
      fullViewport &&
      css`
        height: 100vh;
        width: 100vw;
      `}

    ${({ autoScroll }) =>
      autoScroll &&
      css`
        overflow: auto;
      `}
  }
`;
