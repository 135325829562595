import * as React from 'react';

import {
  arrowButtonMapStyle,
  mapCustomColorsArrowButtonValues,
} from '../map-button-style';
import { BaSeTextButton } from '../text-button/text-button';
import { ArrowButton, ArrowStyledContainer } from './arrow-button-styled';
import { ButtonProps } from '../button-props';
import { BaSeTheme } from '../../../theme';
import { BaSeTooltip } from '../../alerts/tooltip/tooltip';
import { objectNotEmpty } from '../../../utils/object-utils';
import { getFormattedDataset } from '../../../utils/dataset-utils';

const DEFAULT_BORDER_RADIUS = '7.5px';
const AVALIABLE_BORDER_RADIUS_VALUES = [16, 8, 4, 2, 0] as const;
type BorderRadiusTypes = (typeof AVALIABLE_BORDER_RADIUS_VALUES)[number];

export interface BaSeArrowButtonProps
  extends Omit<
    ButtonProps,
    | 'size'
    | 'isBig'
    | 'isBold'
    | 'leftIcon'
    | 'nameIcon'
    | 'rightIcon'
    | 'descriptionLeftIcon'
    | 'descriptionIcon'
    | 'descriptionRightIcon'
    | 'shape'
    | 'sideButton'
    | 'sizeIcon'
    | 'spacedIcons'
    | 'textColor'
    | 'width'
    | 'isLoading'
    | 'loadingText'
  > {
  borderRadius?: BorderRadiusTypes;
}

export const BaSeArrowButton: React.FC<BaSeArrowButtonProps> = ({
  id,
  name,
  dataset,
  type = 'primary',
  value = 'Valor',
  color = 'default',
  isDisabled = false,
  direction = 'right',
  buttonType = 'button',
  autoFocus = false,
  tooltip = {},
  borderRadius,
  onClick = () => {},
  onFocus,
  onBlur,
}) => {
  const formattedDataset = getFormattedDataset(dataset);

  const colorIsMapped = React.useMemo(
    () =>
      color === BaSeTheme.components.button.colors.default ||
      color === BaSeTheme.components.button.colors.negative,
    [color],
  );

  const getDefaultStyleMap = React.useMemo(() => {
    const types = arrowButtonMapStyle[type] ?? arrowButtonMapStyle.primary;
    return types?.[color as string] ?? types?.default;
  }, [type, color]);

  const getColorsAtributes = React.useMemo(() => {
    if (!colorIsMapped && color) {
      return (
        mapCustomColorsArrowButtonValues(color as string)?.[type as string] ??
        mapCustomColorsArrowButtonValues(color as string).primary
      );
    }
    return getDefaultStyleMap;
  }, [color, colorIsMapped, type, getDefaultStyleMap]);

  const typeMapped = React.useMemo(
    () =>
      isDisabled
        ? color === 'negative'
          ? arrowButtonMapStyle[type]?.disabled?.negative ??
            arrowButtonMapStyle[type]?.disabled
          : arrowButtonMapStyle[type]?.disabled ??
            arrowButtonMapStyle.primary.default
        : getColorsAtributes,
    [isDisabled, color, getColorsAtributes],
  );

  const { color: iconColor, arrows, ...typeStyle } = typeMapped;
  const isLeft = direction === 'left';

  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const borderRadiusPx = React.useMemo(
    () =>
      (borderRadius ?? '') in AVALIABLE_BORDER_RADIUS_VALUES
        ? `${borderRadius}px`
        : DEFAULT_BORDER_RADIUS,
    [borderRadius],
  );

  return (
    <ArrowButton
      id={id}
      name={name}
      {...formattedDataset}
      ref={buttonRef}
      aria-disabled={isDisabled ? true : undefined}
      className="BaSe--button-arrow"
      autoFocus={autoFocus}
      disabled={isDisabled}
      isDisabled={isDisabled}
      type={buttonType}
      isLeft={isLeft}
      isSecondaryNegative={color === 'negative' && type === 'secondary'}
      hasBoxShadow={color !== 'negative' || !isDisabled}
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      borderRadius={borderRadiusPx}
      {...typeStyle}
    >
      <ArrowStyledContainer
        isSecondaryNegative={color === 'negative' && type === 'secondary'}
        boxShadow={color !== 'negative' || !isDisabled}
        isLeft={isLeft}
        {...arrows}
      />
      <BaSeTextButton
        fontSize={1}
        lineHeight={1.4}
        hasLeftIcon={false}
        hasRightIcon={false}
        fontWeigh="normal"
        color={iconColor}
      >
        {value}
      </BaSeTextButton>
      {objectNotEmpty(tooltip) && (
        <BaSeTooltip
          buddyRef={buttonRef}
          color={tooltip?.color}
          direction={tooltip?.direction}
          message={tooltip?.message ?? ''}
          open={isTooltipOpen}
          width={tooltip?.width}
          coordinates={tooltip?.coordinates}
        />
      )}
    </ArrowButton>
  );
};

BaSeArrowButton.displayName = 'BaSeArrowButton';
