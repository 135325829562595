import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../public-api/others';
// import { ColorMapper } from '../../utils/color-transformation/color-mapper';
import { ThemeColorValue } from './../../theme/theme-interface';
import { mapTogglerTypeSpan } from './map-toggler-style';

export interface TogglerColorProps {
  defaultColor?: ThemeColorValue;
  checkedColor?: ThemeColorValue;
  disabledColor?: ThemeColorValue;
}

interface TogglerState {
  backgroundColor: string;
  border: string;
  foreground: string;
  spanBorder: string;
  shadowFocus: string;
  iconColor?: string;
  hoverColor?: string;
}

interface StyledTogglerProps {
  isDisabled: boolean;
  type: string;
  value: boolean;
  colors: TogglerState;
  hasFocus?: boolean;
}

// const memo = {};
// const handleColorTransform = (color: string) => {
//   if (color in memo) {
//     return memo[color];
//   }
//   memo[color] = ColorMapper.dark.hover.create(color);
//   return memo[color];
// };

export const StyledTogglerLabel = styled.label.attrs({
  className: 'BaSe--toggler',
})<StyledTogglerProps>`
  ${({ type, isDisabled, colors, hasFocus }) => css`
    display: flex;
    width: 58px;
    min-width: 58px;
    height: ${type === 'contained' ? '16px' : '24px'};
    position: relative;
    border-radius: 26px;
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    background-color: ${colors.backgroundColor};
    border: ${colors.border};
    ${hasFocus &&
    `
      box-shadow: 0px 0px 0px 3px ${colors?.shadowFocus};
    `}
    &:hover {
      ${!isDisabled &&
      `
        ${
          colors.border === 'none'
            ? `
          background-color: ${colors?.iconColor ?? colors?.hoverColor};
          > span {
            background-color: ${colors?.iconColor ?? colors?.hoverColor};
          }
        `
            : `
          border: 2px solid  ${colors?.iconColor ?? colors?.hoverColor};
          > span {
            background-color: ${colors?.iconColor ?? colors?.hoverColor};
          }
        `
        }
      `}
    }
  `}
`;

export const StyledTogglerInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const StyledTogglerSpan = styled.span<StyledTogglerProps>`
  ${({ type, value, colors }) => css`
    position: absolute;
    border-radius: 40px;
    width: ${mapTogglerTypeSpan[type].size};
    height: ${mapTogglerTypeSpan[type].size};
    top: ${mapTogglerTypeSpan[type].position.top};
    left: ${mapTogglerTypeSpan[type].position.left};
    ${value && 'transform:' + mapTogglerTypeSpan[type].transform};
    background-color: ${
      type === 'contained' ? colors.backgroundColor : colors.foreground
    };
    border ${type === 'contained' ? `2px solid ${colors.spanBorder};` : 'none;'}
    transition: transform ${BaSeTheme.transitions.durationNormal};
  `}
`;

export const StyledTogglerIconContainer = styled.div<
  Partial<StyledTogglerProps>
>`
  visibility: ${(props) => (props.value ? 'visible' : 'hidden')};
  position: absolute;
  top: ${(props) => (props.type === 'contained' ? '-2.2px' : '2px')};
  left: 2px;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
`;
