import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { BaSeScrollbarCss } from '../../theme/scrollbar';
import { elevatedCss, getShadow, ShadownizedProps } from '../../theme/shadows';
import { ThemeColorValue } from '../../theme/theme-interface';
import { numberWithUnit } from '../../utils/string-utils';

export interface PanelAppearenceProps extends ShadownizedProps {
  height?: string | number;
  width?: string | number;
  borderRadius?: string | number;
  backgroundColor?: ThemeColorValue;
  foregroundTitleColor: ThemeColorValue;
  foregroundSubtitleColor: ThemeColorValue;
  headerColor: ThemeColorValue;
  borderColor: ThemeColorValue;
  hasShadow?: boolean;
}

export const PanelWrapper = styled.section.attrs({
  className: 'BaSe--panel',
})<
  Omit<
    PanelAppearenceProps,
    'foregroundTitleColor' | 'foregroundSubtitleColor' | 'headerColor'
  >
>`
  &.BaSe--panel {
    display: flex;
    flex-direction: column;

    will-change: box-shadow, transform, border-left-width;
    box-sizing: content-box;
    overflow: hidden;

    transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};

    ${({ height, width, backgroundColor, borderColor, borderRadius }) => css`
      border-radius: ${numberWithUnit(borderRadius)};
      height: ${numberWithUnit(height)};
      width: ${numberWithUnit(width)};
      background-color: ${backgroundColor};
      border: ${`1px solid ${borderColor}`};
    `}

    ${({ shadowLevel, elevationOnHoverOrFocus, isElevated }) =>
      shadowLevel &&
      css`
        box-shadow: ${getShadow(shadowLevel)};

        ${isElevated && elevatedCss}

        ${elevationOnHoverOrFocus &&
        css`
          &:hover,
          &:focus-within {
            ${elevatedCss}
          }
        `}
      `};
  }
`;

export const PanelHeader = styled.header<
  Pick<PanelAppearenceProps, 'borderColor' | 'headerColor' | 'hasShadow'>
>`
  min-height: 72px;
  display: grid !important;
  align-items: center;

  ${({ borderColor, headerColor, hasShadow }) => css`
    border-bottom: 1px solid ${borderColor};
    background-color: ${headerColor};
    ${hasShadow && `box-shadow: ${getShadow(2)}`};
  `}
`;

export const PanelContent = styled.div`
  padding: 0;
  margin: 0;
  overflow: auto;
  height: 100%;

  ${BaSeScrollbarCss}
`;

export const PanelHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const PanelHeaderImage = styled.div`
  padding-right: 12px;
`;

export const PanelHeaderContent = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const PanelHeaderActions = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-end;
`;
