import { Column } from 'react-table';
import { ShapeButtonProps } from '../button/shape-button/shape-button';
import {
  DataCellToMapper,
  DataConfig,
  DataGroupConfig,
  DataRenderedCell,
  ReloadDataConfig,
} from './data';
import { ColumnFilterRenderProps, DataTableColumnConfig } from './table/table';

export function configMapper<Item extends object = {}>({
  config,
  groupConfig,
  reloadData,
  resetSelection,
  whenRenderTextCell,
}: {
  config: DataConfig<Item>[];
  groupConfig?: DataGroupConfig<Item>[];
  reloadData(reloadDataConfig?: ReloadDataConfig): void;
  resetSelection(): void;
  whenRenderTextCell(value: string): DataRenderedCell;
}): Column<Item>[] {
  return (
    groupConfig?.map?.((groupConfigItem) => ({
      Header: groupConfigItem.header as any,
      columns: configMapper({
        whenRenderTextCell,
        reloadData,
        resetSelection,
        config: groupConfigItem.config,
      }),
    })) ??
    config.map((configItem) => ({
      Header: configItem.header,
      accessor: configItem.accessorKey as any,
      id: configItem.id,
      Cell: (data: DataCellToMapper<Item, any>) => {
        if (configItem.mapper) {
          const cellToRender = configItem.mapper({
            values: data.data[data.row.index],
            reloadData,
            resetSelection,
          });

          if (cellToRender === undefined || cellToRender === null) {
            return whenRenderTextCell('');
          }

          if (['number', 'string'].includes(typeof cellToRender)) {
            return whenRenderTextCell(String(cellToRender));
          }

          return cellToRender;
        }
        return whenRenderTextCell(String(data.value));
      },
    }))
  );
}

export interface RowIsSelectedArgs<Item> {
  itemKey: keyof Item;
  isAllSelected: boolean;
  selectedItems: Item[];
  item: Item;
}
export function rowIsSelected<Item>({
  isAllSelected,
  selectedItems,
  item,
  itemKey,
}: RowIsSelectedArgs<Item>): boolean {
  return (
    isAllSelected ||
    selectedItems.some(
      (selectedItem) =>
        selectedItem[itemKey] && selectedItem[itemKey] === item[itemKey],
    )
  );
}

export function renderColumnFilter<Item, Filter>(
  id: keyof Item | string,
  columnConfig: DataTableColumnConfig<Item, Filter>[],
): ((renderProps: ColumnFilterRenderProps<Filter>) => JSX.Element) | undefined {
  return columnConfig?.find(({ columnId: columnId }) => columnId === id)
    ?.renderColumnFilter;
}

export function columnFilterIconProps<Item, Filter>(
  id: keyof Item | string,
  columnConfig: DataTableColumnConfig<Item, Filter>[],
): Partial<Omit<ShapeButtonProps, 'onClick' | 'autoFocus'>> | undefined {
  return columnConfig?.find(({ columnId: columnId }) => columnId === id)
    ?.columnFilterIconProps;
}

const cachePageReducedRange = {};
type PageReducedRangeItem = number | '…';
export type PageReducedRange = PageReducedRangeItem[];
export function pageReducedRange(
  options: number[],
  actual: number,
): PageReducedRange {
  const cacheKey = `${actual}: [${options.toString()}]`;

  if (cachePageReducedRange[cacheKey]) {
    return cachePageReducedRange[cacheKey];
  }

  function resultWithCache(result: PageReducedRange): PageReducedRange {
    cachePageReducedRange[cacheKey] = result;
    return result;
  }

  const ellipsis = '…';
  const siblingCount = 1;
  const boundaryCount = 1;
  const page = actual + 1;
  const count = options.length;

  function range(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.from({ length }, (_, index) => start + index);
  }

  const startPages = range(1, Math.min(boundaryCount, count));
  const endPages = range(
    Math.max(count - boundaryCount + 1, boundaryCount + 1),
    count,
  );

  const startSiblingsPages = Math.max(
    Math.min(page - siblingCount, count - boundaryCount - siblingCount * 2 - 1),
    boundaryCount + 2,
  );

  const startSiblingsPagesOrEllipsis =
    startSiblingsPages > boundaryCount + 2
      ? [ellipsis]
      : boundaryCount + 1 < count - boundaryCount
        ? [boundaryCount + 1]
        : [];

  const endSiblingsPages = Math.min(
    Math.max(page + siblingCount, boundaryCount + siblingCount * 2 + 2),
    endPages.length > 0 ? endPages[0] - 2 : count - 1,
  );

  const endSiblingsPagesOrEllipsis =
    endSiblingsPages < count - boundaryCount - 1
      ? [ellipsis]
      : count - boundaryCount > boundaryCount
        ? [count - boundaryCount]
        : [];

  const middlePages = range(startSiblingsPages, endSiblingsPages);

  const pages = [
    ...startPages,
    ...startSiblingsPagesOrEllipsis,
    ...middlePages,
    ...endSiblingsPagesOrEllipsis,
    ...endPages,
  ] as PageReducedRange;

  return resultWithCache(
    pages.map((item) => (typeof item === 'number' ? item - 1 : item)),
  );

  // const boundary = 6;
  // const halfBoundary = Math.ceil(boundary / 2);
  // const total = options.length;
  // const first = 0;
  // const last = options[total - 1] ?? first;
  // const almostFirst = first + 1;
  // const almostLast = last - 1;
  // const dontNeedToReduce = last <= first + boundary - halfBoundary;

  // function inTheStart(item: number): boolean {
  //   return item <= first;
  // }

  // function notInTheStart(item: number): boolean {
  //   return !inTheStart(item);
  // }

  // function inTheEnd(item: number): boolean {
  //   return item >= last;
  // }

  // function notInTheEnd(item: number): boolean {
  //   return !inTheEnd(item);
  // }

  // function isActualAndSibling(item: number): boolean {
  //   const start = Array(halfBoundary)
  //     .fill(actual)
  //     .map((x, index) => x - index)
  //     .reverse();
  //   const end = Array(halfBoundary)
  //     .fill(actual)
  //     .map((x, index) => x + index);
  //   const actualAndSiblings = [...start, ...end];
  //   return actualAndSiblings.some(
  //     (actualAndSibling) => actualAndSibling === item,
  //   );
  // }

  // if (dontNeedToReduce) {
  //   return resultWithCache(options);
  // }

  // if (inTheEnd(actual)) {
  //   const end = Array(boundary)
  //     .fill(last)
  //     .map((x, index) => x - index)
  //     .reverse();

  //   return resultWithCache([first, '…', ...end]);
  // }

  // if (inTheStart(actual)) {
  //   const start = Array(boundary)
  //     .fill(first)
  //     .map((x, index) => x + index);

  //   return resultWithCache([...start, '…', last]);
  // }

  // const middleWithFirstAndLast: DataPageReducedRangeItem[] =
  //   options.filter(isActualAndSibling);

  // const sizeOfMiddle = middleWithFirstAndLast.length;
  // const startOfMiddle = middleWithFirstAndLast[0];
  // const endOfMiddle = middleWithFirstAndLast[sizeOfMiddle - 1];

  // if (startOfMiddle > almostFirst) {
  //   middleWithFirstAndLast.splice(0, 0, '…');
  // }

  // if (endOfMiddle < almostLast) {
  //   middleWithFirstAndLast.splice(sizeOfMiddle, 0, '…');
  // }

  // const middle = middleWithFirstAndLast
  //   .filter(notInTheStart)
  //   .filter(notInTheEnd);

  // return resultWithCache([first, ...middle, last]);
}
