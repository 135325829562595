import * as React from 'react';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { BaSeColors } from '../../../theme/colors';
import { objectNotEmpty } from '../../../utils/object-utils';
import {
  BaSeTooltip,
  EmbedTooltipProps,
  TooltipProps,
} from '../../alerts/tooltip/tooltip';
import { BaSeShapeButton } from '../../button/shape-button/shape-button';
import { BaSeIcon } from '../../image/icon';
import { BaSeSmall1 } from '../../typography/small/small1';
import { BaSeSmall2 } from '../../typography/small/small2';
import { BaSeTheme } from './../../../theme';
import { mapTagValues } from './map-tag-style';
import {
  StyledTagTextProps,
  TagClose,
  TagContainerStyled,
  TagIcon,
  TagStyledProps,
  TagText,
} from './tag-styled';
import { BaSeBadge } from '../../badge/badge';

export interface OverrideTagColorProps {
  iconColor?: string;
}

export interface TagProps
  extends Partial<Omit<TagStyledProps, 'bigSize' | 'mediumSize' | 'smallSize'>>,
    Pick<StyledTagTextProps, 'limitedWidth'> {
  label: string;
  color?: string;
  overrideColor?: OverrideTagColorProps;
  tooltip?: EmbedTooltipProps;
}

export type ContainerProps = React.HTMLAttributes<HTMLDivElement> &
  TagStyledProps;

export const BaSeTag: React.FC<TagProps> = ({
  icon,
  label,
  overrideColor,
  limitedWidth,
  size = 'medium',
  type = 'primary',
  color = 'default',
  themeMode = 'light',
  isSelected = false,
  isDisabled = false,
  tooltip = {},
  onClick: clickCallback,
  onClose: closeCallback,
}) => {
  if (themeMode && !['light', 'dark'].includes(themeMode)) {
    throw Error('Atributo "themeMode" deve ser do tipo: "light" ou "dark"');
  }

  if (type && !['primary', 'secondary'].includes(type)) {
    throw Error('Atributo "type" deve ser do tipo: "primary" ou "secondary"');
  }

  const { getMessage } = React.useContext(BaSeI18nContext);

  const iconProps = React.useMemo(
    () => ({
      name: 'check',
      description: getMessage('tag.selected'),
      size: 16,
    }),
    [getMessage],
  );

  const { onClick, onClose } = React.useMemo(
    () => ({
      onClick: isDisabled ? undefined : clickCallback,
      onClose: isDisabled ? undefined : closeCallback,
    }),
    [clickCallback, closeCallback, isDisabled],
  );

  const containerProps: ContainerProps = React.useMemo(() => {
    const props: ContainerProps = {
      bigSize: 32,
      mediumSize: 24,
      smallSize: 16,
      className: 'BaSe--tag',
      icon,
      size,
      type,
      isSelected,
      isDisabled,
      themeMode,
      tabIndex: -1,
      onClose,
    };

    if (onClick) {
      props.role = 'button';
      props.tabIndex = 0;
      props.onKeyUp = (e) => e.key === 'Enter' && onClick(e);
      props.onClick = onClick;
    }

    return props;
  }, [icon, size, type, isSelected, isDisabled, themeMode, onClose, onClick]);

  const getColorsAtributes = (newColor: string) => {
    if (newColor) {
      return mapTagValues(newColor)[type][themeMode];
    }
    return mapTagValues('default')[type][themeMode];
  };

  const baseColor =
    themeMode === 'light'
      ? BaSeColors.defaultColors.white
      : BaSeColors.institucionais.cinzaSebrae20;

  const getValues = () => {
    if (isDisabled) {
      return {
        bgC: BaSeTheme.colors.institucionais.cinzaSebrae90,
        bcCHover: BaSeTheme.colors.institucionais.cinzaSebrae75,
        boxShadowFocus: BaSeTheme.colors.institucionais.cinzaSebrae75,
        border: isSelected
          ? BaSeTheme.colors.institucionais.cinzaSebrae75
          : BaSeTheme.colors.institucionais.cinzaSebrae90,
        newIconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
      };
    }

    const colorValue = getColorsAtributes(color);
    if (isSelected && type === 'secondary') {
      return {
        bgC: colorValue.iconColor,
        bcCHover: colorValue.style.bcCHover,
        boxShadowFocus: colorValue.style.boxShadowFocus,
        border: colorValue.style.border,
        newIconColor: colorValue.style.bgC,
      };
    }
    if (isSelected && type === 'primary') {
      return {
        bgC: themeMode === 'dark' ? colorValue.iconColor : baseColor,
        bcCHover: colorValue.style.bcCHover,
        boxShadowFocus: colorValue.style.boxShadowFocus,
        border: colorValue.iconColor,
        newIconColor:
          themeMode === 'dark' ? colorValue.style.bgC : colorValue.iconColor,
      };
    }
    return {
      bgC: colorValue.style.bgC,
      bcCHover: colorValue.style.bcCHover,
      boxShadowFocus: colorValue.style.boxShadowFocus,
      border: colorValue.style.border,
      newIconColor: colorValue.iconColor,
    };
  };

  const {
    style: { bcCHover: bcCHoverIcon },
  } = getColorsAtributes(overrideColor?.iconColor ?? color);

  const { bgC, bcCHover, boxShadowFocus, border, newIconColor } = getValues();

  const [isHover, setIsHover] = React.useState<boolean>(false);
  const [isHoverClose, setIsHoverClose] = React.useState<boolean>(false);
  const [isFocus, setIsFocus] = React.useState<boolean>(false);

  const divRef = React.useRef<HTMLDivElement>(null);
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const Text = size === 'small' ? BaSeSmall2 : BaSeSmall1;

  const buildTag = React.useCallback(
    () => (
      <TagContainerStyled
        {...containerProps}
        bgC={bgC}
        bcCHover={bcCHover}
        boxShadowFocus={boxShadowFocus}
        border={border}
        newIconColor={newIconColor}
        onMouseEnter={() => {
          if (onClick) {
            setIsHover(true);
          }
          setIsTooltipOpen(true);
        }}
        onMouseLeave={() => {
          if (onClick) {
            setIsHover(false);
          }
          setIsTooltipOpen(false);
        }}
        onFocus={() => onClick && setIsFocus(true)}
        onBlur={() => onClick && setIsFocus(false)}
        ref={divRef}
        aria-checked={isSelected}
        tabIndex={isDisabled ? -1 : containerProps.tabIndex}
      >
        {icon && (
          <TagIcon
            className="BaSe--tag-icon icon"
            type={type}
            newIconColor={
              (isHover || isFocus) && !isSelected
                ? bcCHoverIcon
                : overrideColor?.iconColor ?? newIconColor
            }
            size={size}
          >
            <BaSeIcon
              size={size === 'big' ? 28 : size === 'medium' ? 14 : 12}
              name={icon}
              description={getMessage('icon.label', label)}
              color={
                type === 'primary'
                  ? isSelected
                    ? bgC
                    : BaSeColors.defaultColors.white
                  : (isHover || isFocus) && !isSelected
                    ? bcCHoverIcon
                    : overrideColor?.iconColor ?? newIconColor
              }
            />
          </TagIcon>
        )}
        {label && (
          <TagText
            className="BaSe--tag-text text"
            newIconColor={newIconColor}
            size={size}
            icon={icon}
            limitedWidth={limitedWidth}
            onClose={onClose}
          >
            <Text
              isBold={true}
              color={
                isSelected || !(isHover || isFocus) ? newIconColor : bcCHover
              }
            >
              {label}
            </Text>
          </TagText>
        )}
        {onClose && (
          <TagClose
            className="BaSe--tag-close close"
            onClose={onClose}
            bcCHover={newIconColor}
            size={size}
            type={type}
            themeMode={themeMode}
            onMouseEnter={() => {
              setIsHoverClose(true);
            }}
            onMouseLeave={() => {
              setIsHoverClose(false);
            }}
          >
            <BaSeShapeButton
              buttonType="button"
              nameIcon="close"
              shape="circle"
              size={size}
              sizeIcon={size}
              type="tertiary"
              color={themeMode === 'dark' && isHoverClose ? bgC : newIconColor}
              onClick={(e) => {
                if (onClick) {
                  e.stopPropagation();
                }
                onClose(e);
              }}
            />
          </TagClose>
        )}
        {objectNotEmpty(tooltip) && (
          <BaSeTooltip
            buddyRef={divRef}
            open={isTooltipOpen}
            direction="top"
            {...(tooltip as Omit<TooltipProps, 'open' | 'buddyRef'>)}
          />
        )}
      </TagContainerStyled>
    ),
    [
      containerProps,
      bgC,
      bcCHover,
      boxShadowFocus,
      border,
      newIconColor,
      divRef,
      isHover,
      isFocus,
      bcCHoverIcon,
      isHoverClose,
      isTooltipOpen,
      containerProps.tabIndex,
      BaSeColors.defaultColors.white,
      isDisabled,
      icon,
      type,
      isSelected,
      overrideColor?.iconColor,
      size,
      label,
      themeMode,
      onClick,
      onClose,
      objectNotEmpty,
    ],
  );

  return isSelected ? (
    <BaSeBadge
      height={18}
      width={18}
      color={color}
      type={type}
      position={{ right: 12, bottom: 10 }}
      value={iconProps}
    >
      {buildTag()}
    </BaSeBadge>
  ) : (
    buildTag()
  );
};

BaSeTag.displayName = 'BaSeTag';
