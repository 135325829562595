import * as React from 'react';
import { BaSeTheme } from '../../theme';
import {
  ThemeColorValue,
  ThemeShadowsInterface,
} from '../../theme/theme-interface';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { BaSeHeading5 } from '../typography/heading/heading5';
import {
  AccordionContainer,
  AccordionContainerWrapper,
  AccordionContent,
  AccordionTitleContainer,
  AccordionButtonWrapper,
} from './accordion-styled';

type RenderAccordionTitle = ({
  open,
  setOpen,
  color,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  color: ThemeColorValue;
}) => JSX.Element;

type AccordionContentElementFunction = ({
  close,
}: {
  close: () => void;
}) => JSX.Element;

type Size = 'small' | 'medium' | 'big';

export type AccordionContentElement =
  | AccordionContentElementFunction
  | JSX.Element;

export type AccordionTitleElement = RenderAccordionTitle | string | JSX.Element;

export interface AccordionOption {
  title: AccordionTitleElement;
  content: AccordionContentElement;
  noPaddingContent?: boolean;
  noPaddingTitle?: boolean;
  id?: string | number;
}

export interface AccordionProps {
  options: AccordionOption[];
  backgroundTitleColor?: ThemeColorValue;
  borderTitleColor?: ThemeColorValue;
  backgroundContentColor?: ThemeColorValue;
  titleColor?: ThemeColorValue;
  shadow?: ThemeShadowsInterface[keyof ThemeShadowsInterface];
  radius?: number | string;
  multipleOpen?: boolean;
  startOpen?: number | number[];
  hasContentBorder?: boolean;
  hasTitleBorder?: boolean;
  titleButtonIconSize?: Size;
  titleButtonSize?: Size;
}

interface AccordionOptionItemProps {
  data: AccordionOption;
  index: number;
  isOpen: boolean;
  onChange: (value: boolean, index: number) => void;
  id?: string | number;
  titleColor?: string;
  backgroundTitleColor?: string;
  backgroundContentColor?: string;
  borderTitleColor?: string;
  hasContentBorder?: boolean;
  hasTitleBorder?: boolean;
  titleButtonIconSize?: Size;
  titleButtonSize?: Size;
  radius?: string | number;
  qtyOptions?: number;
}

export const AccordionOptionItem: React.FC<AccordionOptionItemProps> = ({
  data,
  index,
  isOpen,
  onChange,
  id,
  backgroundTitleColor = BaSeTheme.colors.defaultColors.white,
  backgroundContentColor = BaSeTheme.colors.institucionais.cinzaSebrae97,
  titleColor = BaSeTheme.colors.institucionais.cinzaSebrae45,
  borderTitleColor = BaSeTheme.colors.institucionais.cinzaSebrae97,
  hasContentBorder = true,
  titleButtonIconSize = 'big',
  titleButtonSize = 'big',
  hasTitleBorder = true,
  radius = 8,
  qtyOptions = 0,
}) => {
  const accordionTitleElementChangeOpen = React.useCallback(
    (value: boolean) => {
      onChange(value, index);
    },
    [onChange, index],
  );

  const accordionTitleElementClose = React.useCallback(() => {
    onChange(false, index);
  }, [onChange, index]);

  const getAccordionTitleElement = React.useCallback(
    (element: AccordionTitleElement) => {
      if (typeof element === 'string') {
        return <BaSeHeading5 color={titleColor}>{element}</BaSeHeading5>;
      } else if (React.isValidElement(element)) {
        return element;
      } else {
        const newElement = element as RenderAccordionTitle;
        return newElement?.({
          open: isOpen,
          setOpen: accordionTitleElementChangeOpen,
          color: titleColor,
        });
      }
    },
    [titleColor, accordionTitleElementChangeOpen, isOpen],
  );

  const getAccordionContentElement = React.useCallback(
    (element: AccordionContentElement) => {
      if (React.isValidElement(element)) {
        return element;
      } else {
        const newElement = element as AccordionContentElementFunction;
        return newElement?.({ close: accordionTitleElementClose });
      }
    },
    [accordionTitleElementClose],
  );

  const onChangeOpen = React.useCallback(
    () => onChange(!isOpen, index),
    [onChange, isOpen, index],
  );

  return (
    <AccordionContainerWrapper isOpen={isOpen} key={id}>
      <AccordionTitleContainer
        isFirst={index === 0}
        isLast={index === qtyOptions - 1}
        isSingle={qtyOptions === 1}
        isOpen={isOpen}
        headerColor={titleColor}
        borderHeaderColor={borderTitleColor}
        backgroundHeaderColor={backgroundTitleColor}
        radius={radius}
        noPaddingTitle={data.noPaddingTitle}
        hasBorder={hasTitleBorder}
      >
        {getAccordionTitleElement(data.title)}
        <AccordionButtonWrapper isOpen={isOpen}>
          <BaSeShapeButton
            color={titleColor}
            type="tertiary"
            size={titleButtonSize}
            nameIcon="angle-down"
            sizeIcon={titleButtonIconSize}
            onClick={onChangeOpen}
          />
        </AccordionButtonWrapper>
      </AccordionTitleContainer>
      {isOpen && (
        <AccordionContent
          hasBorder={hasContentBorder}
          backgroundColor={backgroundContentColor}
          noPaddingContent={data?.noPaddingContent}
          isOpen={isOpen}
          isLast={index === qtyOptions - 1}
          borderHeaderColor={borderTitleColor}
          radius={radius}
        >
          {getAccordionContentElement(data.content)}
        </AccordionContent>
      )}
    </AccordionContainerWrapper>
  );
};

export const BaSeAccordion: React.FC<AccordionProps> = ({
  options = [],
  backgroundTitleColor = BaSeTheme.colors.defaultColors.white,
  backgroundContentColor = BaSeTheme.colors.institucionais.cinzaSebrae97,
  titleColor = BaSeTheme.colors.institucionais.cinzaSebrae45,
  borderTitleColor = BaSeTheme.colors.institucionais.cinzaSebrae97,
  shadow = BaSeTheme.shadows.profundidade2,
  radius = 8,
  multipleOpen = false,
  startOpen: initialStartOpen,
  hasContentBorder = true,
  titleButtonIconSize = 'big',
  titleButtonSize = 'big',
  hasTitleBorder = true,
}) => {
  const startOpen = React.useMemo(
    () =>
      Array.isArray(initialStartOpen)
        ? initialStartOpen
        : typeof initialStartOpen === 'number'
          ? [initialStartOpen]
          : undefined,
    [initialStartOpen],
  );

  const getId = React.useCallback((item: AccordionOption, index: number) => {
    const titleAsId =
      typeof item?.title === 'string'
        ? item?.title?.replace(/\s+/g, '_')
        : null;
    return item?.id ?? titleAsId ?? index;
  }, []);

  const [optionsStates, setOptionStates] = React.useState<boolean[]>([]);

  const hasAnyOpen = React.useMemo(
    () => optionsStates.some((value) => value),
    [optionsStates],
  );

  const startedOpenedObject = React.useMemo(
    () =>
      startOpen?.reduce<{ [key: number]: boolean }>(
        (currentObject, currentValue) => ({
          ...currentObject,
          [currentValue]: true,
        }),
        {},
      ) ?? {},
    [startOpen],
  );

  React.useEffect(() => {
    setOptionStates(options.map((_, index) => startedOpenedObject[index]));
  }, [options, startedOpenedObject]);

  React.useEffect(() => {
    if (startOpen) {
      if (!multipleOpen && startOpen?.length > 1) {
        throw Error(
          '[BaSeAccordion] Para passar mais de um valor para a propriedade "startOpen", é necessário definir a propriedade "multipleOpen" como true',
        );
      }
      if (startOpen?.length > options.length) {
        throw Error(
          '[BaSeAccordion] Não é possível passar uma quantidade no "startOpen" maior que a quantidade de itens do "Accordion"',
        );
      }
      startOpen?.forEach((item) => {
        if (item > options.length - 1) {
          throw Error(
            '[BaSeAccordion] Não é possível inserir um valor de índice no "startOpen" que não existe no "Accordion"',
          );
        }
      });
    }
  }, [startOpen, multipleOpen, options]);

  const onChangeOption = React.useCallback(
    (value: boolean, index: number) => {
      setOptionStates((oldState) => {
        if (multipleOpen) {
          const newState = [...oldState];
          newState[index] = value;
          return [...newState];
        }

        return oldState.map((_, oldIndex) => oldIndex === index && value);
      });
    },
    [multipleOpen],
  );

  const isOpen = React.useCallback(
    (index: number) => optionsStates?.[index] ?? false,
    [optionsStates],
  );

  return (
    <AccordionContainer
      shadow={shadow}
      radius={radius}
      isSingle={options.length === 1}
      isOpen={hasAnyOpen}
    >
      {options.map((data, index) => (
        <AccordionOptionItem
          key={index}
          data={data}
          index={index}
          isOpen={isOpen(index)}
          onChange={onChangeOption}
          backgroundContentColor={backgroundContentColor}
          backgroundTitleColor={backgroundTitleColor}
          borderTitleColor={borderTitleColor}
          hasContentBorder={hasContentBorder}
          hasTitleBorder={hasTitleBorder}
          id={getId(data, index)}
          qtyOptions={options.length}
          radius={radius}
          titleButtonIconSize={titleButtonIconSize}
          titleButtonSize={titleButtonSize}
          titleColor={titleColor}
        />
      ))}
    </AccordionContainer>
  );
};

BaSeAccordion.displayName = 'BaSeAccordion';
