import * as React from 'react';

export interface CookieConsentInjection {
  injected: boolean;
}

function scriptOtAutoBlock({
  uuidDomain,
  onLoad,
}: {
  uuidDomain: string;
  onLoad(): void;
}): HTMLScriptElement {
  const attrs = {
    type: 'text/javascript',
    src: `https://cdn.cookielaw.org/consent/${uuidDomain}/OtAutoBlock.js`,
  };
  const dataset = {};
  return createScript(attrs, dataset, onLoad);
}

function scriptOtSDKStub({
  uuidDomain,
  onLoad,
}: {
  uuidDomain: string;
  onLoad: () => void;
}): HTMLScriptElement {
  const attrs = {
    type: 'text/javascript',
    charset: 'utf-8',
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  };
  const dataset = {
    domainScript: uuidDomain,
  };
  return createScript(attrs, dataset, onLoad);
}

function registerOptanonWrapperFunction() {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function OptanonWrapper() {}
  window['OptanonWrapper'] = OptanonWrapper;
}

function createScript(
  attrs: { [attr: string]: string },
  dataset: { [attr: string]: string },
  onLoad?: () => void,
) {
  const script = document.createElement('script');
  script.onload = () => {
    onLoad?.();
  };
  for (const [key, value] of Object.entries(attrs)) {
    script[key] = value;
  }
  script.dataset.sebraeCookieConsent = 'injected';
  for (const [key, value] of Object.entries(dataset)) {
    script.dataset[key] = value;
  }
  return script;
}

function append(element: HTMLElement): void {
  (document.head || document.body).appendChild(element);
}

function appended(): boolean {
  return !!document.querySelector('[data-sebrae-cookie-consent="injected"]');
}

function registerCustomer(uuidCustomer?: string): void {
  if (uuidCustomer) {
    // Sem interação, no OneTrust, com o UUID do usuário
  }
}

export function useCookieConsentInjection(
  uuidDomain: string,
  uuidCustomer: string,
): CookieConsentInjection {
  const [injected, setInjected] = React.useState<boolean>(false);

  const [autoBlockLoaded, setAutoBlockeLoaded] = React.useState<boolean>(false);
  const [SDKStubLoaded, setSDKStubLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (injected || !uuidDomain) {
      return;
    }

    if (appended()) {
      setInjected(true);
      return;
    }

    append(
      scriptOtAutoBlock({
        uuidDomain,
        onLoad() {
          setAutoBlockeLoaded(true);
        },
      }),
    );

    append(
      scriptOtSDKStub({
        uuidDomain,
        onLoad() {
          setSDKStubLoaded(true);
        },
      }),
    );

    registerOptanonWrapperFunction();
  }, [injected, uuidDomain]);

  React.useEffect(() => {
    if (injected) {
      registerCustomer(uuidCustomer);
    }
  }, [injected, uuidCustomer]);

  React.useEffect(() => {
    if (SDKStubLoaded && autoBlockLoaded) {
      setInjected(true);
    }
  }, [SDKStubLoaded, autoBlockLoaded]);

  return { injected };
}
