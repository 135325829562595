import styled from 'styled-components';

export const DataPaginationWrapper = styled.div.attrs({
  className: 'BaSe--data-pagination',
})`
  height: 3em;
  display: flex;
  align-items: center;
`;

export const DataPaginationController = styled.div.attrs({
  className: 'BaSe--data-pagination-controller',
})`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3em;
  gap: 0.25em;
`;

export const DataPaginationFixedItem = styled.div`
  width: 2em;
`;
