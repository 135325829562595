import styled, { css, keyframes } from 'styled-components';
import { BaSeTheme } from '../../theme';

interface HeaderSideMenuProps {
  sidebarBackgroundColor: string;
}

interface HeaderProps {
  backgroundColor?: string;
  textColor: string;
  mobile?: boolean;
  hasItems: boolean;
  maxWidth?: number;
}

interface HeaderCollapsedMenuItemContainerProps {
  hasClick: boolean;
  delay?: number;
  color?: string;
}

interface HeaderFlexProps {
  alignEnd?: boolean;
  alignStart?: boolean;
  fullWidth?: boolean;
  gap?: number;
  fullHeight?: boolean;
}

interface ColorProps {
  color?: string;
  open?: boolean;
  hasBoxShadow?: boolean;
}

interface HeaderCloseIconContentProps {
  buttonBackgroundColor: string;
}

interface HeaderExpandedMenuItemsContainerProps extends ColorProps {
  showBorder?: boolean;
}

const slideIn = keyframes`
  from {
    transform: translateX(-100vw);
  };
  to {
    transform: translateX(0);
  };
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  };
  to {
    transform: translateX(-100vw);
  }
`;

export const HeaderOutsideContainer = styled.div<ColorProps>`
  min-height: 60px;
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  ${(props) =>
    props.hasBoxShadow &&
    css`
      box-shadow: ${BaSeTheme.shadows.profundidade4};
    `}
`;

export const HeaderContainer = styled.div<HeaderProps>`
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  width: 100%;
  display: grid;
  ${(props) =>
    props.mobile && props.hasItems
      ? css`
          grid-template-columns: 1fr 1fr 1fr;
        `
      : css`
          grid-template-columns: 1fr auto;
        `}
  color: ${(props) => props.textColor};
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.mobile
      ? css`
          padding: 0 18px 0 10px;
        `
      : css`
          padding: 0 24px;
        `};
`;

export const HeaderExpandedMenuItemsContainer = styled.ul<HeaderExpandedMenuItemsContainerProps>`
  position: relative;
  display: flex;
  gap: 16px;
  font-size: 13px;
  height: 21px;
  align-items: center;
  padding-right: 25px;
  height: 100%;

  &:empty {
    padding-right: 0px;
    margin-right: 0px;
  }

  ${(props) =>
    props.showBorder &&
    css`
      margin-right: 8px;

      ::after {
        content: '';
        position: absolute;
        right: 0;
        height: 21px;
        top: calc(50% - 10.5px);
        width: 1px;
        background-color: ${props.color};
      }
    `}
`;

export const HeaderFlex = styled.div<HeaderFlexProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.alignEnd ? 'flex-end' : props.alignStart ? 'start' : 'center'};
  ${(props) =>
    props.alignEnd &&
    css`
      button {
        gap: 0;
      }
    `}
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  ${(props) => `gap: ${props.gap ?? 0}px`};
  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `}
`;

export const HeaderSideMenu = styled.aside.attrs({
  className: 'BaSe--header-side-menu',
})<HeaderSideMenuProps>`
  &.BaSe--header-side-menu {
    background-color: ${(props) => props.sidebarBackgroundColor};
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding: 16px;
    h5 {
      margin-bottom: 16px;
    }
  }
`;

export const HeaderCollapsedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${BaSeTheme.stackOrder.overlay + 2};
  width: 100vw;
  min-height: 100vh;
`;

export const HeaderCollapsedTransparentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${BaSeTheme.colors.institucionais.cinzaSebrae20};
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  z-index: ${BaSeTheme.stackOrder.overlay + 3};
`;

export const HeaderCollapsedContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 56px);
  height: 100vh;
  z-index: ${BaSeTheme.stackOrder.overlay + 4};
  ${css`
    animation: ${slideIn} ${BaSeTheme.transitions.durationNormal}
      ${BaSeTheme.transitions.continuousTimingFunction};
  `};

  &.closed {
    ${css`
      animation: ${slideOut} ${BaSeTheme.transitions.durationNormal}
        ${BaSeTheme.transitions.continuousTimingFunction};
    `};
  }
`;

export const HeaderCollapsedMenuItemNonNav = styled.div`
  z-index: 1;
`;

export const HeaderCollapsedMenuItemContainer = styled.li<HeaderCollapsedMenuItemContainerProps>`
  ${css`
    animation: ${slideIn} ${BaSeTheme.transitions.durationNormal}
      ${BaSeTheme.transitions.continuousTimingFunction};
  `};
  position: relative;
  animation-delay: ${(props) => props.delay}ms;
  animation-fill-mode: both;
  margin: 0.5em 0;

  ${({ hasClick, color }) =>
    hasClick &&
    css`
      cursor: pointer;

      &::after,
      &::before {
        content: '';
        transition: all ${BaSeTheme.transitions.durationNormal};
        position: absolute;
        background-color: ${color};
        height: 0;
      }

      &::after {
        top: 0;
        width: 1px;
        left: -16px;
      }

      &::before {
        bottom: 0;
        left: -16px;
        width: 4px;
      }

      &:focus {
        outline: none;

        &::after {
          height: 100%;
        }
      }

      &:hover::before {
        height: 100%;
      }
    `}
`;

export const HeaderMenuIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderCloseIconContent = styled.div<HeaderCloseIconContentProps>`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: ${BaSeTheme.stackOrder.normal + 1};

  button {
    font-family: ${BaSeTheme.typography.fontFamily};
    font-size: ${BaSeTheme.typography.fontSizeBase};
    line-height: ${BaSeTheme.typography.lineHeightBase};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${({ buttonBackgroundColor }) => buttonBackgroundColor};
  }
`;

export const HeaderExpandedMenuItemContainer = styled.li<{ color: string }>`
  ${({ onClick, color }) =>
    onClick &&
    css`
      cursor: pointer;
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;

      &::after,
      &::before {
        content: '';
        transition: all ${BaSeTheme.transitions.durationNormal};
        position: absolute;
        background-color: ${color};
        width: 0;
      }

      &::after {
        left: 0;
        bottom: 0px;
        height: 1px;
      }

      &::before {
        right: 0;
        bottom: 0;
        height: 4px;
      }

      &:focus {
        outline: none;

        &::after {
          width: 100%;
        }
      }

      &:hover::before {
        width: 100%;
      }
    `}
`;

export const HeaderGhostContainer = styled.div`
  width: 24px;
  height: 100vh;
  background-color: ${BaSeTheme.colors.defaultColors.transparent};
  position: fixed;
`;

export const HeaderExpandedComponentsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SubHeaderContainer = styled.div.attrs({
  className: 'BaSe--header-submenu',
})<ColorProps>`
  background-color: ${(props) =>
    props.color ?? BaSeTheme.colors.defaultColors.white};
  display: flex;
  justify-content: center;
  box-shadow: ${BaSeTheme.shadows.profundidade4};
  align-items: center;
  max-height: ${(props) => (props.open ? '100vh' : '0px')};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  overflow: hidden;
`;
