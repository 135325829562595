import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../utils/string-utils';
import { BaSeTheme } from '../../theme';
import { BaSeScrollbarCss } from '../../theme/scrollbar';
import { mapBaSeSelectColor } from './map-select-style';

export type SelectSizeType = 'small' | 'medium' | 'big';

export interface WrapperOptionsProps {
  hasError: boolean;
  isOpen: boolean;
  border: string;
  boxShadowFocus: string;
  colorType?: string;
  menuAlwaysIsOpen?: boolean;
}

interface WrapperMultipleInformationPropos {
  bcCHover: string;
  isOptionPickerItem?: boolean;
}

interface SelectInputProps {
  vSize: SelectSizeType;
  hasError: boolean;
  isOpen: boolean;
  hasSelectedOption: boolean;
  boxShadowFocus: string;
  border: string;
  bgC: string;
  colorType: string;
  hasIcon?: boolean;
  menuAlwaysIsOpen?: boolean;
}

interface WrapperOptionProps {
  isActive: boolean;
  multiple: boolean;
  bcCHover: string;
  bgC: string;
  withIcon?: boolean;
  hasItemIcon?: boolean;
  isOptionPickerItem?: boolean;
  displayInfo?: boolean;
  ignoreFocus?: boolean;
}

interface WrapperSelectProps {
  width: number | string | null;
  isDisabled: boolean;
}

interface WrapperIconProps {
  vSize: SelectSizeType;
  isOpen: boolean;
  searchable: boolean;
  hasLabel: boolean;
}

interface StyledLabelProps {
  fontSize: string;
  lineHeight: string;
}

const getPixelsFromSize = (size: SelectSizeType, hasLabel: boolean) => {
  if (hasLabel) {
    return size === 'small' ? 32 : size === 'medium' ? 38 : 43;
  } else {
    return size === 'small' ? 9 : size === 'medium' ? 15 : 16;
  }
};

export const WrapperSelect = styled.div.attrs({
  className: 'BaSe--select',
})<WrapperSelectProps>`
  position: relative;
  width: ${({ width = 'auto' }) => numberWithUnit(width)};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      * {
        cursor: not-allowed;
        user-select: none;
      }
    `}
`;

export const WrapperInputLabel = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  z-index: ${BaSeTheme.stackOrder.aboveNormal};
  background-color: transparent;
  position: relative;
  margin-bottom: 2px;
`;

export const HideTopOfWrapperOptions = styled.div`
  height: 8px;
  width: calc(100% - 2px);
  background: ${BaSeTheme.colors.defaultColors.white};
  border: none;
  box-sizing: border-box;
  position: relative;
  z-index: ${BaSeTheme.stackOrder.overlay + 1};
  margin: 0;
  padding: 0;
  margin-top: -8px;
  margin-left: 1px;
`;

export const WrapperOptions = styled.ul.attrs({
  className: 'BaSe--select-options',
})<WrapperOptionsProps>`
  display: flex;
  flex-direction: column;
  padding: 1px;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  z-index: ${BaSeTheme.stackOrder.overlay};
  background-color: ${BaSeTheme.colors.defaultColors.white};
  border: 1px solid
    ${({ hasError, border }) =>
      hasError ? mapBaSeSelectColor.error.borderColor : border};
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  margin-top: ${({ isOpen }) => (isOpen ? '-3px' : '0')};
  border-width: ${({ isOpen }) => (isOpen ? '1px' : '0')};
  box-shadow: ${({ isOpen, hasError, boxShadowFocus }) =>
    isOpen
      ? hasError
        ? BaSeTheme.shadows.focusError
        : `0 0 4px ${boxShadowFocus}`
      : 'none'};

  ${BaSeScrollbarCss}
`;

export const OptionDetail = styled.div.attrs({
  className: 'BaSe--select-option-detail',
})<{ isOptionPicker?: boolean }>`
  display: flex;
  flex-direction: column;
  opacity: 0;
  will-change: opacity, transform, height;
  position: absolute;
  left: 25%;
  width: calc(70% - 2em);
  padding: 0.5em;
  border-radius: 0.25em;
  transform: translateX(125%);
  cursor: default;
  background-color: ${BaSeTheme.colors.defaultColors.white};
  border: 1px solid ${BaSeTheme.colors.institucionais.azulSebrae97};
  box-shadow: ${BaSeTheme.shadows.profundidade4};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  height: 0;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    top: 1em;
    left: -0.5em;
    transform: rotate(270deg) translateX(50%);
    border-top-right-radius: 0.125em;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.25em 0.43em 0.25em;
    border-color: transparent transparent
      ${BaSeTheme.colors.defaultColors.white} transparent;
  }

  ${({ isOptionPicker }) =>
    isOptionPicker &&
    css`
      top: 3px;
      left: auto;
      right: 1px;
      max-width: calc(70% - 2em);
      width: auto;
    `}
`;

export const OptionDetailHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.25em;
`;

export const OptionDetailButton = styled.div`
  position: relative;
  right: -0.25em;
  top: -0.25em;
`;

export const DetailButtonWrapper = styled.span`
  z-index: 0;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
`;

export const WrapperOption = styled.li.attrs({
  className: 'BaSe--select-option',
})<WrapperOptionProps>`
  display: flex;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  will-change: background-color;
  padding: 5px;
  background-color: ${({ isActive, multiple, bcCHover, bgC }) =>
    isActive
      ? multiple
        ? bcCHover
        : bgC
      : BaSeTheme.colors.defaultColors.white};
  cursor: pointer;

  ${({ hasItemIcon, withIcon }) =>
    withIcon &&
    !hasItemIcon &&
    css`
      padding-left: 24px;
    `}

  & > * {
    will-change: font-weight;
  }

  ${({ isOptionPickerItem }) =>
    isOptionPickerItem &&
    css`
      padding-left: 12px;
      padding-right: 32px;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: nowrap;
      position: relative;
    `}

  ${({ ignoreFocus, isActive, multiple, bgC, bcCHover }) =>
    !ignoreFocus &&
    css`
      &:focus,
      &:hover {
        outline: none;
        background-color: ${isActive && !multiple ? bgC : bcCHover};
      }
    `}

  &:focus {
    & > * {
      font-weight: bold;
    }
  }

  &.detail {
    ${OptionDetail} {
      opacity: 1;
      transform: translateX(0);
      height: auto;
      z-index: 1;
      pointer-events: all;
    }

    ${DetailButtonWrapper} {
      .BaSe--icon:first-of-type {
        transform: translateX(100%);
      }
      .BaSe--icon:last-of-type {
        transform: translateX(0);
      }
    }
  }

  > img {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-right: 4px;
  }
`;

export const WrapperIcon = styled.div<WrapperIconProps>`
  position: absolute;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  will-change: transform;
  transform: ${({ isOpen }) => (!isOpen ? 'rotateX(0)' : 'rotateX(180deg)')};
  right: 9px;
  top: ${({ vSize, hasLabel }) => getPixelsFromSize(vSize, hasLabel)}px;
  width: 16px;
  height: 16px;
  & > * {
    transition: opacity ${BaSeTheme.transitions.durationNormal};
    will-change: transform, opacity;
    transform: scale(0);
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;

    &:last-child {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const SelectInput = styled.input.attrs({
  className: 'BaSe--select-input',
})<SelectInputProps>`
  font-family: ${BaSeTheme.typography.fontFamily};
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${({ hasIcon }) =>
    hasIcon
      ? css`
          width: calc(100% - 62px);
        `
      : css`
          width: calc(100% - 46px);
        `};
  height: ${({ vSize }) =>
    vSize === 'small' ? 30 : vSize === 'medium' ? 38 : 46}px;
  background-color: ${({ colorType }) => mapBaSeSelectColor[colorType].bgC};
  border: 1px solid
    ${({ isOpen, border, colorType, menuAlwaysIsOpen }) =>
      colorType !== 'default'
        ? mapBaSeSelectColor[colorType].borderColor
        : isOpen && !menuAlwaysIsOpen
          ? border
          : mapBaSeSelectColor[colorType].borderColor};
  border-radius: 4px;
  font-size: ${({ vSize }) => (vSize === 'small' ? 13 : 16)}px;
  padding: 0px 32px 0px 12px;

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: 28px;
    `}

  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  ${({ isOpen, hasError, boxShadowFocus, colorType, bgC, menuAlwaysIsOpen }) =>
    isOpen &&
    !menuAlwaysIsOpen &&
    css`
      box-shadow: ${hasError
        ? BaSeTheme.shadows.focusError
        : `0 0 4px ${boxShadowFocus}`};
      color: ${mapBaSeSelectColor[colorType].iconColor};

      &:focus {
        color: ${bgC};
      }
    `}

  ${({ colorType, hasSelectedOption }) => css`
    color: ${hasSelectedOption
      ? mapBaSeSelectColor[colorType].selectedColor
      : mapBaSeSelectColor[colorType].color};
  `}

  &:read-only {
    cursor: default;
  }

  &:disabled {
    cursor: not-allowed;
    user-select: none;
  }
`;

export const StyledApoio = styled.span`
  font-size: 0.83em;
  color: ${BaSeTheme.colors.feedbackInterface.erro35};
  margin-top: 3px;
  line-height: 1.2em;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: italic;
  font-weight: normal;
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
`;

export const ButtonInfoContainer = styled.div`
  margin-left: 5.33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSubLabel = styled.label`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: 1.46em;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae60};
  font-style: italic;
  font-weight: normal;
  margin-left: 4px;
`;

export const ButtonInfoContainerHelper = styled.div`
  margin-left: 5.33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MoreInfoContainer = styled.div`
  padding-top: 4px;
`;

export const WrapperHelper = styled.div`
  padding-top: 2px;
`;

export const WrapperMultipleInformation = styled.li<WrapperMultipleInformationPropos>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
  background-color: ${BaSeTheme.colors.defaultColors.white};
  position: sticky;
  top: 0;
  z-index: ${BaSeTheme.stackOrder.overlay};

  ${({ isOptionPickerItem }) =>
    isOptionPickerItem &&
    css`
      padding-left: 12px;
    `}

  &:focus,
  &:hover {
    outline: none;
    background-color: ${({ bcCHover }) => bcCHover};
  }

  &:focus {
    & > * {
      font-weight: bold;
    }
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OptionWithIconSelected = styled.div<
  Pick<WrapperIconProps, 'vSize' | 'hasLabel'>
>`
  position: absolute;
  left: 9px;
  width: 16px;
  height: 16px;
  top: ${({ vSize, hasLabel }) => getPixelsFromSize(vSize, hasLabel)}px;
`;

export const OptionWithIcon = styled.span`
  img.BaSe--icon {
    float: left;
    width: 1.46em;
    height: 1.46em;
  }
`;
