import * as React from 'react';
import { dualButtonMapStyle } from '../map-button-style';
import { BaSeTextButton } from '../text-button/text-button';
import {
  Container,
  Label,
  LeftButton,
  RightButton,
} from './dual-button-styled';
import { useNextHashId } from '../../../hooks/next-id';
import { idGenerator } from '../../../utils/id-generator';
import { getFormattedDataset } from '../../../utils/dataset-utils';
interface DualButtonOption {
  id?: string;
  name?: string;
  dataset?: DOMStringMap;
  value?: any;
  label?: string;
  width?: string | number;
}

type DualButtonSelected = 'right' | 'left';

interface DualButtonProps {
  type?: string;
  selected?: DualButtonSelected;
  left?: DualButtonOption;
  right?: DualButtonOption;
  hasError?: boolean;
  label?: string;
  onChange?: (selectedValue: any) => void;
  buttonType?: 'submit' | 'reset' | 'button';
}

const idSequence = idGenerator();

export const BaSeDualButton: React.FC<DualButtonProps> = ({
  buttonType = 'button',
  type = 'primary-default',
  hasError = false,
  label,
  right = {
    value: true,
    label: 'Valor da direita',
    width: 'auto',
  },
  left = {
    value: false,
    label: 'Valor da esquerda',
    width: 'auto',
  },
  selected: externalSelected,
  onChange = () => {},
}) => {
  const id = left?.id ?? useNextHashId(idSequence);

  const [selected, setSelected] = React.useState<DualButtonSelected>();

  const formattedLeftDataset = getFormattedDataset(left?.dataset);
  const formattedRightDataset = getFormattedDataset(right?.dataset);

  const leftSelected = React.useMemo(() => selected === 'left', [selected]);
  const rightSelected = React.useMemo(() => selected === 'right', [selected]);
  const { color, colorSelected, leftButtonProps, rightButtonProps } =
    React.useMemo(() => {
      const typeSplit = type.split('-');
      const typeMapped = hasError
        ? dualButtonMapStyle[typeSplit[0]]?.error ??
          dualButtonMapStyle.primary.error
        : dualButtonMapStyle[typeSplit[0]]?.[typeSplit[1]] ??
          dualButtonMapStyle.primary.default;

      const typeMappedSelected = hasError
        ? dualButtonMapStyle[typeSplit[0]]?.error ??
          dualButtonMapStyle.primary.error
        : dualButtonMapStyle.selected?.[typeSplit[1]] ??
          dualButtonMapStyle.selected.default;

      const { color: colorMapped, ...typeStyle } = typeMapped;
      const { color: colorMappedSelected, ...typeStyleSelected } =
        typeMappedSelected;

      return {
        color: colorMapped,
        colorSelected: colorMappedSelected,
        leftButtonProps: leftSelected ? typeStyleSelected : typeStyle,
        rightButtonProps: rightSelected ? typeStyleSelected : typeStyle,
      };
    }, [hasError, type, leftSelected, rightSelected]);

  React.useEffect(() => setSelected(externalSelected), [externalSelected]);

  return (
    <div className="BaSe--dual-button">
      {label && <Label htmlFor={id}>{label}</Label>}
      <Container>
        <LeftButton
          id={id}
          name={left?.name}
          {...formattedLeftDataset}
          {...leftButtonProps}
          type={buttonType}
          width={left.width}
          onClick={() => {
            onChange(left.value);
            setSelected('left');
          }}
        >
          <BaSeTextButton
            color={leftSelected ? colorSelected : color}
            fontSize={1}
            lineHeight={1.4}
            hasLeftIcon={false}
            hasRightIcon={false}
            fontWeigh="normal"
          >
            {left.label}
          </BaSeTextButton>
        </LeftButton>
        <RightButton
          id={right?.id}
          name={right?.name}
          {...formattedRightDataset}
          {...rightButtonProps}
          type={buttonType}
          width={right.width}
          onClick={() => {
            onChange(right.value);
            setSelected('right');
          }}
        >
          <BaSeTextButton
            color={rightSelected ? colorSelected : color}
            fontSize={1}
            lineHeight={1.4}
            hasLeftIcon={false}
            hasRightIcon={false}
            fontWeigh="normal"
          >
            {right.label}
          </BaSeTextButton>
        </RightButton>
      </Container>
    </div>
  );
};

BaSeDualButton.displayName = 'BaSeDualButton';
