import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';

const version = 'v1';

const endpointIf = `/${version}/pessoa-juridicas`;

export function usePorteService() {
  const { backendGet } = Sebrae.useBackend();

  const getRecuperaPorte = React.useCallback(
    () => backendGet(`${endpointIf}/portes`),
    [backendGet],
  );

  return {
    getRecuperaPorte,
  };
}
