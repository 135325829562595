import * as React from 'react';
import { BaSeConfig } from '../config/config';

const URL_CONFIG_BY_AUTH_SERVER_URL =
  BaSeConfig.staticAssetsUrl + '/configs/by-auth-server-url.json';

type ConfigByAuthServerUrlWithCache = ConfigByAuthServerUrl | WithCache;

let cache: ConfigByAuthServerUrlWithCache = { cached: false };

const configByAuthServerUrlKeys = [
  'avatarUrl',
  'cpeUrl',
  'jwtAttrAvatar',
  'jwtAttrEmail',
  'jwtAttrName',
  'jwtAttrGivenName',
  'jwtAttrFamilyName',
  'jwtAttrUsername',
  'jwtAttrId',
  'jwtAttrCpf',
] as const;

export type ConfigByAuthServerUrlKey =
  (typeof configByAuthServerUrlKeys)[number];

export interface ConfigByAuthServerUrl {
  [authServerUrl: string]: {
    [key in ConfigByAuthServerUrlKey]: string;
  };
}

export interface WithCache {
  cached?: boolean;
}

export type GetterConfigByAuthServerUrl = ({
  authServerUrl,
  key,
}: {
  authServerUrl?: string;
  key: ConfigByAuthServerUrlKey;
}) => string | undefined;

export interface ConfigByAuthServerUrlHook {
  getConfig: GetterConfigByAuthServerUrl;
  configIsInitialized: boolean;
}

export function useConfigByAuthServerUrl(): ConfigByAuthServerUrlHook {
  const [config, setConfig] =
    React.useState<ConfigByAuthServerUrlWithCache>(cache);

  const configIsInitialized = React.useMemo(
    () => !!config.cached,
    [config.cached],
  );

  React.useEffect(() => {
    if (!!cache.cached) {
      return setConfig(cache);
    }

    const controller = new AbortController();
    const signal = controller.signal;

    fetch(URL_CONFIG_BY_AUTH_SERVER_URL, { signal })
      .then((response) => response.json())
      .then((remoteConfig: ConfigByAuthServerUrl) => {
        cache = { ...remoteConfig, cached: true };
        setConfig(cache);
      })
      .catch((err) => console.error(err));
    return () => controller.abort();
  }, []);

  const getConfig: GetterConfigByAuthServerUrl = React.useCallback(
    ({ authServerUrl, key }) => {
      if (!authServerUrl) {
        return undefined;
      }
      const configAuthServerUrl = config?.[authServerUrl] as Partial<
        ConfigByAuthServerUrl[string]
      >;
      if (configAuthServerUrl === undefined) {
        return undefined;
      }

      const value = configAuthServerUrl?.[key] as unknown as string | undefined;
      return value;
    },
    [config],
  );

  return { getConfig, configIsInitialized };
}
