import * as React from 'react';
import { EnvKey } from '..';
import { HttpErrorModel } from '../models/http-error';
import { EnvValue, useEnv } from './env';
import {
  HookHttpConfigInterface,
  RequestParams,
  ResponseWithHeaders,
  useHttp,
} from './http';

function sanitizeBackendUrl(backendUrl: string): string {
  return backendUrl.trim().replace(/\/$/, '');
}
function sanitizeEndpoint(endpoint: string): string {
  return endpoint.trim().replace(/^\//, '');
}

function sanitize(endpoint: string, forceBackendUrl?: string): string {
  let url = sanitizeEndpoint(endpoint);
  if (forceBackendUrl) {
    url = `${sanitizeBackendUrl(forceBackendUrl)}/${url}`;
  }
  return url;
}

export interface HookBackendInterface {
  backendGet<Response>(
    endpoint: string,
    params?: RequestParams,
  ): Promise<Response>;
  backendGetWithHeaders<Data>(
    endpoint: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  backendGetText(endpoint: string, params?: RequestParams): Promise<string>;
  backendGetTextWithHeaders(
    endpoint: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<string>>;
  backendGetBlob(endpoint: string, params?: RequestParams): Promise<Blob>;
  backendGetBlobWithHeaders(
    endpoint: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Blob>>;
  backendDelete<Response>(
    endpoint: string,
    params?: RequestParams,
  ): Promise<Response>;
  backendDeleteWithHeaders<Data>(
    endpoint: string,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  backendPost<Response>(
    endpoint: string,
    body?: any,
    params?: RequestParams,
  ): Promise<Response>;
  backendPostWithHeaders<Data>(
    endpoint: string,
    body?: any,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  backendPostBlob(
    endpoint: string,
    body?: any,
    params?: RequestParams,
  ): Promise<Blob>;
  backendPostBlobWithHeaders(
    endpoint: string,
    body?: any,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Blob>>;
  backendPut<Response>(
    endpoint: string,
    body?: any,
    params?: RequestParams,
  ): Promise<Response>;
  backendPutWithHeaders<Data>(
    endpoint: string,
    body?: any,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
  backendPatch<Response>(
    endpoint: string,
    body?: any,
    params?: RequestParams,
  ): Promise<Response>;
  backendPatchWithHeaders<Data>(
    endpoint: string,
    body?: any,
    params?: RequestParams,
  ): Promise<ResponseWithHeaders<Data>>;
}

export interface HookBackendConfigInterface
  extends Partial<Omit<HookHttpConfigInterface, 'baseUrl'>> {
  backendUrlEnvKey?: EnvKey;
}

export function useBackend(
  config: HookBackendConfigInterface = {},
): HookBackendInterface {
  const {
    backendUrlEnvKey = 'backendUrl',
    injectToken = true,
    ...backendConfig
  } = config;
  const { getEnv } = useEnv();

  const [cachedBackendUrl, setCachedBackendUrl] = React.useState<EnvValue>();

  const httpConfig = React.useMemo(
    () => ({
      injectToken,
      baseUrl: cachedBackendUrl as string,
      ...backendConfig,
    }),
    [cachedBackendUrl, injectToken, backendConfig],
  );

  const {
    httpGet,
    httpGetWithHeaders,
    httpGetText,
    httpGetTextWithHeaders,
    httpGetBlob,
    httpGetBlobWithHeaders,
    httpDelete,
    httpDeleteWithHeaders,
    httpPost,
    httpPostWithHeaders,
    httpPostBlob,
    httpPostBlobWithHeaders,
    httpPut,
    httpPutWithHeaders,
    httpPatch,
    httpPatchWithHeaders,
  } = useHttp(httpConfig);

  React.useEffect(() => {
    getEnv(backendUrlEnvKey).then(setCachedBackendUrl);
  }, []);

  async function request<Response>({
    method,
    endpoint,
    params = new Map(),
    body = {},
    responseType = 'json',
    responseWithHeaders = false,
  }: {
    method: 'GET' | 'DELETE' | 'POST' | 'PUT' | 'PATCH';
    endpoint: string;
    params?: RequestParams;
    body?: any;
    responseType?: 'json' | 'text' | 'blob';
    responseWithHeaders?: boolean;
  }): Promise<Response> {
    const getters = {
      json: httpGet,
      text: httpGetText,
      blob: httpGetBlob,
    };

    const posters = {
      json: httpPost,
      blob: httpPostBlob,
    };

    const gettersWithHeaders = {
      json: httpGetWithHeaders,
      text: httpGetTextWithHeaders,
      blob: httpGetBlobWithHeaders,
    };

    const postersWithHeaders = {
      json: httpPostWithHeaders,
      blob: httpPostBlobWithHeaders,
    };

    const methods = {
      GET: (nonCachedBackendUrl?: string) =>
        responseWithHeaders
          ? gettersWithHeaders[responseType](
              sanitize(endpoint, nonCachedBackendUrl),
              params,
            )
          : getters[responseType](
              sanitize(endpoint, nonCachedBackendUrl),
              params,
            ),
      DELETE: (nonCachedBackendUrl?: string) =>
        responseWithHeaders
          ? httpDeleteWithHeaders<Response>(
              sanitize(endpoint, nonCachedBackendUrl),
              params,
            )
          : httpDelete<Response>(
              sanitize(endpoint, nonCachedBackendUrl),
              params,
            ),
      POST: (nonCachedBackendUrl?: string) =>
        responseWithHeaders
          ? postersWithHeaders[responseType]?.(
              sanitize(endpoint, nonCachedBackendUrl),
              body,
              params,
            )
          : posters[responseType]?.(
              sanitize(endpoint, nonCachedBackendUrl),
              body,
              params,
            ),
      PUT: (nonCachedBackendUrl?: string) =>
        responseWithHeaders
          ? httpPutWithHeaders<Response>(
              sanitize(endpoint, nonCachedBackendUrl),
              body,
              params,
            )
          : httpPut<Response>(
              sanitize(endpoint, nonCachedBackendUrl),
              body,
              params,
            ),
      PATCH: (nonCachedBackendUrl?: string) =>
        responseWithHeaders
          ? httpPatchWithHeaders<Response>(
              sanitize(endpoint, nonCachedBackendUrl),
              body,
              params,
            )
          : httpPatch<Response>(
              sanitize(endpoint, nonCachedBackendUrl),
              body,
              params,
            ),
    };

    const methodResponse = {
      GET: ['json', 'text', 'blob'],
      DELETE: ['json'],
      POST: ['json', 'blob'],
      PUT: ['json'],
      PATCH: ['json'],
    };

    if (
      !methodResponse[method]?.find((response) => response === responseType)
    ) {
      return Promise.reject(
        new HttpErrorModel('Requisição inválida', 0, 'core.request.invalid'),
      );
    }

    if (cachedBackendUrl) {
      return methods[method]() as Promise<Response>;
    }

    const backendUrl = await getEnv(backendUrlEnvKey);
    if (!backendUrl) {
      return Promise.reject(
        new HttpErrorModel(
          'URL do backend não informada',
          0,
          'core.backendUrl.notFound',
        ),
      );
    }
    return methods[method](backendUrl as string) as Promise<Response>;
  }

  const backendGet = React.useCallback(
    <Response>(endpoint: string, params: RequestParams = new Map()) =>
      request<Response>({ method: 'GET', endpoint, params }),
    [],
  );

  const backendGetWithHeaders = React.useCallback(
    <Data>(endpoint: string, params: RequestParams = new Map()) =>
      request<ResponseWithHeaders<Data>>({
        method: 'GET',
        endpoint,
        params,
        responseWithHeaders: true,
      }),
    [],
  );

  const backendGetText = React.useCallback(
    (endpoint: string, params: RequestParams = new Map()) =>
      request<string>({
        method: 'GET',
        endpoint,
        params,
        responseType: 'text',
      }),
    [],
  );

  const backendGetTextWithHeaders = React.useCallback(
    (endpoint: string, params: RequestParams = new Map()) =>
      request<ResponseWithHeaders<string>>({
        method: 'GET',
        endpoint,
        params,
        responseType: 'text',
        responseWithHeaders: true,
      }),
    [],
  );

  const backendGetBlob = React.useCallback(
    <Blob>(endpoint: string, params: RequestParams = new Map()) =>
      request<Blob>({
        method: 'GET',
        endpoint,
        params,
        responseType: 'blob',
      }),
    [],
  );

  const backendGetBlobWithHeaders = React.useCallback(
    <Blob>(endpoint: string, params: RequestParams = new Map()) =>
      request<ResponseWithHeaders<Blob>>({
        method: 'GET',
        endpoint,
        params,
        responseType: 'blob',
        responseWithHeaders: true,
      }),
    [],
  );

  const backendDelete = React.useCallback(
    <Response>(endpoint: string, params: RequestParams = new Map()) =>
      request<Response>({ method: 'DELETE', endpoint, params }),
    [],
  );

  const backendDeleteWithHeaders = React.useCallback(
    <Data>(endpoint: string, params: RequestParams = new Map()) =>
      request<ResponseWithHeaders<Data>>({
        method: 'DELETE',
        endpoint,
        params,
        responseWithHeaders: true,
      }),
    [],
  );

  const backendPost = React.useCallback(
    <Response>(
      endpoint: string,
      body: any = {},
      params: RequestParams = new Map(),
    ) => request<Response>({ method: 'POST', endpoint, params, body }),
    [],
  );

  const backendPostWithHeaders = React.useCallback(
    <Data>(
      endpoint: string,
      body: any = {},
      params: RequestParams = new Map(),
    ) =>
      request<ResponseWithHeaders<Data>>({
        method: 'POST',
        endpoint,
        params,
        body,
        responseWithHeaders: true,
      }),
    [],
  );

  const backendPostBlob = React.useCallback(
    <Blob>(
      endpoint: string,
      body: any = {},
      params: RequestParams = new Map(),
    ) =>
      request<Blob>({
        method: 'POST',
        endpoint,
        params,
        body,
        responseType: 'blob',
      }),
    [],
  );

  const backendPostBlobWithHeaders = React.useCallback(
    <Blob>(
      endpoint: string,
      body: any = {},
      params: RequestParams = new Map(),
    ) =>
      request<ResponseWithHeaders<Blob>>({
        method: 'POST',
        endpoint,
        params,
        body,
        responseType: 'blob',
        responseWithHeaders: true,
      }),
    [],
  );

  const backendPut = React.useCallback(
    <Response>(
      endpoint: string,
      body: any = {},
      params: RequestParams = new Map(),
    ) => request<Response>({ method: 'PUT', endpoint, params, body }),
    [],
  );

  const backendPutWithHeaders = React.useCallback(
    <Data>(
      endpoint: string,
      body: any = {},
      params: RequestParams = new Map(),
    ) =>
      request<ResponseWithHeaders<Data>>({
        method: 'PUT',
        endpoint,
        params,
        body,
        responseWithHeaders: true,
      }),
    [],
  );

  const backendPatch = React.useCallback(
    <Response>(
      endpoint: string,
      body: any = {},
      params: RequestParams = new Map(),
    ) => request<Response>({ method: 'PATCH', endpoint, params, body }),
    [],
  );

  const backendPatchWithHeaders = React.useCallback(
    <Data>(
      endpoint: string,
      body: any = {},
      params: RequestParams = new Map(),
    ) =>
      request<ResponseWithHeaders<Data>>({
        method: 'PATCH',
        endpoint,
        params,
        body,
        responseWithHeaders: true,
      }),
    [],
  );

  return {
    backendGet,
    backendGetWithHeaders,
    backendGetText,
    backendGetTextWithHeaders,
    backendGetBlob,
    backendGetBlobWithHeaders,
    backendDelete,
    backendDeleteWithHeaders,
    backendPost,
    backendPostWithHeaders,
    backendPostBlob,
    backendPostBlobWithHeaders,
    backendPut,
    backendPutWithHeaders,
    backendPatch,
    backendPatchWithHeaders,
  };
}
