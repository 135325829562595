import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';

const version = 'v1';

const endpointIf = `/${version}/instituicoes-financeiras`;

export function useInstituicoesFinanceirasService() {
  const { backendGet } = Sebrae.useBackend();

  const getRecuperarIfs = React.useCallback(
    () => backendGet(`${endpointIf}/ativas`),
    [backendGet],
  );

  const getRecuperarPre = React.useCallback(
    () => backendGet(`${endpointIf}/pre-credito`),
    [backendGet],
  );

  const getRecuperarPos = React.useCallback(
    () => backendGet(`${endpointIf}/pos-credito`),
    [backendGet],
  );

  const loadIFs = React.useCallback(
    (setIfs) => {
      getRecuperarPre().then((ifs) =>
        setIfs(
          ifs.map((item) => ({
            id: item.id,
            label: item.nome,
          })),
        ),
      );
    },
    [getRecuperarPre],
  );

  return {
    getRecuperarIfs,
    getRecuperarPre,
    getRecuperarPos,
    loadIFs,
  };
}
