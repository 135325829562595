import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';

interface StyledLabelProps {
  fontSize: string;
  lineHeight: string;
  color?: string;
}

export const WrapperInputlabel = styled.div.attrs({
  className: 'BaSe--input-wrapper-label',
})<{ isEmpty?: boolean }>`
  display: flex;
  flex-direction: row;
  z-index: ${BaSeTheme.stackOrder.normal};
  background-color: ${BaSeTheme.colors.defaultColors.transparent};
  position: relative;
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      margin-bottom: 0px;
    `}
`;

export const StyledLabel = styled.label.attrs({
  className: 'BaSe--input-label',
})<StyledLabelProps>`
  ${({ fontSize, lineHeight, color }) => css`
    font-size: ${fontSize};
    line-height: ${lineHeight};
    color: ${color};
    font-family: ${BaSeTheme.typography.fontFamily};
    font-style: normal;
    font-weight: normal;
  `}
`;

export const StyledSubLabel = styled.label.attrs({
  className: 'BaSe--input-sub-label',
})<StyledLabelProps>`
  ${({ fontSize, lineHeight, color }) => css`
    font-size: ${fontSize};
    line-height: ${lineHeight};
    font-family: ${BaSeTheme.typography.fontFamily};
    font-weight: normal;
    color: ${color};
    font-style: italic;
    margin-left: 4px;
  `}
`;

export const ButtonInfoContainer = styled.div`
  margin-left: 5.33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
