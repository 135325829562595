import { BaSeDataTable, BaSeTheme } from '@base/react';
import styled from 'styled-components';

export const PageContent = styled.div`
  padding: 10px 30px 20px 20px;
  margin-bottom: 40px;
`;

export const Form = styled.div`
  padding: 30px 0;
`;

export const ResultSearch = styled.div`
  padding: 18px 20px;
`;

export const InforContainer = styled.div`
  font-weight: 400;
  display: flex;
  gap: 5px;
  font-family: 'Lato';
  margin: 3px 0;
`;

export const FontBold = styled.div`
  font-weight: 800;
`;

export const ResultCard = styled.div`
  background-color: ${BaSeTheme.colors.institucionais.cinzaSebrae97};
  margin-top: 30px;
  padding: 10px 0px;
  border-radius: 10px;
`;

export const ListMultiselect = styled.div`
  margin-top: 10;
`;

export const ContentShapeButtom = styled.div`
  display: flex;
`;

export const FieldInput = styled.div`
  padding: 30px 0;
`;

export const PageContainerSolution = styled.div`
  padding: 30px 20px 20px 20px;
`;

export const BaSeGridFlex = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
`;

export const BaSeGridBlock = styled.div`
  display: block;
  width: 100%;
`;

export const BaSeGrid = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonCancel = styled.button`
  background-color: transparent;
  border: none;
  color: ${BaSeTheme.colors.institucionais.azulSebrae36};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 0;
  margin-left: 15px;
  &:hover {
    color: ${BaSeTheme.colors.institucionais.azulSebrae10};
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
`;

export const CustomDataTable = styled(BaSeDataTable)`
  padding: 0 !important;
  display: none !important;

  .ciNnnP {
    background-color: #ffffff !important;
  }
`;
