import * as React from 'react';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { useNextHashId } from '../../../hooks/next-id';
import { BaSeTheme } from '../../../theme';
import { getFormattedDataset } from '../../../utils/dataset-utils';
import { idGenerator } from '../../../utils/id-generator';
import { BaSeIcon } from '../../image/icon';
import {
  CheckboxContainer,
  CheckboxShapeProps,
  HiddenCheckbox,
  StyledCheckbox,
  StyledLabel,
  WrapperCheckbox,
  checkboxIconSize,
} from './checkbox-styled';
import {
  mapCheckBoxValues,
  mapCustomColorsCheckboxValues,
} from './map-checkbox-style';

export interface BaSeCheckboxProps {
  id?: string;
  name?: string;
  dataset?: DOMStringMap;
  align?:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'center-left'
    | 'center-right';
  value?: string | number;
  isInteractive?: boolean;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  size?: 'medium' | 'small';
  shape?: CheckboxShapeProps;
  color?: string;
  onChange?: (
    checked?: boolean,
    value?: string | number,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => void;
}

const idSequence = idGenerator();

export const BaSeCheckbox: React.FC<BaSeCheckboxProps> = ({
  id,
  name,
  dataset,
  label = '',
  value = '',
  disabled = false,
  isInteractive = true,
  checked = false,
  indeterminate = false,
  align = 'top-left',
  size = 'medium',
  color = 'default',
  shape = 'square',
  onChange = () => {},
}) => {
  const idCheckbox = id ?? useNextHashId(idSequence);

  const [focus, setFocus] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const formattedDataset = getFormattedDataset(dataset);

  const onCheckOrUncheck = (
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (!disabled) {
      onChange(!checked, value, event);
    }
  };

  value = value ?? label;

  const re = new RegExp('(.*)-(.*)');
  const verticalAlign = () => {
    const verticalValue = (align.match(re) || [])[1];
    if (verticalValue === 'top') {
      return 'start';
    }
    if (verticalValue === 'bottom') {
      return 'flex-end';
    }
    if (verticalValue === 'center') {
      return 'center';
    }
    throw Error(
      `BaSeCheckbox: Valor de "align" referente ao valor vertical "${verticalValue}" é invalido! Informe "top", "bottom" ou "center"`,
    );
  };
  const horizontalAlign = () => {
    const horizontalValue = (align.match(re) || [])[2];
    if (horizontalValue === 'right') {
      return 'row-reverse';
    }
    if (horizontalValue === 'left') {
      return 'row';
    }
    throw Error(
      `BaSeCheckbox: Valor de "align" referente ao valor horizontal "${horizontalValue}" é invalido! Informe "right" ou "left"`,
    );
  };

  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.destructive ||
    color === BaSeTheme.components.button.colors.confirmation ||
    color === BaSeTheme.components.button.colors.negative;
  const getColorsAtributes = () => {
    if (color) {
      if (!colorIsMapped) {
        return mapCustomColorsCheckboxValues(color).primary;
      } else {
        if (color === BaSeTheme.components.button.colors.negative) {
          return mapCheckBoxValues('default').primary?.negative;
        }
        return mapCheckBoxValues(color).primary?.generalColors;
      }
    } else {
      return mapCheckBoxValues('default').primary.generalColors;
    }
  };
  const buttonValues = disabled
    ? color === 'negative'
      ? mapCheckBoxValues('disabled').primary?.negative
      : mapCheckBoxValues('disabled').primary.generalColors
    : getColorsAtributes();

  const {
    style: { bgC, bcCHover, boxShadowFocus, border },
    iconColor,
    // color: buttonColor,
  } = buttonValues;
  const { getMessage } = React.useContext(BaSeI18nContext);

  return (
    <WrapperCheckbox horizontalAlign={horizontalAlign()}>
      <CheckboxContainer verticalAlign={verticalAlign()} disabled={disabled}>
        <HiddenCheckbox
          defaultValue={value}
          tabIndex={isInteractive ? 0 : -1}
          id={idCheckbox}
          name={name}
          {...formattedDataset}
          checked={checked}
          disabled={disabled}
          onChange={onCheckOrUncheck}
          onPointerEnter={() => setHover(isInteractive && true)}
          onFocus={() => setFocus(isInteractive && true)}
          onPointerLeave={() => setHover(false)}
          onBlur={() => setFocus(false)}
          onKeyPress={(event) =>
            event.key === 'Enter' && isInteractive && onCheckOrUncheck(event)
          }
        />
        <StyledCheckbox
          shape={shape}
          hover={hover}
          focus={focus}
          disabled={disabled}
          checked={checked}
          backgroundColor={bgC}
          backgroundOnHover={bcCHover}
          isHoverable={isInteractive}
          border={border}
          boxShadowFocus={boxShadowFocus}
        >
          {checked && (
            <BaSeIcon
              description={getMessage('checkbox.iconDescription')}
              name={indeterminate ? 'minus' : 'check'}
              color={iconColor}
              size={checkboxIconSize}
            />
          )}
        </StyledCheckbox>
      </CheckboxContainer>

      <StyledLabel htmlFor={idCheckbox} size={size} disabled={disabled}>
        {label}
      </StyledLabel>
    </WrapperCheckbox>
  );
};

BaSeCheckbox.displayName = 'BaSeCheckbox';
