import * as React from 'react';
import { HttpErrorModel } from '../models/http-error';
import { objectToMap } from '../utils/object/object-to-map';
import { HookBackendConfigInterface, useBackend } from './backend';
import { RequestParams } from './http';

export interface PaginationConfig {
  endpoint: string;
  params?: RequestParams;
  configBackend?: HookBackendConfigInterface;
}

export interface PaginationPage {
  index?: number;
  size?: number;
}

export interface PaginationSort {
  field?: string;
  type?: 'ASC' | 'DESC';
}

export interface PaginationRequest<Filter> {
  filter?: Filter;
  page?: PaginationPage;
  sort?: PaginationSort;
}

export interface PaginationResponse<Data> {
  total: number;
  items: Data[];
}

export interface HookPaginationInterface<Data, Filter> {
  data: PaginationResponse<Data>;
  loading: boolean;
  error?: HttpErrorModel;
  fetchData(request?: PaginationRequest<Filter>): Promise<never>;
}

export function usePagination<Data, Filter>(
  endpointOrConfig: string | PaginationConfig,
): HookPaginationInterface<Data, Filter> {
  const [endpoint, params, configBackend] = React.useMemo(() => {
    let _endpoint = '';
    let _params: RequestParams = new Map();
    let _configBackend: HookBackendConfigInterface = {};

    if (typeof endpointOrConfig === 'string') {
      _endpoint = endpointOrConfig;
    } else {
      _endpoint = endpointOrConfig.endpoint;
      if (endpointOrConfig.params) {
        _params = endpointOrConfig.params;
      }
      if (endpointOrConfig.configBackend) {
        _configBackend = endpointOrConfig.configBackend;
      }
    }
    return [_endpoint, _params, _configBackend];
  }, [endpointOrConfig]);

  const emptyData = React.useMemo(
    () => ({
      total: 0,
      items: [] as Data[],
    }),
    [],
  );

  const [data, setData] = React.useState<PaginationResponse<Data>>(emptyData);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<HttpErrorModel>();

  const { backendGet } = useBackend(configBackend);

  const fetchData = React.useCallback(
    ({ filter, page = {}, sort = {} }: PaginationRequest<Filter> = {}) => {
      const paramsToRequest =
        params instanceof Map ? params : objectToMap(params);

      paramsToRequest.set('pageIndex', page?.index ?? 0);
      paramsToRequest.set('pageSize', page?.size ?? 10);
      paramsToRequest.set('sortType', sort?.type ?? 'ASC');
      paramsToRequest.set('sortField', sort?.field as string);

      if (typeof filter === 'object' && filter !== null) {
        Object.entries(filter).forEach(([key, value]) =>
          paramsToRequest.set(key, value),
        );
      } else if (filter !== undefined) {
        paramsToRequest.set('filter', filter as unknown as string);
      }

      setLoading(true);
      setData(emptyData);
      setError(undefined);

      return new Promise<never>((resolve, reject) =>
        backendGet<PaginationResponse<Data>>(endpoint, paramsToRequest)
          .then((result) => {
            setData({
              total: result?.total ?? 0,
              items: result?.items ?? [],
            });
            resolve(undefined as never);
          })
          .catch((failure) => {
            setError(failure);
            reject();
          })
          .finally(() => setLoading(false)),
      );
    },
    [endpoint, params, backendGet],
  );

  return {
    data,
    loading,
    error,
    fetchData,
  };
}
