import { format } from 'date-fns';
import { dateHandle } from './date';

export type MaskMappedType =
  | 'cep'
  | 'cnpj'
  | 'date'
  | 'decimal'
  | 'oldPhone'
  | 'phone'
  | 'phoneDDD'
  | 'oldPhoneDDD'
  | 'cpf';

export type MaskedTypeValue = string | number | undefined;

export type MaskedDateTypeValue = Date | MaskedTypeValue;

const clearNonDigit = (val: string) => val.replace(/\D/g, '');
const createNewString = (val: MaskedTypeValue) =>
  clearNonDigit(typeof val === 'string' ? val : val + '') + '';
const trimString = (val: string, size: number) =>
  val.length > size ? val.substring(0, size) : val;
const removeLeftZeros = (val: string) => {
  let index = 0;
  for (let i = 0; i < val.length - 1; i++) {
    if (val[i] !== '0') {
      break;
    }
    ++index;
  }
  return val.substring(index, val.length);
};

const addSeparatorToNumber = (numb: string) => {
  let fin = '';
  let count = 0;
  for (let i = numb.length - 1; i >= 0; i--) {
    if (count === 3) {
      fin = '.' + fin;
      count = 0;
    }

    fin = numb[i] + fin;
    count++;
  }
  return fin;
};

export const maskHandler = {
  cep: {
    mask: (cep?: MaskedTypeValue) => {
      let value = createNewString(cep);
      value = value.replace(/(^\d{2})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1-$2');
      value = trimString(value, 10);
      return value;
    },
  },
  cnpj: {
    mask: (cnpj?: MaskedTypeValue) => {
      let value = createNewString(cnpj);
      value = value.replace(/(^\d{2})(\d)/, '$1.$2');
      value = value.replace(/(\d{2}\.\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3}\.\d{3})(\d)/, '$1/$2');
      value = value.replace(/(\d{4})(\d)/, '$1-$2');
      value = trimString(value, 18);
      return value;
    },
  },
  cpf: {
    mask: (cpf?: MaskedTypeValue) => {
      let value = createNewString(cpf);
      value = value.replace(/(^\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3}\.\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3}\.\d{3}\.\d{3})(\d)/, '$1-$2');
      value = trimString(value, 14);
      return value;
    },
  },
  phone: {
    mask: (phone?: MaskedTypeValue) => {
      let value = createNewString(phone);
      value = value.replace(/(^\d{4})(\d)/, '$1-$2');
      value = trimString(value, 10);
      return value;
    },
  },
  phoneDDD: {
    mask: (phone?: MaskedTypeValue) => {
      let value = createNewString(phone);
      value = value.replace(/^(\d{1})/, '($1');
      value = value.replace(/(\(\d{2})/, '$1) ');
      value = value.replace(/(\s\d{4})(\d)/, '$1-$2');
      value = trimString(value, 15);
      return value;
    },
  },
  oldPhone: {
    mask: (phone?: MaskedTypeValue) => {
      let value = createNewString(phone);
      value = value.replace(/(^\d{4})(\d)/, '$1-$2');
      value = trimString(value, 9);
      return value;
    },
  },
  oldPhoneDDD: {
    mask: (phone?: MaskedTypeValue) => {
      let value = createNewString(phone);
      value = value.replace(/^(\d{1})/, '($1');
      value = value.replace(/(\(\d{2})/, '$1) ');
      value = value.replace(/(\s\d{4})(\d)/, '$1-$2');
      value = trimString(value, 14);
      return value;
    },
  },
  date: {
    mask: (date?: MaskedDateTypeValue) => {
      if (typeof date === 'string' && date.length < 10) {
        let value = createNewString(date);
        value = value.replace(/(^\d{2})(\d)/, '$1/$2');
        value = value.replace(/(\d{2}\/\d{2})(\d)/, '$1/$2');
        value = trimString(value, 10);
        return value;
      }

      return dateHandle({
        value: date,
        failure: () => '',
        success: (validatedDate) => format(validatedDate, 'dd/MM/yyyy'),
      });
    },
  },
  decimal: {
    mask: (money?: MaskedTypeValue) => {
      let minus = '';
      if (typeof money === 'string') {
        minus = money[0] === '-' ? '-' : '';
      }
      let value = createNewString(money);
      if (value) {
        if (!value) {
          return '';
        }
        value = removeLeftZeros(value);
        if (!value) {
          return '';
        }
        if (value.length < 5) {
          try {
            value = parseFloat(parseInt(value, 10) / 100 + '').toFixed(2) + '';
            const [integer, float] = value.split('.');
            const newInteger = addSeparatorToNumber(integer);
            value = newInteger + ',' + float;
          } catch {
            value = '';
          }
        } else {
          const splitter = value.length - 2;
          let integer = value.slice(0, splitter);
          const float = value.slice(splitter, splitter + 2);
          integer = addSeparatorToNumber(integer);
          value = integer + ',' + float;
        }
        return minus ? minus + value : value;
      } else {
        return '';
      }
    },
  },
};

/**
 * Classe para agrupar funções de máscara, uma alternativa ao hook useMask
 */

export class MaskUtils {
  static applyMask(maskType: MaskMappedType, value?: string): string {
    return maskHandler[maskType].mask(value);
  }

  static applyCepMask(value?: string): string {
    return maskHandler.cep.mask(value);
  }
  static applyCnpjMask(value?: string): string {
    return maskHandler.cnpj.mask(value);
  }
  static applyCpfMask(value?: string): string {
    return maskHandler.cpf.mask(value);
  }
  static applyPhoneMask(value?: string): string {
    return maskHandler.phone.mask(value);
  }
  static applyPhoneDDDMask(value?: string): string {
    return maskHandler.phoneDDD.mask(value);
  }
  static applyOldPhoneMask(value?: string): string {
    return maskHandler.oldPhone.mask(value);
  }
  static applyOldPhoneDDDMask(value?: string): string {
    return maskHandler.oldPhoneDDD.mask(value);
  }
  static applyDateMask(value?: string | Date): string {
    return maskHandler.date.mask(value);
  }
  static applyDecimalMask(value?: string): string {
    return maskHandler.decimal.mask(value);
  }
}
