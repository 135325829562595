import * as React from 'react';
import {
  GripItemWrapperProps,
  GridItemWrapper,
  GridItemSize,
  GridItemStartAt,
} from './grid-item-styled';

export interface GridItemProps extends Omit<GripItemWrapperProps, 'all'> {
  size?: GridItemSize;
  startAt?: GridItemStartAt;
}

export const BaSeGridItem: React.FC<GridItemProps> = ({
  size = 12,
  startAt,

  mobileSmall,
  mobile,
  mobileLarge,
  tablet,
  desktop,
  desktopLarge,

  children,
}) => (
  <GridItemWrapper
    className="BaSe--grid-item"
    all={{ size, startAt }}
    mobileSmall={mobileSmall}
    mobile={mobile}
    mobileLarge={mobileLarge}
    tablet={tablet}
    desktop={desktop}
    desktopLarge={desktopLarge}
  >
    {children}
  </GridItemWrapper>
);

BaSeGridItem.displayName = 'BaSeGridItem';
