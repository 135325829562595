import * as React from 'react';
import { version as BaSeVersion } from '../../package.json';
import { VLibras } from 'vlibras-plugin';

const VLIBRAS_URL = 'https://vlibras.gov.br/app';
const TYPE = 'libras' as const;
const OPEN_TIMEOUT_DELAY = 1000;

let timeoutToOpen: ReturnType<typeof setTimeout>;

function isVLibras(obj: unknown): obj is VLibras {
  return !!obj && typeof obj === 'object' && 'Widget' in obj;
}

function inject(): void {
  const div_vw = document.createElement('div');
  const div_vw_access_button = document.createElement('div');
  const div_vw_plugin_wrapper = document.createElement('div');
  const div_vw_plugin_top_wrapper = document.createElement('div');
  const script = document.createElement('script');

  div_vw.dataset.designSystem = `BaSe@${BaSeVersion}`;
  div_vw.dataset.type = TYPE;
  div_vw.setAttribute('vw', '');
  div_vw.classList.add('enabled');

  div_vw_access_button.setAttribute('vw-access-button', '');
  div_vw_access_button.classList.add('active');

  div_vw_plugin_wrapper.setAttribute('vw-plugin-wrapper', '');

  div_vw_plugin_top_wrapper.classList.add('vw-plugin-top-wrapper');

  script.src = `${VLIBRAS_URL}/vlibras-plugin.js`;
  script.onload = () => {
    const VLibras = window['VLibras'] as VLibras;
    if (isVLibras(VLibras)) {
      new VLibras.Widget({ rootPath: VLIBRAS_URL });
    }

    const windowOnLoad = window?.['onload'] as () => void | undefined;
    windowOnLoad?.();
    clearTimeout(timeoutToOpen);
    timeoutToOpen = setTimeout(
      () => div_vw_access_button.click(),
      OPEN_TIMEOUT_DELAY,
    );
  };

  div_vw_plugin_wrapper.append(div_vw_plugin_top_wrapper);
  div_vw.append(div_vw_access_button, div_vw_plugin_wrapper);
  document.body.append(div_vw, script);
}

function getElement(): HTMLDivElement | null {
  return document.querySelector<HTMLDivElement>(
    `div[data-type="${TYPE}"][data-design-system="BaSe@${BaSeVersion}"]`,
  );
}

function notInjected(): boolean {
  return !getElement();
}

function elementEnabled(): boolean {
  return getElement()?.classList.contains('enabled') ?? false;
}

function showElement(): void {
  clearTimeout(timeoutToOpen);
  const wrapper = getElement();
  if (wrapper) {
    wrapper.classList.toggle('enabled');
    const accessButton =
      wrapper.querySelector<HTMLButtonElement>('[vw-access-button]');
    timeoutToOpen = setTimeout(() => {
      accessButton?.click();
    }, OPEN_TIMEOUT_DELAY);
  }
}

function hideElement(): void {
  clearTimeout(timeoutToOpen);
  const wrapper = getElement();
  if (wrapper) {
    const accessButton =
      wrapper.querySelector<HTMLButtonElement>('[vw-access-button]');
    const accessButtonActive = accessButton?.classList.contains('active');
    if (!accessButtonActive) {
      const closeButton = wrapper.querySelector<HTMLImageElement>(
        '.vpw-settings-btn-close',
      );
      closeButton?.click();
    }
    wrapper.classList.toggle('enabled');
  }
}

function addVLibras(): void {
  if (notInjected()) {
    return inject();
  }
  showElement();
}

function removeVLibras(): void {
  hideElement();
}

export interface VLibrasHook {
  toggleVLibras(): void;
}

export function useVLibras(): VLibrasHook {
  const toggleVLibras = React.useCallback(
    () => (elementEnabled() ? removeVLibras() : addVLibras()),
    [],
  );
  return {
    toggleVLibras,
  };
}
