import * as React from 'react';
import { BaSeI18nContext } from '../../contexts/i18n';
import { BaSeTheme } from '../../theme';
import { ButtonProps } from '../button/button-props';
import { BaSeButton } from '../button/button/button';
import { BaSeIcon } from '../image/icon';
import { ImageProps } from '../image/image-props';
import { IconButtonInputProps } from '../input/text/input';
import { BaSeFormLabel } from '../labels/form-label/form-label';
import { BaSeTag } from '../labels/tag/tag';
import { BaSeParagraph } from '../typography/paragraph/paragraph';
import { TypographyProps } from '../typography/typography-props';
import {
  FilePickerWrapper,
  HiddenInputFile,
  OutsideWrapper,
  TagWrapper,
} from './file-picker-styled';
import { BaSeFileInputField, FileInputFieldProps } from './input-field';
import { fileId, fileName, fileSize, fileType, mergeFiles } from './utils';

export type Files = File[];
export type Url = string;
export type Urls = Url[];
export type RenderStyle = 'input-field' | 'box';

export interface FilePickerProps
  extends Pick<
    FileInputFieldProps,
    | 'label'
    | 'subLabel'
    | 'showHelpButton'
    | 'helpButtonProps'
    | 'pickMultipleFiles'
    | 'pickInputProps'
  > {
  renderStyle?: RenderStyle;
  acceptsFilesPattern?: string;
  dragInfoText?: string;
  dragInfoProps?: TypographyProps;
  placeholderImageProps?: Partial<
    Omit<ImageProps, 'width' | 'height' | 'onClick'>
  >;
  pickButtonProps?: Partial<
    | ButtonProps
    | Pick<
        IconButtonInputProps,
        'action' | 'color' | 'name' | 'type' | 'typeButton' | 'value'
      >
  >;
  onChange(files: Files, urls: Urls): void;
  onError?(error: string): void;
  validateFiles?(files: Files): Files | Promise<Files>;
}

const ANY_FILE = '*' as const;

export const BaSeFilePicker: React.FC<FilePickerProps> = ({
  pickMultipleFiles = true,
  renderStyle = 'box',
  acceptsFilesPattern: acceptsFilePatternExternal = ANY_FILE,
  dragInfoText,
  dragInfoProps,
  label,
  subLabel,
  showHelpButton,
  helpButtonProps,
  pickButtonProps,
  pickInputProps,
  placeholderImageProps,
  onError,
  onChange,
  validateFiles = (list) => list,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [files, setFiles] = React.useState<Files>([]);
  const [isDrag, setIsDrag] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const acceptsFilesPattern = React.useMemo(
    () => (acceptsFilePatternExternal + '').toLowerCase(),
    [acceptsFilePatternExternal],
  );

  const hasFiles = React.useMemo(() => (files?.length ?? 0) > 0, [files]);

  const { getMessage } = React.useContext(BaSeI18nContext);

  const handleError = React.useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      // eslint-disable-next-line no-console
      console.error(event);
      onError?.('Xii! :(');
    },
    [onError],
  );

  const handleChange = React.useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.DragEvent<HTMLDivElement>,
    ) => {
      const fileList =
        (event as React.ChangeEvent<HTMLInputElement>)?.target?.files ??
        (event as React.DragEvent<HTMLDivElement>)?.dataTransfer?.files;

      setTimeout(() => {
        if (inputRef?.current) {
          inputRef.current.value = '';
        }
      }, 150);

      if (!fileList?.length) {
        return setFiles([]);
      }

      setIsLoading(true);

      const filteredList = Array.from(fileList).filter((file) => {
        if (acceptsFilesPattern === ANY_FILE) {
          return true;
        }

        const patternsList = acceptsFilesPattern.split(',');
        const extFile = '.' + fileName(file).split('.').pop();
        const acceptsPatternList = patternsList.map((pattern) => {
          const filePattern = pattern.slice(0, pattern.indexOf('/')).trim();
          const fileTypePattern = fileType(file).slice(
            0,
            fileType(file).indexOf('/'),
          );
          return pattern.includes('/*') && filePattern === fileTypePattern
            ? fileType(file)
            : pattern.trim();
        });

        return (
          acceptsPatternList.includes(fileType(file)) ||
          acceptsPatternList.includes(extFile) ||
          fileId(file).includes(acceptsFilesPattern)
        );
      });

      const validatedList = validateFiles(filteredList);

      if (Array.isArray(validatedList)) {
        return setFiles((list) => mergeFiles(list, validatedList));
      }

      validatedList.then((resolvedList) =>
        setFiles((list) => mergeFiles(list, resolvedList)),
      );
    },
    [inputRef.current, acceptsFilesPattern, validateFiles, onError],
  );

  const handleDrag = React.useCallback(
    (bool: boolean) => (event: React.DragEvent<HTMLDivElement>) => {
      event?.preventDefault();
      setIsDrag(bool);
    },
    [],
  );

  const handleDrop = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event?.preventDefault();
      event?.stopPropagation();
      handleChange(event);
      setIsDrag(false);
    },
    [handleChange],
  );

  const handleRemoveFile = React.useCallback(
    (fileToRemove: File) =>
      setFiles((list) =>
        list.filter((file) => fileId(file) !== fileId(fileToRemove)),
      ),
    [],
  );

  React.useEffect(() => {
    const urls = Array.from(files).map((file) => URL.createObjectURL(file));
    onChange(files, urls);
    setIsLoading(false);
    return () => urls?.forEach((url) => URL.revokeObjectURL(url));
  }, [onChange, files]);

  return (
    <div className="BaSe--file-picker">
      <HiddenInputFile
        accept={acceptsFilesPattern}
        multiple={pickMultipleFiles}
        ref={inputRef}
        onChange={handleChange}
        onError={handleError}
      />
      {renderStyle === 'input-field' && (
        <BaSeFileInputField
          files={files}
          hasFiles={hasFiles}
          isLoading={isLoading}
          label={label}
          subLabel={subLabel}
          showHelpButton={showHelpButton}
          helpButtonProps={helpButtonProps}
          pickMultipleFiles={pickMultipleFiles}
          pickButtonProps={pickButtonProps as IconButtonInputProps}
          pickInputProps={pickInputProps}
          onClickToPick={() => inputRef.current?.click()}
          onClickToRemove={() => setFiles([])}
        />
      )}

      {renderStyle === 'box' && (
        <>
          <OutsideWrapper>
            <BaSeFormLabel
              label={label}
              subLabel={subLabel}
              showHelpButton={showHelpButton}
              helpButtonProps={helpButtonProps}
            />
            {hasFiles && (
              <BaSeButton
                {...(pickButtonProps as ButtonProps)}
                isLoading={isLoading}
                type={(pickButtonProps as ButtonProps)?.type ?? 'primary'}
                size={(pickButtonProps as ButtonProps)?.size ?? 'small'}
                color={(pickButtonProps as ButtonProps)?.color ?? 'default'}
                leftIcon={
                  (pickButtonProps as ButtonProps)?.leftIcon ?? 'upload'
                }
                value={
                  (pickButtonProps as ButtonProps)?.value ??
                  getMessage('filePicker.buttonPickLabel')
                }
                onClick={(clickEvent: React.MouseEvent<HTMLButtonElement>) => {
                  (pickButtonProps as ButtonProps)?.onClick?.(clickEvent);
                  inputRef.current?.click();
                }}
              />
            )}
          </OutsideWrapper>
          <FilePickerWrapper
            onDragEnter={handleDrag(true)}
            onDragLeave={handleDrag(false)}
            onDragOver={handleDrag(true)}
            onDrop={handleDrop}
            isDrag={isDrag}
          >
            {hasFiles ? (
              <TagWrapper>
                {files.map((file) => (
                  <React.Fragment key={fileId(file)}>
                    <BaSeTag
                      label={fileName(file)}
                      onClose={() => handleRemoveFile(file)}
                      limitedWidth={300}
                      tooltip={{
                        message: [
                          fileName(file),
                          fileSize(file),
                          fileType(file),
                        ],
                      }}
                    />
                  </React.Fragment>
                ))}
              </TagWrapper>
            ) : (
              <>
                <div style={{ pointerEvents: 'none' }}>
                  <BaSeIcon
                    color={BaSeTheme.colors.institucionais.cinzaSebrae90}
                    size={75}
                    name="file-plus-alt"
                    description={getMessage('imagePicker.imagePlaceholder')}
                    {...placeholderImageProps}
                  />
                </div>
                <BaSeButton
                  {...(pickButtonProps as ButtonProps)}
                  isLoading={isLoading}
                  type={(pickButtonProps as ButtonProps)?.type ?? 'primary'}
                  size={(pickButtonProps as ButtonProps)?.size ?? 'small'}
                  color={(pickButtonProps as ButtonProps)?.color ?? 'default'}
                  leftIcon={
                    (pickButtonProps as ButtonProps)?.leftIcon ?? 'upload'
                  }
                  value={
                    (pickButtonProps as ButtonProps)?.value ??
                    getMessage('filePicker.buttonPickLabel')
                  }
                  onClick={(
                    clickEvent: React.MouseEvent<HTMLButtonElement>,
                  ) => {
                    (pickButtonProps as ButtonProps)?.onClick?.(clickEvent);
                    inputRef.current?.click();
                  }}
                />
                <BaSeParagraph
                  {...dragInfoProps}
                  color={
                    dragInfoProps?.color ??
                    BaSeTheme.colors.institucionais.cinzaSebrae45
                  }
                >
                  {dragInfoText ??
                    getMessage(
                      pickMultipleFiles
                        ? 'filePicker.dragManyInfo'
                        : 'filePicker.dragInfo',
                    )}
                </BaSeParagraph>
              </>
            )}
          </FilePickerWrapper>
        </>
      )}
    </div>
  );
};

BaSeFilePicker.displayName = 'BaSeFilePicker';
