import { BaSeTheme } from '../../../theme';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';
import {
  getHSLValues,
  createColorSmartLightingControl,
} from '../../../utils/color-transformation/calculate-pattern';

export const SideMenuColors = {
  light: {
    textColor: ColorMapper.grayScale.foreground,
    hoverTextColor: ColorMapper.grayScale.hover.defaultDark,
    selectedTextColor: ColorMapper.default.foreground,
    childrenActiveTextColor: ColorMapper.default.hover.defaultDark,
    bottomLineColor: ColorMapper.grayScale.getColorByNumber(90),
    backgroundColor: BaSeTheme.colors.defaultColors.white,
    backgroundHoverColor: ColorMapper.grayScale.hover.defaultLight,
  },
  dark: {
    textColor: ColorMapper.grayScale.focus.defaultLight,
    hoverTextColor: BaSeTheme.colors.defaultColors.white,
    selectedTextColor: BaSeTheme.colors.defaultColors.white,
    childrenActiveTextColor: BaSeTheme.colors.defaultColors.white,
    bottomLineColor: ColorMapper.grayScale.getColorByNumber(90),
    backgroundColor: BaSeTheme.colors.defaultColors.transparent,
    backgroundHoverColor: ColorMapper.createTransparent(
      ColorMapper.default.hover.defaultLight,
      97,
      0.5,
    ),
  },
};

// const colorMappedLight = {
//   // light
//   // relative: { s: 100, l: 36 }, // selected
//   normalText: { s: 16, l: 45 }, // s => s * .16 , l => (1 - l) / (64 / 9)
//   backhover: { s: 33, l: 97 }, // s => s * .33 , l => (1 - l) / (64 / 61)
//   lineColor: { s: 22, l: 90 }, // s => s * .22 , l => (1 - l) / (64 / 54)
//   selectedChildText: { s: 80, l: 30 }, // s => s * .8 , l => l * .83
//   hoverText: { s: 25, l: 30 }, // s => s * .8 , l => l * .83
// };

// const colorMappedDark = {
//   // dark
//   // relative: { s: 1, l: 1, o: 1 }, // selected
//   normalText: { s: 14, l: 75 }, // s => s * .14 , l => l + (1 - l) / (25 / 20)
//   backhover: { s: 33, l: 97, o: 0.5 }, // s => s * .33 , l => l * .97
//   lineColor: { s: 22, l: 90 }, // s => s * .22 , l => l * .9
//   selectedChildText: { s: 100, l: 100 }, // s  , l
//   hoverText: { s: 100, l: 100 }, // s  , l
// };

export const colorMappedCustom = (color: string) => {
  if (!color) {
    return SideMenuColors;
  }
  const mappedTypes = {
    destructive: ColorMapper.destructive.foreground,
    confirmation: ColorMapper.confirmation.foreground,
    attention: ColorMapper.attention.foreground,
  };
  const originalValues = getHSLValues(mappedTypes[color] ?? color);
  if (!originalValues) {
    return SideMenuColors;
  }
  let { h, s, l } = originalValues;
  h /= 100;
  s /= 100;
  l /= 100;
  return {
    light: {
      textColor: createColorSmartLightingControl(
        mappedTypes[color] ?? color,
        { h, s: s * 0.16, l: l + (1 - l) / (64 / 9) },
        'lighter',
      ),
      hoverTextColor: createColorSmartLightingControl(
        mappedTypes[color] ?? color,
        { h, s: s * 0.25, l: l * 0.83 },
        'darker',
      ),
      selectedTextColor: mappedTypes[color] ?? color,
      childrenActiveTextColor: createColorSmartLightingControl(
        mappedTypes[color] ?? color,
        { h, s: s * 0.8, l: l * 0.83 },
        'darker',
      ),
      bottomLineColor: createColorSmartLightingControl(
        mappedTypes[color] ?? color,
        { h, s: s * 0.22, l: l + (1 - l) / (64 / 54) },
        'lighter',
      ),
      backgroundColor: BaSeTheme.colors.defaultColors.white,
      backgroundHoverColor: createColorSmartLightingControl(
        mappedTypes[color] ?? color,
        { h, s: s * 0.33, l: l + (1 - l) / (64 / 61) },
        'lighter',
      ),
    },
    dark: {
      textColor: createColorSmartLightingControl(
        mappedTypes[color] ?? color,
        { h, s: s * 0.14, l: l + (1 - l) / (25 / 6) },
        'lighter',
      ),
      hoverTextColor: mappedTypes[color] ?? color,
      selectedTextColor: mappedTypes[color] ?? color,
      childrenActiveTextColor: mappedTypes[color] ?? color,
      bottomLineColor: createColorSmartLightingControl(
        mappedTypes[color] ?? color,
        { h, s: s * 0.22, l: l * 0.9 },
        'darker',
      ),
      backgroundColor: BaSeTheme.colors.defaultColors.transparent,
      backgroundHoverColor: createColorSmartLightingControl(
        mappedTypes[color] ?? color,
        { h, s: s * 0.33, l: l * 0.97, opacity: 0.5 },
        'lighter',
      ),
    },
  };
};
