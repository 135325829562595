import * as React from 'react';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { BaSeDataElement } from '../../../elements/data';
import { BaSeTheme } from '../../../theme';
import { ShapeButtonProps } from '../../button/shape-button/shape-button';
import { BaSeParagraph } from '../../typography/paragraph/paragraph';
import { BaSeData, DataProps } from '../data';
import { DataDensityLevel } from '../data-styled';
import { CustomFilterRenderProps } from '../filter-area/filter-area';
import { DataTableCompactMode } from './compact-mode';
import { DataTableNormalMode } from './normal-mode';
import { DataBodyTableProps } from './table-styled';

type KeyOf<Item> = keyof Item;

export type ColumnId<Item> = KeyOf<Item> | string;

export interface ColumnIdFlaggable {
  [columnId: string]: boolean;
}

export interface ColumnFilterRenderProps<Filter>
  extends CustomFilterRenderProps<Filter> {
  closeFilter(): void;
}

export interface DataTableColumnConfig<Item, Filter> {
  columnId: ColumnId<Item>;
  width?: number | string;
  stickyOnCompactMode?: boolean;
  columnFilterIconProps?: Partial<
    Omit<ShapeButtonProps, 'onClick' | 'autoFocus'>
  >;
  renderColumnFilter?(
    renderProps: ColumnFilterRenderProps<Filter>,
  ): JSX.Element;
}

export interface DataTableProps<Item extends object = {}, Filter = any>
  extends Omit<DataProps<Item, Filter>, 'render' | 'whenRenderTextCell'>,
    Pick<DataBodyTableProps, 'tableHeaderBackgroundColor'> {
  sortIcon?: string;
  sortingAscIcon?: string;
  sortingDescIcon?: string;
  columnConfig?: DataTableColumnConfig<Item, Filter>[];
  compactModeExpandable?: boolean;
}

function BaSeDataTableForwardedRef<Item extends object = {}, Filter = any>(
  props: React.PropsWithoutRef<DataTableProps<Item, Filter>>,
  ref?: React.Ref<BaSeDataElement<Filter>>,
): JSX.Element {
  const {
    itemsPerPage = [5, 10, 15, 50, 100],
    densityLevel: initialDensityLevel = 'normal',
    configActionItems = [],
    highlightedColor = BaSeTheme.colors.institucionais.azulSebrae,
    foregroundColor = BaSeTheme.colors.institucionais.cinzaSebrae60,
    textColor = BaSeTheme.colors.institucionais.cinzaSebrae30,
    separatorColor = BaSeTheme.colors.institucionais.cinzaSebrae90,
    backgroundColor = BaSeTheme.colors.defaultColors.white,
    tableHeaderBackgroundColor: externalTableHeaderBackgroundColor = BaSeTheme
      .colors.defaultColors.transparent,
    sortIcon = 'arrow-head-down',
    sortingAscIcon = 'arrow-head-down',
    sortingDescIcon = 'arrow-head-up',
    columnConfig = [],
    compactMode,
    hasHeader = true,
    shadow,
    idAccessorKey = 'id',
    compactModeExpandable,
    // eslint-disable-next-line comma-dangle
    ...dataProps
  } = props;

  const { getMessage } = React.useContext(BaSeI18nContext);

  const [densityLevel, setDensityLevel] =
    React.useState<DataDensityLevel>(initialDensityLevel);
  const [columnFilterVisible, setColumnFilterVisible] =
    React.useState<ColumnIdFlaggable>({});

  const tableHeaderBackgroundColor = React.useMemo(
    () =>
      hasHeader
        ? externalTableHeaderBackgroundColor
        : BaSeTheme.colors.defaultColors.transparent,
    [hasHeader, externalTableHeaderBackgroundColor],
  );

  return (
    <BaSeData<Item, Filter>
      {...dataProps}
      ref={ref}
      hasHeader={hasHeader}
      itemsPerPage={itemsPerPage}
      densityLevel={densityLevel}
      highlightedColor={highlightedColor}
      foregroundColor={foregroundColor}
      textColor={textColor}
      separatorColor={separatorColor}
      backgroundColor={backgroundColor}
      compactMode={compactMode}
      shadow={shadow}
      idAccessorKey={idAccessorKey}
      configActionItems={[
        {
          icon: 'bars-high-density',
          text: getMessage('dataDensity.high'),
          color: foregroundColor,
          isSelected: densityLevel === 'high',
          callback: () => setDensityLevel('high'),
        },
        {
          icon: 'bars',
          text: getMessage('dataDensity.normal'),
          color: foregroundColor,
          isSelected: densityLevel === 'normal',
          callback: () => setDensityLevel('normal'),
        },
        {
          icon: 'bars-low-density',
          text: getMessage('dataDensity.low'),
          color: foregroundColor,
          isSelected: densityLevel === 'low',
          callback: () => setDensityLevel('low'),
        },
        ...configActionItems,
      ]}
      whenRenderTextCell={(value: string) => (
        <BaSeParagraph color={textColor}>{value}</BaSeParagraph>
      )}
      render={({
        setTags,
        setFilter,
        resetSelection,
        table: {
          getTableBodyProps,
          getTableProps,
          headerGroups,
          rows,
          page,
          prepareRow,
        },
      }) =>
        compactMode ? (
          <DataTableCompactMode<Item>
            page={page}
            rows={rows}
            shadow={shadow}
            idAccessorKey={idAccessorKey}
            densityLevel={densityLevel}
            columnFilterVisible={columnFilterVisible}
            columnConfig={columnConfig}
            compactModeExpandable={compactModeExpandable}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            highlightedColor={highlightedColor}
            separatorColor={separatorColor}
            textColor={textColor}
            tableHeaderBackgroundColor={tableHeaderBackgroundColor}
            getTableProps={getTableProps}
            prepareRow={prepareRow}
            setColumnFilterVisible={setColumnFilterVisible}
            setFilter={setFilter}
            setTags={setTags}
            resetSelection={resetSelection}
          />
        ) : (
          <DataTableNormalMode<Item, Filter>
            headerGroups={headerGroups}
            page={page}
            rows={rows}
            idAccessorKey={idAccessorKey}
            columnFilterVisible={columnFilterVisible}
            columnConfig={columnConfig}
            densityLevel={densityLevel}
            sortIcon={sortIcon}
            sortingAscIcon={sortingAscIcon}
            sortingDescIcon={sortingDescIcon}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            highlightedColor={highlightedColor}
            separatorColor={separatorColor}
            textColor={textColor}
            tableHeaderBackgroundColor={tableHeaderBackgroundColor}
            getTableBodyProps={getTableBodyProps}
            getTableProps={getTableProps}
            prepareRow={prepareRow}
            setColumnFilterVisible={setColumnFilterVisible}
            setFilter={setFilter}
            setTags={setTags}
            resetSelection={resetSelection}
          />
        )
      }
    />
  );
}

export const BaSeDataTable = React.forwardRef(BaSeDataTableForwardedRef) as <
  Item extends object = {},
  Filter = any,
>(
  props: DataTableProps<Item, Filter> & {
    ref?: React.Ref<BaSeDataElement<Filter>>;
  },
) => JSX.Element;

BaSeDataTable['displayName'] = 'BaSeDataTable';
