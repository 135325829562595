import * as React from 'react';
import { BaSeDataSelectionContext } from '../../../contexts/data/selection';
import { BaSeIcon } from '../../image/icon';
import { BaSeCheckbox } from '../../input/checkbox/checkbox';
import { DataInterfaceProps } from '../data-styled';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { BaSeLoadingSpinner } from '../../loading/spinner';

export interface ItemSelectionHandlerArgs<Item> {
  values: Item;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected(checked: boolean): void;
}

export interface ToggleRowCheckboxProps<Item>
  extends Pick<DataInterfaceProps, 'highlightedColor'> {
  rowItem: Item;
  rowIdKey: keyof Item;
  itemSelectionHandler?(args: ItemSelectionHandlerArgs<Item>): void;
}

export function DataToggleRowCheckbox<Item extends object>({
  highlightedColor,
  rowIdKey,
  rowItem,
  itemSelectionHandler,
}: React.PropsWithoutRef<ToggleRowCheckboxProps<Item>>): JSX.Element {
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { isAllSelected, selectedItems, setSelectedItems } = React.useContext(
    BaSeDataSelectionContext,
  );
  const { getMessage } = React.useContext(BaSeI18nContext);

  const rowSelected = React.useMemo(
    () =>
      isAllSelected ||
      selectedItems.some((item: Item) => rowItem[rowIdKey] === item[rowIdKey]),
    [isAllSelected, selectedItems],
  );

  const setSelected = React.useCallback(
    (checked: boolean) => {
      setSelectedItems((actual) =>
        actual.filter((item: Item) => rowItem[rowIdKey] !== item[rowIdKey]),
      );
      if (checked) {
        setSelectedItems((actual) => [...actual, rowItem]);
      }
    },
    [setSelectedItems],
  );

  React.useEffect(
    () =>
      itemSelectionHandler?.({
        values: rowItem,
        setDisabled,
        setLoading,
        setSelected,
      }),
    [itemSelectionHandler, rowItem, setSelected],
  );

  React.useEffect(() => {
    if (disabled) {
      setSelectedItems((actual) =>
        actual.filter((item: Item) => rowItem[rowIdKey] !== item[rowIdKey]),
      );
    }
  }, [disabled]);

  if (loading) {
    return (
      <BaSeLoadingSpinner
        diameter={20}
        description={getMessage('loading.description')}
      />
    );
  }

  return isAllSelected ? (
    <BaSeIcon
      name="check"
      description={getMessage('dataSelection.selectedItem')}
      color={highlightedColor as string}
      size={28}
    />
  ) : (
    <BaSeCheckbox
      color={highlightedColor}
      checked={rowSelected}
      disabled={disabled}
      onChange={setSelected}
    />
  );
}
