import { ThemeColorValue } from './../../theme/theme-interface';
import { BaSeTheme } from '../../theme';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';
import {
  getHSLValues,
  createColor,
} from '../../utils/color-transformation/calculate-pattern';
import { Color, HSLColorMode } from '../../utils/color-transformation/color';

export interface TogglerMappedAtributes {
  border: string;
  backgroundColor: string;
  foreground: string;
}

export const getTogglerLabelColorProps = (
  type: string,
  status: string,
  defaultColor?: ThemeColorValue,
  checkedColor?: ThemeColorValue,
  disabledColor?: ThemeColorValue,
) => {
  if (type === 'outlined') {
    return status === 'default'
      ? {
          border: `2px solid ${defaultColor}`,
          background: BaSeTheme.colors.defaultColors.white,
        }
      : status === 'checked'
        ? {
            border: `2px solid ${checkedColor}`,
            background: BaSeTheme.colors.defaultColors.white,
          }
        : {
            border: `2px solid ${disabledColor}`,
            background: BaSeTheme.colors.defaultColors.white,
          };
  } else {
    return status === 'default'
      ? {
          border: 'none',
          background: `${defaultColor}`,
        }
      : status === 'checked'
        ? {
            border: 'none',
            background: `${checkedColor}`,
          }
        : {
            border: 'none',
            background: `${disabledColor}`,
          };
  }
};

export const getTogglerSpanColorProps = (
  type: string,
  status: string,
  defaultColor?: ThemeColorValue,
  checkedColor?: ThemeColorValue,
  disabledColor?: ThemeColorValue,
) => {
  if (type === 'outlined') {
    return status === 'default'
      ? {
          border: 'none',
          background: `${defaultColor}`,
        }
      : status === 'checked'
        ? {
            border: 'none',
            background: `${checkedColor}`,
          }
        : {
            border: 'none',
            background: `${disabledColor}`,
          };
  } else {
    return status === 'default'
      ? {
          border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
          background: `${defaultColor}`,
        }
      : status === 'checked'
        ? {
            border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
            background: `${checkedColor}`,
          }
        : {
            border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
            background: `${disabledColor}`,
          };
  }
};

export const mapTogglerTypeSpan = {
  outlined: {
    position: {
      top: '4.5%',
      left: '2px',
    },
    size: '22px',
    transform: 'translateX(32px);',
  },
  contained: {
    position: {
      top: '-7px',
      left: '-3px',
    },
    size: '26px',
    transform: ' translateX(35px);',
  },
};

export const TogglerMapper = {
  outlined: {
    default: {
      unchecked: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.default.unchecked}`,
        foreground: ColorMapper.default.unchecked,
        shadowFocus: ColorMapper.default.focus.defaultLight,
        iconColor: ColorMapper.default.hover.defaultDark,
      },
      checked: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.default.foreground}`,
        foreground: ColorMapper.default.foreground,
        shadowFocus: ColorMapper.default.focus.defaultLight,
        iconColor: ColorMapper.default.hover.defaultDark,
      },
      disabled: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.disabled.foreground}`,
        foreground: ColorMapper.disabled.foreground,
        shadowFocus: 'none',
      },
    },
    confirmation: {
      unchecked: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.confirmation.unchecked}`,
        foreground: ColorMapper.confirmation.unchecked,
        shadowFocus: ColorMapper.confirmation.focus.defaultLight,
        iconColor: ColorMapper.confirmation.hover.defaultDark,
      },
      checked: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.confirmation.foreground}`,
        foreground: ColorMapper.confirmation.foreground,
        shadowFocus: ColorMapper.confirmation.focus.defaultLight,
        iconColor: ColorMapper.confirmation.hover.defaultDark,
      },
      disabled: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.disabled.foreground}`,
        foreground: ColorMapper.disabled.foreground,
        shadowFocus: 'none',
      },
    },
    destructive: {
      unchecked: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.destructive.unchecked}`,
        foreground: ColorMapper.destructive.unchecked,
        shadowFocus: ColorMapper.destructive.focus.defaultLight,
        iconColor: ColorMapper.destructive.hover.defaultDark,
      },
      checked: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.destructive.foreground}`,
        foreground: ColorMapper.destructive.foreground,
        shadowFocus: ColorMapper.destructive.focus.defaultLight,
        iconColor: ColorMapper.destructive.hover.defaultDark,
      },
      disabled: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.disabled.foreground}`,
        foreground: ColorMapper.disabled.foreground,
        shadowFocus: 'none',
      },
    },
    negative: {
      unchecked: {
        backgroundColor: BaSeTheme.colors.defaultColors.transparent,
        border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
        foreground: BaSeTheme.colors.defaultColors.white,
        shadowFocus: ColorMapper.default.focus.defaultLight,
      },
      checked: {
        backgroundColor: BaSeTheme.colors.defaultColors.transparent,
        border: `2px solid ${ColorMapper.default.hover.defaultLight}`,
        foreground: ColorMapper.default.hover.defaultLight,
        shadowFocus: ColorMapper.default.focus.defaultLight,
      },
      disabled: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.disabled.foreground}`,
        foreground: ColorMapper.disabled.foreground,
        shadowFocus: 'none',
      },
    },
  },
  contained: {
    default: {
      unchecked: {
        backgroundColor: ColorMapper.default.unchecked,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: ColorMapper.default.focus.defaultLight,
        hoverColor: ColorMapper.default.hover.defaultDark,
      },
      checked: {
        backgroundColor: ColorMapper.default.foreground,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: ColorMapper.default.focus.defaultLight,
        hoverColor: ColorMapper.default.hover.defaultDark,
      },
      disabled: {
        backgroundColor: ColorMapper.disabled.foreground,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: 'none',
      },
    },
    confirmation: {
      unchecked: {
        backgroundColor: ColorMapper.confirmation.unchecked,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: ColorMapper.confirmation.focus.defaultLight,
        hoverColor: ColorMapper.confirmation.hover.defaultDark,
      },
      checked: {
        backgroundColor: ColorMapper.confirmation.foreground,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: ColorMapper.confirmation.focus.defaultLight,
        hoverColor: ColorMapper.confirmation.hover.defaultDark,
      },
      disabled: {
        backgroundColor: ColorMapper.disabled.foreground,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: 'none',
      },
    },
    destructive: {
      unchecked: {
        backgroundColor: ColorMapper.destructive.unchecked,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: ColorMapper.destructive.focus.defaultLight,
        hoverColor: ColorMapper.destructive.hover.defaultDark,
      },
      checked: {
        backgroundColor: ColorMapper.destructive.foreground,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: ColorMapper.destructive.focus.defaultLight,
        hoverColor: ColorMapper.destructive.hover.defaultDark,
      },
      disabled: {
        backgroundColor: ColorMapper.disabled.foreground,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: 'none',
      },
    },
    negative: {
      unchecked: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: 'none',
        foreground: ColorMapper.default.foreground,
        spanBorder: ColorMapper.default.foreground,
        shadowFocus: ColorMapper.default.focus.defaultLight,
        hoverColor: ColorMapper.default.hover.defaultLight,
      },
      checked: {
        backgroundColor: ColorMapper.default.hover.defaultLight,
        border: 'none',
        foreground: ColorMapper.default.foreground,
        spanBorder: ColorMapper.default.foreground,
        shadowFocus: ColorMapper.default.focus.defaultLight,
        hoverColor: ColorMapper.default.hover.defaultLight,
      },
      disabled: {
        backgroundColor: ColorMapper.disabled.foreground,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: ColorMapper.default.foreground,
        shadowFocus: ColorMapper.default.focus.defaultLight,
      },
    },
  },
};

/*
light
main (checked) : {l: .36, s: 1 } => l, s
unchecked : {l: .6, s: .6} // s => s * .6, l => (1 - l) / (64 / 24)
hover: use createHoverColorDark
focus: use createFocusColorLight
*/

const makeUnchecked = (color: string) => {
  let { s, l } = getHSLValues(color) as HSLColorMode;
  s /= 100;
  l /= 100;
  return createColor(color, { s: s * 0.6, l: l + (1 - l) / (64 / 24) });
};

export const createTogglerColor = (color: string) => {
  if (!Color.validateColor(color)) {
    return undefined;
  }

  return {
    outlined: {
      unchecked: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${makeUnchecked(color)}`,
        foreground: makeUnchecked(color),
        shadowFocus: ColorMapper.light.focus.create(color),
        iconColor: ColorMapper.dark.hover.create(color),
      },
      checked: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${color}`,
        foreground: color,
        shadowFocus: ColorMapper.light.focus.create(color),
        iconColor: ColorMapper.dark.hover.create(color),
      },
      disabled: {
        backgroundColor: BaSeTheme.colors.defaultColors.white,
        border: `2px solid ${ColorMapper.disabled.foreground}`,
        foreground: ColorMapper.disabled.foreground,
        shadowFocus: 'none',
      },
    },
    contained: {
      unchecked: {
        backgroundColor: makeUnchecked(color),
        border: `none`,
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: ColorMapper.light.focus.create(color),
        hoverColor: ColorMapper.dark.hover.create(color),
      },
      checked: {
        backgroundColor: color,
        border: `none`,
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: ColorMapper.light.focus.create(color),
        hoverColor: ColorMapper.dark.hover.create(color),
      },
      disabled: {
        backgroundColor: ColorMapper.disabled.foreground,
        border: 'none',
        foreground: BaSeTheme.colors.defaultColors.white,
        spanBorder: BaSeTheme.colors.defaultColors.white,
        shadowFocus: 'none',
      },
    },
  };
};
