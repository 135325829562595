import * as React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { idGenerator } from '../../../utils/id-generator';
import {
  decimalMask,
  forceCurrencyValue,
  removeNonDigit,
  substringContainMask,
} from '../../../utils/mask-utils';
import { BaSeButton } from '../../button/button/button';
import { BaSeShapeButton } from '../../button/shape-button/shape-button';
import { BaSeHelperText } from '../../helpers/helper-text/helper-text';
import { HelperButtonInterface } from '../../helpers/popup-button/popup-button';
import { BaSeIcon } from '../../image/icon';
import { BaSeLoadingSpinner } from '../../loading/spinner';
import {
  CheckIconWrapper,
  ContainerIcon,
  ContainerInput,
  MoreInfoContainer,
  StyledInputCounter,
  StyledTextFooterWrapper,
  WrapperHelper,
  WrapperInput,
} from './input-styled';
import {
  inputColorManager,
  mapBaSeInputColor,
  mapBaSeInputSize,
  overrideExtraColors,
  translationValueToName,
} from './map-input-style';

import { IMaskInput } from 'react-imask/esm/index';
import { BaSeInputElement } from '../../../elements/input';
import { CounterType, useCounter } from '../../../hooks/counter';
import { useNextHashId } from '../../../hooks/next-id';
import {
  ThemeColorValue,
  ThemeNamedType,
} from '../../../theme/theme-interface';
import { getFormattedDataset } from '../../../utils/dataset-utils';
import { LooseString } from '../../../utils/string-utils';
import { BaSeFormLabel } from '../../labels/form-label/form-label';
interface KeyDownListenerProp {
  callback: (event: React.KeyboardEvent) => void;
  listenerKey: string;
}

export interface OverrideInputColorProps {
  status?: {
    valid?: {
      foreground?: ThemeColorValue;
      background?: ThemeColorValue;
    };
    invalid?: {
      foreground?: ThemeColorValue;
      background?: ThemeColorValue;
    };
  };
  label?: ThemeColorValue;
  subLabel?: ThemeColorValue;
  complement?: ThemeColorValue;
  moreInfo?: ThemeColorValue;
  border?: ThemeColorValue;
  value?: ThemeColorValue;
  background?: ThemeColorValue;
}

export interface IconButtonInputProps {
  typeButton: '' | 'base-icon' | 'base-button' | 'base-shape-button';
  name?: string;
  action?: () => void;
  type?: Exclude<ThemeNamedType, 'tertiary'>;
  value?: string;
  color?: string;
}

export type TypeInput = 'password' | 'text' | 'tel' | 'email' | 'url';
export type InputMode = 'search' | 'tel' | 'email' | 'url' | 'text' | 'decimal';
export type Mask = LooseString<
  | 'cnpj'
  | 'cpf'
  | 'cep'
  | 'phone'
  | 'phoneDDD'
  | 'oldPhone'
  | 'oldPhoneDDD'
  | 'date'
  | 'decimal'
  | 'currency'
>;

export type InputStatus = 'normal' | 'valid' | 'invalid';
export interface InputProps {
  id?: string;
  name?: string;
  dataset?: DOMStringMap;
  isLoading?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  showHelpButton?: boolean;
  placeholder?: string;
  label?: string;
  complement?: string;
  size?: 'medium' | 'small' | 'big';
  typeInput?: TypeInput;
  inputMode?: InputMode;
  prefix?: string;
  suffix?: string;
  value?: string;
  width?: string | number;
  subLabel?: string;
  align?: 'start' | 'end';
  helpButtonProps?: HelperButtonInterface;
  moreInfoLabel?: string;
  moreInfoDetails?: string;
  onSpecificKeyDown?: KeyDownListenerProp[];
  mask?: Mask;
  iconButton?: IconButtonInputProps;
  inputStatus?: InputStatus;
  color?: string;
  overrideColors?: OverrideInputColorProps;
  maxLength?: number;
  hasCounter?: Omit<CounterType, 'lines'>;
  counterMinLength?: number;
  counterMaxLength?: number;
  onDoubleClick?: React.MouseEventHandler<HTMLInputElement>;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
  onInput?: React.FormEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onChange?(value: string): void;
  onDirty?(dirty: boolean): void;
  onModified?(modified: boolean): void;
}

const idSequence = idGenerator();

function numeberBySize({
  size,
  // eslint-disable-next-line comma-dangle
  ...sizes
}: Required<Pick<InputProps, 'size'>> & {
  small: number;
  medium: number;
  big: number;
}): number {
  return sizes[size];
}

const DECIMAL_LIMIT = 2;
const DECIMAL_MASK = 'decimal' as const;
const CURRENCY_MASK = 'currency' as const;
const PASSWORD_TYPE = 'password' as const;
const TEXT_TYPE = 'text' as const;

const statusManager = {
  invalid: 0,
  valid: 1,
  normal: 2,
  disabled: 3,
};

const mappedInputMask = {
  cnpj: '00.000.000/0000-00',
  cpf: '000.000.000-00',
  cep: '00.000-000',
  phone: '00000-0000',
  phoneDDD: '(00) 00000-0000',
  oldPhone: '0000-0000',
  oldPhoneDDD: '(00) 0000-0000',
  date: '00/00/0000',
};

interface InputWrappedMaskProps
  extends Pick<
      React.InputHTMLAttributes<HTMLInputElement>,
      | 'id'
      | 'value'
      | 'disabled'
      | 'readOnly'
      | 'placeholder'
      | 'onPaste'
      | 'onChange'
      | 'onFocus'
      | 'onBlur'
      | 'onDoubleClick'
      | 'onKeyDown'
      | 'maxLength'
    >,
    Pick<
      InputProps,
      'mask' | 'placeholder' | 'prefix' | 'suffix' | 'typeInput' | 'inputMode'
    > {
  inputRef: React.RefObject<HTMLInputElement>;
  formattedDataset: DOMStringMap | undefined;
  applyValue(valueToApply?: string): void;
}

const InputWrapper: React.FC<InputWrappedMaskProps> = ({
  children,
  mask,
  id,
  value,
  inputRef,
  typeInput,
  inputMode,
  formattedDataset,
  disabled,
  readOnly,
  placeholder,
  prefix,
  suffix,
  maxLength,
  applyValue,
  onChange,
  onPaste,
  onFocus,
  onBlur,
  onDoubleClick,
  onKeyDown,
}) => {
  const childrenPassThru = React.useMemo(
    () => !mask || mask === DECIMAL_MASK,
    [mask],
  );

  const usingCurrencyInput = React.useMemo(
    () => mask === CURRENCY_MASK,
    [mask],
  );

  if (childrenPassThru) {
    return <>{children}</>;
  }

  if (usingCurrencyInput) {
    const {
      formats: { currency: currencyFormat },
    } = React.useContext(BaSeI18nContext);

    const [currency, setCurrency] = React.useState<string | number>();

    const setSelectionOnDecimal = React.useCallback(
      (
        e:
          | React.MouseEvent<HTMLInputElement>
          | React.KeyboardEvent<HTMLInputElement>,
      ) => {
        if ('key' in e && e.key === 'ArrowLeft') {
          return;
        }

        const target = e.currentTarget;
        const targetValue = target.value ?? '';
        const decimalValue =
          targetValue.split(currencyFormat.decimalSeparator)?.[1] ?? '';
        const valueLength = targetValue.length;
        const selectionStart = target.selectionStart ?? 0;
        const selectionEnd = target.selectionEnd ?? 0;
        const decimalLimit = valueLength - DECIMAL_LIMIT;
        const hasValidDecimals = decimalValue.length === DECIMAL_LIMIT;

        if (
          hasValidDecimals &&
          selectionStart === selectionEnd &&
          selectionEnd >= decimalLimit
        ) {
          target.setSelectionRange(decimalLimit, valueLength);
        }
      },
      [currencyFormat.decimalSeparator],
    );

    React.useEffect(() => {
      if (
        usingCurrencyInput &&
        !currency?.toString() &&
        (typeof value === 'string' || typeof value === 'number')
      ) {
        setCurrency(value.toString().replaceAll(/[^0-9.-]/g, ''));
      }
    }, [usingCurrencyInput, currency, value]);

    return (
      <CurrencyInput
        {...formattedDataset}
        className="BaSe--input-input"
        // defaultValue={0.0}
        decimalsLimit={DECIMAL_LIMIT}
        decimalScale={DECIMAL_LIMIT}
        value={currency}
        maxLength={maxLength}
        id={id}
        type={typeInput}
        inputMode={inputMode}
        ref={inputRef}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        prefix={prefix}
        suffix={suffix}
        decimalSeparator={currencyFormat.decimalSeparator}
        groupSeparator={currencyFormat.classSeparator}
        onValueChange={setCurrency}
        onChange={onChange}
        onPaste={onPaste}
        onFocus={onFocus}
        onBlur={onBlur}
        onDoubleClick={onDoubleClick}
        onKeyDown={onKeyDown}
        onClick={setSelectionOnDecimal}
        onKeyUp={setSelectionOnDecimal}
      />
    );
  }

  return (
    <IMaskInput
      {...formattedDataset}
      className="BaSe--input-input"
      mask={mappedInputMask?.[mask as string] ?? mask}
      value={value as string}
      type={typeInput}
      inputMode={inputMode}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={placeholder}
      onAccept={(val) => applyValue(val)}
      onPaste={onPaste}
      onFocus={onFocus}
      onBlur={onBlur}
      onDoubleClick={onDoubleClick}
      onKeyDown={onKeyDown}
    />
  );
};

export const BaSeInput = React.forwardRef<BaSeInputElement, InputProps>(
  (
    {
      id: externalId,
      name,
      dataset,
      isLoading = false,
      isDisabled = false,
      isReadOnly = false,
      showHelpButton = false,
      placeholder = '',
      label = '',
      complement = '',
      moreInfoLabel = '',
      moreInfoDetails = '',
      size = 'medium',
      typeInput = 'text',
      inputMode = 'text',
      value = '',
      width = '100%',
      subLabel = '',
      align = 'start',
      helpButtonProps = {},
      onSpecificKeyDown = [],
      prefix: externalPrefix,
      suffix,
      mask,
      color = 'default',
      overrideColors = {},
      iconButton = {
        typeButton: '',
        name: '',
        action: () => {},
        type: 'primary',
        value: '',
      },
      inputStatus = 'normal',
      onChange = () => {},
      onBlur,
      onFocus,
      onInput,
      onDoubleClick,
      onPaste,
      onDirty,
      onModified: onMutated,
      hasCounter = false,
      maxLength,
      counterMinLength,
      counterMaxLength,
    },
    forwardedRef,
  ) => {
    if (![false, 'chars', 'words'].includes(hasCounter as boolean | string)) {
      throw Error(
        '[BaSeInput] Atributo "hasCounter" deve ser do tipo: "chars", "words" ou false',
      );
    }
    if (!['text', 'password', 'email', 'tel', 'url'].includes(typeInput)) {
      throw Error(
        '[BaSeInput] Atributo "typeInput" deve ser do tipo: "text", "password", "email", "tel" ou "url"',
      );
    }

    if (
      !['text', 'decimal', 'email', 'tel', 'url', 'search'].includes(inputMode)
    ) {
      throw Error(
        '[BaSeInput] Atributo "inputMode" deve ser do tipo: "text", "decimal", "email", "tel" ou "url"',
      );
    }

    if (align === 'end' && iconButton.typeButton === 'base-button') {
      throw Error(
        '[BaSeInput] Atributo "align" não deve ser "end", quando passamos um "iconButton.typeButton" igual à "base-button"',
      );
    }

    const { counterDescription, cropText } = useCounter({
      value,
      hasCounter,
      counterMinLength,
      counterMaxLength,
      onChange,
    });
    const id = externalId ?? useNextHashId(idSequence);
    const [mutated, setMutated] = React.useState<boolean>(false);
    const [dirty, setDirty] = React.useState<boolean>(false);
    const [copyValue] = React.useState(value);
    const {
      getMessage,
      formats: { currency: currencyFormat },
    } = React.useContext(BaSeI18nContext);

    const prefix = React.useMemo(
      () =>
        externalPrefix ??
        (mask === CURRENCY_MASK ? currencyFormat.prefix : externalPrefix),
      [currencyFormat, mask, externalPrefix],
    );

    const inputRef = React.useRef<HTMLInputElement>(null);

    const [hasFocus, setHasFocus] = React.useState<boolean>(false);

    const formattedDataset = getFormattedDataset(dataset);

    const handlePasteEvent = React.useCallback(
      (event: React.ClipboardEvent<HTMLInputElement>) => {
        onPaste?.(event);
      },
      [onPaste],
    );

    const handleDoubleClickEvent = React.useCallback(
      (event: React.MouseEvent<HTMLInputElement>) => {
        onDoubleClick?.(event);
      },
      [onDoubleClick],
    );

    const handleInputEvent = React.useCallback(
      (event: React.FormEvent<HTMLInputElement>) => {
        setMutated(true);
        onInput?.(event);
        counterMaxLength
          ? cropText(event.currentTarget.value)
          : onChange(event.currentTarget.value);
      },
      [onInput, setMutated, cropText, onChange, counterMaxLength],
    );

    const handleFocusEvent = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        setHasFocus(true);
        onFocus?.(event);
      },
      [onFocus, setHasFocus],
    );

    const handleBlurEvent = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        setHasFocus(false);
        onBlur?.(event);
      },
      [onBlur, setHasFocus],
    );

    const handleInputStatus = React.useCallback(() => {
      if (isDisabled) {
        return statusManager.disabled;
      }
      if (!dirty && !mutated) {
        return statusManager.normal;
      }
      return statusManager[inputStatus ?? 'normal'] ?? statusManager.normal;
    }, [inputStatus, isDisabled, dirty, mutated]);

    const getColorsFromState = React.useCallback(() => {
      const colors = {
        ...inputColorManager(color, overrideColors?.status)[
          isDisabled
            ? 'disabled'
            : !dirty && !mutated
              ? 'normal'
              : inputStatus ?? 'normal'
        ],
      };

      const overrideBorder = getOverridedColorsExtra?.()?.border;
      if (overrideBorder) {
        colors.borderColor = overrideBorder;
      }

      if (overrideColors.value) {
        colors.color = overrideColors.value;
      }

      if (overrideColors.background) {
        colors.backgroundColor = overrideColors.background;
      }

      return colors;
    }, [isDisabled, color, inputStatus, overrideColors, dirty, mutated]);

    const getOverridedColorsExtra = React.useCallback(() => {
      return overrideExtraColors(overrideColors);
    }, [overrideColors]);

    const handleIconColorBaseIcon = React.useCallback(
      (ignoreFocus?: boolean) => {
        if (ignoreFocus) {
          return iconButton.color ?? getColorsFromState()?.focusTextColor;
        }
        return (
          iconButton.color ??
          getColorsFromState()?.[hasFocus ? 'focusTextColor' : 'color']
        );
      },
      [hasFocus, getColorsFromState],
    );

    const getValidTopIconPositions = React.useCallback(() => {
      const topPositionMap = {
        small: 8,
        medium: 12,
        big: 16,
      };
      return iconButton?.typeButton || inputStatus === 'valid'
        ? iconButton?.typeButton !== 'base-icon' && inputStatus !== 'valid'
          ? 2
          : 0
        : topPositionMap[size ?? 'medium'] ?? topPositionMap?.medium;
    }, [size, iconButton?.typeButton, inputStatus]);

    const [showPassword, setShowPassword] = React.useState(false);

    const getValue = React.useCallback(
      (dirtyValue?: string) => {
        const escapeRegExp = (str?: string) =>
          str?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') ?? '';

        const prefixRegExp = new RegExp(`^${escapeRegExp(prefix)}`);
        const suffixRegExp = new RegExp(`${escapeRegExp(suffix)}$`);

        const filterValue = (regExp: RegExp, val?: string, what?: string) =>
          what ? (val ?? '').toString().replace(regExp, '') : val;

        const valueWithoutPrefix = filterValue(
          prefixRegExp,
          dirtyValue,
          prefix,
        );
        const valueWithoutPrefixAndSuffix = filterValue(
          suffixRegExp,
          valueWithoutPrefix,
          suffix,
        );

        const clearValue =
          mask === CURRENCY_MASK
            ? forceCurrencyValue({
                value: valueWithoutPrefixAndSuffix,
                decimalSeparator: currencyFormat.decimalSeparator,
              })
            : mask === DECIMAL_MASK
              ? decimalMask({
                  value: valueWithoutPrefixAndSuffix,
                  decimalSeparator: currencyFormat.decimalSeparator,
                  classSeparator: currencyFormat.classSeparator,
                })
              : valueWithoutPrefixAndSuffix;

        if (clearValue) {
          return clearValue;
        }

        return '';
      },
      [mask, prefix, suffix, currencyFormat],
    );

    const inputValue = React.useMemo(() => {
      const clearValue = getValue(value);

      if (clearValue) {
        return (prefix ?? '') + clearValue + (suffix ?? '');
      }

      return '';
    }, [prefix, suffix, value, getValue]);

    const applyValue = React.useCallback(
      (valueToApply?: string) => onChange(getValue(valueToApply)),
      [onChange, getValue],
    );

    const onChangeEvent = React.useCallback(
      (event: React.FormEvent<HTMLInputElement>) => {
        applyValue(event?.currentTarget?.value);
      },
      [applyValue],
    );

    const hasComplement = React.useMemo(() => complement !== '', [complement]);

    const paddingRight = React.useMemo(
      () =>
        typeInput === PASSWORD_TYPE ||
        iconButton.typeButton === 'base-icon' ||
        isLoading
          ? numeberBySize({ size, small: 24, medium: 32, big: 40 })
          : iconButton.typeButton === 'base-shape-button'
            ? numeberBySize({ size, small: 40, medium: 48, big: 56 })
            : 12,
      [typeInput, iconButton.typeButton, size, isLoading],
    );

    const calcSize = React.useMemo(
      () =>
        iconButton.type === 'secondary'
          ? (mapBaSeInputSize[size] ?? mapBaSeInputSize.medium) + 2
          : mapBaSeInputSize[size] ?? mapBaSeInputSize.medium,
      [iconButton.type, size],
    );

    const inputType = React.useMemo(
      () =>
        typeInput === 'password'
          ? !showPassword
            ? PASSWORD_TYPE
            : TEXT_TYPE
          : typeInput,
      [showPassword, typeInput],
    );

    const onClickIconButton = React.useCallback(() => {
      if (typeInput === PASSWORD_TYPE) {
        return setShowPassword(
          (actualShowPassword: boolean) => !actualShowPassword,
        );
      }
      iconButton?.action?.();
      inputRef?.current?.focus?.();
    }, [typeInput, iconButton, inputRef]);

    const handleKeyDownEvent = React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        for (const keyListener of onSpecificKeyDown) {
          if (keyListener?.listenerKey === event.key) {
            keyListener?.callback?.(event);
          }
        }
        if (suffix && mask !== CURRENCY_MASK) {
          const pos = ((prefix ?? '') + value).length;
          inputRef.current?.setSelectionRange(pos, pos);
        }
      },
      [onSpecificKeyDown, prefix, suffix, value, mask, inputRef.current],
    );

    const handleHelperColor = () => {
      const type = translationValueToName[handleInputStatus()];
      return mapBaSeInputColor[type]?.color ?? mapBaSeInputColor.normal.color;
    };

    React.useImperativeHandle(
      forwardedRef,
      () => ({ focus: () => inputRef?.current?.focus?.() }),
      [inputRef],
    );

    const getOriginalValueFromMaskedInput = React.useCallback(() => {
      if (!!mask) {
        return mask
          ? removeNonDigit(value + '') !== removeNonDigit(copyValue + '')
          : substringContainMask(copyValue, value);
      }
      return value !== copyValue;
    }, [value, copyValue]);

    React.useEffect(
      () => setDirty(getOriginalValueFromMaskedInput()),
      [value, copyValue],
    );

    React.useEffect(() => onDirty?.(dirty), [dirty]);

    React.useEffect(() => onMutated?.(mutated), [mutated]);

    return (
      <ContainerInput width={width}>
        <BaSeFormLabel
          id={id}
          label={label}
          subLabel={subLabel}
          showHelpButton={showHelpButton}
          helpButtonProps={helpButtonProps}
          colors={{
            label: getOverridedColorsExtra()?.label,
            subLabel: getOverridedColorsExtra()?.subLabel,
          }}
        />
        <WrapperInput
          size={calcSize}
          customColors={getColorsFromState()}
          paddingRight={paddingRight}
          align={align}
        >
          <InputWrapper
            mask={mask}
            maxLength={maxLength}
            inputRef={inputRef}
            id={id}
            value={inputValue}
            typeInput={inputType}
            inputMode={inputMode}
            formattedDataset={formattedDataset}
            disabled={isDisabled}
            readOnly={isReadOnly}
            placeholder={placeholder}
            prefix={prefix}
            suffix={suffix}
            applyValue={applyValue}
            onChange={onChangeEvent}
            onPaste={handlePasteEvent}
            onFocus={handleFocusEvent}
            onBlur={handleBlurEvent}
            onDoubleClick={handleDoubleClickEvent}
            onKeyDown={handleKeyDownEvent}
          >
            <input
              className="BaSe--input-input"
              id={id}
              name={name}
              {...formattedDataset}
              ref={inputRef}
              value={inputValue}
              type={inputType}
              inputMode={inputMode}
              disabled={isDisabled}
              readOnly={isReadOnly}
              placeholder={placeholder}
              onChange={onChangeEvent}
              maxLength={maxLength}
              onInput={handleInputEvent}
              onPaste={handlePasteEvent}
              onFocus={handleFocusEvent}
              onBlur={handleBlurEvent}
              onDoubleClick={handleDoubleClickEvent}
              onKeyDown={handleKeyDownEvent}
            />
          </InputWrapper>

          <ContainerIcon rotation={typeInput === PASSWORD_TYPE && showPassword}>
            {isLoading ? (
              <BaSeLoadingSpinner
                description={getMessage('loading.description')}
                diameter={size === 'big' ? 24 : 16}
                color={handleIconColorBaseIcon()}
              />
            ) : (
              <>
                {inputStatus === 'valid' && (dirty || mutated) && (
                  <CheckIconWrapper top={getValidTopIconPositions()}>
                    <BaSeIcon
                      description="Campo validado"
                      name="check"
                      color={handleIconColorBaseIcon(true)}
                      size={18}
                    />
                  </CheckIconWrapper>
                )}
                {(typeInput === PASSWORD_TYPE ||
                  iconButton.typeButton === 'base-icon') && (
                  <div>
                    <BaSeShapeButton
                      name={name ? `input-shape-button-${name}` : undefined}
                      nameIcon={
                        typeInput === PASSWORD_TYPE
                          ? !showPassword
                            ? 'eye-slash'
                            : 'eye'
                          : iconButton.name ?? ''
                      }
                      descriptionIcon={
                        iconButton.value !== '' &&
                        iconButton.value !== undefined
                          ? iconButton.value
                          : typeInput === PASSWORD_TYPE
                            ? !showPassword
                              ? getMessage('buttonIcon.showPassword')
                              : getMessage('buttonIcon.hidePassword')
                            : getMessage('buttonIcon.inputIcon')
                      }
                      size={size}
                      type="tertiary"
                      color={handleIconColorBaseIcon(true)}
                      onClick={onClickIconButton}
                    />
                  </div>
                )}
                {iconButton.typeButton === 'base-button' && (
                  <div>
                    <BaSeButton
                      value={iconButton.value}
                      onClick={onClickIconButton}
                      type={iconButton.type}
                      leftIcon={iconButton.name}
                      size={size}
                      buttonType="button"
                      isDisabled={isDisabled}
                      color={handleIconColorBaseIcon(true)}
                    />
                  </div>
                )}
                {iconButton.typeButton === 'base-shape-button' && (
                  <div>
                    <BaSeShapeButton
                      onClick={onClickIconButton}
                      buttonType="button"
                      nameIcon={iconButton.name ?? ''}
                      shape="square"
                      size={size}
                      type={iconButton.type}
                      isDisabled={isDisabled}
                      color={handleIconColorBaseIcon(true)}
                    />
                  </div>
                )}
              </>
            )}
          </ContainerIcon>
        </WrapperInput>
        <StyledTextFooterWrapper>
          {hasComplement && (
            <WrapperHelper>
              <BaSeHelperText
                isItalic={true}
                color={overrideColors?.complement ?? handleHelperColor()}
                label={complement}
              />
            </WrapperHelper>
          )}
          {hasCounter && (
            <StyledInputCounter
              color={overrideColors?.complement ?? handleHelperColor()}
            >
              {counterDescription}
            </StyledInputCounter>
          )}
        </StyledTextFooterWrapper>
        {!!moreInfoLabel && (
          <MoreInfoContainer>
            <BaSeHelperText
              color={overrideColors?.moreInfo}
              size="small"
              label={moreInfoLabel}
              details={moreInfoDetails}
            />
          </MoreInfoContainer>
        )}
      </ContainerInput>
    );
  },
);

BaSeInput.displayName = 'BaSeInput';
