import * as React from 'react';
import { BaSeIcon } from '../../image/icon';
import { BaSeTheme } from '../../../theme';
import { BaSeSmall2 } from '../../typography/small/small2';
import { BaSeI18nContext } from '../../../contexts/i18n';

import {
  HelperStyled,
  HelperContainer,
  HelperDetailsContainer,
} from './helper-text-styled';
import { colorMappedCustom } from './helper-text-map';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';

export interface HelperTextInterface {
  label: string;
  details?: string;
  color?: string;
  size?: 'small' | 'medium';
  isItalic?: boolean;
}

export const BaSeHelperText: React.FC<HelperTextInterface> = ({
  label = '',
  details = '',
  color,
  size = 'medium',
  isItalic = false,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { getMessage } = React.useContext(BaSeI18nContext);

  const handleDetailsColor = React.useCallback(
    () => colorMappedCustom(color),
    [color],
  );

  return (
    <HelperContainer>
      <HelperStyled
        isItalic={isItalic}
        size={size}
        open={open}
        color={ColorMapper.getColor(
          color,
          BaSeTheme.colors.institucionais.azulSebrae36,
        )}
        hasComplement={!!details}
        onClick={() => setOpen((prev) => !prev && !!details)}
      >
        {!!details && (
          <BaSeIcon
            size={12}
            name="angle-down"
            description={
              open
                ? getMessage('helperText.openButton')
                : getMessage('helperText.closeButton')
            }
            color={ColorMapper.getColor(
              color,
              BaSeTheme.colors.institucionais.azulSebrae36,
            )}
          />
        )}
        {label}
      </HelperStyled>
      <HelperDetailsContainer open={!!details && open}>
        <BaSeSmall2 color={handleDetailsColor()}>{details}</BaSeSmall2>
      </HelperDetailsContainer>
    </HelperContainer>
  );
};

BaSeHelperText.displayName = 'BaSeHelperText';
