import * as React from 'react';
import { useOutsideEvent } from '../../hooks/outside-event';
import { BaSeTheme } from '../../theme';
import { BaSeHelperText } from '../helpers/helper-text/helper-text';
import {
  BaSePopupButton,
  HelperButtonInterface,
} from '../helpers/popup-button/popup-button';
import { BaSeIcon } from '../image/icon';
import { BaSeCheckbox } from '../input/checkbox/checkbox';
import {
  ButtonInfoContainerHelper,
  HideTopOfWrapperOptions,
  MoreInfoContainer,
  SelectInput,
  SelectSizeType,
  StyledLabel,
  StyledSubLabel,
  WrapperHelper,
  WrapperIcon,
  WrapperInputLabel,
  WrapperOption,
  WrapperOptions,
  WrapperSelect,
  WrapperMultipleInformation,
} from './select-styled';
import { idGenerator } from '../../utils/id-generator';
import { BaSeI18nContext } from '../../contexts/i18n';
import { mapSelectValues, mapBaSeSelectColor } from './map-select-style';
import { useNextHashId } from '../../hooks/next-id';
import { joinWithSeparators } from '../../utils/string-utils';
import { getFormattedDataset } from '../../utils/dataset-utils';

const idSequence = idGenerator();

export type ValueId = number | string | boolean;

export interface SelectValue {
  id: ValueId;
  label: string;
}

export interface OverrideSelectColorProps {
  selectHoverColor?: string;
  iconColor?: string;
}

export interface SelectProps {
  id?: string;
  name?: string;
  dataset?: DOMStringMap;
  initialSelectedValuesId: ValueId[];
  values: SelectValue[];
  label?: string;
  subLabel?: string;
  complement?: string;
  emptyValueLabel?: string;
  removeEmptyValue?: boolean;
  moreInfoLabel?: string;
  moreInfoDetails?: string;
  showHelpButton?: boolean;
  helpButtonProps?: HelperButtonInterface;
  size?: SelectSizeType;
  width?: number | string;
  searchable?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  color?: string;
  overrideColor?: OverrideSelectColorProps;
  onChange: (selectedValue: SelectValue[]) => void;
}

/**
 * @deprecated use BaSeOptionPicker
 */
export const BaSeMultipleSelect: React.FC<SelectProps> = ({
  id: externalId,
  name,
  dataset,
  initialSelectedValuesId = [0],
  values = [],
  label = '',
  subLabel = '',
  complement = '',
  moreInfoLabel = '',
  moreInfoDetails = '',
  showHelpButton = false,
  helpButtonProps = {},
  size = 'medium',
  width = null,
  isDisabled = false,
  hasError = false,
  color = 'default',
  overrideColor = {},
  onChange = () => {},
  emptyValueLabel = '',
}) => {
  const id = externalId ?? useNextHashId(idSequence);

  const { getMessage } = React.useContext(BaSeI18nContext);
  const getColorsAtributes = () => {
    if (color) {
      return mapSelectValues(color);
    }
    return mapSelectValues('default');
  };

  const {
    style: { bcCHover, bgC, boxShadowFocus, border },
  } = getColorsAtributes();

  emptyValueLabel =
    emptyValueLabel === '' ? getMessage('select.emptyValue') : emptyValueLabel;

  const [colorType, setColorType] = React.useState('default');
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValuesId, setSelectedValuesId] = React.useState(
    initialSelectedValuesId,
  );
  const [refValues, setRefValues] = React.useState<
    (SelectValue & { ref?: React.RefObject<HTMLLIElement> })[]
  >([]);

  const formattedDataset = getFormattedDataset(dataset);

  React.useEffect(() => {
    setRefValues(
      values.map((item) => ({
        ...item,
        ref: React.createRef<HTMLLIElement>(),
      })),
    );
  }, [values]);

  React.useEffect(() => {
    setSelectedValuesId(initialSelectedValuesId);
  }, [initialSelectedValuesId.toString()]);

  const selectInputRef = React.useRef<HTMLInputElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  useOutsideEvent<HTMLDivElement>(wrapperRef, setIsOpen);

  React.useEffect(() => {
    setColorType(isDisabled ? 'disabled' : hasError ? 'error' : 'default');
  }, [hasError, isDisabled]);

  function emitChange(): void {
    const emitValues = values.filter(
      (val) => selectedValuesId.indexOf(val.id) > -1,
    );
    onChange(emitValues);
  }

  React.useEffect(() => {
    emitChange();
  }, [selectedValuesId.toString()]);

  const updateValue = React.useCallback(
    (selected: any) => {
      if (selectedValuesId.indexOf(selected.id) > -1) {
        return setSelectedValuesId((input) => {
          return input.filter((val) => val !== selected.id);
        });
      }
      setSelectedValuesId((input) => input.concat([selected.id]));
    },
    [selectedValuesId],
  );

  const selectAll = React.useCallback(() => {
    if (refValues.length > selectedValuesId.length) {
      setSelectedValuesId(refValues.map((option) => option.id));
    } else {
      setSelectedValuesId([]);
    }
  }, [refValues, selectedValuesId]);

  const onFocusInput = React.useCallback(
    () => setIsOpen(!isDisabled && true),
    [isDisabled],
  );

  const onClose = React.useCallback(() => {}, []);

  React.useEffect(() => {
    if (isDisabled) {
      return;
    }
    if (isOpen) {
      selectInputRef?.current?.focus();
    } else {
      onClose();
    }
  }, [isOpen]);

  return (
    <WrapperSelect ref={wrapperRef} isDisabled={isDisabled} width={width}>
      <WrapperInputLabel>
        <StyledLabel
          fontSize={size === 'small' ? '13px' : '16px'}
          lineHeight={size === 'small' ? '16px' : '23px'}
          htmlFor={id}
        >
          {label}
        </StyledLabel>
        <StyledSubLabel htmlFor={id}>{subLabel}</StyledSubLabel>
        {showHelpButton && (
          <ButtonInfoContainerHelper>
            <BaSePopupButton {...helpButtonProps} />
          </ButtonInfoContainerHelper>
        )}
      </WrapperInputLabel>
      <WrapperIcon
        hasLabel={!!label}
        vSize={size}
        isOpen={isOpen}
        searchable={false}
      >
        <BaSeIcon
          description={
            isOpen
              ? getMessage('select.closeOptions')
              : getMessage('select.openOptions')
          }
          name="arrow-head-down"
          size={16}
          onClick={
            isDisabled
              ? undefined
              : () => setIsOpen((actualState) => !actualState)
          }
          color={
            isDisabled
              ? BaSeTheme.colors.institucionais.cinzaSebrae75
              : overrideColor.iconColor ?? bgC
          }
        />
      </WrapperIcon>
      <SelectInput
        id={id}
        name={name}
        {...formattedDataset}
        ref={selectInputRef}
        hasSelectedOption={false}
        disabled={isDisabled}
        vSize={size}
        boxShadowFocus={boxShadowFocus}
        readOnly={true}
        border={border}
        bgC={mapBaSeSelectColor[colorType].iconColor}
        colorType={colorType}
        value={
          selectedValuesId.length < 1
            ? emptyValueLabel
            : selectedValuesId.length > 3
              ? getMessage('select.selectedCounter', selectedValuesId.length)
              : joinWithSeparators(
                  refValues
                    .filter((val) => selectedValuesId.indexOf(val.id) > -1)
                    .map((val) => val.label),
                  getMessage('list.separator'),
                  getMessage('list.finalSeparator'),
                )
        }
        isOpen={isOpen}
        hasError={hasError}
        onChange={(newValue) => updateValue(newValue)}
        onFocus={onFocusInput}
      />
      {refValues.length > 0 && (
        <>
          {isOpen && <HideTopOfWrapperOptions />}
          <WrapperOptions
            border={border}
            boxShadowFocus={boxShadowFocus}
            isOpen={isOpen}
            role="menu"
            hasError={hasError}
          >
            <WrapperMultipleInformation
              tabIndex={0}
              bcCHover={overrideColor.selectHoverColor ?? bcCHover}
              onKeyDown={(event) => event.key === 'Enter' && selectAll()}
              onClick={(e) => {
                e.preventDefault();
                selectAll();
              }}
            >
              <BaSeCheckbox
                align="top-left"
                isInteractive={false}
                color={color}
                label={getMessage('select.selectAll')}
                size="medium"
                indeterminate={selectedValuesId.length !== refValues.length}
                checked={selectedValuesId.length > 0}
              />
            </WrapperMultipleInformation>
            {refValues.map((option) => (
              <WrapperOption
                ref={option.ref}
                key={option.id.toString()}
                multiple={true}
                bcCHover={overrideColor.selectHoverColor ?? bcCHover}
                bgC={bgC}
                isActive={false}
                role="menuitem"
                tabIndex={0}
                onKeyDown={(event) =>
                  event.key === 'Enter' && updateValue(option)
                }
                onClick={(e) => {
                  e.preventDefault();
                  updateValue(option);
                }}
                onFocus={() => option.ref?.current?.classList.add('focus')}
                onBlur={() => option.ref?.current?.classList.remove('focus')}
              >
                <BaSeCheckbox
                  align="top-left"
                  isInteractive={false}
                  label={option.label}
                  color={color}
                  size="medium"
                  checked={selectedValuesId.indexOf(option.id) > -1}
                />
              </WrapperOption>
            ))}
          </WrapperOptions>
        </>
      )}
      {!!complement && (
        <WrapperHelper>
          <BaSeHelperText
            isItalic={true}
            size="medium"
            color={mapBaSeSelectColor[colorType].color}
            label={complement}
          />
        </WrapperHelper>
      )}
      {!!moreInfoLabel && (
        <MoreInfoContainer>
          <BaSeHelperText
            size="small"
            label={moreInfoLabel}
            details={moreInfoDetails}
            color={bgC}
          />
        </MoreInfoContainer>
      )}
    </WrapperSelect>
  );
};

BaSeMultipleSelect.displayName = 'BaSeMultipleSelect';
