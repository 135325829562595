import {
  BaSeButton,
  BaSeDialogContext,
  BaSeGrid,
  BaSeGridItem,
  BaSeHeading4,
  BaSeInput,
  BaSeOptionPicker,
  BaSeParagraph,
  BaSeSelect,
  BaSeTheme,
} from '@base/react';
import * as React from 'react';
import { CustomResultSearchCard } from '../../components/custom-result-search-card';
import { useInstituicoesFinanceirasService } from '../../hooks/instituicoes-financeiras-service';
import { useOriginInformationService } from '../../hooks/origin-information-service';
import { usePorteService } from '../../hooks/pessoa-juridicas-service';
import { useSolicitacoesService } from '../../hooks/solicitacoes-service';
import { useUf } from '../../hooks/uf-service';
import { messageError } from '../../hooks/utils/errors';
import { formatDateINTL, useFormatCnpjCpf } from '../../hooks/utils/utils';
import { Form, PageContent } from './report-styled';

const BindClientIF = () => {
  const { getRecuperarUfsUsuarioAutenticado } = useUf();
  const { getListOriginInformationRequest } = useOriginInformationService();
  const { getRecuperaPorte } = usePorteService();
  const {
    getFinalidades,
    getFluxoRelacionamento,
    getFluxoRelacionamentoAgrupado,
    putVincular,
  } = useSolicitacoesService();
  const { getRecuperarIfs } = useInstituicoesFinanceirasService();

  const { showModal } = React.useContext(BaSeDialogContext);

  const { showNotification } = React.useContext(BaSeDialogContext);
  const { formatedCnpjCpf, unformatCnpjCpf } = useFormatCnpjCpf();

  const [loading, setLoading] = React.useState(false);
  const [cnpj, setCnpj] = React.useState('');
  const [municipio, setMunicipio] = React.useState('');
  const [dataInicialOperacao, setDataInicialOperacao] = React.useState('');
  const [dataFinalOperacao, setDataFinalOperacao] = React.useState('');
  const [ufKey, setUfKey] = React.useState(0);
  const [institutionName, setInstitutionName] = React.useState('');
  const [setInstitutionNameAgrupada] = React.useState('');

  const [ufs, setUfs] = React.useState([]);
  const [ufSiglaPj, setUfSiglaPj] = React.useState('');
  const [searchResult, setSearchResult] = React.useState(false);
  const [quantityRequests, setQuantityRequests] = React.useState('');
  const [fluxoAgrupado, setFluxoAgrupado] = React.useState('');
  const [finalidade, setFinalidade] = React.useState([]);
  const [finalidadeRequest, setFinalidadeRequest] = React.useState([]);
  const [porte, setPorte] = React.useState([]);
  const [porteRequest, setPorteRequest] = React.useState([]);
  const [fonteLead, setFonteLead] = React.useState([]);
  const [fonteLeadRequest, setFonteLeadRequest] = React.useState([]);
  const [recuperaIfModal, setRecuperaIfModal] = React.useState([]);
  const [recuperaIfRequestModal, setRecuperaIfRequestModal] = React.useState(
    [],
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const params = React.useCallback(() => {
    const data = {
      pageIndex: 0,
      pageSize: 10,
      sortField: 'id',
      sortType: 'DESC',
      ufSigla: ufSiglaPj || undefined,
      municipioCodigoIbge: municipio || undefined,
      cnpj: unformatCnpjCpf(cnpj) || undefined,
      dataInicialRecebimentoSolicitacao: dataInicialOperacao
        ? formatDateINTL(dataInicialOperacao)
        : undefined,
      dataFimRecebimentoSolicitacao: dataFinalOperacao
        ? formatDateINTL(dataFinalOperacao)
        : undefined,
      finalidadeSolicitacaoId:
        finalidadeRequest.length > 0
          ? finalidadeRequest.map((item) => item.id).join(',')
          : undefined,
      origemInformacaoId:
        fonteLeadRequest.length > 0
          ? fonteLeadRequest.map((item) => item.id).join(',')
          : undefined,
      pessoaJuridicaPorte:
        porteRequest.length > 0
          ? porteRequest.map((item) => item.label).join(',')
          : undefined,
    };
    return data;
  }, [
    ufSiglaPj,
    municipio,
    unformatCnpjCpf,
    cnpj,
    dataInicialOperacao,
    dataFinalOperacao,
    finalidadeRequest,
    fonteLeadRequest,
    porteRequest,
  ]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const search = React.useCallback(() => {
    setLoading(true);
    setSearchResult(true);

    Promise.all([
      getFluxoRelacionamento(params()),
      getFluxoRelacionamentoAgrupado(params()),
    ])
      .then(([response, responseAgrupado]) => {
        setQuantityRequests(response);

        if (response.items && response.items.length > 0) {
          const name = response.items[0].instituicaoFinanceiraNome;
          setInstitutionName(name);
        }

        setFluxoAgrupado(responseAgrupado);

        if (responseAgrupado.items && responseAgrupado.items.length > 0) {
          const agrupadoName =
            responseAgrupado.items[0].instituicaoFinanceiraNome;
          setInstitutionNameAgrupada(agrupadoName);

          const agrupadoData = responseAgrupado.items.map((item) => ({
            instituicaoFinanceiraNome: item.instituicaoFinanceiraNome,
            quantidade: item.qtdSolicitacoes,
          }));
          setRecuperaIfModal(agrupadoData);
        }
      })
      .catch((error) =>
        showNotification({
          message: 'Erro ao efetuar pesquisa',
          color: 'destructive',
          supportMessage: messageError(error.statusCode),
        }),
      )
      .finally(() => setLoading(false));
  }, [
    getFluxoRelacionamento,
    getFluxoRelacionamentoAgrupado,
    params,
    setInstitutionNameAgrupada,
    showNotification,
  ]);

  const clear = React.useCallback(() => {
    setCnpj('');
    setUfSiglaPj('');
    setMunicipio('');
    setSearchResult(false);
    setDataInicialOperacao('');
    setDataFinalOperacao('');
    setQuantityRequests('');
    setFonteLeadRequest([]);
    setFinalidadeRequest([]);
    setPorteRequest([]);
    setUfKey((prevKey) => prevKey + 1);
  }, []);

  React.useEffect(() => {
    getListOriginInformationRequest()
      .then((list) => {
        setFonteLead(
          list.map((item) => ({
            id: item.id,
            label: item.descricao,
          })),
        );
      })
      .catch((error) =>
        showNotification({
          message: 'Erro ao recuperar a lista de perfis',
          color: 'destructive',
          supportMessage: messageError(error.statusCode),
        }),
      );
  }, [getListOriginInformationRequest, showNotification]);

  React.useEffect(() => {
    getRecuperarIfs()
      .then((list) => {
        setRecuperaIfModal(
          list.map((item) => ({
            id: item.id,
            label: item.nome,
          })),
        );
      })
      .catch((error) =>
        showNotification({
          message: 'Erro ao recuperar a lista de IFs',
          color: 'destructive',
          supportMessage: messageError(error.statusCode),
        }),
      );
  }, [getRecuperarIfs, showNotification]);

  React.useEffect(() => {
    getFinalidades()
      .then((listFinalidade) => {
        setFinalidade(
          listFinalidade.map((item) => ({
            id: item.id,
            label: item.descricao,
          })),
        );
      })
      .catch((error) =>
        showNotification({
          message: 'Erro ao recuperar a lista de Finalidade',
          color: 'destructive',
          supportMessage: messageError(error.statusCode),
        }),
      );
  }, [getFinalidades, showNotification]);

  React.useEffect(() => {
    let isMounted = true;

    getRecuperaPorte()
      .then((listPorte) => {
        if (isMounted) {
          setPorte(
            listPorte.map((descricao, index) => ({
              label: descricao,
              id: index.toString(),
            })),
          );
        }
      })
      .catch((error) =>
        showNotification({
          message: 'Erro ao recuperar a lista de Porte',
          color: 'destructive',
          supportMessage: messageError(error.statusCode),
        }),
      );

    return () => {
      isMounted = false;
    };
  }, [getRecuperaPorte, showNotification]);

  React.useEffect(() => {
    getRecuperarUfsUsuarioAutenticado()
      .then((listUfs) => {
        setUfs(
          listUfs.map((item) => ({
            id: item.id,
            label: item.sigla,
            sigla: item.sigla,
          })),
        );
        if (listUfs && listUfs.length === 1) {
          setUfSiglaPj(listUfs[0].id);
        }
      })
      .catch((error) =>
        showNotification({
          message: 'Erro ao recuperar a lista de Ufs do Usuário',
          color: 'destructive',
          supportMessage: messageError(error.statusCode),
        }),
      );
  }, [getRecuperarUfsUsuarioAutenticado, showNotification]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const refetchSearchResults = React.useCallback(() => {
    setLoading(true);

    Promise.all([
      getFluxoRelacionamento(params()),
      getFluxoRelacionamentoAgrupado(params()),
    ])
      .then(([response, responseAgrupado]) => {
        setQuantityRequests(response);

        if (response.items && response.items.length > 0) {
          const name = response.items[0].instituicaoFinanceiraNome;
          setInstitutionName(name);
        }

        setFluxoAgrupado(responseAgrupado);

        if (responseAgrupado.items && responseAgrupado.items.length > 0) {
          const agrupadoName =
            responseAgrupado.items[0].instituicaoFinanceiraNome;
          setInstitutionNameAgrupada(agrupadoName);

          const agrupadoData = responseAgrupado.items.map((item) => ({
            instituicaoFinanceiraNome: item.instituicaoFinanceiraNome,
            quantidade: item.qtdSolicitacoes,
          }));
          setRecuperaIfModal(agrupadoData);
        }
      })
      .catch((error) =>
        showNotification({
          message: 'Erro ao efetuar pesquisa',
          color: 'destructive',
          supportMessage: messageError(error.statusCode),
        }),
      )
      .finally(() => setLoading(false));
  }, [
    getFluxoRelacionamento,
    getFluxoRelacionamentoAgrupado,
    params,
    setInstitutionNameAgrupada,
    showNotification,
  ]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleShowModalVincular = () => {
    const additionalParams = params();
    let selectedIFId = null;

    const handleVincular = () => {
      if (!selectedIFId) {
        showNotification({
          message: 'Nenhuma Instituição Financeira selecionada.',
          color: 'attention',
        });
        return;
      }

      const vinculoDTO = {
        instituicaoFinanceiraId: selectedIFId,
      };

      const queryParamsObj = {
        ufSigla: additionalParams.ufSigla,
        municipioCodigoIbge: additionalParams.municipioCodigoIbge,
        cnpj: additionalParams.cnpj,
        dataInicialRecebimentoSolicitacao:
          additionalParams.dataInicialRecebimentoSolicitacao,
        dataFimRecebimentoSolicitacao:
          additionalParams.dataFimRecebimentoSolicitacao,
        finalidadeSolicitacaoId: additionalParams.finalidadeSolicitacaoId,
        origemInformacaoId: additionalParams.origemInformacaoId,
        pessoaJuridicaPorte: additionalParams.pessoaJuridicaPorte,
      };

      const queryParams = new URLSearchParams(
        Object.fromEntries(
          Object.entries(queryParamsObj).filter(([_, v]) => v != null),
        ),
      ).toString();

      setLoading(true);
      putVincular(vinculoDTO, queryParams)
        .then(() => {
          showNotification({
            message: 'Vínculo efetuado com sucesso!',
            color: 'confirmation',
          });
          refetchSearchResults();
        })
        .catch((error) => {
          showNotification({
            message: 'Erro ao efetuar vínculo',
            color: 'attention',
            supportMessage: messageError(error.statusCode),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    showModal({
      actions: ['Vincular', 'Cancelar'],
      size: 'medium',
      title: 'Selecionar IF',
      content: () => (
        <>
          <BaSeParagraph
            isBold
            color={BaSeTheme.colors.institucionais.cinzaSebrae45}
          >
            {quantityRequests.total}{' '}
            {quantityRequests.total > 1
              ? 'clientes selecionados'
              : 'cliente selecionado'}
          </BaSeParagraph>
          <br />
          <BaSeGridItem tablet={{ size: 2 }}>
            <BaSeOptionPicker
              label="Selecione a IF para vincular o cliente"
              maxMenuHeight="100px"
              color="default"
              initialSelectedValuesId={recuperaIfRequestModal.map(
                (item) => item.id,
              )}
              maxOptionsSelected={1}
              enableSelectAll={true}
              values={recuperaIfModal}
              onChange={(values) => {
                selectedIFId = values.length > 0 ? values[0].id : null;
                setRecuperaIfRequestModal(values);
              }}
            />
          </BaSeGridItem>
        </>
      ),
      onChooseAction: (action) => {
        if (action === 'Vincular') {
          handleVincular();
        }
      },
    });
  };

  return (
    <PageContent>
      <BaSeGrid>
        <BaSeGridItem tablet={{ size: 9 }}>
          <BaSeHeading4
            color={BaSeTheme.colors.institucionais.cinzaSebrae45}
            isBold
          >
            Vincular clientes a uma IF
          </BaSeHeading4>
        </BaSeGridItem>
      </BaSeGrid>

      <br />
      <BaSeParagraph
        isItalic
        color={BaSeTheme.colors.institucionais.cinzaSebrae45}
      >
        Pesquise clientes para vinculá-los a uma IF específica. Você pode
        filtrar por UF,
      </BaSeParagraph>
      <BaSeParagraph
        isItalic
        color={BaSeTheme.colors.institucionais.cinzaSebrae45}
      >
        munícipio, data de solicitação, finalidade, fonte do lead e porte.
      </BaSeParagraph>
      <br />
      <br />
      <BaSeParagraph color={BaSeTheme.colors.institucionais.cinzaSebrae45}>
        Selecione clientes para vinculá-los a uma IF.
      </BaSeParagraph>
      <Form>
        <BaSeGrid>
          <BaSeGridItem tablet={{ size: 2 }}>
            <BaSeSelect
              key={ufKey}
              label="UF"
              values={ufs}
              selectedValueId={ufSiglaPj}
              removeEmptyValue={ufs && ufs.length === 1}
              onChange={(value) => {
                setUfSiglaPj(value.sigla);
              }}
            />
          </BaSeGridItem>
          <BaSeGridItem tablet={{ size: 4 }}>
            <BaSeInput
              label="Município"
              value={municipio}
              onChange={(value) => setMunicipio(value)}
            />
          </BaSeGridItem>
        </BaSeGrid>
        <br />
        <BaSeGrid>
          <BaSeGridItem tablet={{ size: 5 }}>
            <BaSeInput
              label="CNPJ"
              subLabel="(Apenas números)"
              value={cnpj}
              onChange={(value) => setCnpj(formatedCnpjCpf(value))}
            />
          </BaSeGridItem>
        </BaSeGrid>
        <br />
        <BaSeParagraph color={BaSeTheme.colors.institucionais.cinzaSebrae45}>
          Data da solicitação
        </BaSeParagraph>
        <br />
        <BaSeGrid>
          <BaSeGridItem tablet={{ size: 2 }}>
            <BaSeInput
              label="Data inicio"
              mask="date"
              iconButton={{
                name: 'calendar-alt',
                type: 'primary',
                color: BaSeTheme.colors.institucionais.azulSebrae36,
                typeButton: 'base-shape-button',
              }}
              value={dataInicialOperacao}
              onChange={(value) => {
                setDataInicialOperacao(value);
              }}
            />
          </BaSeGridItem>
          <BaSeGridItem tablet={{ size: 2 }}>
            <BaSeInput
              label="Data final"
              mask="date"
              iconButton={{
                name: 'calendar-alt',
                type: 'primary',
                color: BaSeTheme.colors.institucionais.azulSebrae36,
                typeButton: 'base-shape-button',
              }}
              value={dataFinalOperacao}
              onChange={(value) => {
                setDataFinalOperacao(value);
              }}
            />
          </BaSeGridItem>
        </BaSeGrid>
        <br />
        <BaSeGrid>
          <BaSeGridItem tablet={{ size: 2 }}>
            <BaSeOptionPicker
              label="Finalidade"
              maxMenuHeight="100px"
              color="default"
              initialSelectedValuesId={finalidadeRequest.map((item) => item.id)}
              maxOptionsSelected={1}
              enableSelectAll={false}
              values={finalidade}
              onChange={(selectedValues) => {
                setFinalidadeRequest(selectedValues);
              }}
            />
          </BaSeGridItem>
          <BaSeGridItem tablet={{ size: 2 }}>
            <BaSeOptionPicker
              label="Fonte do lead"
              maxMenuHeight="100px"
              color="default"
              initialSelectedValuesId={fonteLeadRequest.map((item) => item.id)}
              maxOptionsSelected={1}
              enableSelectAll={true}
              values={fonteLead}
              onChange={(values) => {
                setFonteLeadRequest(values);
              }}
            />
          </BaSeGridItem>
          <BaSeGridItem tablet={{ size: 2 }}>
            <BaSeOptionPicker
              label="Porte"
              maxMenuHeight="100px"
              color="default"
              initialSelectedValuesId={porteRequest.map((item) => item.id)}
              maxOptionsSelected={1}
              enableSelectAll={false}
              values={porte}
              onChange={(values) => {
                setPorteRequest(values);
              }}
            />
          </BaSeGridItem>
        </BaSeGrid>
      </Form>
      <BaSeGrid>
        <BaSeGridItem desktop={{ size: 1 }}>
          <BaSeButton
            value={'Pesquisar'}
            onClick={() => search()}
            isLoading={loading}
          />
        </BaSeGridItem>
        <BaSeGridItem desktop={{ size: 1 }}>
          <BaSeButton
            value={'Limpar'}
            onClick={() => clear()}
            type="secondary"
            color={BaSeTheme.colors.institucionais.azulSebrae36}
          />
        </BaSeGridItem>

        <BaSeGridItem>
          <CustomResultSearchCard
            searchResult={searchResult}
            loading={loading}
            quantity={quantityRequests.total}
            ifPrioritaria={institutionName}
            cnpj={cnpj}
            instituicao={recuperaIfModal.map((item) => item.label).join(', ')}
            agrupadoData={fluxoAgrupado}
            onSelectIF={() => handleShowModalVincular()}
          />
        </BaSeGridItem>
      </BaSeGrid>
    </PageContent>
  );
};

export { BindClientIF as default };
