import * as React from 'react';
import { BaSeAccordion } from '../../components/accordion/accordion';
import { BaSeI18nContext } from '../../contexts/i18n';
import { BaSeTheme } from '../../theme';
import { BaSeGrid } from '../layout/grid';
import { BaSeGridItem } from '../layout/grid-item';
import {
  CollapsedSubFooter,
  CollapsedUpperFooter,
  FooterContent,
  FooterLink,
  FooterLinks,
  FooterWrapper,
  Line,
  RelationFooter,
  Separator,
  SystemFooter,
  TextFooter,
  YearText,
} from './footer-styled';

import {
  BaSeDialogContext,
  BaSeDialogModalConfig,
} from '../../contexts/dialog';
import { useMenuItemsShrink } from '../../hooks/menu-items-shrink';
import {
  EventBreakpointType,
  ThemeBreakpointType,
} from '../../theme/theme-interface';

const SEBRAE_URL =
  'https://www.sebrae.com.br/sites/PortalSebrae/canais_adicionais/conheca_quemsomos';
const SEBRAE_TEL = '0800 570 0800';
const SEBRAE_TEL_URL = 'tel:08005700800';
const CURRENT_YEAR = new Date().getFullYear();

export interface FooterLink {
  modal?: BaSeDialogModalConfig;
  label: string;
  link?: string;
}

export interface FooterProps {
  links?: FooterLink[];
  collapsedHeader?: string;
  relationText?: string;
  relationLink?: FooterLink;
  systemName?: string;
  systemVersion?: string;
  collapsedHeaderColor?: string;
  backgroundColor?: string;
  textColor?: string;
  linkColor?: string;
  separatorColor?: string;
  versionColor?: string;
  collapsedBreakpoint?: EventBreakpointType;
  delimitedOnBreakpoint?: ThemeBreakpointType;
}

interface RelationFooterProps extends Required<Pick<FooterProps, 'linkColor'>> {
  menuDisplay: boolean;
  textColor: string;
  relationText: string;
  relationLink?: FooterLink;
}

interface ItemsFooterProps
  extends Required<Pick<FooterProps, 'linkColor' | 'links'>> {
  menuDisplay: boolean;
}

interface TextFooterProps
  extends Pick<ItemsFooterProps, 'menuDisplay'>,
    Required<
      Pick<
        FooterProps,
        'textColor' | 'versionColor' | 'systemName' | 'systemVersion'
      >
    > {}

const BaSeLinkFooter: React.FC<
  FooterLink & Required<Pick<FooterProps, 'linkColor'>>
> = ({ linkColor, modal, link, label }) => {
  const { showModal } = React.useContext(BaSeDialogContext);
  return (
    <FooterLink
      as={modal ? undefined : 'a'}
      linkColor={linkColor}
      onClick={() => (modal ? showModal(modal) : undefined)}
      href={link ?? undefined}
    >
      {' ' + label}
    </FooterLink>
  );
};

const BaSeRelationFooter: React.FC<RelationFooterProps> = ({
  menuDisplay,
  textColor,
  relationText,
  linkColor,
  relationLink,
}) => (
  <RelationFooter direction={menuDisplay ? 'column' : 'row'}>
    <YearText textColor={textColor}>
      {relationText}
      <BaSeLinkFooter
        linkColor={linkColor}
        label={relationLink?.label ?? SEBRAE_TEL}
        modal={relationLink?.modal}
        link={relationLink?.link ?? SEBRAE_TEL_URL}
      />
    </YearText>
  </RelationFooter>
);

const BaSeItemsFooter: React.FC<ItemsFooterProps> = ({
  menuDisplay,
  links,
  linkColor,
}) => (
  <FooterLinks direction={menuDisplay ? 'column' : 'row'}>
    {links.map((item) => {
      if (!item.modal && !item.link) {
        throw Error(`item '${item.label}' deve ter link ou modal`);
      }
      return (
        <BaSeLinkFooter
          linkColor={linkColor}
          key={item.label}
          label={item.label}
          link={item.link}
          modal={item.modal}
        />
      );
    })}
  </FooterLinks>
);

const BaSeTextFooter: React.FC<TextFooterProps> = ({
  menuDisplay,
  textColor,
  versionColor,
  systemName,
  systemVersion,
}) => {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const { sebrae, rights, version } = React.useMemo(
    () => ({
      sebrae: getMessage('system.sebrae'),
      rights: getMessage('system.rights'),
      version: getMessage('footer.system.version'),
    }),
    [getMessage],
  );

  return (
    <TextFooter direction={menuDisplay ? 'column' : 'row'}>
      <YearText textColor={textColor}>
        {sebrae} {CURRENT_YEAR} - {rights}
      </YearText>
      <SystemFooter versionColor={versionColor}>
        {systemName} {version} {systemVersion}
      </SystemFooter>
    </TextFooter>
  );
};

export const BaSeFooter: React.FC<FooterProps> = ({
  relationLink,
  relationText: relationTextExternal,
  links: linksExternal = [],
  collapsedHeader: collapsedHeaderExternal,
  systemName = '',
  systemVersion = '',
  collapsedHeaderColor = BaSeTheme.colors.institucionais.azulSebrae36,
  backgroundColor = BaSeTheme.colors.institucionais.cinzaSebrae97,
  linkColor = BaSeTheme.colors.institucionais.azulSebrae36,
  textColor = BaSeTheme.colors.institucionais.cinzaSebrae60,
  versionColor = BaSeTheme.colors.institucionais.cinzaSebrae75,
  separatorColor = BaSeTheme.colors.gestao.cinza85,
  collapsedBreakpoint = 'onTablet',
  delimitedOnBreakpoint = 'desktop',
}) => {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const relationText = React.useMemo(
    () => relationTextExternal ?? getMessage('footer.relationText'),
    [relationTextExternal],
  );

  const links = React.useMemo(
    () =>
      linksExternal.length === 0
        ? [
            {
              label: getMessage('footer.collapsedHeader'),
              link: SEBRAE_URL,
            },
          ]
        : linksExternal,
    [linksExternal],
  );

  const collapsedHeader = React.useMemo(
    () => collapsedHeaderExternal ?? links[0].label ?? '',
    [collapsedHeaderExternal, links],
  );

  const [menuDisplay, setMenuDisplay] = React.useState(false);

  useMenuItemsShrink(setMenuDisplay, collapsedBreakpoint);

  return (
    <FooterWrapper backgroundColor={backgroundColor}>
      {!menuDisplay ? (
        <FooterContent>
          <BaSeGrid delimitedOnBreakpoint={delimitedOnBreakpoint}>
            <BaSeGridItem size={12}>
              <TextFooter direction={menuDisplay ? 'column' : 'row'}>
                <BaSeItemsFooter
                  menuDisplay={menuDisplay}
                  links={links}
                  linkColor={linkColor}
                />
                <BaSeRelationFooter
                  linkColor={linkColor}
                  menuDisplay={menuDisplay}
                  textColor={textColor}
                  relationText={relationText}
                  relationLink={relationLink}
                />
              </TextFooter>
            </BaSeGridItem>
          </BaSeGrid>
          <Line separatorColor={separatorColor} />
          <BaSeGrid delimitedOnBreakpoint={delimitedOnBreakpoint}>
            <BaSeGridItem size={12}>
              <BaSeTextFooter
                menuDisplay={menuDisplay}
                systemName={systemName}
                systemVersion={systemVersion}
                textColor={textColor}
                versionColor={versionColor}
              />
            </BaSeGridItem>
          </BaSeGrid>
        </FooterContent>
      ) : (
        <>
          <CollapsedUpperFooter>
            <BaSeAccordion
              options={[
                {
                  content: (
                    <>
                      <BaSeItemsFooter
                        menuDisplay={menuDisplay}
                        links={links}
                        linkColor={linkColor}
                      />
                      <Separator separatorColor={separatorColor} />
                    </>
                  ),
                  title: collapsedHeader,
                },
              ]}
              backgroundTitleColor={collapsedHeaderColor}
              hasContentBorder={false}
              titleColor={BaSeTheme.colors.defaultColors.white}
              // borderTitleColor={collapsedHeaderColor}
              backgroundContentColor={backgroundColor}
              shadow="none"
            />
          </CollapsedUpperFooter>
          <CollapsedSubFooter>
            <BaSeRelationFooter
              linkColor={linkColor}
              menuDisplay={menuDisplay}
              textColor={textColor}
              relationText={relationText}
              relationLink={relationLink}
            />
            <BaSeTextFooter
              menuDisplay={menuDisplay}
              systemName={systemName}
              systemVersion={systemVersion}
              textColor={textColor}
              versionColor={versionColor}
            />
          </CollapsedSubFooter>
        </>
      )}
    </FooterWrapper>
  );
};

BaSeFooter.displayName = 'BaSeFooter';
