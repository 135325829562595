export const decimalValidator = (value: string) => {
  value = String(value);
  if (!value) {
    return false;
  }
  let decimal;
  let real;
  let decimalValid;
  let realValid;
  if (value.includes(',')) {
    decimal = value.split(',')[1];
    real = value?.split(',')[0];
    decimalValid = !!decimal?.length;
  } else {
    real = value;
    decimalValid = true;
  }
  if (real.includes('.')) {
    realValid = real
      .split('.')
      .map((val, index) => {
        if (!index) {
          return val.length <= 3;
        }
        return val.length === 3;
      })
      .every((bool) => !!bool);
  } else {
    realValid = true;
  }
  return realValid && decimalValid;
};
