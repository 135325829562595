import { isMatch, isValid, parse } from 'date-fns';

export interface DateHandleArgs<Success, Failure> {
  value?: Date | string | number;
  failure(): Failure;
  success(validatedDate: Date): Success;
}

export function dateHandle<Success, Failure>({
  value,
  failure,
  success,
}: DateHandleArgs<Success, Failure>): Success | Failure {
  if (!value) {
    return failure();
  }
  if (value instanceof Date || typeof value === 'number') {
    return isValid(value) ? success(new Date(value)) : failure();
  }

  const valueToMatch = value.replace(/\D/g, '');

  if (valueToMatch.length < value.length - 2) {
    return failure();
  }

  const [first] = value.split(/\D/);

  const patterns = {
    4: ['yyyyMMdd', 'yyyyMdd', 'yyyyMMd', 'yyyyMd'],
    2: ['ddMMyyyy', 'ddMyyyy'],
    1: ['dMMyyyy', 'dMyyyy'],
  };

  for (const pattern of patterns[first.length] ?? []) {
    if (!isMatch(valueToMatch, pattern)) {
      continue;
    }
    const parsedDate = parse(valueToMatch, pattern, new Date());
    return success(parsedDate);
  }
  return failure();
}
