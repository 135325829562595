import { BaSeHeader, BaSeHeading5, BaSeTheme } from '@base/react';
import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';

export const LayoutHeader = () => {
  const { keycloak, isAuthenticated } = Sebrae.useAuth();

  const TitleHeader = () => (
    <div
      style={{
        width: '100%',
      }}
    >
      <BaSeHeading5 isBold color={BaSeTheme.colors.defaultColors.white}>
        Sistema Acompanha
      </BaSeHeading5>
    </div>
  );

  return (
    <BaSeHeader
      delimitedOnBreakpoint={isAuthenticated ? undefined : 'desktop'}
      accountProfileProps={{ keycloak }}
      customComponents={[
        {
          component: () => <TitleHeader />,
          showOnExpanded: true,
          showOnCollapsed: true,
        },
      ]}
    />
  );
};
