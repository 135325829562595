import * as React from 'react';
export interface StepRenderContentProps {
  gotoStep: (step: number) => void;
  gotoPrev: () => void;
  gotoNext: () => void;
  canGotoPrev: boolean;
  canGotoNext: boolean;
}

export interface StepContentProps
  extends Pick<
    StepRenderContentProps,
    'canGotoNext' | 'canGotoPrev' | 'gotoStep'
  > {
  content: (props: StepRenderContentProps) => JSX.Element;
  onNext: () => void;
  onPrev: () => void;
}

export const BaSeStepContent: React.FC<StepContentProps> = ({
  canGotoNext,
  canGotoPrev,
  content,
  onNext,
  onPrev,
  gotoStep,
}) => (
  <>
    {content({
      gotoPrev: onPrev,
      gotoNext: onNext,
      canGotoPrev,
      canGotoNext,
      gotoStep,
    })}
  </>
);

BaSeStepContent.displayName = 'BaSeStepContent';
