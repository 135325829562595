import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../utils/string-utils';
import { BaSeTheme } from '../../theme';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';
import { SingleItemStyledProps } from './action-item-props';

export interface ActionItemSingleItemProps {
  customColor: SingleItemStyledProps;
}

const handleNumberString = (val: string | number) => numberWithUnit(val);

export const ActionItemWrapper = styled.div<{ relative: boolean }>`
  ${(props) =>
    props.relative
      ? css`
          position: relative;
          display: inline-block;
        `
      : css`
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        `}
`;

export const ActionItemContainer = styled.div<{
  minWidth: string | number;
  addStyles: string;
}>`
  ${(props) => css`
    min-width: ${handleNumberString(props.minWidth)};
  `}
  ${(props) => props.addStyles};
  position: absolute;
  background-color: ${BaSeTheme.colors.defaultColors.white};
  border: 1px solid ${ColorMapper.disabled.foreground};
  box-sizing: border-box;
  box-shadow: ${BaSeTheme.shadows.profundidade2};
  border-radius: 4px;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  max-height: 0;
  overflow-y: hidden;
  visibility: hidden;
  z-index: ${BaSeTheme.stackOrder.belowOverlay};

  &.BaSe--action-item-open {
    max-height: 1000vh;
    visibility: visible;
  }
`;

export const ActionItemAccordionContent = styled.div`
  padding-left: 10px;
`;

export const ActionItemListWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 8px 0;

  > .BaSe--accordion-content,
  .BaSe--accordion {
    overflow: hidden;
  }
`;

export const ActionItemTitleAccordionContainer = styled.div<ActionItemSingleItemProps>`
  cursor: pointer;
  padding: 8px 16px;

  display: flex;
  align-items: center;
  border: none;
  outline: none;
  margin: 0;
  box-sizing: border-box;
  gap: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: ${BaSeTheme.typography.lineHeightBase};

  img {
    width: 20px;
  }
`;

export const ActionItemSingleItem = styled.button<ActionItemSingleItemProps>`
  ${({ customColor }) => css`
    font-family: ${BaSeTheme.typography.fontFamily};
    font-size: ${BaSeTheme.typography.fontSizeBase};
    line-height: ${BaSeTheme.typography.lineHeightBase};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 8px 16px;

    display: flex;
    align-items: center;
    background-color: ${customColor?.backgroundColor};
    border: none;
    outline: none;
    cursor: ${customColor?.disabled ? 'not-allowed' : 'pointer'};
    margin: 0;
    box-sizing: border-box;
    gap: 14px;
    will-change: background-color;

    &,
    span {
      transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
    }

    img {
      width: 20px;
    }

    ${customColor?.hoverBackgroundColor &&
    css`
      &:hover,
      &:focus {
        background-color: ${customColor?.hoverBackgroundColor};
      }

      &:focus span {
        font-weight: bold;
      }
    `}
  `}
`;

export const ButtonWrapperRelative = styled.div`
  position: relative;
`;
