import * as React from 'react';

export interface GotoQuery {
  withIndex?: number;
  withTitle?: string;
}
interface ContentProps {
  canGotoTab: (query: GotoQuery) => boolean;
  gotoTab: (query: GotoQuery) => void;
}
export interface TabContentProps extends ContentProps {
  content: (contentProps: ContentProps) => JSX.Element;
}

export const BaSeTabContent: React.FC<TabContentProps> = React.memo(
  ({ canGotoTab, gotoTab, content }) => content?.({ canGotoTab, gotoTab }),
);

BaSeTabContent.displayName = 'BaSeTabContent';
