import { BaSeTheme } from '@base/react';
import styled from 'styled-components';

export const Content = styled.div`
  @media (max-width: 769px) {
    padding: 0px 20px 0px 20px;
  }
  padding: 40px 0 40px 85px;
`;

export const Content2 = styled.div`
  @media (max-width: 769px) {
    padding: 0px 20px 0px 20px;
    background: none;
  }
  padding: 0px 90px;
  background: linear-gradient(
    to right,
    ${BaSeTheme.colors.defaultColors.white} 40%,
    ${BaSeTheme.colors.institucionais.azulSebrae90} 70%
  );
`;

export const BannerHome = styled.div`
  @media (max-width: 769px) {
    height: 370px;
  }
  max-width: 100%;
  height: 277px;
  background: linear-gradient(89.91deg, #2d66b7 8.27%, #8fb8ef 66.51%);
`;

export const TitleBanner = styled.div`
  @media (max-width: 769px) {
    margin-left: auto;
    margin-top: 30px;
  }
  margin-left: 65px;
`;

export const CircleIcon = styled.div`
  border: 3px solid;
  border-color: ${BaSeTheme.colors.institucionais.azulSebrae75};
  width: 67px;
  height: 67px;
  box-sizing: border-box;
  border-radius: 50%;
  div {
    margin: '20px';
  }
`;

export const CenterIcon = styled.div`
  margin: '16px';
`;

export const Br = styled.div`
  margin-top: 20px;
`;

export const ListIconsContent = styled(Br)`
  display: flex;
  align-items: center;
  @media (max-width: 769px) {
    margin-left: -10px;
  }
`;

export const JustifyCenterIcon = styled.div`
  margin: 17px;
`;

export const TitleServices = styled.div`
  @media (max-width: 980px) {
    min-width: 80px;
  }
  margin: 20px;
  min-width: 130px;
`;

export const ImageContent = styled.div`
  @media (max-width: 769px) {
    margin: 52px;
  }
  margin-top: 105px;
  width: 70%;
  display: flex;
  justify-content: center;
`;

export const InfoContent = styled.div`
  @media (max-width: 767px) {
    padding: 370px 0 0 0;
  }
  padding: 30px 0 30px 100px;
  height: 700px;
`;

export const ListInfoContent = styled.div`
  padding: 30px;
`;

export const Marker = styled.ul`
  list-style: disc;
  color: ${BaSeTheme.colors.institucionais.azulSebrae36};
`;
