import styled, { css, keyframes } from 'styled-components';
import { transformLightness } from '../../utils/color-transformation/transform-lightness';
import { transformToFilter } from '../../utils/color-transformation/transform-to-filter';
import { BaSeTheme } from './../../theme/index';
import { ThemeColorValue } from './../../theme/theme-interface';

function lighting(color: string, lightness: number): string {
  return transformLightness(color, lightness);
}

function darking(color: string): string {
  return transformLightness(color, 0.3);
}

function getFilter(color: string): string {
  return transformToFilter(color);
}

export type TypeTransitionProps = 'leftToRight' | 'rightToLeft' | 'inert';
export interface StepTransitionProps {
  transition: TypeTransitionProps;
}
export interface StepOrientationProps {
  orientation?: 'horizontal' | 'vertical';
}
export interface StepActionProps {
  isClickable?: boolean;
}

export interface StepBehaviorProps {
  isActive?: boolean;
  isCompleted?: boolean;
  withError?: boolean;
}
export interface StepColorProps {
  activeColor?: ThemeColorValue;
  completedColor?: ThemeColorValue;
  uncompletedColor?: ThemeColorValue;

  activeWithErrorColor?: ThemeColorValue;
  uncompletedWithErrorColor?: ThemeColorValue;
  completedWithErrorColor?: ThemeColorValue;
}

type StepperProps = StepOrientationProps &
  StepColorProps &
  StepActionProps &
  StepBehaviorProps;

const slideIn = (origin: number) => keyframes`
  from {
    transform: translateX(${origin}%);
    opacity: 0.9;
  }
  to {
    opacity: 1;
  }
`;

const fade = (from: number = 0) => keyframes`
  from {
    opacity: ${from};
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const StepperContainer = styled.div.attrs({
  className: 'BaSe--stepper',
})<StepOrientationProps>`
  display: flex;
  gap: 30px;
  flex-direction: ${({ orientation }) =>
    orientation === 'horizontal' ? 'column' : 'row'};
`;

export const StepsContainer = styled.ul.attrs({
  className: 'BaSe--stepper-container',
})<StepOrientationProps>`
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  gap: 10px;
  margin: 0;
  padding: 0;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  flex-direction: ${({ orientation }) =>
    orientation === 'horizontal' ? 'row' : 'column'};
`;

export const StepContainer = styled.li.attrs({
  className: 'BaSe--stepper-header',
})<StepperProps>`
  flex: 1;
  display: grid;
  place-items: ${({ orientation }) =>
    orientation === 'horizontal' ? 'center' : 'start'};
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    ${({ orientation }) =>
      orientation === 'horizontal'
        ? css`
            transform: translateX(20px);
            width: calc(100% - 30px);
            height: 1.5px;
            top: 19px;
            left: 50%;
          `
        : css`
            width: 1.5px;
            height: calc(100% - 31px);
            transform: translateY(24px);
            top: 15px;
            left: 24px;
          `}
    z-index: ${BaSeTheme.stackOrder.normal + 1};
  }

  &::before {
    background-color: ${({
      withError,
      isActive,
      isCompleted,
      completedColor,
      completedWithErrorColor,
      uncompletedColor,
      uncompletedWithErrorColor,
      activeColor,
      activeWithErrorColor,
    }) =>
      isActive
        ? withError
          ? activeWithErrorColor
          : activeColor
        : lighting(
            (isCompleted
              ? withError
                ? completedWithErrorColor
                : completedColor
              : withError
                ? uncompletedWithErrorColor
                : uncompletedColor) as string,
            0.75,
          )};

    ${({ orientation, isActive, isCompleted }) =>
      orientation === 'horizontal'
        ? css`
            width: ${!isCompleted || isActive ? 'calc(100% - 30px)' : '0'};
          `
        : css`
            height: ${!isCompleted || isActive ? 'calc(100% - 31px)' : '0'};
          `}
  }

  &::after {
    transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
    background-color: ${({
      withError,
      isCompleted,
      completedColor,
      completedWithErrorColor,
      uncompletedColor,
      uncompletedWithErrorColor,
    }) =>
      lighting(
        (isCompleted
          ? withError
            ? completedWithErrorColor
            : completedColor
          : withError
            ? uncompletedWithErrorColor
            : uncompletedColor) as string,
        0.75,
      )};

    ${({ orientation, isCompleted }) =>
      orientation === 'horizontal'
        ? css`
            width: ${isCompleted ? 'calc(100% - 30px)' : '0'};
          `
        : css`
            height: ${isCompleted ? 'calc(100% - 31px)' : '0'};
          `}
    will-change: width;
  }
  &:last-child::before,
  &:last-child::after {
    content: none;
  }
`;

export const StepItem = styled.button<StepperProps>`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: ${BaSeTheme.typography.lineHeightBase};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  background: ${BaSeTheme.colors.defaultColors.transparent};
  border: none;
  cursor: ${({ disabled, isClickable }) =>
    disabled ? 'not-allowed' : isClickable ? 'pointer' : 'default'};
  ${({ isClickable }) =>
    !isClickable &&
    css`
      pointer-events: none;
    `};
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover,
  &:focus {
    outline: none;
  }

  ${({ orientation }) =>
    orientation === 'horizontal'
      ? css`
          flex-direction: column;
          justify-content: center;
        `
      : css`
          flex-direction: row;
          justify-content: flex-start;
        `}

  ${({
    disabled,
    isClickable,
    withError,
    isActive,
    activeColor,
    activeWithErrorColor,
  }) =>
    !disabled &&
    !isActive &&
    isClickable &&
    css`
      &:focus:not(:hover) {
        div {
          background-color: ${lighting(
            (withError ? activeWithErrorColor : activeColor) as string,
            0.98,
          )};
        }
        span,
        small {
          color: ${withError ? activeWithErrorColor : activeColor};
          img {
            opacity: 0.9;
            animation: ${fade(0.9)} ${BaSeTheme.transitions.durationFast}
              ${BaSeTheme.transitions.durationFast} 0.5 forwards;
            filter: ${getFilter(
              (withError ? activeWithErrorColor : activeColor) as string,
            )};
          }
        }
      }

      &:hover {
        div {
          background: ${lighting(
            (withError ? activeWithErrorColor : activeColor) as string,
            0.97,
          )};
          border-color: ${darking(
            (withError ? activeWithErrorColor : activeColor) as string,
          )};
        }
        span,
        small {
          color: ${darking(
            (withError ? activeWithErrorColor : activeColor) as string,
          )};
          img {
            opacity: 0.9;
            animation: ${fade(0.9)} ${BaSeTheme.transitions.durationFast}
              ${BaSeTheme.transitions.durationFast} 0.5 forwards;
            filter: ${getFilter(
              darking(
                (withError ? activeWithErrorColor : activeColor) as string,
              ),
            )};
          }
        }
      }
    `};

  &:disabled {
    div {
      border-color: ${BaSeTheme.colors.institucionais.cinzaSebrae90};
      background: ${BaSeTheme.colors.institucionais.cinzaSebrae90};
    }
  }
  span {
    small {
      color: ${BaSeTheme.colors.institucionais.cinzaSebrae75};
    }
  }
`;

export const StepCircleHeader = styled.div<StepColorProps & StepBehaviorProps>`
  height: 32px;
  width: 32px;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  border: 2px solid ${BaSeTheme.colors.defaultColors.transparent};
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: ${BaSeTheme.colors.defaultColors.transparent};
  flex-basis: 50px;
  transition: ${`
    ${BaSeTheme.transitions.allPropertiesContinuousFast}
    ${BaSeTheme.transitions.durationFast}
  `};
  z-index: ${BaSeTheme.stackOrder.normal + 1};
  border-color: ${({
    withError,
    isActive,
    isCompleted,
    completedColor,
    completedWithErrorColor,
    uncompletedColor,
    uncompletedWithErrorColor,
    activeColor,
    activeWithErrorColor,
  }) =>
    isActive
      ? withError
        ? activeWithErrorColor
        : activeColor
      : lighting(
          (isCompleted
            ? withError
              ? completedWithErrorColor
              : completedColor
            : withError
              ? uncompletedWithErrorColor
              : uncompletedColor) as string,
          0.75,
        )};
`;

export const FadableItem = styled.span`
  opacity: 0;
  animation: ${fade()} ${BaSeTheme.transitions.durationFast}
    ${BaSeTheme.transitions.durationFast} 1 forwards;
`;

export const CustomTooltip = styled.div`
  width: 100%;
  visibility: hidden;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.5s ease;
`;

export const StepText = styled(FadableItem)<StepperProps>`
  text-align: ${({ orientation }) =>
    orientation === 'horizontal' ? 'center' : 'start'};

  & > div > [class*='BaSe'] {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    transition: ${`${BaSeTheme.transitions.allPropertiesContinuousFast} ${BaSeTheme.transitions.durationFast}`};

    color: ${({
      withError,
      isActive,
      isCompleted,
      activeColor,
      completedWithErrorColor,
      uncompletedColor: incompletedColor,
      uncompletedWithErrorColor: incompletedWithErrorColor,
      activeWithErrorColor,
      completedColor,
    }) =>
      isActive
        ? withError
          ? activeWithErrorColor
          : activeColor
        : isCompleted
          ? withError
            ? completedWithErrorColor
            : completedColor
          : withError
            ? incompletedWithErrorColor
            : incompletedColor};
  }

  &:hover + ${CustomTooltip} {
    visibility: visible;
    transition: opacity 0.5s ease;
    opacity: 1;
  }
`;

export const StepperContent = styled(FadableItem)<
  StepOrientationProps & StepTransitionProps
>`
  ${({ transition, orientation }) =>
    transition === 'inert' &&
    orientation === 'vertical' &&
    css`
      opacity: 1;
      animation: none;
    `}
  ${({ orientation }) =>
    orientation === 'vertical' &&
    css`
      padding-left: 64px;
    `}
`;

export const StepContentTransitionHorizontal = styled.div<StepTransitionProps>`
  ${({ transition }) =>
    transition !== 'inert' &&
    css`
      animation: ${slideIn(transition === 'leftToRight' ? -100 : 100)}
        ${BaSeTheme.transitions.durationFast}
        ${BaSeTheme.transitions.continuousTimingFunction};
    `};
`;
