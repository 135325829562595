import React from 'react';
import { BaSeTheme } from '../../theme';
import { BaSeButton, ButtonProps } from '../button/button/button';
import { BaSeHeading6 } from '../typography/heading/heading6';
import { BaSeParagraph } from '../typography/paragraph/paragraph';
import {
  BaSeShapeButton,
  ShapeButtonProps,
} from '../button/shape-button/shape-button';
import {
  PanelAppearenceProps,
  PanelContent,
  PanelHeader,
  PanelHeaderActions,
  PanelHeaderContent,
  PanelHeaderImage,
  PanelHeaderWrapper,
  PanelWrapper,
} from './panel-styled';

export interface RenderPanelAction {
  shapeButtons?: ShapeButtonProps[];
  buttons?: ButtonProps[];
}

interface PanelProps extends Partial<PanelAppearenceProps> {
  title: string;
  subTitle?: string;
  renderHeaderImage?(): JSX.Element;
  renderHeaderActions?(): JSX.Element | RenderPanelAction;
  renderContent(): JSX.Element;
}

const colors = BaSeTheme.colors;

export const BaSePanel: React.FC<PanelProps> = ({
  title,
  subTitle,
  height,
  width,
  borderRadius,
  shadowLevel = 0,
  isElevated = false,
  elevationOnHoverOrFocus = false,
  backgroundColor = colors.defaultColors.transparent,
  foregroundTitleColor = colors.institucionais.cinzaSebrae45,
  foregroundSubtitleColor = colors.institucionais.cinzaSebrae45,
  headerColor = colors.defaultColors.white,
  borderColor = colors.institucionais.cinzaSebrae90,
  renderHeaderImage,
  renderHeaderActions,
  renderContent,
}) => {
  const showHeaderImage = React.useMemo(
    () => typeof renderHeaderImage === 'function',
    [renderHeaderImage],
  );

  const showHeaderActions = React.useMemo(
    () => typeof renderHeaderActions === 'function',
    [renderHeaderActions],
  );

  const contentHeaderActions = React.useCallback(() => {
    if (!showHeaderActions) {
      return;
    }
    const rhc = renderHeaderActions?.();
    if (React.isValidElement(rhc)) {
      return <PanelHeaderActions>{rhc}</PanelHeaderActions>;
    }

    return (
      <PanelHeaderActions>
        {(rhc as RenderPanelAction)?.shapeButtons?.map((shapeButton) => (
          <BaSeShapeButton
            key={`${shapeButton.nameIcon}-${shapeButton.value}`}
            color={foregroundTitleColor}
            type="secondary"
            {...shapeButton}
          />
        ))}
        {(rhc as RenderPanelAction)?.buttons?.map((button) => (
          <BaSeButton
            key={`${button.leftIcon}-${button.value}-${button.rightIcon}`}
            color={foregroundTitleColor}
            type="secondary"
            {...button}
          />
        ))}
      </PanelHeaderActions>
    );
  }, [showHeaderActions, renderHeaderActions, foregroundTitleColor]);

  return (
    <PanelWrapper
      height={height}
      width={width}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      shadowLevel={shadowLevel}
      isElevated={isElevated}
      elevationOnHoverOrFocus={elevationOnHoverOrFocus}
    >
      <PanelHeader
        headerColor={headerColor}
        borderColor={borderColor}
        hasShadow={shadowLevel > 0}
      >
        <PanelHeaderWrapper>
          {showHeaderImage && (
            <PanelHeaderImage>{renderHeaderImage?.()}</PanelHeaderImage>
          )}
          <PanelHeaderContent>
            <BaSeHeading6 color={foregroundTitleColor} isBold={true}>
              {title}
            </BaSeHeading6>
            {subTitle && (
              <BaSeParagraph color={foregroundSubtitleColor} isItalic={true}>
                {subTitle}
              </BaSeParagraph>
            )}
          </PanelHeaderContent>
          {contentHeaderActions()}
        </PanelHeaderWrapper>
      </PanelHeader>
      <PanelContent>{renderContent()}</PanelContent>
    </PanelWrapper>
  );
};
