import Axios from 'axios';
import * as React from 'react';
import { RootContext } from '../contexts/root';
import { log } from '../utils/log';
import { StorageUtil } from '../utils/storage';

const cacheStore = StorageUtil.inTemporaryStore();
const axiosInstance = Axios.create();

export type EnvKey = string;
export type EnvValue =
  | string
  | number
  | boolean
  | EnvValue[]
  | { [key: string]: EnvValue };

export interface HookEnvInterface {
  getEnv<Env = EnvValue>(key: EnvKey): Promise<Env>;
}

const ENV_JSON_URL = '/env.json';

function keyInData<Env>(key: string, data?: any): data is Env {
  return key in data;
}

export function useEnv(): HookEnvInterface {
  const { appName, appVersion } = React.useContext(RootContext);

  const getEnv = React.useCallback(<Env = EnvValue>(key: EnvKey) => {
    const cacheKey = `× ENV × ${appName} @ ${appVersion}`;

    if (cacheStore.hasItem(cacheKey)) {
      const env = cacheStore.getItem(cacheKey);
      if (!env?.hasOwnProperty?.(key)) {
        return Promise.reject(`Variável "${key}" inexistente no ENV!`);
      }
      return Promise.resolve(env[key]);
    }

    const request = axiosInstance.get<Env>(ENV_JSON_URL);
    request.catch((error) =>
      log(
        'ENV',
        'Erro ao achar o env.json. Certifique-se que o arquivo está publicado.',
        error,
      ),
    );

    return request.then(({ data }) => {
      if (!keyInData<Env>(key, data)) {
        throw Error(`Variável "${key}" inexistente no ENV!`);
      }
      cacheStore.setItem(cacheKey, data);
      return data[key];
    });
  }, []);

  return {
    getEnv,
  };
}
