import styled from 'styled-components';
import { BaSeTheme } from '../../theme';

export const TAG_WIDTH_LIMIT = 300 as const;

const institucionais = BaSeTheme.colors.institucionais;
const defaults = BaSeTheme.colors.defaultColors;

export const FilePickerWrapper = styled.div.attrs({
  className: 'BaSe--file-picker-wrapper',
})<{ isDrag: boolean }>`
  display: grid;
  border: 0.125em dashed ${institucionais.cinzaSebrae90};
  background-color: ${({ isDrag }) =>
    isDrag ? institucionais.azulSebrae90 : defaults.white};
  place-items: center;
  border-radius: 0.25em;
  padding: 1em;
  gap: 0.5em;
  width: calc(100% - 2em);
  height: calc(100% - 2em);
  transition: background-color ${BaSeTheme.transitions.durationSlow};
  will-change: background-color;

  & > p.BaSe--paragraph {
    text-align: center;
  }
`;

export const TagWrapper = styled.div.attrs({
  className: 'BaSe--file-picker-selected-files',
})`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: minmax(${TAG_WIDTH_LIMIT}px, 1fr);
  grid-auto-rows: 2em;
  grid-template-columns: repeat(auto-fill, minmax(${TAG_WIDTH_LIMIT}px, 1fr));
  grid-template-rows: repeat(auto-fill, 2em);
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1em;
  gap: 0.5em;
`;

export const OutsideWrapper = styled.div.attrs({
  className: 'BaSe--file-picker-outside-button',
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.125em;
  width: 100%;
`;

export const HiddenInputFile = styled.input.attrs({
  className: 'BaSe--file-picker-hidden-input-file',
  type: 'file',
})`
  position: absolute;
  appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  color: transparent;
  display: none;
  height: 0;
  margin: 0;
  max-height: 0;
  max-width: 0;
  min-height: 0;
  min-width: 0;
  opacity: 0;
  outline: none;
  pointer-events: none;
  visibility: hidden;
  width: 0;
`;
