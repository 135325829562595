import * as React from 'react';
import { mask, unMask } from 'remask';

export function useCurrentDate() {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const date = new Date();
  const [currentYear] = React.useState(date.getFullYear());
  const [currentMonth] = React.useState(date.getMonth() + 1);
  const [defaultMonth] = React.useState(months[date.getMonth()]);
  return { currentYear, currentMonth, defaultMonth };
}

export function formatDate(value) {
  const day = value?.substring(8, 10) ?? '';
  const month = value?.substring(5, 7) ?? '';
  const year = value?.substring(0, 4) ?? '';

  const maskDate = mask(`${day}${month}${year}`, '99/99/9999');
  return maskDate;
}

export function formatDateNoTime(value) {
  if (!value) return '';
  const dateParts = value.split('-');
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2].split('T')[0];

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export function formatDateINTL(value) {
  const day = value?.substring(0, 2) ?? '';
  const month = value?.substring(3, 5) ?? '';
  const year = value?.substring(6, 10) ?? '';

  const maskDate = mask(`${year}${month}${day}`, '9999-99-99');
  return maskDate;
}

export function dateAndTimeFormat(value) {
  const day = value?.substring(8, 10) ?? '';
  const month = value?.substring(5, 7) ?? '';
  const year = value?.substring(0, 4) ?? '';
  const hour = value?.substring(11, 13) ?? '';
  const minute = value?.substring(14, 16) ?? '';

  const maskDate = mask(
    `${day}${month}${year}${hour}${minute}`,
    '99/99/9999 99:99',
  );
  return maskDate;
}

export function subtractedDates(dateStrEnd, dataStrStart) {
  if (dataStrStart && dateStrEnd) {
    const differenceInMilliseconds = toDate(dateStrEnd) - toDate(dataStrStart);

    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);

    const seconds = differenceInSeconds % 60;
    const minutes = differenceInMinutes % 60;

    return `${differenceInHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else {
    return '';
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function toDate(value) {
  const day = value?.substring(8, 10) ?? '';
  const month = value?.substring(5, 7) ?? '';
  const year = value?.substring(0, 4) ?? '';
  const hour = value?.substring(11, 13) ?? '';
  const minute = value?.substring(14, 16) ?? '';
  const second = value?.substring(17, 19) ?? '';
  return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
}

export function useFormatCnpjCpf() {
  const formatedCnpjCpf = React.useCallback(
    (value) => mask(unMask(value), ['999.999.999-99', '99.999.999/9999-99']),
    [],
  );
  const formatCpf = React.useCallback(
    (value) => mask(value, '999.999.999-99'),
    [],
  );
  const formatCnpj = React.useCallback(
    (value) => mask(value, '99.999.999/9999-99'),
    [],
  );
  const unformatCnpjCpf = React.useCallback(
    (value) => value.replace(/(\.|\/|-)/g, ''),
    [],
  );

  const formatedDate = React.useCallback((value) => {
    const day = value.substring(0, 2);
    const month = value.substring(3, 5);
    const year = value.substring(6, 10);
    if (day && month && year) {
      const formatDate = `${year}-${month}-${day}`;
      return formatDate;
    } else {
      return '';
    }
  }, []);

  return {
    formatedCnpjCpf,
    unformatCnpjCpf,
    formatedDate,
    formatCpf,
    formatCnpj,
  };
}

export function useAddAndRemoveFilter() {
  const addList = React.useCallback((value, list) => {
    return list.filter((item) => item.id === value.id);
  }, []);

  const addSelect = React.useCallback((value, list) => {
    return list.filter((item) => item.label === value);
  }, []);

  const removeList = React.useCallback((value, list) => {
    return list.filter((item) => item.label !== value);
  }, []);

  const removeSelected = React.useCallback((value, list) => {
    return list.filter((item) => item.id !== value.id);
  }, []);

  return { addList, addSelect, removeList, removeSelected };
}
