import styled from 'styled-components';
import { DataInterfaceProps, densityPixels, spacePixels } from '../data-styled';
import { DataPaginationProps } from '../pagination/pagination';

export interface DataBodyGridProps
  extends Pick<DataInterfaceProps, 'densityLevel'>,
    Pick<DataPaginationProps, 'compactMode'> {
  gridColumns?: number;
}

export const DataBodyGrid = styled.div.attrs({
  className: 'BaSe--data-body-grid',
})<DataBodyGridProps>`
  padding: ${({ compactMode }) => (compactMode ? 0 : spacePixels)};
  margin: 0;
  box-sizing: content-box;

  ul.BaSe--data-grid {
    display: grid;
    grid-template-columns: repeat(
      ${({ gridColumns = 3 }) => (gridColumns < 1 ? 1 : gridColumns)},
      1fr
    );
    gap: ${({ densityLevel }) => densityPixels(densityLevel)};
    padding: 0;
  }
`;
