import * as React from 'react';
import {
  BrowserRouter as Router,
  Link,
  LinkProps,
  Redirect,
  Route,
  RouteProps as RouterRouteProps,
  Switch,
} from 'react-router-dom';
import {
  ROUTE_403_PATH,
  ROUTE_404_PATH,
  ROUTE_BACKEND_NO_SIGNAL_PATH,
} from '../constants/routes';
import { useAuth } from '../hooks/auth';
import { useRouter } from '../hooks/router';
import { BackendNoSignalPage } from '../pages/backend-no-signal';
import { ForbiddenPage } from '../pages/forbidden';
import { NotFoundPage } from '../pages/not-found';

const route403: RouterRouteProps = {
  exact: true,
  path: ROUTE_403_PATH,
  render: () => <ForbiddenPage />,
};
const route404: RouterRouteProps = {
  exact: true,
  path: ROUTE_404_PATH,
  render: () => <NotFoundPage />,
};
const routeBackendNoSignal: RouterRouteProps = {
  exact: true,
  path: ROUTE_BACKEND_NO_SIGNAL_PATH,
  render: () => <BackendNoSignalPage />,
};

export const defaultRoutes: RouterRouteProps[] = [
  route403,
  route404,
  routeBackendNoSignal,
];

export type ComponentType = JSX.Element;
export type PathType = string;

export interface ProtectedPageWrapperProps {
  ifAuthenticated?: ComponentType;
  ifHasRoles?: {
    roles: string[];
    page: ComponentType;
  };
  withGuard?: {
    guard: () => boolean | Promise<boolean>;
    page: ComponentType;
    // loadingPage: ComponentType;
  };
  orRedirectTo?: ComponentType | PathType;
}

const AuthenticatedPage: React.FC<
  Required<Pick<ProtectedPageWrapperProps, 'ifAuthenticated'>>
> = ({ ifAuthenticated }) => <>{ifAuthenticated}</>;

const AuthenticatedWithRolesPage: React.FC<
  Required<Pick<ProtectedPageWrapperProps, 'ifHasRoles'>>
> = ({ ifHasRoles }) => <>{ifHasRoles.page}</>;

const GuardPage: React.FC<
  Required<Pick<ProtectedPageWrapperProps, 'withGuard'>>
> = ({ withGuard }) => <>{withGuard.page}</>;

export const ProtectedPageWrapper: React.FC<ProtectedPageWrapperProps> =
  React.memo(
    ({
      ifAuthenticated,
      ifHasRoles,
      orRedirectTo = <Redirect to={ROUTE_403_PATH} />,
      withGuard,
    }) => {
      const { isAuthenticated, hasRoles } = useAuth();
      const { navigateTo } = useRouter();
      const [internalLoading, setInternalLoading] =
        React.useState<boolean>(true);

      if (ifAuthenticated && isAuthenticated) {
        return <AuthenticatedPage ifAuthenticated={ifAuthenticated} />;
      }

      const roles = ifHasRoles?.roles ?? [];
      if (ifHasRoles?.page && hasRoles(roles)) {
        return <AuthenticatedWithRolesPage ifHasRoles={ifHasRoles} />;
      }

      if (withGuard?.page) {
        const guardResult = withGuard?.guard?.();

        if (guardResult === true) {
          return <GuardPage withGuard={withGuard} />;
        }
        if (guardResult instanceof Promise) {
          guardResult.then((result) => setInternalLoading(result));
          return (
            <>
              {internalLoading === false ? (
                <GuardPage withGuard={withGuard} />
              ) : (
                <p>Carregando</p>
              )}
            </>
          );
        }
      }

      if (typeof orRedirectTo === 'string') {
        navigateTo(orRedirectTo);
        return <></>;
      }

      const RedirectPage = () => orRedirectTo;
      return <RedirectPage />;
    },
  );
ProtectedPageWrapper.displayName = 'SebraeProtectedPageWrapper';
export interface RouteConfig {
  routePath: PathType;
  pageToRender: ComponentType;
  isExactPath?: boolean;
  ifIsAuthenticated?: boolean;
  ifHaveTheseRoles?: string[];
  ifPassThruThisGuard?: () => boolean | Promise<boolean>;
  orRedirectTo?: ComponentType | PathType;
}

export type RoutesConfig = RouteConfig[];

export interface RouteProps {
  route: RouteConfig;
}

export interface RoutesProps {
  routes: RoutesConfig;
  // isNestedRoutes?: boolean;
}

function routeFactory(route: RouteConfig): JSX.Element {
  if (route.ifIsAuthenticated) {
    return (
      <Route
        exact={route.isExactPath}
        path={route.routePath}
        key={route.routePath}
        render={() => (
          <ProtectedPageWrapper
            ifAuthenticated={route.pageToRender}
            orRedirectTo={route.orRedirectTo}
          />
        )}
      />
    );
  }

  if (route.ifHaveTheseRoles) {
    return (
      <Route
        exact={route.isExactPath}
        path={route.routePath}
        key={route.routePath}
        render={() => (
          <ProtectedPageWrapper
            ifHasRoles={{
              roles: route.ifHaveTheseRoles ?? [],
              page: route.pageToRender,
            }}
            orRedirectTo={route.orRedirectTo}
          />
        )}
      />
    );
  }

  if (route.ifPassThruThisGuard) {
    return (
      <Route
        exact={route.isExactPath}
        path={route.routePath}
        key={route.routePath}
        render={() => (
          <ProtectedPageWrapper
            withGuard={{
              guard: route.ifPassThruThisGuard ?? (() => true),
              page: route.pageToRender,
            }}
            orRedirectTo={route.orRedirectTo}
          />
        )}
      />
    );
  }

  return (
    <Route
      exact={route.isExactPath}
      path={route.routePath}
      key={route.routePath}
      render={() => route.pageToRender}
    />
  );
}

export const Routes: React.FC<RoutesProps> = ({
  routes,
  // isNestedRoutes = false,
  // eslint-disable-next-line arrow-body-style
}) => {
  // const { path: parentPath } = useRouteMatch();

  // log('Routes', 'parent path', parentPath);

  // if (isNestedRoutes) {
  //   routes.forEach(route => route.routePath = `${parentPath}/${route.routePath}`);
  // }

  // log('Routes', 'routes', routes, isNestedRoutes);

  // React.useEffect(() => {
  //   log('Routes', 'Routes', 'mount');
  //   return () => log('Routes', 'Routes', 'dismount');
  // }, []);

  return (
    <Router>
      <Switch>
        {defaultRoutes.map((props, index) => (
          <Route {...props} key={index} />
        ))}

        {routes.map((route) => routeFactory(route))}

        <Redirect from="*" to={ROUTE_404_PATH} />
      </Switch>
    </Router>
  );
};
Routes.displayName = 'SebraeRoutes';

export interface RouteLinkProps extends LinkProps {
  keepQueryString?: boolean;
}
export const RouteLink: React.FC<RouteLinkProps> = (props) => {
  const { keepQueryString = false, ...propsToLink } = props;
  if (keepQueryString && typeof propsToLink.to === 'string') {
    propsToLink.to += window.location.search ?? '';
  }
  return <Link {...propsToLink} />;
};
RouteLink.displayName = 'SebraeRouteLink';
