import { ReactKeycloakProvider } from '@react-keycloak/web';
import { KeycloakConfig, KeycloakInstance } from 'keycloak-js';
import * as React from 'react';
import { useEnv, EnvKey } from '../hooks/env';
import { ErrorPage } from '../pages/error';
import { LoadingPage } from '../pages/loading';
import { log } from '../utils/log';

const keycloakJsPath = '/js/keycloak.js';

type KeycloakCallable = (
  config?: Keycloak.KeycloakConfig | string,
) => Keycloak.KeycloakInstance;

class AuthError {
  constructor(public message = 'Erro não identificado na autenticação') {}

  toString(): string {
    return this.message;
  }
}

function alreadyInjected(): boolean {
  return !!document.querySelector(`[data-keycloak-script="true"]`);
}

function afterInjected(onLoad: (Keycloak: KeycloakCallable) => void): void {
  onLoad(window['Keycloak'] as unknown as KeycloakCallable);
}

function injectKeycloak(injectConfig: {
  url: string;
  onLoad(Keycloak: KeycloakCallable): void;
}): void {
  if (alreadyInjected()) {
    return afterInjected(injectConfig.onLoad);
  }

  const script = document.createElement('script');
  script.async = false;
  script.src = injectConfig.url + keycloakJsPath;
  script.setAttribute('data-keycloak-script', 'true');
  script.onload = () => afterInjected(injectConfig.onLoad);

  (document.head || document.body).appendChild(script);
}

export interface AuthProviderProps {
  loginRequired?: boolean;
  keycloakEnvKey?: EnvKey;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  loginRequired = false,
  keycloakEnvKey = 'keycloak',
  children,
}) => {
  const [rolesClientId, setRolesClientId] = React.useState<string>();
  const [keycloak, setKeycloak] = React.useState<KeycloakInstance>();
  const [error, setError] = React.useState<any>(null);
  const { getEnv } = useEnv();

  React.useEffect(() => {
    getEnv(keycloakEnvKey)
      .then((keycloakConfig) => {
        if (!keycloakConfig?.hasOwnProperty?.('url')) {
          throw new AuthError(
            '"url" não encontrado nas configurações do Keycloak no ENV',
          );
        }
        if (!keycloakConfig?.hasOwnProperty?.('realm')) {
          throw new AuthError(
            '"realm" não encontrado nas configurações do Keycloak no ENV',
          );
        }
        if (!keycloakConfig?.hasOwnProperty?.('clientId')) {
          throw new AuthError(
            '"clientID" não encontrado nas configurações do Keycloak no ENV',
          );
        }
        setRolesClientId(keycloakConfig?.['rolesClientId']);
        injectKeycloak({
          url: keycloakConfig['url'],
          onLoad(Keycloak: KeycloakCallable): void {
            setKeycloak(Keycloak(keycloakConfig as unknown as KeycloakConfig));
          },
        });
      })
      .catch((e) => setError(e));
  }, []);

  React.useEffect(() => {
    if (keycloak) {
      keycloak['rolesClientId'] = rolesClientId;
      keycloak.onTokenExpired = () => {
        log('Auth', '🔄 token expirou');
        keycloak
          .updateToken(5)
          .then((refreshed) => {
            if (refreshed) {
              log('Auth', '😃 token foi atualizado');
            } else {
              log('Auth', '😃 token continua válido');
            }
          })
          .catch((e) =>
            log(
              'Auth',
              '😖 deu ruim ao atualizar o token ou a sessão expirou',
              e,
            ),
          )
          .finally(() => log('Auth', '⚠️ finally updateToken'));
      };
    }
  }, [keycloak, rolesClientId]);

  if (!!error) {
    return <ErrorPage error={error?.toString()} />;
  }

  if (!keycloak) {
    return <LoadingPage />;
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<LoadingPage />}
      initOptions={{
        onLoad: loginRequired ? 'login-required' : 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
      }}
      onEvent={(event: unknown) => {
        if (event === 'onInitError') {
          setError(
            '😖 Não foi possível se comunicar com o AMEI, verifique a configuração e tente novamente.',
          );
        }
      }}
      // onTokens={(tokens: unknown) => {
      //   console.log('onKeycloakTokens', tokens);
      // }}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

AuthProvider.displayName = 'SebraeAuthProvider';
