import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { numberWithUnit } from '../../utils/string-utils';

const { azulSebrae36, azulSebrae75, cinzaSebrae90, cinzaSebrae97 } =
  BaSeTheme.colors.institucionais;

export interface SelectableContainerStyledProps {
  isSelected: boolean;
  borderRadius?: string | number;
}

export const SelectableContainer = styled.button.attrs({
  className: 'BaSe--selectable-container',
})<SelectableContainerStyledProps>`
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  appearance: none;
  position: relative;
  background-color: ${BaSeTheme.colors.defaultColors.transparent};
  border-radius: ${({ borderRadius }) => numberWithUnit(borderRadius)};
  box-shadow: ${BaSeTheme.shadows.profundidade4};

  ${({ isSelected }) =>
    isSelected
      ? css`
          outline: 3px solid ${azulSebrae36};
          :focus {
            outline: 3px solid ${azulSebrae75};
          }
        `
      : css`
          outline: 1px solid ${cinzaSebrae90};
          :focus {
            outline: 3px solid ${azulSebrae75};
          }
        `};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${cinzaSebrae97};
      outline: 3px solid ${cinzaSebrae90};
      cursor: not-allowed;
    `};

  :hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        outline: 3px solid ${azulSebrae36};
        box-shadow: ${BaSeTheme.shadows.profundidade8};
        transition: box-shadow 0.3s ease-in-out;
      `}
  }
`;

export const CheckboxContent = styled.div`
  position: absolute;
  top: 0.6em;
  right: 0.5em;

  .BaSe--checkbox div > div,
  img {
    width: 1.125em;
    height: 1.125em;
  }
`;
