import * as React from 'react';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { ThemeColorValue } from '../../theme/theme-interface';
import { BaSeTag, TagProps } from '../labels/tag/tag';
import {
  CardTagContainer,
  TagButtonWrapper,
  ContentTag,
  ContentTagSeparator,
  CardAppearenceProps,
  CardTagWrapper,
} from './card-styled';

export interface CardTag extends TagProps {
  key?: string | number;
}

export interface ExpandableTag {
  items: CardTag[];
}

export interface GroupedTag {
  key?: string | number;
  collapsedItem?: CardTag;
  alwaysVisible: CardTag | CardTag[];
  collapsibleItems: CardTag[];
}

export type RenderContentTag =
  | JSX.Element
  | CardTag
  | CardTag[]
  | GroupedTag
  | GroupedTag[]
  | ExpandableTag;

export interface CardContentTagProps
  extends Pick<CardAppearenceProps, 'disposition'> {
  foregroundColor?: ThemeColorValue;
  destakColor?: ThemeColorValue;
  renderContentTag?(): RenderContentTag;
}

export const BaSeCardContentTag: React.FC<CardContentTagProps> = ({
  destakColor,
  foregroundColor,
  disposition,
  renderContentTag,
}) => {
  const ct = renderContentTag?.();

  const containerTagRef = React.useRef<HTMLUListElement>(null);

  const [contentTagHeight, setContentTagHeight] = React.useState<string>();
  const [contentTagExpandable, setContentTagExpandable] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [contentTagGrouped, setContentTagGrouped] = React.useState(false);
  const [showExpandableButton, setShowExpandableButton] = React.useState(false);

  const renderTag = React.useCallback(
    (tag: CardTag) => <BaSeTag color={foregroundColor} {...tag} />,
    [foregroundColor],
  );

  const renderOthers = React.useCallback(
    (others: CardTag[], collapsedItem?: CardTag) => {
      if (!others.length) {
        return <React.Fragment key={`+${others.length}`} />;
      }
      return isCollapsed
        ? renderTag({
            key: `+${others.length}`,
            ...collapsedItem,
            label: `+${others.length}`,
            onClick: undefined,
            onClose: undefined,
            tooltip: undefined,
          })
        : others.map((tag, index) => renderTag({ key: index, ...tag }));
    },
    [renderTag, isCollapsed],
  );

  const renderCollpasibleTag = React.useCallback(
    (group: GroupedTag) =>
      Array.isArray(group.alwaysVisible)
        ? group.alwaysVisible
            .map((tag, index) => renderTag({ key: index, ...tag }))
            .concat(renderOthers(group.collapsibleItems, group.collapsedItem))
        : Array.of(
            renderTag({
              key:
                group?.key ??
                group.alwaysVisible?.key ??
                group.alwaysVisible?.label ??
                '😭',
              ...group.alwaysVisible,
            }),
          ).concat(renderOthers(group.collapsibleItems, group.collapsedItem)),
    [renderTag, renderOthers],
  );

  const renderContent = React.useCallback(
    (value?: RenderContentTag) => {
      if (value) {
        const tagProps = value as CardTag;
        const collapsedTag = value as GroupedTag;
        const expandableTag = value as ExpandableTag;

        if (Array.isArray(value)) {
          return value.map((item, index) => (
            <ContentTag key={item?.key ?? index}>
              {item && 'alwaysVisible' in item ? (
                <>
                  {renderCollpasibleTag(item)}
                  {!isCollapsed && value.length - 1 !== index && (
                    <ContentTagSeparator />
                  )}
                </>
              ) : (
                renderTag({ key: index, ...item })
              )}
            </ContentTag>
          ));
        }

        if (tagProps?.label) {
          return (
            <ContentTag key={tagProps?.key ?? tagProps?.label}>
              {renderTag({ key: tagProps.key ?? tagProps.label, ...tagProps })}
            </ContentTag>
          );
        }

        if (collapsedTag?.alwaysVisible) {
          return (
            <ContentTag
              key={
                collapsedTag?.key ??
                (collapsedTag.alwaysVisible as CardTag)?.key ??
                (collapsedTag.alwaysVisible as CardTag)?.label ??
                (collapsedTag.alwaysVisible as CardTag[])?.length ??
                '🤔'
              }
            >
              {renderCollpasibleTag(collapsedTag)}
            </ContentTag>
          );
        }

        if (expandableTag?.items) {
          return expandableTag.items.map((tag, index) => (
            <ContentTag key={tag?.key ?? index}>
              {renderTag({ key: index, ...tag })}
            </ContentTag>
          ));
        }
      }
      return value;
    },
    [isCollapsed, renderCollpasibleTag, renderTag],
  );

  React.useEffect(() => {
    const tagContainer = containerTagRef.current;

    const isGroupedTag =
      (ct && 'alwaysVisible' in ct) ||
      (Array.isArray(ct) && 'alwaysVisible' in ct[0]);
    setShowExpandableButton(isGroupedTag);
    setContentTagGrouped(isGroupedTag);

    const isExpandableTag = (ct as ExpandableTag)?.items?.length > 0;
    if (isExpandableTag) {
      const calculateToShowButton = () => {
        setContentTagExpandable(isExpandableTag);

        const firstLine: HTMLLIElement[] = [];
        const list = Array.from(tagContainer?.children ?? []);

        for (const current of list as HTMLLIElement[]) {
          const index = list.indexOf(current);

          const currentRect = current.getBoundingClientRect();

          if (index === 0) {
            firstLine.push(current);
            continue;
          }

          const prev = list[index - 1];
          const prevRect = prev.getBoundingClientRect();

          if (Math.abs(prevRect.y - currentRect.y) > 8) {
            break;
          }
          firstLine.push(current);
        }

        const greaterHeight = firstLine.reduce(
          (height: number, current: HTMLLIElement) => {
            const currentRect = current.getBoundingClientRect();
            return Math.max(height, currentRect.height);
          },
          0,
        );

        setContentTagHeight(`${greaterHeight}px`);
        setShowExpandableButton(list.length > firstLine.length);
      };

      if (tagContainer) {
        const calculate = () => calculateToShowButton();
        const resizeObserver = new ResizeObserver(calculate);
        resizeObserver.observe(tagContainer);
        const timeout = setTimeout(calculate, 1000);
        return () => {
          resizeObserver.unobserve(tagContainer);
          clearTimeout(timeout);
        };
      }
    }

    return;
  }, [containerTagRef.current]);

  return (
    <CardTagContainer disposition={disposition}>
      <CardTagWrapper
        isCollapsed={isCollapsed}
        contentTagGrouped={contentTagGrouped}
        contentTagExpandable={contentTagExpandable}
        contentTagHeight={contentTagHeight}
        ref={containerTagRef}
        as={React.isValidElement(ct) ? 'div' : 'ul'}
      >
        {renderContent(ct)}
        {showExpandableButton && (
          <TagButtonWrapper>
            <BaSeShapeButton
              color={destakColor}
              type="tertiary"
              size="small"
              sizeIcon="medium"
              nameIcon={isCollapsed ? 'angle-down' : 'angle-up'}
              onClick={() => setIsCollapsed((actual) => !actual)}
            />
          </TagButtonWrapper>
        )}
      </CardTagWrapper>
    </CardTagContainer>
  );
};

BaSeCardContentTag.displayName = 'BaSeCardContentTag';
