import * as React from 'react';
import { BaSeDataSelectionContext } from '../../../contexts/data/selection';
import { BaSeCheckbox } from '../../input/checkbox/checkbox';
import { DataInterfaceProps } from '../data-styled';
import { BaSeLoadingSpinner } from '../../loading/spinner';
import { BaSeI18nConsumer } from '../../../contexts/i18n';

export interface ToggleAllSelectionHandlerArgs<Item> {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setItems: React.Dispatch<React.SetStateAction<Item[]>>;
}

export interface ToggleAllCheckboxProps<Item>
  extends Pick<DataInterfaceProps, 'highlightedColor'> {
  actualItems: Item[];
  rowIdKey: keyof Item;
  hasToggleAllSelection?: boolean;
  toggleAllSelectionHandler?(args: ToggleAllSelectionHandlerArgs<Item>): void;
}

export function DataToggleAllCheckbox<Item extends object>({
  highlightedColor,
  actualItems,
  rowIdKey,
  hasToggleAllSelection,
  toggleAllSelectionHandler,
}: React.PropsWithoutRef<ToggleAllCheckboxProps<Item>>): JSX.Element {
  const [itemsToSelect, setItems] = React.useState(actualItems);
  const [loading, setLoading] = React.useState(false);

  const {
    isAllSelected,
    isAllFromPageSelected,
    selectedItems,
    setIsAllFromPageSelected,
    setSelectedItems,
  } = React.useContext(BaSeDataSelectionContext);

  const checked = React.useMemo(
    () => isAllSelected || isAllFromPageSelected,
    [isAllSelected, isAllFromPageSelected],
  );

  const indeterminate = React.useMemo(
    () =>
      actualItems.some((actualItem) =>
        selectedItems.some(
          (selectedItem: Item) =>
            selectedItem[rowIdKey] === actualItem[rowIdKey],
        ),
      ),
    [itemsToSelect, selectedItems],
  );

  React.useEffect(
    () =>
      setIsAllFromPageSelected(
        isAllSelected ||
          (selectedItems.length > 0 &&
            actualItems.every((actualItem) =>
              selectedItems.some(
                (selectedItem: Item) =>
                  selectedItem[rowIdKey] === actualItem[rowIdKey],
              ),
            )),
      ),
    [isAllSelected, itemsToSelect, selectedItems, rowIdKey],
  );

  React.useEffect(
    () =>
      toggleAllSelectionHandler?.({
        setLoading,
        setItems,
      }),
    [toggleAllSelectionHandler],
  );

  if (!hasToggleAllSelection || isAllSelected || itemsToSelect.length === 0) {
    return <></>;
  }

  if (loading) {
    return (
      <BaSeI18nConsumer>
        {({ getMessage }) => (
          <BaSeLoadingSpinner
            diameter={20}
            description={getMessage('loading.description')}
          />
        )}
      </BaSeI18nConsumer>
    );
  }

  return (
    <BaSeCheckbox
      color={highlightedColor}
      checked={checked || indeterminate}
      indeterminate={!checked && indeterminate}
      onChange={(changedChecked) => {
        setSelectedItems((actualSelectedItems) =>
          actualSelectedItems.filter((actualSelectedItem: Item) =>
            actualItems.every(
              (actualItem: Item) =>
                actualItem[rowIdKey] !== actualSelectedItem[rowIdKey],
            ),
          ),
        );
        if (changedChecked) {
          setSelectedItems((actual) => [...actual, ...itemsToSelect]);
        }
      }}
    />
  );
}
