import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';

export interface DataTextFilterWrapperProps {
  textFilterVisible: boolean;
  textFilterWidth?: number;
}

export interface DataTextFilterContainerProps {
  textFilterAlwaysVisible?: boolean;
}

export const IconButton = styled.div`
  overflow: visible;
`;

export const TextFilterContainer = styled.div<DataTextFilterContainerProps>`
  ${({ textFilterAlwaysVisible }) =>
    !textFilterAlwaysVisible &&
    css`
      overflow: hidden;
      transition: width ${BaSeTheme.transitions.durationSlow};
    `}
`;

export const DataTextFilterWrapper = styled.div<DataTextFilterWrapperProps>`
  ${({ textFilterVisible, textFilterWidth }) =>
    textFilterVisible
      ? css`
          ${TextFilterContainer} {
            width: ${textFilterWidth}px;
            height: auto;
          }
          ${IconButton} {
            display: none;
          }
        `
      : css`
          ${TextFilterContainer} {
            width: 0;
            height: 0;
          }
          ${IconButton} {
            display: block;
          }
        `}
`;
