import * as React from 'react';
import { AlertDirection } from '../../../hooks/coordinates-from-buddy-and-direction';
import { useOutsideEvent } from '../../../hooks/outside-event';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';
import { AlertColor } from '../../alerts/alerts-props';
import { BaSePopover } from '../../alerts/popover/popover';
import { BaSeTooltip } from '../../alerts/tooltip/tooltip';
import { BaSeShapeButton } from '../../button/shape-button/shape-button';
import { Coordinates } from './popup-button-map';
import {
  HelperButtonContainer,
  HelperButtonIconContainer,
} from './popup-button-styled';

export interface HelperIconProps {
  name?: string;
  color?: string;
  size?: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface HelperButtonInterface {
  direction?: AlertDirection;
  type?: 'popover' | 'tooltip';
  tooltipColor?: AlertColor;
  alertSize?: number;
  message?: string;
  title?: string;
  description?: string;
  iconProps?: HelperIconProps;
  overrideColors?: {
    alert?: {
      foreground?: string;
      backgroundColor?: string;
      borderColor?: string;
      titleColor?: string;
    };
  };
}

const defaultIconName = 'question-circle';
const defaultIconSize = 13;
const defaultIconColor = 'default';

export const BaSePopupButton: React.FC<HelperButtonInterface> = ({
  direction = 'top',
  type = 'tooltip',
  tooltipColor = 'default',
  alertSize = type === 'tooltip' ? 106 : 180,
  message = '',
  title = '',
  iconProps = {
    name: defaultIconName,
    color: defaultIconColor,
    size: defaultIconSize,
    onClick: () => {},
  },
  overrideColors,
}) => {
  const initialCoordinates = React.useMemo(
    () =>
      Coordinates(alertSize, null, iconProps?.size ?? defaultIconSize)[
        direction
      ],
    [alertSize, iconProps?.size, defaultIconSize, direction],
  );
  const [coordinates, setCoordinates] = React.useState(initialCoordinates);
  const [open, setOpen] = React.useState(false);
  const helperButtonRef = React.useRef(null);

  const handleRef = React.useCallback(
    (e?: { offsetHeight?: number }) =>
      setCoordinates(
        Coordinates(
          alertSize,
          e?.offsetHeight,
          iconProps?.size ?? defaultIconSize,
        )[direction],
      ),
    [alertSize, iconProps?.size, defaultIconSize, direction],
  );

  useOutsideEvent(helperButtonRef, (e) => setOpen(e));

  return (
    <HelperButtonContainer ref={helperButtonRef}>
      <HelperButtonIconContainer
        size={iconProps.size ?? defaultIconSize}
        onClick={() => setOpen((prev) => !prev)}
      >
        <BaSeShapeButton
          buttonType="button"
          type="tertiary"
          size="medium"
          nameIcon={iconProps.name ?? defaultIconName}
          color={ColorMapper.getColor(iconProps?.color, defaultIconColor)}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            iconProps?.onClick?.(e)
          }
        />
      </HelperButtonIconContainer>
      {type === 'tooltip' ? (
        <BaSeTooltip
          width={alertSize}
          open={open}
          direction={direction}
          message={message}
          color={tooltipColor}
          coordinates={coordinates}
          refOutput={handleRef}
          overrideColors={overrideColors?.alert}
        />
      ) : (
        <BaSePopover
          width={alertSize}
          open={open}
          direction={direction}
          message={message}
          coordinates={coordinates}
          refOutput={handleRef}
          title={title}
          overrideColors={overrideColors?.alert}
          handleClose={() => setOpen(false)}
        />
      )}
    </HelperButtonContainer>
  );
};

BaSePopupButton.displayName = 'BaSePopupButton';
