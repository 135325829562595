import * as React from 'react';
import { BaSeI18nContext } from '../contexts/i18n';

export interface CounterProps<T> {
  value: string;
  hasCounter: T;
  onChange: (value: string) => void;
  counterMinLength?: number;
  counterMaxLength?: number;
}

export interface HookCounter {
  counterDescription: string;
  cropText: (newValue: string) => void;
}

export type CounterType = 'words' | 'chars' | 'lines' | false;

export const lineSeparatorRegEx = /\n/;
export const wordSeparatorRegEx =
  /(!([^\s0-9À-ÿa-zA-zÀ-ÖØ-öø-ÿ][^0-9À-ÿa-zA-zÀ-ÖØ-öø-ÿ\w])+|\s|[-–@.,][^0-9À-ÿa-zA-zÀ-ÖØ-öø-ÿ\w])+/gim;

export function clearValueToFilterWord(value: string = ''): string {
  return value
    .trim()
    .replace(/^[^0-9À-ÿa-zA-zÀ-ÖØ-öø-ÿ\w]+/gim, '')
    .replace(/[^0-9À-ÿa-zA-zÀ-ÖØ-öø-ÿ\w]+$/gim, '');
}

export function charCounter(value: string): number {
  return value?.length ?? 0;
}

export function lineCounter(value: string): number {
  return value.split(lineSeparatorRegEx).length;
}

export function wordCounter(value: string): number {
  const cleanValue = clearValueToFilterWord(value);
  if (!cleanValue) {
    return 0;
  }
  const splittedValue = cleanValue.split(wordSeparatorRegEx);
  const filteredVaue = splittedValue.filter(
    (val) => !!clearValueToFilterWord(val),
  );
  const { length } = filteredVaue;
  return length;
}

export function useCounter<T>({
  value,
  hasCounter,
  counterMinLength = 0,
  counterMaxLength = 0,
  onChange,
}: CounterProps<T>): HookCounter {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const length = React.useMemo(
    () =>
      hasCounter === 'chars'
        ? charCounter(value)
        : hasCounter === 'lines'
          ? lineCounter(value)
          : hasCounter === 'words'
            ? wordCounter(value)
            : 0,
    [value, hasCounter],
  );

  const getDescription = React.useCallback(
    (singularWord: string, pluralWord: string) => {
      return counterMinLength && counterMaxLength
        ? getMessage(
            'counter.minMax',
            length <= counterMinLength ? length : counterMinLength,
            counterMinLength,
            length,
            counterMaxLength,
            counterMaxLength > 1 ? pluralWord : singularWord,
          )
        : counterMinLength && length === 0
          ? getMessage(
              'counter.initialMin',
              counterMinLength,
              counterMinLength > 1 ? pluralWord : singularWord,
            )
          : counterMinLength && length < counterMinLength
            ? getMessage(
                counterMinLength - length === 1
                  ? 'counter.minLastOne'
                  : 'counter.min',
                counterMinLength - length,
                counterMinLength - length > 1 ? pluralWord : singularWord,
              )
            : counterMinLength && length >= counterMinLength
              ? getMessage(
                  'counter.doneMin',
                  counterMinLength,
                  counterMinLength > 1 ? pluralWord : singularWord,
                )
              : counterMaxLength && length === 0
                ? getMessage(
                    'counter.initialMax',
                    counterMaxLength,
                    counterMaxLength > 1 ? pluralWord : singularWord,
                  )
                : counterMaxLength && length < counterMaxLength
                  ? getMessage(
                      counterMaxLength - length === 1
                        ? 'counter.maxLastOne'
                        : 'counter.max',
                      counterMaxLength - length,
                      counterMaxLength - length > 1 ? pluralWord : singularWord,
                    )
                  : counterMaxLength && length === counterMaxLength
                    ? getMessage(
                        'counter.doneMax',
                        counterMaxLength,
                        counterMaxLength > 1 ? pluralWord : singularWord,
                      )
                    : getMessage(
                        'counter.withoutMinMax',
                        length,
                        length > 1 ? pluralWord : singularWord,
                      );
    },
    [getMessage, counterMinLength, counterMaxLength, length],
  );

  const cropWord = React.useCallback(
    (newValue: string) => {
      const separatorList = newValue.match(wordSeparatorRegEx) ?? [];

      const cleanValue = clearValueToFilterWord(newValue);
      const splittedValue = cleanValue.split(wordSeparatorRegEx);
      const filteredVaue = splittedValue.filter(
        (val) => !!clearValueToFilterWord(val),
      );

      const valueListCropped = filteredVaue.splice(0, counterMaxLength);
      const valueToChange = valueListCropped.reduce(
        (acc, item, index, list) =>
          acc + item + (index + 1 < list.length ? separatorList[index] : ''),
        '',
      );
      onChange(valueToChange);
    },
    [counterMaxLength, onChange],
  );

  const cropLine = React.useCallback(
    (newValue: string) =>
      onChange(
        newValue
          .split(lineSeparatorRegEx)
          .splice(0, counterMaxLength)
          .join('\n'),
      ),
    [counterMaxLength, onChange],
  );

  const cropText = React.useCallback(
    (newValue: string) => {
      if (hasCounter === 'chars' || hasCounter === false) {
        return;
      }

      length <= counterMaxLength
        ? onChange(newValue)
        : hasCounter === 'words'
          ? cropWord(newValue)
          : cropLine(newValue);
    },
    [counterMaxLength, hasCounter, onChange, length],
  );

  const counterDescription = React.useMemo(
    () =>
      hasCounter === 'words'
        ? getDescription(
            getMessage('counter.word'),
            getMessage('counter.words'),
          )
        : hasCounter === 'chars'
          ? getDescription(
              getMessage('counter.char'),
              getMessage('counter.chars'),
            )
          : hasCounter === 'lines'
            ? getDescription(
                getMessage('counter.line'),
                getMessage('counter.lines'),
              )
            : '',
    [getDescription, getMessage, hasCounter],
  );

  return { counterDescription, cropText };
}
