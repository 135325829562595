import * as React from 'react';
import { TypographyProps } from '../typography-props';
import { BaSeTypographyWrapper } from '../typography-wrapper';
import { StyledHeading1 } from './heading1-styled';

export const BaSeHeading1: React.FC<TypographyProps> = (props) => (
  <BaSeTypographyWrapper
    fontSize={2.48}
    lineHeight={1.2}
    wrapper={StyledHeading1}
    {...props}
  >
    {props.children}
  </BaSeTypographyWrapper>
);

BaSeHeading1.displayName = 'BaSeHeading1';
