import styled from 'styled-components';
import { numberWithUnit } from '../../utils/string-utils';
import { BaSeTheme } from '../../theme';

interface StyledLabelProps {
  fontSize: string;
  lineHeight: string;
}

interface StyledCounterInputProps {
  value: number;
  disabled: boolean;
  height: number;
  width: string | number;
  hasError: boolean;
}

interface StyledCounterApoio {
  hasError: boolean;
}

export const WrapperCounterLabel = styled.div.attrs({
  className: 'BaSe--counter-wrapper-label',
})`
  display: flex;
  flex-direction: row;
  background-color: ${BaSeTheme.colors.defaultColors.transparent};
  position: relative;
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
`;

export const StyledSubLabel = styled.label.attrs({
  className: 'BaSe--counter-sub-label',
})<StyledLabelProps>`
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-weight: normal;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae60};
  font-style: italic;
  margin-left: 4px;
`;

export const ButtonInfoContainer = styled.div`
  margin-left: 5.33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledApoio = styled.span<StyledCounterApoio>`
  font-size: 0.83em;
  color: ${(props) =>
    props.hasError
      ? BaSeTheme.colors.feedbackInterface.erro35
      : BaSeTheme.colors.institucionais.cinzaSebrae45};
  margin-top: 3px;
  line-height: 1.2em;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: italic;
  font-weight: normal;
`;

export const StyledCounterInput = styled.input.attrs({
  inputMode: 'numeric',
})<StyledCounterInputProps>`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: ${BaSeTheme.typography.lineHeightBase};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 12px;
  background: ${BaSeTheme.colors.defaultColors.transparent};
  border-top: 1px solid
    ${(props) =>
      props.hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : BaSeTheme.colors.institucionais.cinzaSebrae75};
  border-bottom: 1px solid
    ${(props) =>
      props.hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : BaSeTheme.colors.institucionais.cinzaSebrae75};
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  height: ${(props) => props.height}px;
  padding-right: 26px;
  padding: 0;
  margin-left: -2px;
  margin-right: -2px;
  width: ${({ width }) => numberWithUnit(width)};
  min-width: 60px;
  color: ${(props) =>
    props.hasError
      ? BaSeTheme.colors.feedbackInterface.erro35
      : BaSeTheme.colors.institucionais.cinzaSebrae30};
  text-align: center;
  &:focus {
    outline: 0;
  }
`;

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MoreInfoContainer = styled.div`
  padding-top: 4px;
`;

export const CounterInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
