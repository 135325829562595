import * as React from 'react';
import { TypographyProps } from '../typography-props';
import { BaSeTypographyWrapper } from '../typography-wrapper';
import { StyledHeading2 } from './heading2-styled';

export const BaSeHeading2: React.FC<TypographyProps> = (props) => (
  <BaSeTypographyWrapper
    fontSize={2.07}
    lineHeight={1.2}
    wrapper={StyledHeading2}
    {...props}
  >
    {props.children}
  </BaSeTypographyWrapper>
);

BaSeHeading2.displayName = 'BaSeHeading2';
