import * as React from 'react';
import { BaSeTheme } from '../../../theme';
import {
  BaSePopupButton,
  HelperButtonInterface,
} from '../../helpers/popup-button/popup-button';
import {
  WrapperInputlabel,
  StyledLabel,
  StyledSubLabel,
  ButtonInfoContainer,
} from './form-label-styled';

export interface FormLabelInterface {
  id?: string;
  size?: 'medium' | 'small' | 'big';
  label?: string;
  subLabel?: string;
  showHelpButton?: boolean;
  helpButtonProps?: Partial<HelperButtonInterface>;
  colors?: {
    label?: string;
    subLabel?: string;
  };
  getContainerHeight?: (height?: number) => void;
}

export const BaSeFormLabel: React.FC<FormLabelInterface> = ({
  id,
  size,
  label,
  subLabel,
  showHelpButton,
  helpButtonProps,
  colors,
  getContainerHeight,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const isEmpty = React.useCallback(
    () => !label && !subLabel && !showHelpButton,
    [label, subLabel, showHelpButton],
  );

  const handleResize = React.useCallback(() => {
    if (
      containerRef?.current?.offsetHeight &&
      getContainerHeight &&
      !isEmpty()
    ) {
      getContainerHeight(containerRef?.current?.offsetHeight + 2); // 2px da margin-bottom
    }
  }, [containerRef, isEmpty, getContainerHeight]);

  React.useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  React.useEffect(() => {
    window.addEventListener('resize', () => handleResize());
    return window.removeEventListener('resize', () => handleResize());
  }, []);

  return (
    <WrapperInputlabel ref={containerRef} isEmpty={isEmpty()}>
      <StyledLabel
        fontSize={size === 'small' ? '13px' : '16px'}
        lineHeight={size === 'small' ? '16px' : '23px'}
        htmlFor={id}
        color={colors?.label ?? BaSeTheme.colors.institucionais.cinzaSebrae45}
      >
        {label}
      </StyledLabel>
      <StyledSubLabel
        fontSize={size === 'small' ? '13px' : '16px'}
        lineHeight={size === 'small' ? '16px' : '23px'}
        htmlFor={id}
        color={
          colors?.subLabel ?? BaSeTheme.colors.institucionais.cinzaSebrae60
        }
      >
        {subLabel}
      </StyledSubLabel>
      {showHelpButton && (
        <ButtonInfoContainer>
          <BaSePopupButton {...helpButtonProps} />
        </ButtonInfoContainer>
      )}
    </WrapperInputlabel>
  );
};
