import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { SideMenuItemStyledProp } from './item';

export const SideMenuMainItem = styled.li<
  SideMenuItemStyledProp & {
    active: boolean;
    backgroundColor: string;
    backgroundHoverColor: string;
  }
>`
  display: flex;
  align-items: center;
  list-style: none;
  position: relative;
  padding: 16px;
  cursor: pointer;

  ${({
    hoverTextColor,
    selectedTextColor,
    bottomLineColor,
    active,
    backgroundColor,
    backgroundHoverColor,
  }) => css`
    background-color: ${backgroundColor};
    border-bottom: 1px solid ${bottomLineColor};

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 0;
      background-color: ${selectedTextColor};
      top: 0;
      bottom: 0;
      will-change: widht;
      transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
    }

    ${active &&
    css`
      &::before {
        width: 4px;
      }
    `}

    &:hover , &:focus {
      color: ${hoverTextColor};
      background-color: ${backgroundHoverColor};
      outline: none;
    }
  `};

  img {
    margin-right: 8px;
  }
`;

export const SideMenuChildItem = styled(SideMenuMainItem)`
  font-weight: normal;
  font-size: 13px;
  padding: 8px 16px;
  border-bottom: none;

  &::before {
    display: none;
  }
`;
