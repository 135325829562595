import * as React from 'react';
import { useVLibras } from './vlibras';

const nonCssZoomPropertyKeys = [
  'transform',
  'webkit-transform',
  'moz-transform',
  'ms-transform',
  'o-transform',
] as const;

type NonCssZoomPropertyKeys = (typeof nonCssZoomPropertyKeys)[number];

const nonCssZoomPropertyValue =
  (value: number) => (property: NonCssZoomPropertyKeys) =>
    (document.body.style[property] = `scale(${value})` as const);

function supportsCssZoom(): boolean {
  return CSS?.supports?.('zoom: 1') ?? false;
}

function rawNonCssZoomValue(rawValue?: string): number | undefined {
  if (!rawValue) {
    return undefined;
  }
  return parseFloat(rawValue.replace(/[scale()]/g, ''));
}

function getZoom(): number {
  const zoom = supportsCssZoom()
    ? +document.body.style?.['zoom']
    : nonCssZoomPropertyKeys.reduce(
        (value, property) =>
          value && !isNaN(value)
            ? value
            : rawNonCssZoomValue(document.body.style?.[property] as string),
        undefined,
      );
  return zoom || 1;
}

function setZoom(value: number): void {
  if (supportsCssZoom()) {
    document.body.style['zoom'] = value;
    return;
  }
  nonCssZoomPropertyKeys.forEach(nonCssZoomPropertyValue(value));
}

function maxZoom(zoom: number, max: number): number {
  const diff = supportsCssZoom() ? 0.5 : 0.25;
  return zoom >= max ? max : zoom + diff;
}

function minZoom(zoom: number, min: number): number {
  const diff = supportsCssZoom() ? 0.5 : 0.25;
  return zoom <= min ? min : zoom - diff;
}

function bodyZoomIn(max: number): void {
  setZoom(maxZoom(getZoom(), max));
}

function bodyZoomOut(min: number): void {
  setZoom(minZoom(getZoom(), min));
}

export interface A11yCtrlProps {
  contrastModeOptions?: string[];
  zoomOptions?: { min: number; max: number };

  useDefaultToggleLibras?: boolean;
  useDefaultZoomIn?: boolean;
  useDefaultZoomOut?: boolean;
  useDefaultToggleContrastMode?: boolean;

  onToggleLibras?(shown: boolean): void;
  onZoomIn?(): void;
  onZoomOut?(): void;
  onToggleContrastMode?(mode: string): void;
}

export const selectedContrastModeIndicator = 'x' as const;
export const unselectedContrastModeIndicator = '-' as const;
export type ContrastModeIndicator =
  | typeof selectedContrastModeIndicator
  | typeof unselectedContrastModeIndicator;

export interface A11yCtrlHook {
  contrastModeIndicator: ContrastModeIndicator[];
  toggleContrastMode(): void;
  toggleLibras(): void;
  zoomIn(): void;
  zoomOut(): void;
}

export function useA11yCtrl({
  // TODO Colocar as opções padrões depois que tiver resolvido o uso com o provedor de tema
  // contrastModeOptions =  ['off', 'on', 'high'],
  contrastModeOptions = [],
  zoomOptions = { min: 1, max: 2 },
  useDefaultToggleLibras = true,
  useDefaultToggleContrastMode = true,
  useDefaultZoomIn = true,
  useDefaultZoomOut = true,
  onToggleContrastMode,
  onToggleLibras,
  onZoomIn,
  onZoomOut,
}: A11yCtrlProps): A11yCtrlHook {
  const { toggleVLibras } = useVLibras();

  const [shownLibras, setShownLibras] = React.useState(false);
  const [contrastMode, setContrastMode] = React.useState(0);

  const contrastModeIndicator = React.useMemo(
    () =>
      contrastModeOptions.map((_, mode) =>
        mode === contrastMode
          ? selectedContrastModeIndicator
          : unselectedContrastModeIndicator,
      ),
    [contrastMode, contrastModeOptions],
  );

  const toggleLibras = React.useCallback(() => {
    if (useDefaultToggleLibras) {
      toggleVLibras();
    }
    setShownLibras((i) => !i);
  }, [useDefaultToggleLibras, toggleVLibras]);

  const toggleContrastMode = React.useCallback(() => {
    setContrastMode((actual) => {
      const next = actual + 1;
      return next >= contrastModeOptions.length ? 0 : next;
    });
  }, [contrastModeOptions]);

  const zoomIn = React.useCallback(() => {
    if (useDefaultZoomIn) {
      bodyZoomIn(zoomOptions.max);
    }
    onZoomIn?.();
  }, [useDefaultZoomIn, zoomOptions.max]);

  const zoomOut = React.useCallback(() => {
    if (useDefaultZoomOut) {
      bodyZoomOut(zoomOptions.min);
    }
    onZoomOut?.();
  }, [useDefaultZoomOut, zoomOptions.min]);

  React.useEffect(() => {
    if (!contrastModeOptions.length) {
      return;
    }

    if (useDefaultToggleContrastMode) {
      // TODO Colocar aqui a interação com o provedor de tema para mudar o modo do contrast
    }
    document.body.style['-moz-transform-origin'] = 'left center';
    document.body.style['transform-origin'] = 'left center';
    onToggleContrastMode?.(contrastModeOptions[contrastMode]);
  }, [
    contrastMode,
    useDefaultToggleContrastMode,
    contrastModeOptions,
    onToggleContrastMode,
  ]);

  React.useEffect(() => {
    onToggleLibras?.(shownLibras);
  }, [shownLibras, onToggleLibras]);

  return {
    contrastModeIndicator,
    toggleContrastMode,
    toggleLibras,
    zoomIn,
    zoomOut,
  };
}
