import * as React from 'react';
import { BaSeTheme } from '../../theme';
import { StepContentProps } from './step-content';
import {
  BaSeStepHeader,
  StepHeaderProps,
  StepperContentToRender,
} from './step-header';
import {
  StepperContainer,
  StepsContainer,
  TypeTransitionProps,
} from './stepper-styled';

export type StepProps = Pick<
  StepHeaderProps,
  | 'title'
  | 'isDisabled'
  | 'isClickable'
  | 'isCompleted'
  | 'tooltip'
  | 'withError'
> &
  Partial<Pick<StepContentProps, 'content'>>;

export interface StepperProps
  extends Pick<
    StepHeaderProps,
    | 'activeColor'
    | 'completedColor'
    | 'uncompletedColor'
    | 'orientation'
    | 'activeWithErrorColor'
    | 'uncompletedWithErrorColor'
    | 'completedWithErrorColor'
  > {
  steps: StepProps[] | number;
  currentStep: number;
  withTransition?: boolean;
  onStepChange: (step: number) => void;
}

export const BaSeStepper: React.FC<StepperProps> = ({
  steps: initialStepper,
  activeColor,
  completedColor,
  uncompletedColor,
  currentStep = 1,
  orientation = 'horizontal',
  withTransition = false,
  onStepChange,
  activeWithErrorColor,
  completedWithErrorColor,
  uncompletedWithErrorColor,
}: StepperProps) => {
  const [lastActivatedTabIndex, setLastActivatedTabIndex] =
    React.useState(currentStep);
  const [transition, setTransition] =
    React.useState<TypeTransitionProps>('inert');

  const steps = React.useMemo(() => {
    if (typeof initialStepper === 'number') {
      return Array(initialStepper).fill({});
    }
    return initialStepper;
  }, [initialStepper]);

  if (!Array.isArray(steps) || steps?.length === 0) {
    throw Error('Prop "steps" é obrigatória');
  }

  if (!onStepChange) {
    throw Error('Prop "onStepChange" é obrigatória');
  }

  React.useEffect(() => {
    onStepChange(currentStep);
    if (withTransition) {
      setTransition(() =>
        lastActivatedTabIndex < currentStep ? 'leftToRight' : 'rightToLeft',
      );
      setTimeout(() => {
        setTransition(() => 'inert');
      }, BaSeTheme.transitions.durationNormalInMilliseconds);
    }
    setLastActivatedTabIndex(currentStep);
  }, [currentStep]);

  return (
    <StepperContainer orientation={orientation}>
      <nav>
        <StepsContainer orientation={orientation}>
          {steps.map(
            (
              {
                title,
                isDisabled,
                isClickable,
                isCompleted,
                tooltip,
                withError,
              },
              index,
            ) => (
              <React.Fragment key={index}>
                <BaSeStepHeader
                  activeWithErrorColor={activeWithErrorColor}
                  completedWithErrorColor={completedWithErrorColor}
                  uncompletedWithErrorColor={uncompletedWithErrorColor}
                  steps={steps}
                  withError={withError}
                  isDisabled={isDisabled}
                  isClickable={isClickable}
                  tooltip={tooltip}
                  title={title}
                  currentStep={index + 1}
                  activeColor={activeColor}
                  completedColor={completedColor}
                  uncompletedColor={uncompletedColor}
                  isActive={currentStep === index + 1}
                  isCompleted={isCompleted ?? currentStep > index + 1}
                  orientation={orientation}
                  onActivated={() => onStepChange(index + 1)}
                  onStepChange={onStepChange}
                  transition={transition}
                />
              </React.Fragment>
            ),
          )}
        </StepsContainer>
      </nav>
      {orientation === 'horizontal' && (
        <StepperContentToRender
          orientation={orientation}
          transition={transition}
          steps={steps}
          currentStep={currentStep}
          onStepChange={onStepChange}
        />
      )}
    </StepperContainer>
  );
};

BaSeStepper.displayName = 'BaSeStepper';
