import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { BaSeScrollbarCss } from '../../theme/scrollbar';
import {
  mapBaseTextAreaFontSize,
  mapBaseTextAreaColor,
} from './map-text-area-style';
import { ThemeColorValue } from '../../theme/theme-interface';

interface StyledOuterTextAreaBoxProps {
  hasError: boolean;
  hasFocus: boolean;
  disabled: boolean;
}

interface StyledTextAreaProps {
  size: 'medium' | 'small';
  hasError?: boolean;
}

export const StyledTextArea = styled.textarea.attrs({
  className: 'BaSe--text-area',
})<StyledTextAreaProps>`
  padding-right: 8px;
  resize: none;
  width: 100%;
  height: 100%;

  font-family: ${BaSeTheme.typography.fontFamily};
  font-weight: 400;
  box-sizing: border-box;
  border: none;
  font-size: ${(props) =>
    mapBaseTextAreaFontSize[props.size]?.size ??
    mapBaseTextAreaFontSize.default.size}px;
  line-height: ${(props) =>
    mapBaseTextAreaFontSize[props.size]?.lineHeight ??
    mapBaseTextAreaFontSize.default.lineHeight}px;

  &:not(:disabled) {
    color: ${(props) =>
      props.hasError
        ? mapBaseTextAreaColor.error.color
        : mapBaseTextAreaColor.default.color};
  }

  &:focus {
    color: ${(props) =>
      props.hasError
        ? mapBaseTextAreaColor.error.color
        : BaSeTheme.colors.institucionais.azulSebrae36};
    outline: none !important;
  }

  ${BaSeScrollbarCss}

  &:disabled {
    background: ${mapBaseTextAreaColor.disabled.backgroundColor};
    color: ${mapBaseTextAreaColor.disabled.color};
  }
`;

export const StyledOuterTextAreaBox = styled.div<StyledOuterTextAreaBoxProps>`
  background: ${BaSeTheme.colors.defaultColors.white};
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.hasError && !props.disabled
        ? mapBaseTextAreaColor.error.borderColor
        : props.hasFocus
          ? mapBaseTextAreaColor.focus.borderColor
          : mapBaseTextAreaColor.default.borderColor};
  padding: 8px;
  ${(props) =>
    props.disabled &&
    css`
      background: ${mapBaseTextAreaColor.disabled.backgroundColor};
    `}
  ${(props) => props.disabled && 'pointer-events:none'}
  box-shadow: ${(props) =>
    props.hasFocus &&
    (props.hasError
      ? BaSeTheme.shadows.focusError
      : BaSeTheme.shadows.focusPrimary)}
`;

export const StyledTextAreaContainer = styled.div`
  position: relative;
`;

export const StyledTextAreaLabel = styled.label.attrs({
  className: 'BaSe--text-area-label',
})<StyledTextAreaProps>`
  margin: 0;
  padding: 0;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
  line-height: 23px;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
  margin-bottom: 2px;
  font-size: ${(props) => (props.size === 'medium' ? '16px' : '13px')};
`;

export const StyledTextAreaLabelContainer = styled.div.attrs({
  className: 'BaSe--text-area-label-container',
})`
  display: flex;
`;

export const StyledTextAreaSubLabel = styled.label.attrs({
  className: 'BaSe--text-area-sub-label',
})<StyledTextAreaProps>`
  margin: 0;
  padding: 0;
  font-size: ${(props) => (props.size === 'medium' ? '16px' : '13px')};
  line-height: 23px;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-weight: normal;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae60};
  font-style: italic;
  margin-left: 4px;
  margin-bottom: 2px;
`;

export const StyledTextAreaErrorText = styled.span`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${BaSeTheme.colors.feedbackInterface.erro35};
  margin-top: 2px;
  grid-column: 1/2;
`;

export const StyledTextAreaFooter = styled.div<{ showMultiple: boolean }>`
  ${({ showMultiple }) => css`
    display: grid;
    grid-template-columns: 1fr ${showMultiple && '1fr'};
  `}
`;

export const StyledTextAreaFooterLimit = styled.div<{ color: ThemeColorValue }>`
  grid-column: 2/3;
  display: flex;
  justify-content: flex-end;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-top: 2px;
  color: ${({ color }) => color};
`;

export const StyledTextAreaFooterNumbers = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  color: #45586a;
`;

export const ButtonInfoContainer = styled.div`
  margin-left: 5.33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MoreInfoContainer = styled.div`
  padding-top: 4px;
`;

export const WrapperHelper = styled.div`
  padding-top: 2px;
`;
