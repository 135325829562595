import styled, { css } from 'styled-components';
import { Color } from '../../utils/color-transformation/color';
import { BaSeTheme } from '../../theme';

interface ColorProps {
  backgroundColor?: string;
  textColor?: string;
  linkColor?: string;
  separatorColor?: string;
  versionColor?: string;
  mobileHeaderColor?: string;
}

interface DirectionProps {
  direction: string;
}

const validateColor = (color: string) => {
  const customColorHsv = Color.generateFromHex(color);
  return color && customColorHsv;
};

const defaultColor = {
  background: BaSeTheme.colors.institucionais.cinzaSebrae97,
  link: BaSeTheme.colors.institucionais.azulSebrae36,
  text: BaSeTheme.colors.institucionais.cinzaSebrae60,
  version: BaSeTheme.colors.institucionais.cinzaSebrae75,
  mobileHeader: BaSeTheme.colors.institucionais.azulSebrae36,
  separator: BaSeTheme.colors.gestao.cinza85,
};

export const FooterWrapper = styled.footer.attrs({
  className: 'BaSe--footer',
})<ColorProps>`
  &.BaSe--footer {
    font-family: ${BaSeTheme.typography.fontFamily};
    width: 100%;
    background-color: ${({ backgroundColor }) =>
      backgroundColor && validateColor(backgroundColor)
        ? backgroundColor
        : defaultColor.background};
    display: grid;
    place-items: center;
  }
`;

export const CollapsedSubFooter = styled.div`
  justify-self: start;
  padding: 1em;
  a,
  p {
    padding: 0.5em 0;
    justify-content: start;
  }
`;

export const CollapsedUpperFooter = styled.div`
  width: 100%;
  p,
  a {
    padding-left: 0;
  }
`;

export const TextFooter = styled.div<DirectionProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  padding: ${({ direction }) => (direction === 'row' ? '13px 0px' : '0px')};
`;

export const UpperHeader = styled.div<ColorProps>`
  background-color: ${({ mobileHeaderColor }) =>
    mobileHeaderColor && validateColor(mobileHeaderColor)
      ? mobileHeaderColor
      : defaultColor.mobileHeader};
  padding: 18px 16px;
  color: #ffffff;
  font-size: 13px;
`;

export const YearText = styled.p<ColorProps>`
  color: ${({ textColor }) =>
    textColor && validateColor(textColor) ? textColor : defaultColor.text};
  font-size: 13px;
  a {
    padding: 0;
  }
`;

export const SystemFooter = styled.p<ColorProps>`
  color: ${({ versionColor }) =>
    versionColor && validateColor(versionColor)
      ? versionColor
      : defaultColor.version};
  font-size: 11px;
`;

export const RelationFooter = styled.div<DirectionProps>`
  font-size: 13px;
  align-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: ${({ direction }) =>
    direction === 'row' ? 'flex-end' : 'flex-start'};
  text-align: ${({ direction }) => (direction === 'row' ? 'end' : 'start')};
`;

export const Line = styled.hr<ColorProps>`
  border: none;
  border-bottom: 1px solid
    ${({ separatorColor }) =>
      separatorColor && validateColor(separatorColor)
        ? separatorColor
        : defaultColor.separator};
  width: 100%;
  margin: 0px;
`;

export const Separator = styled(Line)`
  transform: translateY(16px);
`;

export const FooterLinks = styled.div<DirectionProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ direction }) => (direction === 'row' ? 'center' : 'start')};
  justify-self: start;
  text-align: center;

  ${({ direction }) =>
    direction === 'column' &&
    css`
      padding: 0;
      p:not(:last-child),
      a:not(:last-child) {
        border: none;
      }
    `}
`;

export const FooterLink = styled.p<ColorProps>`
  font-size: 13px;
  text-decoration: none;
  color: ${({ linkColor }) =>
    linkColor && validateColor(linkColor) ? linkColor : defaultColor.link};
  cursor: pointer;
  padding: 4px 16px;
  :not(:last-child) {
    border-right: 1px solid
      ${({ separatorColor }) =>
        separatorColor && validateColor(separatorColor)
          ? separatorColor
          : defaultColor.separator};
  }
  :first-child {
    padding-left: 0;
  }
`;

export const FooterContent = styled.div`
  width: 100%;
`;
