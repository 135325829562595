export const cnpjValidator = (value: string) => {
  value = String(value);
  const cnpj = value ? value.replace(/[^\d]+/g, '') : '';

  if (cnpj === '') {
    return false;
  }

  const MAX_LENGTH = 14;

  if (cnpj.length !== MAX_LENGTH) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj.length !== MAX_LENGTH ||
    cnpj === '0'.repeat(MAX_LENGTH) ||
    cnpj === '1'.repeat(MAX_LENGTH) ||
    cnpj === '2'.repeat(MAX_LENGTH) ||
    cnpj === '3'.repeat(MAX_LENGTH) ||
    cnpj === '4'.repeat(MAX_LENGTH) ||
    cnpj === '5'.repeat(MAX_LENGTH) ||
    cnpj === '6'.repeat(MAX_LENGTH) ||
    cnpj === '7'.repeat(MAX_LENGTH) ||
    cnpj === '8'.repeat(MAX_LENGTH) ||
    cnpj === '9'.repeat(MAX_LENGTH)
  ) {
    return false;
  }

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    const num = Number(numeros.charAt(tamanho - i));
    soma += num * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    const num = Number(numeros.charAt(tamanho - i));
    soma += num * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) {
    return false;
  }
  return true;
};
