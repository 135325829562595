import styled from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { DataHeaderTitle, DataInterfaceProps } from '../data-styled';

export interface DataSelectionCounterProps {
  fadeIn: boolean;
}

export interface DataSelectOrUnselectAllAreaProps
  extends Pick<DataInterfaceProps, 'backgroundColor'> {}

export const DataHeaderTitleSelection = styled(DataHeaderTitle)`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const DataSelectOrUnselectAllItem = styled(DataHeaderTitleSelection)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;

export const DataSelectionCounter = styled.span<DataSelectionCounterProps>`
  flex: 1 100%;
  opacity: ${({ fadeIn }) => (fadeIn ? 1 : 0)};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DataSelectOrUnselectAllArea = styled.span<DataSelectOrUnselectAllAreaProps>`
  display: grid;
  place-items: center;
  overflow: hidden;
  height: 50px;
  margin-bottom: 0;
  will-change: height, margin-bottom;
  transition: height ${`
    ${BaSeTheme.transitions.durationFast}
    ${BaSeTheme.transitions.continuousTimingFunction}
  `};
  background-color: ${({ backgroundColor }) => backgroundColor};

  &:empty {
    height: 0;
    margin-bottom: -100%;
  }
`;
