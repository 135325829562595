export const mapCounterSize = {
  small: {
    padding: 30,
  },
  big: {
    padding: 46,
  },
  medium: {
    padding: 38,
  },
};
