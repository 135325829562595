import styled from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { numberWithUnit } from '../../../utils/string-utils';

export interface PopupButtonContainerProps {
  size?: number | string;
}

const handleProps = (size: string | number | undefined) => {
  if (!size) {
    return undefined;
  }
  return numberWithUnit(size);
};

export const HelperButtonContainer = styled.div.attrs({
  className: 'BaSe--helper-button-container',
})`
  position: relative;
`;

export const HelperButtonIconContainer = styled.div<PopupButtonContainerProps>`
  cursor: pointer;
  button {
    font-family: ${BaSeTheme.typography.fontFamily};
    font-size: ${BaSeTheme.typography.fontSizeBase};
    line-height: ${BaSeTheme.typography.lineHeightBase};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 4px;
  }

  img {
    width: ${(props) => handleProps(props.size) ?? '13px'};
    height: ${(props) => handleProps(props.size) ?? '13px'};
  }
`;
