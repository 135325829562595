import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../utils/string-utils';

export interface BoardListWrapperProps {
  length: number;
  itemMinWidth?: number | string;
  itemMaxWidth?: number | string;
  loadingValidDrop: boolean;
}

export interface BoardListColumnProps {
  bgColor?: string;
  bgColorHeader?: string;
}

export const BoardListsWrapper = styled.section.attrs({
  className: 'BaSe--board',
})<BoardListWrapperProps>`
  &.BaSe--board {
    gap: 1em;
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(
      ${({ length }) => length},
      minmax(
        ${({ itemMinWidth }) => numberWithUnit(itemMinWidth, 'px', '300px')},
        ${({ itemMaxWidth }) => numberWithUnit(itemMaxWidth, 'px', '1fr')}
      )
    );
    height: 100%;
    overflow-x: scroll;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      pointer-events: none;
      z-index: 1;
      display: ${({ loadingValidDrop }) =>
        loadingValidDrop ? 'block' : 'none'};
    }
  }
`;

export const BoardItemsWrapper = styled.section.attrs({
  className: 'BaSe--board-items',
})`
  &.BaSe--board-items {
    gap: 1em;
    padding: 1em;
    display: flex;
    flex-direction: column;
  }
`;

export const BoardWithoutItems = styled.section.attrs({
  className: 'BaSe--board-without-items',
})<{ bgColor: string | undefined }>`
  &.BaSe--board-without-items {
    ${({ bgColor }) => css`
      background-color: ${bgColor ?? '#F5F5F5'};
    `}

    gap: 1em;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: initial;

    overflow: hidden;

    text-overflow: ellipsis;
  }
`;

export const BoardItemListWrapper = styled.section.attrs({
  className: 'BaSe--board--list--item--wrapper',
})<{ loading: boolean }>`
  &.BaSe--board--list--item--wrapper {
    display: ${({ loading }) => css`
      ${loading ? 'none' : 'block'};
    `};
  }
`;

export const BoardColumnHeader = styled.section.attrs({
  className: 'BaSe--board-column-header',
})<BoardListColumnProps>`
  &.BaSe--board-column-header {
    ${({ bgColorHeader }) => css`
      background-color: ${bgColorHeader ?? '#005EB8'};
    `}

    height: 47px;
    padding: 5px 10px;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    justify-content: space-between;

    position: relative;
  }
`;

export const AlignHeaderTitle = styled.section.attrs({
  className: 'BaSe--board-align-title-header',
})<BoardListColumnProps>`
  &.BaSe--board-align-title-header {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    text-align: center;
    padding: 5px 0;
  }
`;
export const AlignHeaderRight = styled.section.attrs({
  className: 'BaSe--board-align-header-right',
})<BoardListColumnProps>`
  &.BaSe--board-align-header-right {
    position: absolute;
    right: 0;
    top: 48%;
    transform: translateY(-48%);
  }
`;

export const BoardColumnContainer = styled.section.attrs({
  className: 'BaSe--board-column-container',
})`
  &.BaSe--board-column-container {
    padding: 10px;
    display: inline-block;
  }
`;

export const BoardColumnContent = styled.section.attrs({
  className: 'BaSe--board-column-content',
})<BoardListColumnProps>`
  &.BaSe--board-column-content {
    padding: 10px;
    ${({ bgColor }) => css`
      background-color: ${bgColor ?? '#F5F5F5'};
    `}

    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    .BaSe--board-column-content::-webkit-scrollbar {
      display: none;
    }
  }
`;
