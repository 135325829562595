import * as React from 'react';
import { RootContext } from '../contexts/root';
import { useEnv } from '../hooks/env';
import { log } from '../utils/log';
import { AgentConfigOptions, init as initApm } from '@elastic/apm-rum';

function failure(): void {
  log('APM', '⚠️ Não achou a variável "apm" no env.json');
}

function validApmConfig(apmConfig?: any): apmConfig is AgentConfigOptions {
  return !!apmConfig && 'serverUrl' in apmConfig;
}

export const ApmAgent: React.FC = React.memo(({ children }) => {
  const { appName, appVersion } = React.useContext(RootContext);
  const { getEnv } = useEnv();

  React.useEffect(() => {
    log('APM', 'Inicialização do Real User Monitoring APM agent');
    getEnv<AgentConfigOptions>('apm')
      .catch(failure)
      .then((apmConfig) => {
        if (!validApmConfig(apmConfig)) {
          return failure();
        }
        initApm({
          ...apmConfig,
          serviceName: appName,
          serviceVersion: appVersion,
          logLevel: apmConfig.logLevel ?? 'info',
          transactionSampleRate: apmConfig.transactionSampleRate ?? 0.2,
        });
        log('APM', 'APM inicializado');
      });
  }, []);

  return <>{children}</>;
});

ApmAgent.displayName = 'SebraeApmAgent';
