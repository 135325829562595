import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';
import { DatePickerOriginal } from './date-picker-original-style';
import { ColorsCustomProps } from '../input/text/input-styled';
import { numberWithUnit } from '../../utils/string-utils';

export interface DatePickerColor {
  color?: string;
  hoverColor?: string;
  focusColor?: string;
  shadowColor?: string;
}

export interface DatePickerContainerProps
  extends Pick<DatePickerColor, 'color'> {
  borderColor?: string;
}

export interface MaskedDatePickerProps extends ColorsCustomProps {
  size?: number;
}

export interface DatePickProps {
  colorProps?: {
    titleProps?: Pick<DatePickerColor, 'color'>;
    headerProps?: Pick<DatePickerColor, 'color'>;
    arrowProps?: Pick<DatePickerColor, 'color' | 'hoverColor'>;
    containerCardProps?: DatePickerContainerProps;
    daysText?: DatePickerColor;
  };
  isStartDaySameEndDay?: boolean;
  maskInputProp?: MaskedDatePickerProps;
  monthPickNotRange?: boolean;
  alwaysIsOpen?: boolean;
}

const { white } = BaSeTheme.colors.defaultColors;

export const DatePickerWrapper = styled(DatePickerOriginal)<DatePickProps>`
  &,
  & * {
    box-sizing: border-box;
  }

  ${({
    colorProps,
    isStartDaySameEndDay,
    maskInputProp,
    monthPickNotRange,
    alwaysIsOpen,
  }) => css`
    .react-datepicker {
      font-family: ${BaSeTheme.typography.fontFamily};
      border-radius: 4px;
      border: 1px solid ${colorProps?.containerCardProps?.borderColor};
      box-shadow: 0px 0px 4px ${colorProps?.containerCardProps?.borderColor};
      background-color: ${colorProps?.containerCardProps?.color};
    }

    .react-datepicker-popper {
      z-index: ${BaSeTheme.stackOrder.overlay};
    }

    /** Display do mês atual */
    .react-datepicker__current-month {
      font-family: ${BaSeTheme.typography.fontFamily};
      font-weight: bold;
      font-size: 19px;
      line-height: 23px;
      text-align: center;
      color: ${colorProps?.titleProps?.color};
      &::first-letter {
        text-transform: capitalize;
      }
    }
    /** Display do mês atual */

    /**Cabeçalho */
    .react-datepicker__header {
      padding: 16px 12px 0 12px;
      background-color: ${colorProps?.headerProps?.color};
      border-bottom: none;
    }
    .react-datepicker__day-name {
      margin-bottom: 0;
    }
    /**Cabeçalho */

    /** Icones de navegação */

    button.react-datepicker__navigation--previous,
    button.react-datepicker__navigation--next {
      top: 10px;
      display: grid;
      place-items: center;
      border-radius: 32px;
      &:hover {
        background-color: ${colorProps?.daysText?.hoverColor};
      }
    }

    button.react-datepicker__navigation--previous {
      left: 20px;
      span {
        top: -4px;
        left: -4px;
      }
    }
    button.react-datepicker__navigation--next {
      right: 20px;
      span {
        top: -4px;
        left: 4px;
      }
    }

    button.react-datepicker__navigation:focus-visible {
      outline: none;
      box-shadow: 0px 0px 0px 3px ${colorProps?.daysText?.shadowColor};
    }

    .react-datepicker__navigation-icon::before {
      border-color: ${BaSeTheme.colors.defaultColors.transparent};
      border-style: solid;
      border-width: 0;
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
    }

    .react-datepicker__navigation-icon--previous::before {
      border-top: 8px solid ${BaSeTheme.colors.defaultColors.transparent};
      border-bottom: 8px solid ${BaSeTheme.colors.defaultColors.transparent};
      border-right: 8px solid ${colorProps?.arrowProps?.color};
      transform: rotate(0);
    }

    .react-datepicker__navigation-icon--next::before {
      border-top: 8px solid ${BaSeTheme.colors.defaultColors.transparent};
      border-bottom: 8px solid ${BaSeTheme.colors.defaultColors.transparent};
      border-left: 8px solid ${colorProps?.arrowProps?.color};
      transform: rotate(0);
    }

    .react-datepicker__navigation:hover *::before {
      border-color: ${BaSeTheme.colors.defaultColors.transparent};
    }

    .react-datepicker__navigation:hover {
      .react-datepicker__navigation-icon--next::before {
        border-left-color: ${colorProps?.arrowProps?.hoverColor};
      }
      .react-datepicker__navigation-icon--previous::before {
        border-right-color: ${colorProps?.arrowProps?.hoverColor};
      }
    }
    /** Icones de navegação */

    /* Dias do mês e da semana*/

    .react-datepicker__month {
      margin: 0.4rem 0.75rem;
    }

    .react-datepicker__week {
      grid-template-columns: repeat(7, 2rem);
      grid-gap: 0px;
    }

    .react-datepicker__day-names {
      padding-top: 16px;
      padding-left: 3px;
      margin-bottom: 0;
      grid-template-columns: repeat(7, 2rem);
      grid-gap: 0px;
    }

    .react-datepicker__day-name {
      font-family: ${BaSeTheme.typography.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: ${BaSeTheme.typography.fontSizeBase};
      line-height: 1.2em;

      margin: 0px;

      width: 32px;
      /* height: 32px; */
      padding: 8px 0;

      color: ${colorProps?.headerProps?.color};
      &::first-letter {
        text-transform: capitalize;
        color: #333333; // TODO cor não mapeada no base
      }
    }

    .react-datepicker__day-name--outside-month,
    .react-datepicker__day,
    .react-datepicker__day-name,
    .react-datepicker__time-name,
    .react-datepicker__month-text,
    .react-datepicker__year-text {
      font-family: ${BaSeTheme.typography.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;

      margin: 0;
      padding: 0;

      width: 32px;
      /* height: 32px; */
      padding: 8px 0;

      color: ${colorProps?.daysText?.color};
      user-select: none;
      text-align: center;
    }

    .react-datepicker__day-name--outside-month:hover,
    .react-datepicker__day:hover,
    .react-datepicker__time-name:hover,
    .react-datepicker__month-text:hover {
      border-radius: 0;
      background-color: ${colorProps?.daysText?.hoverColor};
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected {
      border-radius: 100%;
      color: ${colorProps?.daysText?.color};
      background-color: ${colorProps?.containerCardProps?.color};
      &:hover {
        background-color: ${colorProps?.daysText?.hoverColor};
      }
    }

    .react-datepicker__day:focus-visible {
      outline: none;
      box-shadow: 0px 0px 0px 3px ${colorProps?.daysText?.shadowColor};
    }

    .react-datepicker__month-text--keyboard-selected {
      outline: none;
      box-shadow: 0px 0px 0px 3px ${colorProps?.daysText?.shadowColor};
    }

    .react-datepicker__month-text--keyboard-selected {
      &:hover {
        background-color: ${colorProps?.daysText?.hoverColor}!important;
      }
    }

    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__month--in-range {
      background-color: ${colorProps?.daysText?.hoverColor};
      border-radius: 0;
    }

    .react-datepicker__day--selected,
    .react-datepicker__month-text--selected,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__year-text--selected,
    .react-datepicker__month--selected {
      font-family: ${BaSeTheme.typography.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;

      margin: 0;
      color: ${colorProps?.daysText?.color};
      background-color: ${colorProps?.containerCardProps?.color};
      &:hover {
        border-radius: 0;
        background-color: ${colorProps?.daysText?.hoverColor};
      }

      ${monthPickNotRange &&
      css`
        position: relative;
        border-radius: 5px;
        color: ${BaSeTheme.colors.defaultColors?.white};
        background-color: ${colorProps?.daysText?.focusColor};
        &:hover {
          border-radius: 5px;
          background-color: ${colorProps?.daysText?.focusColor}!important;
        }
      `}
    }
    .unique-date {
      color: ${BaSeTheme.colors.defaultColors?.white};
      background-color: ${colorProps?.daysText?.focusColor};
      border-radius: 100%;
      &:hover {
        border-radius: 100%;
        background-color: ${colorProps?.daysText?.focusColor};
      }
    }

    .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__year-text--highlighted {
      position: relative;
      border-radius: 100%;
      color: ${BaSeTheme.colors.defaultColors?.white};
      background-color: ${colorProps?.daysText?.focusColor};
      &:hover {
        border-radius: 100%;
        background-color: ${colorProps?.daysText?.focusColor};
      }
    }

    .startDate,
    .react-datepicker__month--range-start {
      position: relative;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: ${isStartDaySameEndDay ? 5 : 1.5}px;
      border-bottom-right-radius: ${isStartDaySameEndDay ? 5 : 1.5}px;
      color: ${BaSeTheme.colors.defaultColors?.white};
      background-color: ${colorProps?.daysText?.focusColor};
      &:hover {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: ${isStartDaySameEndDay ? 5 : 1.5}px;
        border-bottom-right-radius: ${isStartDaySameEndDay ? 5 : 1.5}px;
        background-color: ${colorProps?.daysText?.focusColor}!important;
      }
      ${!isStartDaySameEndDay &&
      css`
        &::after {
          content: '';
          position: absolute;
          border-left: 6px solid ${colorProps?.daysText?.focusColor};
          border-top: 16px solid ${BaSeTheme.colors.defaultColors?.transparent};
          border-bottom: 16px solid
            ${BaSeTheme.colors.defaultColors?.transparent};
          left: 100%;
          top: 0;
          border-radius: 6px;
        }
      `}
    }

    .endDate,
    .react-datepicker__month--range-end {
      position: relative;
      border-top-left-radius: ${isStartDaySameEndDay ? 5 : 1.5}px;
      border-bottom-left-radius: ${isStartDaySameEndDay ? 5 : 1.5}px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: ${BaSeTheme.colors.defaultColors?.white};
      background-color: ${colorProps?.daysText?.focusColor};
      &:hover {
        border-top-left-radius: ${isStartDaySameEndDay ? 5 : 1.5}px;
        border-bottom-left-radius: ${isStartDaySameEndDay ? 5 : 1.5}px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: ${colorProps?.daysText?.focusColor}!important;
      }
      ${!isStartDaySameEndDay &&
      css`
        &::before {
          content: '';
          position: absolute;
          border-right: 6px solid ${colorProps?.daysText?.focusColor};
          border-top: 16px solid ${BaSeTheme.colors.defaultColors?.transparent};
          border-bottom: 16px solid
            ${BaSeTheme.colors.defaultColors?.transparent};
          top: 0;
          right: 100%;
          border-radius: 6px;
        }
      `}
    }

    .react-datepicker__month-in-range,
    .react-datepicker__month-text {
      &:hover {
        background-color: ${colorProps?.daysText?.hoverColor}!important;
      }
    }

    .react-datepicker__month--range-end,
    .react-datepicker__month--range-start {
      &:hover {
        background-color: ${colorProps?.daysText?.focusColor}!important;
      }
    }

    .react-datepicker__day--outside-month {
      color: ${ColorMapper?.grayScale?.focus.defaultLight};
      &.endDate,
      &.startDate {
        color: ${BaSeTheme.colors.defaultColors.white};
      }
      &:empty {
        height: 0;
        overflow: hidden;
        opacity: 0;
        cursor: auto;
        padding: 0 8px;
        &:hover {
          background-color: ${BaSeTheme.colors.defaultColors?.transparent};
        }
      }
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      background-color: ${ColorMapper.grayScale.hover.defaultLight};
      cursor: not-allowed;
      color: ${ColorMapper.grayScale.foreground};
      &:hover {
        background-color: ${ColorMapper.grayScale.hover.defaultLight};
        color: ${ColorMapper.grayScale.foreground};
      }
    }

    /* Dias do mês e da semana*/

    /* Mêses do ano*/

    .react-datepicker__month-text {
      margin: 0 !important;
    }

    /* Mêses do ano*/

    /* Anos */
    /* Anos */

    /*Cores do arrow */
    .react-datepicker-popper[data-placement^='bottom']
      .react-datepicker__triangle::before {
      border-bottom-color: ${colorProps?.containerCardProps?.borderColor};
    }
    .react-datepicker-popper[data-placement^='bottom']
      .react-datepicker__triangle::after {
      border-bottom-color: ${colorProps?.containerCardProps?.color};
    }

    .react-datepicker-popper[data-placement^='top']
      .react-datepicker__triangle::before {
      border-top-color: ${colorProps?.containerCardProps?.borderColor};
    }
    .react-datepicker-popper[data-placement^='top']
      .react-datepicker__triangle::after {
      border-top-color: ${colorProps?.containerCardProps?.color};
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      top: 0.625em;
    }

    /*Cores do arrow */

    /** Wrapper do date picker */
    .react-datepicker-wrapper {
      display: flex;
    }
    /** Wrapper do date picker */

    /**Deixar com width com 100% de espaço */
    .BaSe--date-picker {
      width: 100%;
    }

    .react-datepicker__input-container {
      input {
        width: 100%;
      }

      > input {
        font-family: ${BaSeTheme.typography.fontFamily};
        font-size: ${BaSeTheme.typography.fontSizeBase};
        line-height: ${BaSeTheme.typography.lineHeightBase};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding-left: 12px;
        padding-right: 12px;
        width: calc(100%);
        color: ${maskInputProp?.color};
        background: ${maskInputProp?.backgroundColor};
        border: 1px solid ${maskInputProp?.borderColor};
        border-radius: 4px;
        height: ${numberWithUnit(maskInputProp?.size)};
        &:focus {
          outline: none !important;
          box-shadow: 0 0 4px ${maskInputProp?.boxShadowFocus};
          border: 1px solid ${maskInputProp?.focusBorderColor};
          color: ${maskInputProp?.focusTextColor};
        }
      }
    }

    .react-datepicker__year-wrapper {
      max-width: 204px;
    }

    .react-datepicker__aria-live {
      position: absolute;
      clip-path: circle(0);
      border: 0;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      width: 1px;
      white-space: nowrap;
      display: none;
    }

    ${alwaysIsOpen &&
    css`
      .react-datepicker {
        border: 1px solid ${BaSeTheme.colors.institucionais.azulSebrae97};
        box-shadow: unset;
        border-radius: 0.5em;
        width: auto;

        .react-datepicker__month-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: inherit;
        }

        .react-datepicker__week,
        .react-datepicker__day-names {
          grid-template-columns: repeat(7, 2.7em);
        }
      }
    `}
  `}

  /** Componente do base de wrapper */
  display: flex;
  flex-flow: column;
  position: relative;
  /** Componente do base de wrapper */
`;

export const DatePickerCalendarContainer = styled.div<{
  width: number;
  top: number;
}>`
  cursor: pointer;
  background-color: ${white};
  ${({ width, top }) => css`
    position: absolute;
    right: 1em;
    top: ${numberWithUnit(top)};
    bottom: 0;
    width: ${numberWithUnit(width)};
    z-index: ${BaSeTheme.stackOrder.aboveNormal};
    display: flex;
    align-items: center;
    height: 1em;
  `}
`;
