import * as React from 'react';
import { BaSeI18nContext } from '../../contexts/i18n';
import { joinWithSeparators } from '../../utils/string-utils';
import {
  BaSeInput,
  IconButtonInputProps,
  InputProps,
} from '../input/text/input';
import { Files } from './file-picker';

export interface FileInputFieldProps
  extends Partial<
    Pick<
      InputProps,
      'label' | 'subLabel' | 'showHelpButton' | 'helpButtonProps' | 'isLoading'
    >
  > {
  files: Files;
  hasFiles: boolean;
  pickMultipleFiles?: boolean;
  pickButtonProps?: Partial<
    Pick<
      IconButtonInputProps,
      'action' | 'color' | 'name' | 'type' | 'typeButton' | 'value'
    >
  >;
  pickInputProps?: Partial<
    Pick<
      InputProps,
      | 'placeholder'
      | 'complement'
      | 'size'
      | 'width'
      | 'align'
      | 'moreInfoLabel'
      | 'moreInfoDetails'
      | 'color'
    >
  >;
  onClickToPick(): void;
  onClickToRemove(): void;
}

export const BaSeFileInputField: React.FC<FileInputFieldProps> = ({
  label,
  subLabel,
  showHelpButton,
  helpButtonProps,
  isLoading,
  files,
  hasFiles,
  pickButtonProps,
  pickInputProps,
  pickMultipleFiles,
  onClickToPick,
  onClickToRemove,
}) => {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const inputPlaceholder = React.useMemo(
    () =>
      pickInputProps?.placeholder ??
      getMessage(
        pickMultipleFiles
          ? 'filePicker.inputPickManyLabel'
          : 'filePicker.inputPickLabel',
      ),
    [pickInputProps?.placeholder, pickMultipleFiles, getMessage],
  );
  const inputValue = React.useMemo(
    () =>
      joinWithSeparators(
        files.map((file) => file.name),
        getMessage('list.separator'),
        getMessage('list.finalSeparator'),
      ),
    [getMessage, files],
  );
  const iconButtonColor = React.useMemo(
    () => pickButtonProps?.color ?? 'default',
    [pickButtonProps?.color],
  );
  const iconButtonName = React.useMemo(
    () => (hasFiles ? 'close-big' : pickButtonProps?.name ?? 'upload'),
    [pickButtonProps?.name, hasFiles],
  );
  const iconButtonTypeButton = React.useMemo(
    () => pickButtonProps?.typeButton ?? 'base-button',
    [pickButtonProps?.typeButton],
  );
  const iconButtonType = React.useMemo(
    () => pickButtonProps?.type ?? 'primary',
    [pickButtonProps?.type],
  );
  const iconButtonValue = React.useMemo(
    () =>
      hasFiles
        ? getMessage('filePicker.buttonDeleteLabel')
        : pickButtonProps?.value ?? getMessage('filePicker.buttonPickLabel'),
    [hasFiles, pickButtonProps?.value, getMessage],
  );

  const iconButtonAction = React.useCallback(() => {
    pickButtonProps?.action?.();

    if (hasFiles) {
      return onClickToRemove();
    }
    onClickToPick();
  }, [hasFiles, pickButtonProps?.action, onClickToPick, onClickToRemove]);

  return (
    <BaSeInput
      {...pickInputProps}
      isReadOnly={true}
      isLoading={isLoading}
      placeholder={inputPlaceholder}
      value={inputValue}
      label={label}
      subLabel={subLabel}
      showHelpButton={showHelpButton}
      helpButtonProps={helpButtonProps}
      iconButton={{
        color: iconButtonColor,
        name: iconButtonName,
        typeButton: iconButtonTypeButton,
        type: iconButtonType,
        value: iconButtonValue,
        action: iconButtonAction,
      }}
    />
  );
};

BaSeFileInputField.displayName = 'BaSeFileInputField';
