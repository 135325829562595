import * as React from 'react';
import { BaSeI18nContext } from '../../contexts/i18n';
import {
  ThemeNamedColorOrColorValue,
  ThemeNamedType,
} from '../../theme/theme-interface';
import { BaSeIcon } from '../image/icon';
import {
  Badge,
  BadgeContainer,
  BadgeContent,
  defaultBorderRadius,
  initialPosition,
  paddingWithIcon,
  paddingWithValue,
  paddingWithoutAnything,
} from './badge-style';
import { mapBadgeValues } from './map-badge-style';

export interface PositionProps {
  right?: string | number;
  top?: string | number;
  left?: string | number;
  bottom?: string | number;
}

export interface IconProps {
  name: string;
  description: string;
  size?: string | number;
}

export interface BadgeProps {
  color?: ThemeNamedColorOrColorValue;
  value?: string | number | IconProps;
  position?: PositionProps;
  type?: ThemeNamedType;
  show?: boolean;
  borderRadius?: number | string;
  width?: number | string;
  height?: number | string;
}

export const BaSeBadge: React.FC<BadgeProps> = (props) => {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const {
    color: textColor,
    style: { bgC, border },
  } = React.useMemo(
    () =>
      mapBadgeValues((props.color as string) ?? 'default')[
        props.type ?? 'primary'
      ],
    [props.color, props.type],
  );

  const showBadge = React.useMemo(() => props.show ?? true, [props.show]);

  const padding = React.useMemo(
    () =>
      !props.value
        ? paddingWithoutAnything
        : typeof props.value === 'object'
          ? paddingWithIcon
          : paddingWithValue,
    [props.value],
  );

  return (
    <BadgeContainer>
      {props.children}

      {showBadge && (
        <Badge
          right={props.position?.right ?? initialPosition}
          left={props.position?.left ?? initialPosition}
          bottom={props.position?.bottom ?? initialPosition}
          top={props.position?.top ?? initialPosition}
          bgC={bgC}
          border={border}
          color={textColor}
          padding={padding}
          borderRadius={props.borderRadius ?? defaultBorderRadius}
          width={props.width}
          height={props.height}
        >
          <BadgeContent>
            {typeof props.value === 'object' ? (
              <BaSeIcon
                color={textColor}
                name={props.value.name}
                size={props.value.size ?? 16}
                description={
                  props.value.description ??
                  getMessage('icon.label', getMessage('badge.marker'))
                }
              />
            ) : (
              props.value
            )}
          </BadgeContent>
        </Badge>
      )}
    </BadgeContainer>
  );
};

BaSeBadge.displayName = 'BaSeBadge';
