import * as React from 'react';
import { BaSeTheme } from '../../../theme';
import { getFormattedDataset } from '../../../utils/dataset-utils';
import { mapCustomColorsRadioValues, mapRadioValues } from './map-radio-styled';
import {
  ContainerRadio,
  InputRadio,
  LabelRadio,
  RadioMarker,
  RadioShapeProps,
  RadioWrapper,
} from './radio-styled';

export type RadioId = number | string;

export interface RadioProps {
  id: RadioId;
  name: string;
  dataset?: DOMStringMap;
  label?: string;
  size?: 'medium' | 'small';
  checked?: boolean;
  disabled?: boolean;
  labelWidth?: string;
  color?: string;
  shape?: RadioShapeProps;
  onChange?: (id: RadioId) => void;
}

export const BaSeRadio: React.FC<RadioProps> = ({
  id,
  name,
  dataset,
  label = '',
  labelWidth = 'auto',
  size = 'medium',
  checked = false,
  disabled = false,
  color = 'default',
  shape = 'circle',
  onChange = () => {},
}) => {
  if (!name) {
    throw Error('Atributo "name" é obrigatório no BaSeRadio');
  }
  if (!id) {
    throw Error('Atributo "id" é obrigatório no BaSeRadio');
  }

  const [hover, setHover] = React.useState(false);

  const formattedDataset = getFormattedDataset(dataset);

  const changeValue = () => onChange(id);

  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.destructive ||
    color === BaSeTheme.components.button.colors.confirmation ||
    color === BaSeTheme.components.button.colors.negative;
  const getColorsAtributes = () => {
    if (color) {
      if (!colorIsMapped) {
        return mapCustomColorsRadioValues(color).primary;
      } else {
        if (color === BaSeTheme.components.button.colors.negative) {
          return mapRadioValues('default').primary?.negative;
        }
        return mapRadioValues(color).primary?.generalColors;
      }
    } else {
      return mapRadioValues('default').primary.generalColors;
    }
  };
  const buttonValues = disabled
    ? color === 'negative'
      ? mapRadioValues('disabled').primary.negative
      : mapRadioValues('disabled').primary.generalColors
    : getColorsAtributes();

  const {
    style: { bgC, border, bcCHover },
    iconColor,
    // color: buttonColor,
  } = buttonValues;

  return (
    <RadioWrapper className="BaSe--radio">
      <ContainerRadio
        className={`BaSe--radio-marker-container ${hover ? 'hover' : ''}`}
        shape={shape}
        checked={checked}
        disabled={disabled}
        backgroundColor={bgC}
        border={border}
        iconColor={iconColor}
        backgroundOnHover={bcCHover}
      >
        <InputRadio
          className="BaSe--radio-input"
          onChange={changeValue}
          id={id as string}
          name={name}
          {...formattedDataset}
          value={id}
          checked={checked}
          aria-checked={checked}
          disabled={disabled}
        />
        <RadioMarker
          className="BaSe--radio-marker"
          shape={shape}
          checked={checked}
          disabled={disabled}
          backgroundColor={bgC}
          border={border}
          backgroundOnHover={bcCHover}
          iconColor={iconColor}
        />
      </ContainerRadio>
      <LabelRadio
        className="BaSe--radio-label"
        disabled={disabled}
        size={size}
        width={labelWidth}
        onClick={() => !disabled && onChange(id)}
        onPointerEnter={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
      >
        {label}
      </LabelRadio>
    </RadioWrapper>
  );
};

BaSeRadio.displayName = 'BaSeRadio';
