import { BaSeTheme } from '../../../theme';
import {
  createColorSmartLightingControl,
  getHSLValues,
} from '../../../utils/color-transformation/calculate-pattern';
import { ColorMapper } from '../../../utils/color-transformation/color-mapper';

// relative: { s: 100, l: 36 },
// normalText: { s: 16, l: 45 },

const defaultDetailsColor = BaSeTheme.colors.institucionais.cinzaSebrae45;

export const colorMappedCustom = (color?: string) => {
  if (!color) {
    return defaultDetailsColor;
  }
  const mappedTypes = {
    destructive: ColorMapper.destructive.foreground,
    confirmation: ColorMapper.confirmation.foreground,
    attention: ColorMapper.attention.foreground,
    default: ColorMapper.default.foreground,
  };
  const toTransformColor = mappedTypes[color] ?? color;
  const originalValues = getHSLValues(toTransformColor);
  if (!originalValues) {
    return defaultDetailsColor;
  }
  let { h, s, l } = originalValues;
  h /= 100;
  s /= 100;
  l /= 100;
  return createColorSmartLightingControl(
    toTransformColor,
    { h, s: s * 0.16, l: l + (1 - l) / (64 / 9) },
    'lighter',
  );
};
