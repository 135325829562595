import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';

const version = 'v1';

const endpointUfs = `/${version}/ufs`;

export function useUf() {
  const { backendGet } = Sebrae.useBackend();

  const getUfs = React.useCallback(
    () => backendGet(`${endpointUfs}`),
    [backendGet],
  );

  const getRecuperarUfsUsuarioAutenticado = React.useCallback(
    () => backendGet(`${endpointUfs}/usuario-autenticado`),
    [backendGet],
  );

  const loadUnidadeSebrae = React.useCallback(
    (setUfs) => {
      getUfs().then((ufs) => {
        const list = [
          { id: 'NA', label: 'NA' },
          ...ufs.map((uf) => ({ id: uf.sigla, label: uf.sigla })),
        ];
        setUfs(list);
      });
    },
    [getUfs],
  );

  return { loadUnidadeSebrae, getRecuperarUfsUsuarioAutenticado };
}
