import * as React from 'react';
import { BaSeBreakpointConsumer } from '../../contexts/breakpoint';
import { BaSeI18nContext } from '../../contexts/i18n';
import { useNextHashId } from '../../hooks/next-id';
import { useOutsideEvent } from '../../hooks/outside-event';
import { BaSeTheme } from '../../theme';
import { idGenerator } from '../../utils/id-generator';
import { joinWithSeparators, normalize } from '../../utils/string-utils';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { BaSeHelperText } from '../helpers/helper-text/helper-text';
import { BaSePopupButton } from '../helpers/popup-button/popup-button';
import { BaSeIcon } from '../image/icon';
import { BaSeCheckbox } from '../input/checkbox/checkbox';
import { BaSeInput } from '../input/text/input';
import { BaSeParagraph } from '../typography/paragraph/paragraph';
import { BaSeSmall1 } from '../typography/small/small1';
import { mapBaSeSelectColor, mapSelectValues } from './map-select-style';
import { SelectProps, SelectValue } from './multiple-select';
import {
  OptionPickerBoxWrapper,
  OptionPickerComplementWrapper,
  OptionPickerExtraActionButton,
  OptionPickerExtraActionsHeaderWrapper,
  OptionPickerFilterContainer,
  OptionPickerHeaderLabelContainer,
  OptionPickerInfoWrapper,
  OptionPickerListUl,
  OptionPickerOuterList,
} from './option-picker-style';
import {
  ButtonInfoContainerHelper,
  DetailButtonWrapper,
  HideTopOfWrapperOptions,
  MoreInfoContainer,
  OptionDetail,
  OptionDetailButton,
  OptionDetailHeader,
  SelectInput,
  StyledLabel,
  StyledSubLabel,
  WrapperHelper,
  WrapperIcon,
  WrapperInputLabel,
  WrapperMultipleInformation,
  WrapperOption,
  WrapperSelect,
} from './select-styled';
import { getFormattedDataset } from '../../utils/dataset-utils';

const idSequence = idGenerator();

interface OptionPickerExtraActions {
  label: string;
  action: () => void;
  icon?: string;
}

export interface OptionPickerItem extends SelectValue {
  complement?: string;
  detail?: {
    title: string;
    description: string;
  };
}

export interface OptionPickerProps
  extends Omit<SelectProps, 'values' | 'searchable' | 'removeEmptyValue'> {
  values: OptionPickerItem[];
  headerLabel?: string;
  extraActions?: OptionPickerExtraActions[];
  enableSelectAll?: boolean;
  maxMenuHeight?: string;
  maxOptionsSelected?: number;
  showItemDetailButton?: boolean;
  searchPlaceholder?: string;
  optionNotFoundLabel?: string;
  menuAlwaysIsOpen?: boolean;
}

export const BaSeOptionPicker: React.FC<OptionPickerProps> = ({
  id: externalId,
  name,
  dataset,
  isDisabled,
  width = null,
  size = 'medium',
  label,
  helpButtonProps,
  showHelpButton,
  subLabel,
  color,
  overrideColor = {},
  initialSelectedValuesId = [0],
  hasError = false,
  emptyValueLabel = '',
  complement = '',
  moreInfoLabel = '',
  moreInfoDetails = '',
  values = [],
  headerLabel = '',
  extraActions = [],
  enableSelectAll = true,
  maxMenuHeight = '200px',
  maxOptionsSelected,
  showItemDetailButton = false,
  searchPlaceholder = '',
  optionNotFoundLabel = '',
  menuAlwaysIsOpen = false,
  onChange = () => {},
}) => {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const [isOpen, setIsOpen] = React.useState<boolean>(menuAlwaysIsOpen);
  const [colorType, setColorType] = React.useState('default');
  const [currentExtraFocus, setCurrentExtraFocus] = React.useState<number[]>(
    [],
  );

  const [inputFilter, setInputFilter] = React.useState<string>('');

  const [selectedValuesId, setSelectedValuesId] = React.useState(
    initialSelectedValuesId,
  );

  const [refValues, setRefValues] = React.useState<
    (OptionPickerItem & { ref?: React.RefObject<HTMLLIElement> })[]
  >([]);

  const handleIsOpen = React.useCallback(
    (openOrClose) => {
      if (!menuAlwaysIsOpen) {
        setIsOpen(openOrClose);
      }
    },
    [menuAlwaysIsOpen],
  );

  const inputFilterRef = React.useRef<HTMLInputElement>(null);
  const selectInputRef = React.useRef<HTMLInputElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useOutsideEvent<HTMLDivElement>(wrapperRef, handleIsOpen);
  const id = externalId ?? useNextHashId(idSequence);

  const formattedDataset = getFormattedDataset(dataset);

  const getColorsAtributes = () => {
    if (color) {
      return mapSelectValues(color);
    }
    return mapSelectValues('default');
  };

  const updateValue = React.useCallback(
    (selected: any) => {
      if (maxOptionsSelected === 1) {
        setSelectedValuesId([selected.id]);
        handleIsOpen(false);
        return;
      }

      if (selectedValuesId.indexOf(selected.id) > -1) {
        return setSelectedValuesId((input) => {
          return input.filter((val) => val !== selected.id);
        });
      }
      if (
        !maxOptionsSelected ||
        (selectedValuesId.length < maxOptionsSelected && maxOptionsSelected > 0)
      ) {
        setSelectedValuesId((input) => input.concat([selected.id]));
      }
    },
    [selectedValuesId, maxOptionsSelected],
  );

  const onFocusInput = React.useCallback(
    () => handleIsOpen(!isDisabled && true),
    [isDisabled],
  );

  const {
    style: { bcCHover, bgC, boxShadowFocus, border },
  } = getColorsAtributes();

  const handleFilter = React.useCallback(
    (item: OptionPickerItem) => {
      return (
        !inputFilter ||
        normalize(
          item.label +
            (item?.complement ?? '') +
            (item?.detail?.description ?? '') +
            (item?.detail?.title ?? ''),
        ).includes(normalize(inputFilter))
      );
    },
    [inputFilter],
  );

  const selectAll = React.useCallback(() => {
    const filteredValues = refValues.filter(handleFilter);
    if (filteredValues.length > selectedValuesId.length) {
      setSelectedValuesId(filteredValues.map((option) => option.id));
    } else {
      setSelectedValuesId([]);
    }
  }, [refValues, selectedValuesId, handleFilter]);

  function emitChange(): void {
    const emitValues = values.filter(
      (val) => selectedValuesId.indexOf(val.id) > -1,
    );
    onChange(emitValues);
  }

  const addIndexExtra = React.useCallback((index: number) => {
    if (currentExtraFocus.findIndex((i) => i === index) === -1) {
      setCurrentExtraFocus((prev) => [...prev, index]);
    }
  }, []);

  const removeIndexExtra = React.useCallback(
    (index: number) => {
      const auxCurrentExtraFocus = [...currentExtraFocus];
      const ind = auxCurrentExtraFocus.findIndex((i) => i === index);
      if (ind > -1) {
        auxCurrentExtraFocus.splice(ind, 1);
      }
      setCurrentExtraFocus([...auxCurrentExtraFocus]);
    },
    [currentExtraFocus],
  );

  const indexInExtra = React.useCallback(
    (index: number) => {
      return currentExtraFocus.findIndex((i) => i === index) !== -1;
    },
    [currentExtraFocus],
  );

  const extraActionCall = React.useCallback((action: () => void) => {
    action();
    handleIsOpen(false);
  }, []);

  const checkboxIsDisabled = React.useCallback(
    (opt: OptionPickerItem) => {
      return (
        !!maxOptionsSelected &&
        selectedValuesId.length === maxOptionsSelected &&
        selectedValuesId.indexOf(opt.id) === -1
      );
    },
    [maxOptionsSelected, selectedValuesId],
  );

  const removeDetailClassFromList = React.useCallback(() => {
    refValues.forEach((refVal) => {
      refVal.ref?.current?.classList.remove('detail');
    });
  }, [refValues]);

  const handleKeyDown = React.useCallback(
    (
      event: React.KeyboardEvent<HTMLLIElement>,
      option?: OptionPickerItem & {
        ref?: React.RefObject<HTMLLIElement>;
      },
    ) => {
      const spaceBarKey = event.key === ' ';
      const enterKey = event.key === 'Enter';

      if (enterKey || spaceBarKey) {
        return option ? updateValue(option) : selectAll();
      }
    },
    [updateValue, selectAll],
  );

  React.useEffect(() => {
    setRefValues(
      values.map((item) => ({
        ...item,
        ref: React.createRef<HTMLLIElement>(),
      })),
    );
  }, [values]);

  React.useEffect(() => {
    setSelectedValuesId(initialSelectedValuesId);
  }, [initialSelectedValuesId.toString()]);

  React.useEffect(() => {
    setColorType(isDisabled ? 'disabled' : hasError ? 'error' : 'default');
  }, [hasError, isDisabled]);

  React.useEffect(() => {
    emitChange();
  }, [selectedValuesId.toString()]);

  React.useEffect(() => {
    if (!isOpen) {
      setCurrentExtraFocus([]);
    } else {
      inputFilterRef.current?.focus();
    }
  }, [isOpen, inputFilterRef]);

  return (
    <BaSeBreakpointConsumer>
      {({ inTablet }) => (
        <WrapperSelect ref={wrapperRef} isDisabled={!!isDisabled} width={width}>
          <WrapperInputLabel>
            <StyledLabel
              fontSize={size === 'small' ? '13px' : '16px'}
              lineHeight={size === 'small' ? '16px' : '23px'}
              htmlFor={id}
            >
              {label}
            </StyledLabel>
            <StyledSubLabel htmlFor={id}>{subLabel}</StyledSubLabel>
            {showHelpButton && (
              <ButtonInfoContainerHelper>
                <BaSePopupButton {...helpButtonProps} />
              </ButtonInfoContainerHelper>
            )}
          </WrapperInputLabel>
          {!menuAlwaysIsOpen && (
            <WrapperIcon
              hasLabel={!!label}
              vSize={size}
              isOpen={isOpen}
              searchable={false}
            >
              <BaSeIcon
                description={
                  isOpen
                    ? getMessage('select.closeOptions')
                    : getMessage('select.openOptions')
                }
                name="arrow-head-down"
                size={16}
                onClick={isDisabled ? undefined : () => handleIsOpen(!isOpen)}
                color={
                  isDisabled
                    ? BaSeTheme.colors.institucionais.cinzaSebrae75
                    : overrideColor.iconColor ?? bgC
                }
              />
            </WrapperIcon>
          )}
          <SelectInput
            id={id}
            name={name}
            {...formattedDataset}
            ref={selectInputRef}
            hasSelectedOption={false}
            disabled={isDisabled}
            vSize={size}
            boxShadowFocus={boxShadowFocus}
            readOnly={true}
            border={border}
            bgC={mapBaSeSelectColor[colorType].iconColor}
            colorType={colorType}
            value={
              selectedValuesId.length < 1
                ? emptyValueLabel || getMessage('select.emptyValue')
                : selectedValuesId.length > 3
                  ? getMessage(
                      'select.selectedCounter',
                      selectedValuesId.length,
                    )
                  : joinWithSeparators(
                      refValues
                        .filter((val) => selectedValuesId.indexOf(val.id) > -1)
                        .map((val) => val.label),
                      getMessage('list.separator'),
                      getMessage('list.finalSeparator'),
                    )
            }
            isOpen={isOpen}
            hasError={hasError}
            onChange={(newValue) => updateValue(newValue)}
            onFocus={onFocusInput}
            menuAlwaysIsOpen={menuAlwaysIsOpen}
          />
          {refValues.length > 0 && (
            <>
              {!complement && !moreInfoLabel && isOpen && (
                <HideTopOfWrapperOptions />
              )}
              <OptionPickerBoxWrapper
                hasBorderTop={!!complement || !!moreInfoLabel}
                border={border}
                boxShadowFocus={boxShadowFocus}
                isOpen={isOpen}
                role="menu"
                hasError={hasError}
                colorType={colorType}
                menuAlwaysIsOpen={menuAlwaysIsOpen}
              >
                {isOpen && (
                  <OptionPickerFilterContainer>
                    <BaSeInput
                      placeholder={searchPlaceholder}
                      iconButton={{
                        typeButton: 'base-icon',
                        name: !!inputFilter ? 'times' : 'search',
                        action: () => setInputFilter(''),
                      }}
                      value={inputFilter}
                      ref={inputFilterRef}
                      onChange={setInputFilter}
                    />
                  </OptionPickerFilterContainer>
                )}

                {!!headerLabel && isOpen && (
                  <OptionPickerHeaderLabelContainer>
                    <BaSeSmall1
                      color={BaSeTheme.colors.institucionais.cinzaSebrae60}
                      isBold={true}
                    >
                      {headerLabel}
                    </BaSeSmall1>
                  </OptionPickerHeaderLabelContainer>
                )}
                <OptionPickerOuterList isOpen={isOpen}>
                  <OptionPickerListUl maxHeight={maxMenuHeight} role="listbox">
                    {enableSelectAll && !maxOptionsSelected && (
                      <WrapperMultipleInformation
                        aria-selected={selectedValuesId.length > 0}
                        isOptionPickerItem={true}
                        tabIndex={0}
                        bcCHover={overrideColor.selectHoverColor ?? bcCHover}
                        onKeyDown={handleKeyDown}
                        onClick={(e) => {
                          e.preventDefault();
                          selectAll();
                        }}
                      >
                        <BaSeCheckbox
                          align="top-left"
                          isInteractive={false}
                          color={color}
                          label={
                            inputFilter === ''
                              ? getMessage('select.selectAll')
                              : getMessage('select.selectResults')
                          }
                          size="medium"
                          indeterminate={
                            selectedValuesId.length !== refValues.length
                          }
                          checked={selectedValuesId.length > 0}
                        />
                      </WrapperMultipleInformation>
                    )}
                    {refValues.filter(handleFilter).map((option) => (
                      <WrapperOption
                        aria-selected={selectedValuesId.indexOf(option.id) > -1}
                        displayInfo={!(!inTablet || showItemDetailButton)}
                        ref={option.ref}
                        key={option.id.toString()}
                        multiple={true}
                        bcCHover={overrideColor.selectHoverColor ?? bcCHover}
                        bgC={bgC}
                        isActive={false}
                        role="option"
                        tabIndex={0}
                        isOptionPickerItem={true}
                        onKeyDown={(event) => handleKeyDown(event, option)}
                        onClick={(e) => {
                          e.preventDefault();
                          updateValue(option);
                        }}
                        onPointerEnter={
                          !showItemDetailButton && inTablet
                            ? () => option.ref?.current?.classList.add('detail')
                            : undefined
                        }
                        onPointerLeave={
                          !showItemDetailButton && inTablet
                            ? () =>
                                option.ref?.current?.classList.remove('detail')
                            : undefined
                        }
                      >
                        <OptionPickerInfoWrapper>
                          {(!maxOptionsSelected || maxOptionsSelected > 1) && (
                            <BaSeCheckbox
                              align="top-left"
                              isInteractive={false}
                              label={option.label}
                              color={color}
                              size="medium"
                              disabled={checkboxIsDisabled(option)}
                              checked={selectedValuesId.indexOf(option.id) > -1}
                            />
                          )}
                          {!(!maxOptionsSelected || maxOptionsSelected > 1) && (
                            <BaSeParagraph>{option.label}</BaSeParagraph>
                          )}
                        </OptionPickerInfoWrapper>
                        {option.detail && (
                          <>
                            {(showItemDetailButton || !inTablet) && (
                              <DetailButtonWrapper>
                                <BaSeIcon
                                  color={mapBaSeSelectColor[colorType].item}
                                  name={'question-circle'}
                                  size="1em"
                                  description={getMessage('select.showDetail')}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeDetailClassFromList();
                                    option.ref?.current?.classList.add(
                                      'detail',
                                    );
                                  }}
                                />
                              </DetailButtonWrapper>
                            )}
                            <OptionDetail
                              isOptionPicker={true}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <OptionDetailHeader>
                                <BaSeSmall1 color={bgC} isBold={true}>
                                  {option.detail.title}
                                </BaSeSmall1>
                                <OptionDetailButton>
                                  <BaSeShapeButton
                                    size="extra-small"
                                    nameIcon="close-big"
                                    onClick={() => {
                                      option.ref?.current?.classList.remove(
                                        'detail',
                                      );
                                    }}
                                    type="tertiary"
                                  />
                                </OptionDetailButton>
                              </OptionDetailHeader>
                              <BaSeSmall1
                                color={mapBaSeSelectColor[colorType].item}
                                isThin={true}
                              >
                                {option.detail.description}
                              </BaSeSmall1>
                            </OptionDetail>
                          </>
                        )}
                        {!!option.complement && (
                          <OptionPickerComplementWrapper>
                            <BaSeParagraph
                              isThin={true}
                              color={
                                BaSeTheme.colors.institucionais.cinzaSebrae30
                              }
                            >
                              {option.complement}
                            </BaSeParagraph>
                          </OptionPickerComplementWrapper>
                        )}
                      </WrapperOption>
                    ))}
                    {!refValues.filter(handleFilter).length &&
                      !!optionNotFoundLabel && (
                        <WrapperOption
                          onClick={() => setInputFilter('')}
                          ignoreFocus={true}
                          displayInfo={false}
                          multiple={true}
                          bcCHover={bcCHover}
                          bgC={bgC}
                          isActive={false}
                          isOptionPickerItem={true}
                        >
                          <BaSeParagraph>{optionNotFoundLabel}</BaSeParagraph>
                        </WrapperOption>
                      )}
                  </OptionPickerListUl>
                </OptionPickerOuterList>
                {!!extraActions?.length && isOpen && (
                  <>
                    <OptionPickerExtraActionsHeaderWrapper>
                      <BaSeSmall1
                        isBold={true}
                        color={BaSeTheme.colors.institucionais.cinzaSebrae60}
                      >
                        AÇÕES EXTRAS
                      </BaSeSmall1>
                    </OptionPickerExtraActionsHeaderWrapper>
                    {extraActions.map((exact, index) => (
                      <OptionPickerExtraActionButton
                        isLast={index === extraActions.length - 1}
                        onClick={() => extraActionCall(exact.action)}
                        key={exact.label}
                        onFocus={() => addIndexExtra(index)}
                        onBlur={() => removeIndexExtra(index)}
                      >
                        {!!exact.icon && (
                          <BaSeIcon
                            name={exact.icon}
                            size={20}
                            description={exact.label}
                            color={
                              BaSeTheme.colors.institucionais.cinzaSebrae60
                            }
                          />
                        )}
                        <BaSeParagraph
                          color={BaSeTheme.colors.institucionais.cinzaSebrae30}
                          isBold={indexInExtra(index)}
                        >
                          {exact.label}
                        </BaSeParagraph>
                      </OptionPickerExtraActionButton>
                    ))}
                  </>
                )}
              </OptionPickerBoxWrapper>
            </>
          )}
          {!!complement && (
            <WrapperHelper>
              <BaSeHelperText
                isItalic={true}
                size="medium"
                color={mapBaSeSelectColor[colorType].color}
                label={complement}
              />
            </WrapperHelper>
          )}
          {!!moreInfoLabel && (
            <MoreInfoContainer>
              <BaSeHelperText
                size="small"
                label={moreInfoLabel}
                details={moreInfoDetails}
                color={bgC}
              />
            </MoreInfoContainer>
          )}
        </WrapperSelect>
      )}
    </BaSeBreakpointConsumer>
  );
};
