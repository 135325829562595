import * as React from 'react';
import {
  MaskedDateTypeValue,
  MaskedTypeValue,
  maskHandler,
} from '../utils/masks';
import { MaskMappedType } from '../utils/masks';

type MaskedValue = MaskedTypeValue | MaskedDateTypeValue;

export function useMask<Value extends MaskedValue>(
  stateValue: Value,
  type: MaskMappedType,
): string {
  const [value, setValue] = React.useState('');
  React.useEffect(
    () => setValue(maskHandler[type]?.mask(stateValue as any)),
    [stateValue],
  );
  return value;
}
