import styled from 'styled-components';
import { DataInterfaceProps, densityPixels, spacePixels } from '../data-styled';
import { DataPaginationProps } from '../pagination/pagination';

export interface DataBodyListProps
  extends Pick<DataInterfaceProps, 'densityLevel'>,
    Pick<DataPaginationProps, 'compactMode'> {}

export const DataBodyList = styled.div.attrs({
  className: 'BaSe--data-body-list',
})<DataBodyListProps>`
  padding: ${({ compactMode }) => (compactMode ? 0 : spacePixels)};
  margin: 0;
  box-sizing: content-box;

  ul.BaSe--data-list {
    display: flex;
    flex-direction: column;
    gap: ${({ densityLevel }) => densityPixels(densityLevel)};
    padding: 0;
  }
`;
