import * as React from 'react';
import { RootContext } from '../contexts/root';

export const BackendNoSignalPage: React.FC = () => (
  <RootContext.Consumer>
    {({ backendNoSignalPage: renderComponent }) =>
      renderComponent?.() ?? (
        <section>
          <header>
            <h1>Sem sinal!</h1>
          </header>
          <p>
            Um problema ocorreu ao se comunicar com o servidor. Tente mais
            tarde.
          </p>
          <p>
            <a href="/">Voltar</a> para o início.
          </p>
        </section>
      )
    }
  </RootContext.Consumer>
);
