import { BaSeGrid, BaSeGridItem } from '@base/react';
import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';
import { Role } from '../models/role';
import { LayoutAside } from './layout/aside';
import { LayoutFooter } from './layout/footer';
import { LayoutHeader } from './layout/header';

export const Layout = ({ children }) => {
  const { isAuthenticated, hasRoles } = Sebrae.useAuth();

  return (
    <>
      <LayoutHeader />
      <BaSeGrid>
        {isAuthenticated &&
          hasRoles([Role.GestorNacional, Role.GestorUF, Role.GestorIF]) && (
            <BaSeGridItem size={3}>
              <LayoutAside />
            </BaSeGridItem>
          )}
        <BaSeGridItem
          size={
            isAuthenticated &&
            hasRoles([Role.GestorNacional, Role.GestorUF, Role.GestorIF])
              ? 9
              : 12
          }
        >
          {children}
        </BaSeGridItem>
      </BaSeGrid>
      <LayoutFooter />
    </>
  );
};
