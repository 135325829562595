import * as React from 'react';
import {
  StyledTogglerInput,
  StyledTogglerLabel,
  StyledTogglerSpan,
  StyledTogglerIconContainer,
  TogglerColorProps,
} from './toggler-styled';
import { BaSeIcon } from '../image/icon';
import { BaSeTheme } from '../../theme';
import { TogglerMapper, createTogglerColor } from './map-toggler-style';
import { Color } from '../../utils/color-transformation/color';
import { BaSeI18nContext } from '../../contexts/i18n';
import { getFormattedDataset } from '../../utils/dataset-utils';

const TogglerColorOptions = [
  'default',
  'negative',
  'confirmation',
  'destructive',
];

interface TogglerType extends TogglerColorProps {
  id?: string;
  name?: string;
  dataset?: DOMStringMap;
  type?: 'outlined' | 'contained';
  isDisabled?: boolean;
  value?: boolean;
  onChange?: () => void;
  color?: string;
}

const handleMappedType = (type: string) =>
  type === 'outlined' || type === 'contained' ? type : 'outlined';
const handleMappedColor = (color: string) =>
  !!TogglerColorOptions.find((c) => c === color)
    ? color
    : Color.validateColor(color)
      ? 'unmapped'
      : 'default';

export const BaSeToggler: React.FC<TogglerType> = ({
  id,
  name,
  dataset,
  type = 'outlined',
  isDisabled = false,
  value = false,
  onChange = () => {},
  color,
}) => {
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);
  const styledMappedValues = React.useCallback(() => {
    if (!color) {
      return TogglerMapper[handleMappedType(type)].default;
    }
    if (handleMappedColor(color) === 'unmapped') {
      return createTogglerColor(color)?.[handleMappedType(type)];
    }
    return TogglerMapper[handleMappedType(type)]?.[color];
  }, [color, type]);

  const { getMessage } = React.useContext(BaSeI18nContext);
  const {
    unchecked,
    checked,
    disabled: disabledState,
  } = styledMappedValues() ?? TogglerMapper[handleMappedType(type)].default;
  const getStyles = React.useCallback(() => {
    if (isDisabled) {
      return disabledState;
    }
    return value ? checked : unchecked;
  }, [isDisabled, value, unchecked, checked, disabledState]);
  // const getStyles() = getStyles();

  const [hasHover, setHasHover] = React.useState<boolean>(false);

  const formattedDataset = getFormattedDataset(dataset);

  return (
    <StyledTogglerLabel
      value={value}
      isDisabled={isDisabled}
      type={handleMappedType(type)}
      colors={getStyles()}
      hasFocus={hasFocus}
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
    >
      <StyledTogglerInput
        id={id}
        name={name}
        {...formattedDataset}
        aria-checked={value}
        role="checkbox"
        checked={value}
        onChange={onChange}
        type="checkbox"
        disabled={isDisabled}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
      />
      <StyledTogglerSpan
        type={handleMappedType(type)}
        isDisabled={isDisabled}
        value={value}
        colors={getStyles()}
      />
      <StyledTogglerIconContainer value={value} type={handleMappedType(type)}>
        <BaSeIcon
          color={
            type === 'contained'
              ? color === 'negative'
                ? BaSeTheme.colors.institucionais.azulSebrae36
                : BaSeTheme.colors.defaultColors.white
              : hasHover
                ? getStyles()?.iconColor
                : getStyles()?.foreground
          }
          description={getMessage('toggler.iconDescription')}
          name="check"
          size={20}
        />
      </StyledTogglerIconContainer>
    </StyledTogglerLabel>
  );
};

BaSeToggler.displayName = 'BaSeToggler';
