import * as React from 'react';
import { RootContext, RootContextInterface } from '../contexts/root';
import { log } from '../utils/log';
import { AuthProvider, AuthProviderProps } from './auth-provider';
import { CookieConsent } from './cookie-consent';
import { ApmAgent } from './apm-agent';

export type RootProps = RootContextInterface & AuthProviderProps;

export const Root: React.FC<RootProps> = ({
  appName = 'app-name',
  appVersion = '0.0.0',
  errorPage,
  loadingPage,
  notFoundPage,
  forbiddenPage,
  backendNoSignalPage,
  loginRequired,
  keycloakEnvKey,
  withAuthentication = true,
  children,
}) => {
  if (withAuthentication === false && loginRequired === true) {
    throw Error(`
      [Sebrae Root] Caso a aplicação tenha o login required,
      logo tem que ter autenticação ligada com a prop: "withAuthentication"
    `);
  }

  React.useEffect(() => log('Root', `🚀 ${appName} @ ${appVersion}`), []);

  const appContent = React.useMemo(
    () => (
      <>
        <CookieConsent />
        {children}
      </>
    ),
    [],
  );

  return (
    <RootContext.Provider
      value={{
        appName,
        appVersion,
        errorPage,
        loadingPage,
        notFoundPage,
        forbiddenPage,
        backendNoSignalPage,
      }}
    >
      <ApmAgent>
        {withAuthentication ? (
          <AuthProvider
            loginRequired={loginRequired}
            keycloakEnvKey={keycloakEnvKey}
          >
            {appContent}
          </AuthProvider>
        ) : (
          appContent
        )}
      </ApmAgent>
    </RootContext.Provider>
  );
};

Root.displayName = 'SebraeRoot';
