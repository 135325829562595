import * as React from 'react';

export interface DataInfoContextInterface<Payload> {
  info: Payload;
  setInfo: React.Dispatch<React.SetStateAction<Payload>>;
}

export const BaSeDataInfoContext = React.createContext<
  DataInfoContextInterface<any>
>({
  info: undefined,
  setInfo: () => {},
});

export const BaSeDataInfoProvider: React.FC = ({ children }) => {
  const [info, setInfo] = React.useState<DataInfoContextInterface<any>>();

  return (
    <BaSeDataInfoContext.Provider
      value={{
        info,
        setInfo,
      }}
    >
      {children}
    </BaSeDataInfoContext.Provider>
  );
};

export const BaSeDataInfoConsumer = BaSeDataInfoContext.Consumer;
