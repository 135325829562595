import * as React from 'react';
import { BaSeBreakpointConsumer } from '../../contexts/breakpoint';
import { BaSeI18nContext } from '../../contexts/i18n';
import { useNextHashId } from '../../hooks/next-id';
import { useOutsideEvent } from '../../hooks/outside-event';
import { BaSeTheme } from '../../theme';
import { idGenerator } from '../../utils/id-generator';
import { joinWithSeparators, normalize } from '../../utils/string-utils';
import { BaSeHelperText } from '../helpers/helper-text/helper-text';
import { HelperButtonInterface } from '../helpers/popup-button/popup-button';
import { BaSeIcon } from '../image/icon';
import { BaSeFormLabel } from '../labels/form-label/form-label';
import { BaSeSmall1 } from '../typography/small/small1';
import { BaSeText } from '../typography/text/text';
import { mapBaSeSelectColor, mapSelectValues } from './map-select-style';
import {
  DetailButtonWrapper,
  HideTopOfWrapperOptions,
  MoreInfoContainer,
  OptionDetail,
  OptionWithIconSelected,
  SelectInput,
  SelectSizeType,
  WrapperHelper,
  WrapperIcon,
  WrapperOption,
  WrapperOptions,
  WrapperSelect,
} from './select-styled';
import { getFormattedDataset } from '../../utils/dataset-utils';

const idSequence = idGenerator();

export type ValueId = number | string | boolean;

export interface SelectExtraAction {
  label: string;
  iconName?: string;
  onClick: (searchValue?: string) => void;
}

export interface SelectValue {
  id: ValueId;
  label: string;
  icon?: string;
  iconDescription?: string;
  detail?: {
    title: string;
    description: string;
  };
}

export interface OverrideSelectColorProps {
  selectedBackgroundColor?: string;
  selectHoverColor?: string;
  iconColor?: string;
}

export interface SelectProps {
  id?: string;
  name?: string;
  dataset?: DOMStringMap;
  selectedValueId: ValueId | ValueId[];
  values: SelectValue[];
  label?: string;
  subLabel?: string;
  complement?: string;
  emptyValueLabel?: string;
  removeEmptyValue?: boolean;
  moreInfoLabel?: string;
  moreInfoDetails?: string;
  showHelpButton?: boolean;
  helpButtonProps?: HelperButtonInterface;
  size?: SelectSizeType;
  width?: number | string;
  searchable?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  color?: string;
  overrideColor?: OverrideSelectColorProps;
  extraActions?: SelectExtraAction[];
  onChange: (selectedValue: SelectValue) => void;
}

function filteredEmptyValue<T extends { label: string }>(
  list: T[],
  removeEmptyValue: boolean,
  emptyValueLabel: string,
): T[] {
  if (removeEmptyValue) {
    return list.filter((item) => item.label !== emptyValueLabel);
  }
  return list;
}

const ExtraAction = ({
  extraAction: { label, onClick, iconName },
  search = '',
  bcCHover,
  bgC,
  color,
  afterClick,
}: {
  extraAction: SelectExtraAction;
  search?: string;
  bcCHover: string;
  bgC: string;
  color: string;
  afterClick: () => void;
}) => {
  const ref = React.useRef<HTMLLIElement>(null);

  const onClickExtraAction = () => {
    onClick(search);
    afterClick();
  };

  return (
    <WrapperOption
      ref={ref}
      isActive={false}
      multiple={false}
      role="menuitem"
      onKeyDown={(event) => event.key === 'Enter' && onClickExtraAction()}
      onClick={(e) => {
        e.preventDefault();
        onClickExtraAction();
      }}
      bgC={bgC}
      bcCHover={bcCHover}
      onFocus={() => ref?.current?.classList.add('focus')}
      onBlur={() => ref?.current?.classList.remove('focus')}
    >
      {iconName && (
        <BaSeIcon
          name={iconName ?? ''}
          color={color}
          size={16}
          description={`Ícone da ação: ${label}`}
        />
      )}
      <BaSeText color={color}>{label}</BaSeText>
    </WrapperOption>
  );
};

/**
 * @deprecated use BaSeOptionPicker
 */
export const BaSeSelect: React.FC<SelectProps> = ({
  id: externalId,
  name,
  dataset,
  complement = '',
  hasError = false,
  label = '',
  searchable = false,
  selectedValueId = 0,
  subLabel = '',
  showHelpButton = false,
  values = [],
  width = null,
  size = 'medium',
  emptyValueLabel = '',
  isDisabled = false,
  helpButtonProps = {},
  moreInfoLabel = '',
  moreInfoDetails = '',
  removeEmptyValue = false,
  color = 'default',
  overrideColor = {},
  extraActions = [],
  onChange = () => {},
}) => {
  const id = externalId ?? useNextHashId(idSequence);

  const { getMessage } = React.useContext(BaSeI18nContext);
  const getColorsAtributes = () => {
    if (color) {
      return mapSelectValues(color);
    }
    return mapSelectValues('default');
  };

  const {
    style: { bcCHover, bgC, boxShadowFocus, border },
  } = getColorsAtributes();

  emptyValueLabel =
    emptyValueLabel === '' ? getMessage('select.emptyValue') : emptyValueLabel;

  const formattedDataset = getFormattedDataset(dataset);

  const memoValues = React.useMemo(() => {
    if (!Array.isArray(values)) {
      return [];
    }
    return values;
  }, [values.toString()]); // Nem repara nisso… Vai entender? ¯\_(ツ)_/¯

  const emptyValue = React.useMemo(
    () => ({ label: emptyValueLabel, id: 0 }),
    [],
  );

  const originalValues = React.useMemo(
    () =>
      [
        emptyValue,
        ...filteredEmptyValue(memoValues, removeEmptyValue, emptyValueLabel),
      ].map((item) => ({
        ...item,
        ref: React.createRef<HTMLLIElement>(),
      })),
    [memoValues],
  );

  const externalSelectedValues = React.useMemo(
    () =>
      Array.isArray(selectedValueId) ? selectedValueId : [selectedValueId],
    [selectedValueId],
  );

  const initialSelectedValues = React.useMemo(
    () =>
      originalValues.filter((s) =>
        externalSelectedValues.some((valueId) => s.id === valueId),
      ),
    [
      originalValues.map((val) => val.id).toString(),
      externalSelectedValues.toString(),
    ],
  );

  const withIcon = React.useMemo(
    () => memoValues.some((item) => item.icon ?? false),
    [memoValues],
  );

  const [colorType, setColorType] = React.useState('default');
  const [firstTimeEmited, setFirstTimeEmited] = React.useState(true);
  const [searchableText, setSearchableText] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [allValues, setAllValues] = React.useState<
    (SelectValue & { ref?: React.RefObject<HTMLLIElement> })[]
  >([]);
  const [selectedValues, setSelectedValues] = React.useState<
    (SelectValue & { ref?: React.RefObject<HTMLLIElement> })[]
  >([originalValues[0]]);

  const selectInputRef = React.useRef<HTMLInputElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  useOutsideEvent<HTMLDivElement>(wrapperRef, setIsOpen);

  React.useEffect(() => {
    setColorType(isDisabled ? 'disabled' : hasError ? 'error' : 'default');
  }, [hasError, isDisabled]);

  const emitChange = React.useCallback(() => {
    if (firstTimeEmited) {
      setFirstTimeEmited(false);
      return;
    }
    function valueToEmit(
      val: SelectValue & {
        ref?: any;
      },
    ): SelectValue {
      const finalValue = { ...val };
      delete finalValue.ref;
      return finalValue;
    }

    onChange(valueToEmit(selectedValues[0]));
    return;
  }, [selectedValues]);

  const setSingleValue = React.useCallback(
    (selected: any, noClose: boolean) => {
      if (!noClose) {
        setIsOpen(false);
      }
      if (selected.id === 0) {
        setSearchableText('');
      }
      setSelectedValues([selected]);
    },
    [],
  );

  const updateValue = React.useCallback(
    (selected: any, isNotInputEvent = false, noClose = false) => {
      if (isNotInputEvent) {
        setAllValues(originalValues);
        setSingleValue(selected, noClose);
      } else {
        const valueFromTarget = selected.target.value;
        const filteredValues = filteredEmptyValue(
          originalValues,
          removeEmptyValue,
          emptyValueLabel,
        ).filter((item) =>
          normalize(item.label).includes(normalize(valueFromTarget)),
        );
        setSearchableText(valueFromTarget);
        setAllValues(filteredValues);
      }
    },
    [originalValues],
  );

  const onFocusInput = React.useCallback(
    () => setIsOpen(!isDisabled && true),
    [isDisabled],
  );

  const onKeyDownWrapper = React.useCallback(
    (key) => {
      if (['ArrowUp', 'ArrowDown'].includes(key)) {
        const focusedIndex = allValues.findIndex((option) =>
          option?.ref?.current?.classList?.contains?.('focus'),
        );
        selectedValues.map((value) => {
          const activeIndex = allValues.findIndex(
            (option) => option.id === value.id,
          );
          const actualIndex = focusedIndex > -1 ? focusedIndex : activeIndex;
          const nextIndex = actualIndex + (key === 'ArrowDown' ? 1 : -1);
          const nextItem = allValues?.[nextIndex];
          nextItem?.ref?.current?.focus();
        });
      }
    },
    [selectedValues, allValues],
  );

  const allValuesFiltered = React.useMemo(
    () => filteredEmptyValue(allValues, removeEmptyValue, emptyValueLabel),
    [allValues, removeEmptyValue, emptyValueLabel],
  );

  const onClose = React.useCallback(() => {
    allValues.forEach(({ ref }) => ref?.current?.classList?.remove?.('focus'));
    setSearchableText('');
  }, [allValues]);

  React.useEffect(() => {
    emitChange();
  }, [selectedValues]);

  React.useEffect(() => {
    initialSelectedValues.forEach((value) => updateValue(value, true, true));
  }, [initialSelectedValues]);

  React.useEffect(() => {
    setAllValues(originalValues);
  }, [originalValues]);

  React.useEffect(() => {
    if (isDisabled) {
      return;
    }
    if (isOpen) {
      selectInputRef?.current?.focus();
    } else {
      onClose();
    }
  }, [isOpen]);

  const hasAllFiltered = allValuesFiltered.length > 0;

  return (
    <>
      <WrapperSelect
        ref={wrapperRef}
        isDisabled={isDisabled}
        width={width}
        onKeyDown={(event) => onKeyDownWrapper(event.key)}
      >
        <BaSeFormLabel
          id={id}
          label={label}
          subLabel={subLabel}
          showHelpButton={showHelpButton}
          helpButtonProps={helpButtonProps}
        />
        <WrapperIcon
          hasLabel={!!label}
          vSize={size}
          isOpen={isOpen}
          searchable={searchable}
        >
          <BaSeIcon
            description={
              isOpen
                ? getMessage('select.closeOptions')
                : getMessage('select.openOptions')
            }
            name="arrow-head-down"
            size={16}
            onClick={
              isDisabled
                ? undefined
                : () => setIsOpen((actualState) => !actualState)
            }
            color={
              isDisabled
                ? BaSeTheme.colors.institucionais.cinzaSebrae75
                : overrideColor.iconColor ?? bgC
            }
          />
          {searchable && selectedValues && isOpen && (
            <BaSeIcon
              description={getMessage('select.cleanSearch')}
              name="close"
              size={16}
              onClick={
                isDisabled
                  ? undefined
                  : () => updateValue(emptyValue, true, true)
              }
              color={
                isDisabled
                  ? BaSeTheme.colors.institucionais.cinzaSebrae75
                  : overrideColor.iconColor ?? bgC
              }
            />
          )}
        </WrapperIcon>
        <OptionWithIconSelected hasLabel={!!label} vSize={size}>
          {selectedValues[0].icon && !isOpen && (
            <BaSeIcon
              name={selectedValues[0].icon ?? ''}
              color={mapBaSeSelectColor[colorType].item}
              description={
                selectedValues[0]?.iconDescription ??
                getMessage('icon.label', selectedValues[0].label)
              }
              size={16}
            />
          )}
        </OptionWithIconSelected>

        <SelectInput
          id={id}
          name={name}
          {...formattedDataset}
          ref={selectInputRef}
          hasSelectedOption={selectedValues[0].label !== emptyValueLabel}
          disabled={isDisabled}
          readOnly={!searchable}
          vSize={size}
          boxShadowFocus={boxShadowFocus}
          border={border}
          bgC={searchable ? bgC : mapBaSeSelectColor[colorType].iconColor}
          colorType={colorType}
          value={
            searchable && isOpen
              ? searchableText
              : selectedValues.length > 3
                ? getMessage(
                    'select.selectedCounter',
                    selectedValues.filter((val) => val.id !== 0).length,
                  )
                : joinWithSeparators(
                    selectedValues.map((val) => val.label),
                    getMessage('list.separator'),
                    getMessage('list.finalSeparator'),
                  )
          }
          isOpen={isOpen}
          hasIcon={selectedValues[0].icon !== undefined}
          hasError={hasError}
          onChange={(newValue) => updateValue(newValue)}
          onFocus={onFocusInput}
        />
        {(hasAllFiltered || extraActions.length > 0) && (
          <>
            {isOpen && <HideTopOfWrapperOptions />}
            <WrapperOptions
              border={border}
              boxShadowFocus={boxShadowFocus}
              isOpen={isOpen}
              role="menu"
              hasError={hasError}
            >
              {hasAllFiltered ? (
                allValuesFiltered.map((option) => (
                  <WrapperOption
                    ref={option.ref}
                    key={option.id.toString()}
                    multiple={false}
                    withIcon={withIcon}
                    hasItemIcon={option.icon !== undefined}
                    bcCHover={overrideColor.selectHoverColor ?? bcCHover}
                    bgC={overrideColor.selectedBackgroundColor ?? bgC}
                    isActive={
                      selectedValues.findIndex(
                        (select) => select.id === option.id,
                      ) > -1
                    }
                    role="menuitem"
                    tabIndex={-1}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && updateValue(option, true)
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      updateValue(option, true);
                    }}
                    onFocus={() => option.ref?.current?.classList.add('detail')}
                    onBlur={() =>
                      option.ref?.current?.classList.remove('detail')
                    }
                    onPointerEnter={() =>
                      option.ref?.current?.classList.add('detail')
                    }
                    onPointerLeave={() =>
                      option.ref?.current?.classList.remove('detail')
                    }
                  >
                    {option.icon && (
                      <BaSeIcon
                        name={option.icon ?? ''}
                        color={
                          selectedValues.findIndex(
                            (select) => select.id === option.id,
                          ) > -1
                            ? BaSeTheme.colors.defaultColors.white
                            : mapBaSeSelectColor[colorType].item
                        }
                        size="1em"
                        description={
                          option.iconDescription ??
                          getMessage('icon.label', option.label)
                        }
                      />
                    )}
                    <BaSeText
                      color={
                        selectedValues.findIndex(
                          (select) => select.id === option.id,
                        ) > -1
                          ? BaSeTheme.colors.defaultColors.white
                          : mapBaSeSelectColor[colorType].item
                      }
                    >
                      {option.label}
                    </BaSeText>
                    {option.detail && (
                      <>
                        <BaSeBreakpointConsumer>
                          {({ inTablet }) =>
                            !inTablet && (
                              <DetailButtonWrapper>
                                <BaSeIcon
                                  color={
                                    selectedValues.findIndex(
                                      (select) => select.id === option.id,
                                    ) > -1
                                      ? BaSeTheme.colors.defaultColors.white
                                      : mapBaSeSelectColor[colorType].item
                                  }
                                  name="question-circle"
                                  size="1em"
                                  description={getMessage('select.showDetail')}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    option.ref?.current?.focus();
                                  }}
                                />
                              </DetailButtonWrapper>
                            )
                          }
                        </BaSeBreakpointConsumer>
                        <OptionDetail onClick={(e) => e.stopPropagation()}>
                          <BaSeSmall1
                            color={overrideColor.selectedBackgroundColor ?? bgC}
                            isBold={true}
                          >
                            {option.detail.title}
                          </BaSeSmall1>
                          <BaSeSmall1
                            color={mapBaSeSelectColor[colorType].item}
                            isThin={true}
                          >
                            {option.detail.description}
                          </BaSeSmall1>
                        </OptionDetail>
                      </>
                    )}
                  </WrapperOption>
                ))
              ) : (
                <WrapperOption
                  multiple={false}
                  withIcon={false}
                  bcCHover={overrideColor.selectHoverColor ?? bcCHover}
                  bgC={overrideColor.selectedBackgroundColor ?? bgC}
                  isActive={false}
                  role="menuitem"
                  tabIndex={-1}
                >
                  <BaSeText color={mapBaSeSelectColor[colorType].item}>
                    Nenhum item encontrado.
                  </BaSeText>
                </WrapperOption>
              )}
              {extraActions.map((action, index) => (
                <ExtraAction
                  extraAction={action}
                  search={searchableText}
                  key={index}
                  color={mapBaSeSelectColor[colorType].item}
                  bcCHover={overrideColor.selectedBackgroundColor ?? bcCHover}
                  bgC={overrideColor.selectedBackgroundColor ?? bgC}
                  afterClick={() => {
                    setIsOpen(false);
                  }}
                />
              ))}
            </WrapperOptions>
          </>
        )}

        {!!complement && (
          <WrapperHelper>
            <BaSeHelperText
              isItalic={true}
              size="medium"
              color={mapBaSeSelectColor[colorType].color}
              label={complement}
            />
          </WrapperHelper>
        )}
        {!!moreInfoLabel && (
          <MoreInfoContainer>
            <BaSeHelperText
              size="small"
              label={moreInfoLabel}
              details={moreInfoDetails}
              color={bgC}
            />
          </MoreInfoContainer>
        )}
      </WrapperSelect>
    </>
  );
};

BaSeSelect.displayName = 'BaSeSelect';
