import styled from 'styled-components';
import { BaSeTheme } from '../../../theme';

export interface HelperProps {
  color?: string;
  hasComplement?: boolean;
  open?: boolean;
  size?: 'medium' | 'small';
  isItalic?: boolean;
}

export interface HelperOpen {
  open?: boolean;
}

export const HelperStyled = styled.span.attrs({
  className: 'BaSe--helper-text',
})<HelperProps>`
  color: ${(props) =>
    props.color ? props.color : BaSeTheme.colors.institucionais.azulSebrae36};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: ${(props) => (props.isItalic ? 'italic' : 'normal')};
  font-weight: normal;
  font-size: ${(props) => (props.size === 'small' ? '11px' : '13px')};
  line-height: 13px;
  ${(props) => props.hasComplement && 'cursor: pointer;'}
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
    ${(props) => props.open && 'transform: rotate(180deg);'}
    transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  }
  ::selection {
    color: inherit;
    background: inherit;
  }
`;

export const HelperContainer = styled.div``;

export const HelperDetailsContainer = styled.div<HelperOpen>`
  padding-left: 16px;
  max-height: ${(props) => (props.open ? '100vh' : '0px')};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  overflow: hidden;
`;
