import * as React from 'react';
import Cropper from 'react-image-crop';
import { BaSeI18nContext } from '../../contexts/i18n';
import { BaSeButton } from '../button/button/button';
import { Image } from '../file/image-picker';
import { CrooperContainer, CrooperActionsButton } from './cropper-styled';

type GetMessage = (name: string, args?: any) => string;

export type CropperImage = Image | HTMLImageElement;
export type CropType = Cropper.Crop;

export interface CrooperInterface {
  file?: Image;
  cropInitialValues?: CropType;
  circularCrop?: boolean;
  saveCallback: (file: Image) => void;
  cancelCallback: (param?: string) => void;
  onCropError?: (message: string, error?: any) => void;
}

const handleFirstImageCrop = (
  aspectRatio?: number,
  imageAux?: HTMLImageElement,
) => {
  if (!aspectRatio || !imageAux) {
    return;
  }
  if (aspectRatio > 1) {
    return {
      width: imageAux.width,
      height: Math.round(imageAux.width / aspectRatio),
    };
  }
  return {
    height: imageAux.height,
    width: Math.round(imageAux.height * aspectRatio),
  };
};

const cropDefaultParams: CropType = {
  aspect: 16 / 9,
  unit: '%',
  x: 0,
  y: 0,
  width: 100,
};

const SVGToPngType = (type?: string) => {
  if (!type) {
    return 'image/jpg';
  }
  if (type.includes('svg')) {
    return 'image/png';
  }
  return type;
};

const makeBlobFromCanvas = (
  canvas: HTMLCanvasElement,
  originalImgTag: File,
  getMessage: GetMessage,
) =>
  new Promise((resolve, reject) => {
    try {
      canvas.toBlob((blob: Blob | null) => {
        if (!blob) {
          reject('canvas is empty');
          return;
        }
        const image = new File(
          [blob],
          originalImgTag?.name ??
            getMessage('cropper.file.default.name', Date?.now()),
          {
            type: SVGToPngType(originalImgTag?.type),
          },
        );
        resolve(image);
      }, SVGToPngType(originalImgTag?.type));
    } catch (e) {
      reject(e);
    }
  });

export const BaSeCropper: React.FC<CrooperInterface> = ({
  file,
  cropInitialValues,
  circularCrop,
  saveCallback,
  cancelCallback,
  onCropError,
}) => {
  const [crop, setCrop] = React.useState<CropType>({
    ...cropDefaultParams,
    ...cropInitialValues,
  });
  const [image, setImage] = React.useState<CropperImage>('');
  const [refImage, setRefImage] = React.useState<Partial<CropperImage>>();
  const [completedCrop, setCompletedCrop] = React.useState<any>();
  const [imageUrl, setImageUrl] = React.useState('');

  const { getMessage } = React.useContext(BaSeI18nContext);

  const handleUpload = async (part: Partial<CropperImage>) => {
    if (!part) {
      onCropError?.(getMessage('cropper.error.upload'));
      return;
    }
    setImage(part as CropperImage);
    const imageTag = part as HTMLImageElement;
    const canvas = document.createElement('canvas');
    const scaleX = imageTag.naturalWidth / imageTag.width;
    const scaleY = imageTag.naturalHeight / imageTag.height;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const pixelRatio = window.devicePixelRatio;
    const canvasSize = handleFirstImageCrop(crop?.aspect, imageTag);
    if (!canvasSize) {
      onCropError?.(getMessage('cropper.error.upload'));
      return;
    }
    canvas.width = canvasSize.width * pixelRatio;
    canvas.height = canvasSize.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      imageTag,
      0,
      0,
      canvasSize.width * scaleX,
      canvasSize.height * scaleY,
      0,
      0,
      canvasSize.width,
      canvasSize.height,
    );
    await makeBlobFromCanvas(canvas, file as File, getMessage as GetMessage)
      .then((recreatedImage: any) => setRefImage(recreatedImage))
      .catch((error) => onCropError?.(getMessage('cropper.error.crop'), error));
  };

  const handleSave = async (imageToSave: any) => {
    if (!completedCrop || !file || !imageToSave || !refImage) {
      return;
    }
    const canvas = document.createElement('canvas');
    const scaleX = imageToSave.naturalWidth / imageToSave.width;
    const scaleY = imageToSave.naturalHeight / imageToSave.height;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const pixelRatio = window.devicePixelRatio;
    canvas.width = completedCrop.width * pixelRatio;
    canvas.height = completedCrop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      imageToSave,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height,
    );
    await makeBlobFromCanvas(canvas, file as File, getMessage as GetMessage)
      .then((recreatedImage: any) => saveCallback(recreatedImage))
      .catch((error) => onCropError?.(getMessage('cropper.error.crop'), error));
  };

  React.useEffect(() => {
    if (file) {
      const urls = URL.createObjectURL(file as File | Blob);
      setImageUrl(urls);
    }
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [file]);

  return (
    <CrooperContainer width={(image as HTMLImageElement)?.width}>
      {file && imageUrl && (
        <>
          <Cropper
            src={imageUrl}
            crop={crop}
            onChange={setCrop}
            onImageLoaded={handleUpload}
            onComplete={setCompletedCrop}
            circularCrop={circularCrop}
          />
          <CrooperActionsButton>
            <BaSeButton
              value={getMessage('cropper.button.save')}
              onClick={() => handleSave(image)}
            />
            <BaSeButton
              value={getMessage('cropper.button.cancel')}
              onClick={() => cancelCallback()}
            />
          </CrooperActionsButton>
        </>
      )}
    </CrooperContainer>
  );
};

BaSeCropper.displayName = 'BaSeCropper';
