import * as React from 'react';

export interface RootContextInterface {
  appName: string;
  appVersion: string;
  withAuthentication?: boolean;
  errorPage?: (error: string) => JSX.Element;
  loadingPage?: () => JSX.Element;
  notFoundPage?: () => JSX.Element;
  forbiddenPage?: () => JSX.Element;
  backendNoSignalPage?: () => JSX.Element;
}

export const RootContext = React.createContext<RootContextInterface>({
  appName: 'app-name',
  appVersion: '0.0.0',
});

RootContext.displayName = 'SebraeRootContext';
