import styled, { css } from 'styled-components';
import { numberWithUnit } from '../../utils/string-utils';
import { ThemeColorValue } from '../../theme/theme-interface';
import { BaSeTheme } from '../../theme';

export type DataDensityLevel = 'low' | 'normal' | 'high';

export interface DataInterfaceProps {
  width?: number | string;
  densityLevel?: DataDensityLevel;
  highlightedColor?: ThemeColorValue;
  foregroundColor?: ThemeColorValue;
  backgroundColor?: ThemeColorValue;
  headerBackgroundColor?: ThemeColorValue;
  footerBackgroundColor?: ThemeColorValue;
  titleColor?: ThemeColorValue;
  textColor?: ThemeColorValue;
  separatorColor?: ThemeColorValue;
  shadow?: string;
  hasHeader?: boolean;
}

export interface DataWrapperProps
  extends Pick<DataInterfaceProps, 'width' | 'shadow'> {}

export interface DataContentProps
  extends Pick<
    DataInterfaceProps,
    | 'backgroundColor'
    | 'hasHeader'
    | 'footerBackgroundColor'
    | 'headerBackgroundColor'
  > {}

export interface DataHeaderProps
  extends Pick<DataInterfaceProps, 'headerBackgroundColor'> {
  horizontalPadding: string;
}

export interface DataFooterProps
  extends Pick<DataInterfaceProps, 'separatorColor' | 'footerBackgroundColor'> {
  horizontalPadding?: string;
  verticalPadding?: string;
}

export const spacePixels = '24px';

export const densityPixels = (
  densityLevel?: DataDensityLevel,
  factor: number = 1,
) =>
  `${
    factor * (densityLevel === 'high' ? 4 : densityLevel === 'low' ? 16 : 8) // normal
  }px`;

export const DataContainer = styled.div.attrs({
  className: 'BaSe--data',
})<DataWrapperProps>`
  box-sizing: border-box;

  ${({ width }) => css`
    width: ${numberWithUnit(width)};
  `}
`;

export const DataWrapper = styled.section.attrs({
  className: 'BaSe--data-wrapper',
})<DataWrapperProps>`
  &.BaSe--data-wrapper {
    display: grid;
    grid-template-areas:
      'header'
      'selection-helper'
      'filter-or-tags'
      'content'
      'footer';
    border-radius: 8px;
    box-shadow: ${({ shadow }) => shadow};
  }
`;

export const DataLoading = styled.div<{ height: number }>`
  will-change: min-height;
  min-height: ${({ height }) => height}px;
`;

export const DataHeader = styled.header.attrs({
  className: 'BaSe--data-header',
})<DataHeaderProps>`
  &.BaSe--data-header {
    grid-area: header;
    border-radius: 8px 8px 0 0;
    position: relative;
    will-change: padding;
    padding: ${spacePixels} ${({ horizontalPadding }) => horizontalPadding};
    display: flex;
    gap: 4px;
    align-items: center;
    border: none;
    ${({ headerBackgroundColor }) =>
      headerBackgroundColor &&
      css`
        background-color: ${headerBackgroundColor};
      `}

    & > *:last-child {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 48px;
      gap: 4px;
    }
  }
`;

export const DataHeaderTitle = styled.span.attrs({
  className: 'BaSe--data-header-title',
})`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
`;

export const DataFooter = styled.footer.attrs({
  className: 'BaSe--data-footer',
})<DataFooterProps>`
  &.BaSe--data-footer {
    grid-area: footer;
    border-radius: 0 0 8px 8px;
    border: none;
    will-change: padding;

    ${({ separatorColor, footerBackgroundColor }) =>
      footerBackgroundColor !== BaSeTheme.colors.defaultColors.transparent &&
      css`
        border-top: 1px solid ${separatorColor};
      `}

    ${({ verticalPadding = '8px', horizontalPadding = spacePixels }) => css`
      padding: ${verticalPadding} ${horizontalPadding};
    `}

    ${({ footerBackgroundColor }) =>
      footerBackgroundColor &&
      css`
        background-color: ${footerBackgroundColor};
      `}
  }
`;

export const DataSelectionHelper = styled.div`
  grid-area: selection-helper;
`;

export const DataFilterOrTags = styled.div.attrs({
  className: 'BaSe--data-filter-or-tags',
})`
  grid-area: filter-or-tags;
`;

export const DataContent = styled.div.attrs({
  className: 'BaSe--data-content',
})<DataContentProps>`
  grid-area: content;

  ${({ footerBackgroundColor }) =>
    footerBackgroundColor === BaSeTheme.colors.defaultColors.transparent &&
    css`
      border-radius: 8px;
    `};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  ${({ hasHeader, headerBackgroundColor }) =>
    (!hasHeader ||
      headerBackgroundColor === BaSeTheme.colors.defaultColors.transparent) &&
    css`
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    `}
`;
