import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { ColorMapper } from '../../utils/color-transformation/color-mapper';
import { TypographyProps, LinkWrapperProps } from './typography-props';
import { getFontWeight } from './typography-mapper';

const childrenMappedTypographyElements = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'span',
  'p',
  'a',
  'li',
]
  .map((i) => '> ' + i)
  .join(', ')
  .toString();

const tagsKeepBasicTypographyStyle = css`
  i,
  em,
  var {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bold;
  }
  blockquote {
    padding-left: 40px;
  }
  abbr {
    display: inline;
  }
  big {
    font-size: larger;
  }
  small {
    font-size: small;
  }
  code,
  pre,
  samp,
  tt {
    font-family: monospace;
  }
  pre {
    white-space: pre;
  }
  del,
  s,
  strike {
    text-decoration: line-through;
  }
  ins,
  u {
    text-decoration: underline;
  }
  sub,
  sup {
    font-size: smaller;
  }
  sub {
    vertical-align: sub;
  }
  sup {
    vertical-align: super;
  }
  center {
    text-align: center;
  }
  q::before,
  q::after {
    content: '“';
  }
`;

const handleColor = (hasHref: boolean, color?: string) => {
  if (!color) {
    return hasHref
      ? BaSeTheme.colors.institucionais.azulSebrae36
      : BaSeTheme.colors.institucionais.cinzaSebrae;
  }
  return (ColorMapper.typography[color] ??
    (ColorMapper[color as keyof typeof ColorMapper] as { foreground: string })
      ?.foreground ??
    color) as string;
};

const getFontWeightType = (isThin?: boolean, isBold?: boolean) => {
  return isThin ? 'light' : isBold ? 'bold' : 'regular';
};

const createFontFamily = (font?: string) => {
  const prefixFont = font ? `${font},` : '';
  return prefixFont + BaSeTheme.typography.fontFamily;
};

export const makeDefaultTypographyStyled = (
  element: string,
  tagName: string,
  fontSize: number,
  lineHeight: number,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
) => styled[tagName].attrs({
  className: `BaSe--${element.toLocaleLowerCase()}`,
})`
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  will-change: color, font-weight, font-style;
  font-size: ${fontSize}em;
  line-height: ${lineHeight}em;

  font-family: ${(
    props: Omit<TypographyProps, 'isDisabled' | 'linkVisitedColor'>,
  ) => `${createFontFamily(props?.fontFamily)}`};

  color: ${(props: Omit<TypographyProps, 'isDisabled'>) =>
    handleColor(false, props?.color as string)};
  font-weight: ${(
    props: Omit<TypographyProps, 'isDisabled' | 'linkVisitedColor'>,
  ) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    getFontWeight(props?.fontFamily)[
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      getFontWeightType(props?.isThin, props?.isBold)
    ]};
  font-style: ${(
    props: Omit<TypographyProps, 'isDisabled' | 'linkVisitedColor'>,
  ) => (props?.isItalic ? 'italic' : 'normal')};

  ${childrenMappedTypographyElements} {
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  ${tagsKeepBasicTypographyStyle}
`;

export const LinkWrapper = styled.a<LinkWrapperProps>`
  ${({ isDisabled, isUnderline, colors, fontSize, lineHeight }) => css`
    cursor: pointer;
    outline: none;
    color: ${handleColor(true, colors?.foreground)};
    text-decoration: ${isUnderline ? 'underline' : 'none'};
    font-size: ${fontSize}em;
    line-height: ${lineHeight}em;
    ${childrenMappedTypographyElements} {
      display: inline;
      font-size: inherit;
      color: ${handleColor(true, colors?.foreground)};
    }
    ${isDisabled
      ? `
      pointer-events: none;
      color: ${
        colors?.disabled ?? BaSeTheme.colors.institucionais.cinzaSebrae75
      };
      ${childrenMappedTypographyElements} {
        color: ${
          colors?.disabled ?? BaSeTheme.colors.institucionais.cinzaSebrae75
        };
      }
      `
      : `
        &:visited {
          color: ${colors?.visited ?? BaSeTheme.colors.gestao.roxo60};
          ${childrenMappedTypographyElements} {
            color: ${colors?.visited ?? BaSeTheme.colors.gestao.roxo60};
          }
        }
        &:hover {
          color: ${
            colors?.hover ?? BaSeTheme.colors.institucionais.azulSebrae60
          };
          text-decoration: none;
          ${childrenMappedTypographyElements} {
            color: ${
              colors?.hover ?? BaSeTheme.colors.institucionais.azulSebrae60
            };
          }
        }
        &:focus {
          color: ${
            colors?.focus ?? BaSeTheme.colors.institucionais.azulSebrae60
          };
          text-decoration: none;
          border: none;
          ${childrenMappedTypographyElements} {
            color: ${
              colors?.focus ?? BaSeTheme.colors.institucionais.azulSebrae60
            };
            border: 1px solid ${
              colors?.focusShadow ??
              BaSeTheme.colors.institucionais.azulSebrae75
            };
            padding: 2px 7.5px;
            border-radius: 4px;
          }
        }
        &:active {
          color: ${
            colors?.active ?? BaSeTheme.colors.institucionais.azulSebrae30
          };
          text-decoration: ${isUnderline ? 'underline' : 'none'};
          ${childrenMappedTypographyElements} {
            color: ${
              colors?.active ?? BaSeTheme.colors.institucionais.azulSebrae30
            };
            border: none;
            padding: 0;
            border-radius: 0;
          }
        }
      `}
  `}
`;
