import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';

const version = 'v1';

const endpointOriginInformation = `/${version}/origem-informacoes`;

export const ENDPOINT_LIST_COMPLETO = `${endpointOriginInformation}`;

export function useOriginInformationService() {
  const { backendGet } = Sebrae.useBackend();

  const getListOriginInformation = React.useCallback(
    () => backendGet(endpointOriginInformation),
    [backendGet],
  );

  const getListOriginInformationRequest = React.useCallback(
    () => backendGet(`${endpointOriginInformation}/solicitacao`),
    [backendGet],
  );

  return {
    getListOriginInformation,
    getListOriginInformationRequest,
  };
}
