import { SebraeCookieConsent } from '@sebrae/cookie-consent';
import * as React from 'react';
import { useAuth } from '../hooks/auth';
import { useEnv } from '../hooks/env';
import { log } from '../utils/log';

export const CookieConsent: React.FC = React.memo(() => {
  const [uuidApp, setUuidApp] = React.useState<string>();
  const [uuidUser, setUuidUser] = React.useState<string>();

  const { tokenInfo } = useAuth();
  const { getEnv } = useEnv();

  React.useEffect(() => {
    const noop = () => log('Cookie Consent', 'sem UUID para registrar');
    getEnv('cookie-consent-uuid')
      .then((fromEnv) => {
        if (!fromEnv) {
          noop();
          return;
        }

        setUuidApp(fromEnv as string);
        log('Cookie Consent', 'App UUID', fromEnv);
      })
      .catch(noop);
  }, []);

  React.useEffect(() => {
    if (tokenInfo.preferred_username) {
      setUuidUser(tokenInfo.preferred_username);
      log('Cookie Consent', 'User UUID', tokenInfo.preferred_username);
    }
  }, [tokenInfo.preferred_username]);

  return uuidApp ? (
    <SebraeCookieConsent uuidApp={uuidApp} uuidUser={uuidUser} />
  ) : (
    <></>
  );
});

CookieConsent.displayName = 'CookieConsent';
