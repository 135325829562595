import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { BaSeScrollbarCss } from '../../../theme/scrollbar';

export interface AutocompleteOpenableProps {
  isOpen: boolean;
}

export interface AutocompleteWrapperProps extends AutocompleteOpenableProps {
  mode?: 'inline' | 'list';
}

export interface AutocompleteResultListProps extends AutocompleteOpenableProps {
  hasError: boolean;
  attached: boolean;
}

export interface AutocompleteResultInlineProps {
  size: 'small' | 'medium' | 'big';
}

export interface AutocompleteResultItemImageProps {
  size: number;
}

export const AutocompleteWrapper = styled.div.attrs({
  className: 'BaSe--autocomplete',
})<AutocompleteWrapperProps>`
  position: relative;
  ${({ isOpen, mode }) =>
    mode === 'list' &&
    isOpen &&
    css`
      & .BaSe--input-input:not(:focus) {
        border: 1px solid ${BaSeTheme.colors.institucionais.azulSebrae36};
        color: ${BaSeTheme.colors.institucionais.azulSebrae36};
        box-shadow: ${BaSeTheme.shadows.focusPrimary};
      }
    `}
`;

export const AutocompleteResultInline = styled.output.attrs({
  className: 'BaSe--autocomplete-result-inline',
})<AutocompleteResultInlineProps>`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  line-height: ${BaSeTheme.typography.lineHeightBase};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  bottom: ${({ size }) =>
    size === 'small' ? 7 : size === 'medium' ? 11 : 15}px;
  left: 0.8em;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  pointer-events: none;

  & > span {
    color: ${BaSeTheme.colors.defaultColors.transparent};
  }
`;

export const HideTopOfAutocompleteResultList = styled.div`
  height: 8px;
  width: calc(100% - 2px);
  background: ${BaSeTheme.colors.defaultColors.white};
  border: none;
  box-sizing: border-box;
  position: relative;
  z-index: ${BaSeTheme.stackOrder.overlay + 1};
  margin: 0;
  padding: 0;
  margin-top: -8px;
  margin-left: 1px;
`;

export const AutocompleteResultList = styled.ul.attrs({
  className: 'BaSe--autocomplete-result-list',
})<AutocompleteResultListProps>`
  display: flex;
  flex-direction: column;
  padding: 1px;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  z-index: ${BaSeTheme.stackOrder.overlay};
  background-color: ${BaSeTheme.colors.defaultColors.white};
  border-radius: 4px;
  border: 1px solid
    ${({ hasError }) =>
      hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : BaSeTheme.colors.institucionais.azulSebrae36};
  ${({ attached }) =>
    attached &&
    css`
      border-top: none;
      border-radius: 0px 0px 4px 4px;
    `};
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  margin-top: ${({ isOpen, attached }) =>
    isOpen && attached ? '-3px' : '4px'};
  border-width: ${({ isOpen }) => (isOpen ? '1px' : '0')};
  box-shadow: ${({ isOpen, hasError }) =>
    isOpen
      ? hasError
        ? BaSeTheme.shadows.focusError
        : BaSeTheme.shadows.focusPrimary
      : 'none'};

  ${BaSeScrollbarCss}
`;

export interface AutocompleteResultListItemProps {
  hasStatus?: boolean;
  cursor?: string;
}

export const AutocompleteResultListItem = styled.li<AutocompleteResultListItemProps>`
  padding-left: 12px;
  display: flex;
  align-items: center;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  will-change: background-color;
  padding: 8px 4px;
  gap: 16px;
  cursor: ${({ cursor = 'pointer' }) => cursor};
  justify-content: ${({ hasStatus }) =>
    hasStatus ? 'space-between' : 'normal'};
  background-color: ${BaSeTheme.colors.contrast.institucionais.azulSebrae};

  &:hover {
    outline: none;
    background-color: ${BaSeTheme.colors.institucionais.azulSebrae97};
  }

  &.active {
    background-color: ${BaSeTheme.colors.institucionais.azulSebrae};
  }
`;

export const AutocompleteResultItemImage = styled.img<AutocompleteResultItemImageProps>`
  border: 2px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  object-fit: contain;
  ${({ size }) => css`
    border-radius: ${size}px;
    height: ${size}px;
    width: ${size}px;
    max-width: ${size}px;
    max-height: ${size}px;
    flex: 0 0 ${size}px;
  `}
`;
