import {
  BaSeButton,
  BaSeGrid,
  BaSeGridItem,
  BaSeHeading2,
  BaSeHeading5,
  BaSeHeading6,
  BaSeIcon,
  BaSeTheme,
} from '@base/react';
import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';
import { ReactComponent as HomeImage } from '../../images/home.svg';
import { Role } from '../../models/role';
import {
  BannerHome,
  Br,
  CircleIcon,
  Content,
  Content2,
  ImageContent,
  InfoContent,
  JustifyCenterIcon,
  ListIconsContent,
  ListInfoContent,
  Marker,
  TitleBanner,
  TitleServices,
} from './home-styled';

export const HomePage = () => {
  const { isAuthenticated, hasRoles, login } = Sebrae.useAuth();

  const { navigateTo } = Sebrae.useRouter();

  React.useEffect(() => {
    if (isAuthenticated) {
      const route = hasRoles([
        Role.GestorNacional,
        Role.GestorUF,
        Role.GestorIF,
      ])
        ? 'relatorio/cliente'
        : 'sala-espera';
      navigateTo(route);
    }
  }, [isAuthenticated, hasRoles, navigateTo]);

  const ListInfoAcompanha = [
    'Consulte relação de clientes encaminhados pela Instituição Financeira para prestar atendimento.',
    'Parametrize soluções que atendam a critérios de orientação para acesso a linhas de crédito.',
    'Parametrize projetos e ações para registros de atendimento por UF e Instituição Financeira.',
  ];

  return (
    <>
      <BannerHome>
        <Content>
          <BaSeGrid delimitedOnBreakpoint="desktop">
            <BaSeGridItem tablet={{ size: 7 }}>
              <TitleBanner>
                <BaSeHeading2 color={BaSeTheme.colors.defaultColors.white}>
                  Bem vindo ao Sistema Acompanha
                </BaSeHeading2>
                <BaSeHeading5
                  color={BaSeTheme.colors.defaultColors.white}
                  style={{ marginTop: 30 }}
                >
                  Este sistema foi criado para fazer a gestão do relacionamento
                  com clientes solicitantes de crédito junto às instituições
                  financeiras parceiras do Sebrae.
                </BaSeHeading5>
                <Br />
                <BaSeButton
                  size="small"
                  type="secondary"
                  onClick={() => login()}
                  value="Acessar o Acompanha"
                />
              </TitleBanner>
            </BaSeGridItem>
            <BaSeGridItem tablet={{ size: 5 }}>
              <ImageContent>
                <HomeImage />
              </ImageContent>
            </BaSeGridItem>
          </BaSeGrid>
        </Content>
      </BannerHome>
      <Content2>
        <BaSeGrid delimitedOnBreakpoint="desktop">
          <BaSeGridItem tablet={{ size: 6 }}>
            <InfoContent>
              <BaSeHeading5
                color={BaSeTheme.colors.institucionais.azulSebrae60}
              >
                Usuários de instituição financeira parceira:
              </BaSeHeading5>
              <ListIconsContent>
                <CircleIcon>
                  <JustifyCenterIcon>
                    <BaSeIcon
                      color={BaSeTheme.colors.institucionais.azulSebrae75}
                      description="users"
                      name="users-alt"
                      size={28}
                    />
                  </JustifyCenterIcon>
                </CircleIcon>
                <TitleServices>
                  <BaSeHeading6
                    color={BaSeTheme.colors.institucionais.azulSebrae60}
                  >
                    Consulte relatórios de relacionamento e atendimento do
                    Sebrae a seus clientes.
                  </BaSeHeading6>
                </TitleServices>
              </ListIconsContent>
              <ListIconsContent>
                <CircleIcon>
                  <JustifyCenterIcon>
                    <BaSeIcon
                      color={BaSeTheme.colors.institucionais.azulSebrae75}
                      description="users"
                      name="file-check-alt"
                      size={28}
                    />
                  </JustifyCenterIcon>
                </CircleIcon>
                <TitleServices>
                  <BaSeHeading6
                    color={BaSeTheme.colors.institucionais.azulSebrae60}
                  >
                    Consulte exigibilidades de capacitação ou atendimento do
                    Sebrae para melhoria do score de crédito.
                  </BaSeHeading6>
                </TitleServices>
              </ListIconsContent>
              <Br />
              <BaSeHeading5
                color={BaSeTheme.colors.institucionais.azulSebrae36}
              >
                Usuários do Sistema Sebrae:
              </BaSeHeading5>
              <ListInfoContent>
                <Marker>
                  {ListInfoAcompanha.map((text, index) => (
                    <li key={index} style={{ marginBottom: 20 }}>
                      <BaSeHeading6
                        color={BaSeTheme.colors.institucionais.azulSebrae36}
                      >
                        {text}
                      </BaSeHeading6>
                    </li>
                  ))}
                </Marker>
              </ListInfoContent>
            </InfoContent>
          </BaSeGridItem>
        </BaSeGrid>
      </Content2>
    </>
  );
};
