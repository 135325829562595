interface Store {
  setItem: (key: string, value: any) => void;
  removeItem: (key: string) => void;
  getItem: (key: string) => any;
  hasOwnProperty: (key: string) => boolean;
}

/**
 * Atalho otimizado para recuperar e
 * inserir coisas no `window.sessionStorage` ou `window.localStorage`
 */
export class StorageUtil {
  private constructor(private store: 'sessionStorage' | 'localStorage') {}

  /**
   * Inicializa a instância para o `window.sessionStorage`
   */
  static inTemporaryStore(): StorageUtil {
    return new StorageUtil('sessionStorage');
  }
  /**
   * Inicializa a instância para o `window.localStorage`
   */
  static inPersistentStore(): StorageUtil {
    return new StorageUtil('localStorage');
  }

  /**
   * Insere informação no `window.sessionStorage` ou `window.localStorage`
   * @param key Chave que será utilizada
   * @param value Valor que vai ser inserido
   */
  setItem(key: string, value: any): void {
    this.getStore().setItem(key, JSON.stringify(value));
  }

  /**
   * Recupera o valor no `window.sessionStorage` ou `window.localStorage`
   * @param key Chave do valor que será recuperado
   */
  getItem(key: string): any {
    try {
      return JSON.parse(this.getStore().getItem(key));
    } catch (_error) {
      return undefined;
    }
  }

  /**
   * Remove o valor no `window.sessionStorage` ou `window.localStorage`
   * @param key Chave do valor que será removido
   */
  removeItem(key: string): void {
    this.getStore().removeItem(key);
  }

  /**
   * Verifica se existe valor no `window.sessionStorage` ou `window.localStorage`
   * @param key Chave do valor que será verificada
   */
  hasItem(key: string): boolean {
    return this.getStore().hasOwnProperty(key);
  }

  private getStore(): Store {
    return (window as Window)[this.store] as unknown as Store;
  }
}
