import * as React from 'react';
import { TableInstance, UsePaginationInstanceProps } from 'react-table';
import { BaSeDataRowInfoProvider } from '../../../contexts/data/row-info';
import {
  BaSeDataSelectionConsumer,
  DataSelectionContextInterface,
} from '../../../contexts/data/selection';
import { BaSeI18nContext } from '../../../contexts/i18n';
import { BaSeTheme } from '../../../theme';
import { transformLightness } from '../../../utils/color-transformation/transform-lightness';
import { BaSeShapeButton } from '../../button/shape-button/shape-button';
import { BaSeSmall1 } from '../../typography/small/small1';
import {
  columnFilterIconProps,
  renderColumnFilter,
  rowIsSelected,
} from '../data-utils';
import { CustomFilterRenderProps } from '../filter-area/filter-area';
import {
  DataTableColumnFilter,
  DataTableColumnFilterProps,
} from './column-filter';
import { ColumnIdFlaggable, DataTableProps } from './table';
import { DataBodyTable, DataTableCell, DataTableHeader } from './table-styled';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface DataTableNormalMode<Item extends object = {}, Filter = any>
  extends CustomFilterRenderProps<Filter>,
    Pick<
      DataTableProps<Item, Filter>,
      | 'idAccessorKey'
      | 'sortIcon'
      | 'sortingAscIcon'
      | 'sortingDescIcon'
      | 'columnConfig'
      | 'densityLevel'
      | 'backgroundColor'
      | 'foregroundColor'
      | 'highlightedColor'
      | 'separatorColor'
      | 'textColor'
      | 'tableHeaderBackgroundColor'
    >,
    Pick<
      TableInstance<Item>,
      | 'rows'
      | 'headerGroups'
      | 'getTableProps'
      | 'getTableBodyProps'
      | 'prepareRow'
    >,
    Pick<UsePaginationInstanceProps<Item>, 'page'>,
    Pick<DataTableColumnFilterProps, 'setColumnFilterVisible'> {
  columnFilterVisible: ColumnIdFlaggable;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function DataTableNormalMode<Item extends object = {}, Filter = any>({
  headerGroups,
  page,
  rows,
  idAccessorKey,
  columnFilterVisible,
  densityLevel,
  columnConfig = [],
  highlightedColor = BaSeTheme.colors.institucionais.azulSebrae,
  foregroundColor = BaSeTheme.colors.institucionais.cinzaSebrae60,
  textColor = BaSeTheme.colors.institucionais.cinzaSebrae30,
  separatorColor = BaSeTheme.colors.institucionais.cinzaSebrae90,
  backgroundColor = BaSeTheme.colors.defaultColors.white,
  tableHeaderBackgroundColor = BaSeTheme.colors.defaultColors.white,
  sortIcon = 'arrow-head-down',
  sortingAscIcon = 'arrow-head-down',
  sortingDescIcon = 'arrow-head-up',
  getTableBodyProps,
  getTableProps,
  prepareRow,
  setColumnFilterVisible,
  setFilter,
  setTags,
  resetSelection,
}: React.PropsWithoutRef<DataTableNormalMode<Item, Filter>>): JSX.Element {
  const { getMessage } = React.useContext(BaSeI18nContext);

  const width = React.useCallback(
    (id: keyof Item | string) =>
      columnConfig?.find(({ columnId: columnId }) => columnId === id)?.width,
    [columnConfig],
  );

  return (
    <DataBodyTable
      densityLevel={densityLevel}
      separatorColor={separatorColor}
      textColor={textColor}
      tableHeaderBackgroundColor={tableHeaderBackgroundColor}
    >
      <table {...getTableProps({ className: 'BaSe--data-table' })}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const sortClick = column?.getSortByToggleProps?.()?.onClick as (
                  event: React.MouseEvent<HTMLButtonElement>,
                ) => void;
                return (
                  column.isVisible && (
                    <th
                      {...column.getHeaderProps(
                        column?.getSortByToggleProps?.({
                          title: undefined,
                          onClick: undefined,
                          style: {
                            cursor: 'default',
                            width: width(column.id),
                          },
                        }),
                      )}
                    >
                      <DataTableHeader>
                        <BaSeSmall1 color={foregroundColor}>
                          {column.render('Header')}
                        </BaSeSmall1>
                        {column.canSort &&
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <BaSeShapeButton
                                size="small"
                                sizeIcon="small"
                                type="tertiary"
                                nameIcon={sortingDescIcon}
                                color={highlightedColor}
                                tooltip={{
                                  size: 'small',
                                  direction: 'top',
                                  messageNoBreakLine: true,
                                  delayToOpen: 500,
                                  color: textColor,
                                  message: getMessage(
                                    'dataTable.sortHint.desc',
                                  ),
                                }}
                                onClick={sortClick}
                              />
                            ) : (
                              <BaSeShapeButton
                                size="small"
                                sizeIcon="small"
                                color={highlightedColor}
                                type="tertiary"
                                nameIcon={sortingAscIcon}
                                tooltip={{
                                  size: 'small',
                                  direction: 'top',
                                  messageNoBreakLine: true,
                                  delayToOpen: 500,
                                  color: textColor,
                                  message: getMessage('dataTable.sortHint.asc'),
                                }}
                                onClick={sortClick}
                              />
                            )
                          ) : (
                            <BaSeShapeButton
                              size="small"
                              sizeIcon="small"
                              type="tertiary"
                              nameIcon={sortIcon}
                              color={foregroundColor}
                              tooltip={{
                                size: 'small',
                                direction: 'top',
                                messageNoBreakLine: true,
                                delayToOpen: 500,
                                color: textColor,
                                message: getMessage('dataTable.sortHint'),
                              }}
                              onClick={sortClick}
                            />
                          ))}
                        {!!renderColumnFilter(column.id, columnConfig) && (
                          <>
                            <BaSeShapeButton
                              key={column.id}
                              size="small"
                              sizeIcon="small"
                              nameIcon="filter"
                              type="tertiary"
                              color={
                                !!columnFilterVisible?.[column.id as string]
                                  ? highlightedColor
                                  : foregroundColor
                              }
                              tooltip={{
                                size: 'small',
                                direction: 'top',
                                messageNoBreakLine: true,
                                delayToOpen: 500,
                                color: textColor,
                                message: getMessage(
                                  !!columnFilterVisible?.[column.id as string]
                                    ? 'dataFilter.closeFilter'
                                    : 'dataFilter.openFilter',
                                ),
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setColumnFilterVisible((actual) => ({
                                  ...actual,
                                  [column.id]: !actual?.[column.id],
                                }));
                              }}
                              {...columnFilterIconProps(
                                column.id,
                                columnConfig,
                              )}
                            />
                            {!!columnFilterVisible?.[column.id as string] && (
                              <DataTableColumnFilter
                                separatorColor={separatorColor}
                                backgroundColor={backgroundColor}
                                renderColumnFilter={renderColumnFilter}
                                column={column}
                                columnConfig={columnConfig}
                                setFilter={setFilter}
                                setTags={setTags}
                                resetSelection={resetSelection}
                                setColumnFilterVisible={setColumnFilterVisible}
                              />
                            )}
                          </>
                        )}
                      </DataTableHeader>
                    </th>
                  )
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(page ?? rows).map((row) => {
            prepareRow(row);
            return (
              <BaSeDataRowInfoProvider key={row.id}>
                <BaSeDataSelectionConsumer>
                  {({
                    isAllSelected,
                    selectedItems,
                  }: DataSelectionContextInterface<Item>) => (
                    <tr
                      {...row.getRowProps({
                        style: {
                          backgroundColor: rowIsSelected({
                            itemKey: idAccessorKey as keyof Item,
                            item: row.original,
                            isAllSelected,
                            selectedItems,
                          })
                            ? transformLightness(highlightedColor, 0.96)
                            : undefined,
                        },
                      })}
                    >
                      {row.cells.map((cell) => (
                        // eslint-disable-next-line react/jsx-key
                        <td {...cell.getCellProps()}>
                          <DataTableCell>{cell.render('Cell')}</DataTableCell>
                        </td>
                      ))}
                    </tr>
                  )}
                </BaSeDataSelectionConsumer>
              </BaSeDataRowInfoProvider>
            );
          })}
        </tbody>
      </table>
    </DataBodyTable>
  );
}
